import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  constructor(private router: Router, private idle: Idle, private keepalive: Keepalive,) {
    idle.setIdle(environment.idle);
    idle.setTimeout(5);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.reset();
      this.router.navigate(['/login']);
    });

    keepalive.interval(30);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.onRouteReload();
      }
    });
  }
  onRouteReload() {
    // Add any logic here that you want to execute on each click, e.g., reloading data
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
