<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Express UPI Service</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Express UPI</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row" *ngIf="!isLoggedIn && !isOTPNeeded && !aadharKYCNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Authorize</h3>
          </div>
          <div class="card-body">
            <form autocomplete="off" [formGroup]="customerVerificationForm" (ngSubmit)="verification()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number <strong class="text-danger">*</strong></label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerVerificationForm.get('mobileNumber').invalid && (customerVerificationForm.get('mobileNumber').dirty || customerVerificationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerVerificationForm.get('mobileNumber').errors.required">Mobile number is required.
                  </div>
                  <div *ngIf="customerVerificationForm.get('mobileNumber').errors.pattern">Please enter a valid Indian
                    mobile
                    number.</div>
                </div>
              </div>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="verification()">
                Continue
              </pf-button>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isOTPNeeded && !isLoggedIn && !aadharKYCNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Register</h3>
          </div>
          <div class="card-body">

            <form autocomplete="off" [formGroup]="customerRegistrationForm" (ngSubmit)="register()">
              <div class="form-group">
                <label for="number">Mobile Number</label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerRegistrationForm.get('mobileNumber').invalid && (customerRegistrationForm.get('mobileNumber').dirty || customerRegistrationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('mobileNumber').errors.required">Mobile number is required.
                  </div>
                  <div *ngIf="customerRegistrationForm.get('mobileNumber').errors.pattern">Please enter a valid Indian
                    mobile number.</div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Your name</label>
                <input formControlName="name" type="text" class="form-control" id="name"
                  placeholder="Please enter your your name.">
                <div
                  *ngIf="customerRegistrationForm.get('name').invalid && (customerRegistrationForm.get('name').dirty || customerRegistrationForm.get('name').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('name').errors.required">Your name is required.
                  </div>
                  <div *ngIf="customerRegistrationForm.get('name').errors.pattern">Please enter a valid name.</div>
                </div>
              </div>
              <div class="form-group">
                <label for="otp">OTP</label>
                <input formControlName="otp" type="text" class="form-control" id="otp"
                  placeholder="Please enter the otp recieved.">
                <div
                  *ngIf="customerRegistrationForm.get('otp').invalid && (customerRegistrationForm.get('otp').dirty || customerRegistrationForm.get('otp').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('otp').errors.required">OTP is required.</div>
                  <div *ngIf="customerRegistrationForm.get('otp').errors.pattern">Please enter a valid OTP.</div>
                </div>
              </div>
              <button type="submit" class="d-none"></button>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="register()">
                Continue
              </pf-button>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="aadharKYCNeeded && !aadharVerificationNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Register</h3>
          </div>
          <div class="card-body">

            <form autocomplete="off" [formGroup]="customerAadharKYCForm" (ngSubmit)="aadharKYC()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number</label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerAadharKYCForm.get('mobileNumber').invalid && (customerAadharKYCForm.get('mobileNumber').dirty || customerAadharKYCForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerAadharKYCForm.get('mobileNumber').errors.required">Mobile number is required.
                  </div>
                  <div *ngIf="customerAadharKYCForm.get('mobileNumber').errors.pattern">Please enter a valid Indian
                    mobile number.</div>
                </div>
              </div>
              <div class="form-group">
                <label for="aadharnumber">Aadhar Number</label>
                <input formControlName="aadharnumber" type="text" class="form-control" id="aadharnumber"
                  placeholder="Please enter your your name.">
                <div
                  *ngIf="customerAadharKYCForm.get('aadharnumber').invalid && (customerAadharKYCForm.get('aadharnumber').dirty || customerAadharKYCForm.get('aadharnumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerAadharKYCForm.get('aadharnumber').errors.required">Your aadharnumber is required.
                  </div>
                  <div *ngIf="customerAadharKYCForm.get('aadharnumber').errors.pattern">Please enter a valid
                    aadharnumber.</div>
                </div>
              </div>
              <button type="submit" class="d-none"></button>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="aadharKYC()">
                Continue
              </pf-button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="aadharVerificationNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Register</h3>
          </div>
          <div class="card-body">

            <form autocomplete="off" [formGroup]="customerAadharVerificationForm" (ngSubmit)="aadharKYCVerification()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number</label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerAadharVerificationForm.get('mobileNumber').invalid && (customerAadharVerificationForm.get('mobileNumber').dirty || customerAadharVerificationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerAadharVerificationForm.get('mobileNumber').errors.required">Mobile number is
                    required.
                  </div>
                  <div *ngIf="customerAadharVerificationForm.get('mobileNumber').errors.pattern">Please enter a valid
                    Indian
                    mobile number.</div>
                </div>
              </div>
              <div class="form-group">
                <label for="otp">OTP</label>
                <input formControlName="otp" type="text" class="form-control" id="otp"
                  placeholder="Please enter the otp recieved.">
                <div
                  *ngIf="customerAadharVerificationForm.get('otp').invalid && (customerAadharVerificationForm.get('otp').dirty || customerAadharVerificationForm.get('otp').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerAadharVerificationForm.get('otp').errors.required">OTP is required.</div>
                  <div *ngIf="customerAadharVerificationForm.get('otp').errors.pattern">Please enter a valid OTP.</div>
                </div>
              </div>
              <button type="submit" class="d-none"></button>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="aadharKYCVerification()">
                Continue
              </pf-button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isLoggedIn && !isOTPNeeded && !aadharKYCNeeded && !aadharVerificationNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">
              <small><strong>Hi, {{remitter.name}}</strong></small><br />
              <small><strong>{{remitter.number}}</strong></small><br />
              <small><strong>Reamaining Limit - {{remitter.limit.remaining}}</strong></small>
            </div>
            <div class="card-tools mt-3">
              <pf-button [variant]="'danger'" [loading]="false" (click)="isLoggedIn = false">Change Remitter</pf-button>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="accountForm">

              <div class="form-group">
                <label for="benId">Beneficiary ID</label>
                <input formControlName="benId" type="text" class="form-control" id="benId"
                  placeholder="Please enter the benId recieved.">
                <div
                  *ngIf="accountForm.get('benId').invalid && (accountForm.get('benId').dirty || accountForm.get('benId').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="accountForm.get('benId').errors.required">Beneficiary ID is required.</div>
                  <div *ngIf="accountForm.get('benId').errors.pattern">Please enter a valid Beneficiary ID.</div>
                </div>
              </div>

              <div class="form-group">
                <label for="amount">Amount</label>
                <input type="text" class="form-control" prefix="₹" mask="00 || 000 || 0,000 || 00,000 || 0,00,000"
                  thousandSeparator="," [min]="0" [max]="100000" id="amount" formControlName="amount">
                <small>{{ accountForm?.value?.amount| numToWords }}</small>

                <div
                  *ngIf="accountForm.get('amount').invalid && (accountForm.get('amount').dirty || accountForm.get('amount').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="accountForm.get('amount').errors.required">Amount is required.</div>
                  <div *ngIf="accountForm.get('amount').errors.min">Amount must be at least ₹0.</div>
                  <div *ngIf="accountForm.get('amount').errors.max">Amount cannot exceed ₹1,00,000.
                  </div>
                </div>
              </div>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="transfer()">Continue</pf-button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mt-2">Beneficiary List</h3>
            <div class="card-tools">
              <pf-button [variant]="'info'" [block]="true" [loading]="isLoading" (click)="addBeneficiaryModal.show()">
                Add Beneficiary
              </pf-button>
            </div>
          </div>
          <div class="card-body">
            <div class="input-group mb-2">
              <input type="text" class="form-control" placeholder="Search beneficiaries..." [(ngModel)]="searchQuery">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-search"></i></span>
              </div>
            </div>

            <div class="list-group">
              <div *ngFor="let beneficiary of filteredBeneficiaries"
                class="list-group-item d-flex justify-content-between">
                <div>
                  <h5 class="mb-1">{{beneficiary.ben_name}}</h5>
                  <small class="mb-1">Mobile Number: {{beneficiary.ben_number || beneficiary.ben__number}}</small>
                  <br />
                  <small class="mb-1">UPI: {{beneficiary.upi}}</small>
                </div>
                <div id="btnGroup" class="d-flex justify-content-between align-items-center gap-2">
                  <pf-button [variant]="'success'" [loading]="false" (click)="details(beneficiary)">
                    <i class="fas fa-money-check" title="Transfer"></i>
                  </pf-button>
                  <pf-button [variant]="'danger'" [loading]="false" (click)="delete(beneficiary)">
                    <i class="fas fa-trash-alt" title="Delete"></i>
                  </pf-button>
                </div>
              </div>

              <div *ngIf="filteredBeneficiaries.length === 0 && beneficiaries.length !== 0"
                class="list-group-item d-flex justify-content-between">
                <h3 class="mb-0">No matching beneficiaries found.</h3>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Popup -->
<div #modal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Beneficiary</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="addBeneficiaryModal.hide ()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="beneficiaryForm" (ngSubmit)="addBeneficiary()">
          <div class="form-group">
            <label for="name">Name <strong class="text-danger">*</strong></label>
            <input type="text" class="form-control" formControlName="name" id="name"
              placeholder="Please enter name name."
              [ngClass]="{'is-invalid': beneficiaryForm.get('name').invalid && (beneficiaryForm.get('name').dirty || beneficiaryForm.get('name').touched)}">
            <div
              *ngIf="beneficiaryForm.get('name').invalid && (beneficiaryForm.get('name').dirty || beneficiaryForm.get('name').touched)"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="beneficiaryForm.get('name').errors.required">Beneficiary name is required.</div>
              <div *ngIf="beneficiaryForm.get('name').errors.maxLength">Beneficiary name must be less than 255
                characters.</div>
            </div>
          </div>

          <div class="form-group">
            <label for="beneficiaryMobileNumber">Mobile Number <strong class="text-danger">*</strong></label>
            <input type="text" class="form-control" formControlName="beneficiaryMobileNumber"
              id="beneficiaryMobileNumber" placeholder="Please enter beneficiary mobile number.">
            <div
              *ngIf="beneficiaryForm.get('beneficiaryMobileNumber').invalid && (beneficiaryForm.get('beneficiaryMobileNumber').dirty || beneficiaryForm.get('beneficiaryMobileNumber').touched)"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="beneficiaryForm.get('beneficiaryMobileNumber').errors.required">
                Beneficiary mobile number is
                required.
              </div>
              <div *ngIf="beneficiaryForm.get('beneficiaryMobileNumber').errors.pattern">Please
                enter a valid
                beneficiary mobile
                number.</div>
            </div>
          </div>

          <div class="form-group">
            <label for="upi">UPI address <strong class="text-danger">*</strong></label>
            <input type="text" class="form-control" formControlName="upi" id="upi"
              placeholder="Please enter UPI address.">
            <div
              *ngIf="beneficiaryForm.get('upi').invalid && (beneficiaryForm.get('upi').dirty || beneficiaryForm.get('upi').touched)"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="beneficiaryForm.get('upi').errors.required">UPI is required.</div>
              <div *ngIf="beneficiaryForm.get('upi').errors.pattern">Please enter a valid UPI.</div>
            </div>
          </div>

          <button type="submit" class="d-none"></button>
          <pf-button [variant]="'primary'" [loading]="isLoading" (click)="addBeneficiary()">
            Save
          </pf-button>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- TPinmodal -->
<div #TPinmodal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add TPin</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="resetTpinForm(); tPinModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="accountForm" (ngSubmit)="transfer()">

          <div class="form-group">
            <label for="tPin">TPin <strong class="text-danger">*</strong></label>
            <input type="password" class="form-control" formControlName="tpin" id="tpin"
              placeholder="Please enter TPin."
              [ngClass]="{'is-invalid': accountForm.get('tpin').invalid && (accountForm.get('tpin').dirty || accountForm.get('tpin').touched)}">
            <div
              *ngIf="accountForm.get('tpin').invalid && (accountForm.get('tpin').dirty || accountForm.get('tpin').touched)"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="accountForm.get('tpin').errors.required">TPin is required.</div>
              <div *ngIf="accountForm.get('tpin').errors.pattern">Please enter a valid TPin code.</div>
            </div>
          </div>

          <button type="submit" class="d-none"></button>
          <button type="submit" class="btn btn-primary" [disabled]="isLoading">Save</button>
        </form>
      </div>
    </div>
  </div>
</div>