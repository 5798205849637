<div class="content-header">
   <div class="container-fluid">
      <div class="row mb-2">
         <div class="col-sm-6">
            <h1 class="m-0 text-dark">Add User</h1>
         </div>
         <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
               <li class="breadcrumb-item"><a href="#">Home</a></li>
               <li class="breadcrumb-item active">Add User</li>
            </ol>
         </div>
      </div>
   </div>
</div>
<section class="content">
   <div class="container-fluid pb-4">
      <div class="row">
         <form (submit)="submitForm()" [formGroup]="userForm">
            <!-- Card 1: Basic Settings -->
            <div class="card mb-3">
               <div class="card-header">Basic Settings</div>
               <div class="card-body">
                  <div class="form-group">
                     <label for="member_type">Member Type</label>
                     <select class="form-control" id="member_type" formControlName="member_type"
                        [ngClass]="{ 'is-invalid': userForm.get('member_type').invalid && (userForm.get('member_type').dirty || userForm.get('member_type').touched) }">
                        <option value="" selected>Select Type</option>
                        <option *ngFor="let role of roles | keyvalue" [value]="role.key">{{ role.value }}</option>
                     </select>
                     <div
                        *ngIf="userForm.get('member_type').invalid && (userForm.get('member_type').dirty || userForm.get('member_type').touched)"
                        class="text-danger text-sm font-weight-bold">Member Type is required.</div>
                  </div>
               </div>
            </div>
            <!-- Card 2: Member Information -->
            <div class="card mb-3">
               <div class="card-header">Member Information</div>
               <div class="card-body">
                  <div class="row">
                     <div class="form-group col-md-4">
                        <label for="first_name">First Name <strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your first name." type="text" class="form-control"
                           id="first_name" formControlName="first_name"
                           [ngClass]="{ 'is-invalid': userForm.get('first_name').invalid && (userForm.get('first_name').dirty || userForm.get('first_name').touched) }">
                        <div
                           *ngIf="userForm.get('first_name').invalid && (userForm.get('first_name').dirty || userForm.get('first_name').touched)"
                           class="text-danger text-sm font-weight-bold">First Name is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="last_name">Last Name <strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your last name." type="text" class="form-control"
                           id="last_name" formControlName="last_name"
                           [ngClass]="{ 'is-invalid': userForm.get('last_name').invalid && (userForm.get('last_name').dirty || userForm.get('last_name').touched) }">
                        <div
                           *ngIf="userForm.get('last_name').invalid && (userForm.get('last_name').dirty || userForm.get('last_name').touched)"
                           class="text-danger text-sm font-weight-bold">Last Name is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="gender">Gender <strong class="text-danger">*</strong></label>
                        <select class="form-control" id="gender" formControlName="gender"
                           [ngClass]="{ 'is-invalid': userForm.get('gender').invalid && (userForm.get('gender').dirty || userForm.get('gender').touched) }">
                           <option value="" disabled selected>Select Gender</option>
                           <option value="M">Male</option>
                           <option value="F">Female</option>
                        </select>
                        <div
                           *ngIf="userForm.get('gender').invalid && (userForm.get('gender').dirty || userForm.get('gender').touched)"
                           class="text-danger text-sm font-weight-bold">Gender is required.</div>
                     </div>
                  </div>

                  <div class="row">
                     <div class="form-group col-md-4">
                        <label for="dob">Date of Birth <strong class="text-danger">*</strong></label>
                        <input type="date" class="form-control" id="dob" formControlName="dob"
                           [ngClass]="{ 'is-invalid': userForm.get('dob').invalid && (userForm.get('dob').dirty || userForm.get('dob').touched) }">
                        <div
                           *ngIf="userForm.get('dob').invalid && (userForm.get('dob').dirty || userForm.get('dob').touched)"
                           class="text-danger text-sm font-weight-bold">Date of Birth is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="aadhar">Aadhar No. <strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your Aadhar." type="text" class="form-control" id="aadhar"
                           formControlName="aadhar"
                           [ngClass]="{ 'is-invalid': userForm.get('aadhar').invalid && (userForm.get('aadhar').dirty || userForm.get('aadhar').touched) }">
                        <div
                           *ngIf="userForm.get('aadhar').invalid && (userForm.get('aadhar').dirty || userForm.get('aadhar').touched)"
                           class="text-danger text-sm font-weight-bold">Aadhar No. is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="pan">PAN No. <strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your PAN." type="text" class="form-control" id="pan"
                           formControlName="pan"
                           [ngClass]="{ 'is-invalid': userForm.get('pan').invalid && (userForm.get('pan').dirty || userForm.get('pan').touched) }">
                        <div
                           *ngIf="userForm.get('pan').invalid && (userForm.get('pan').dirty || userForm.get('pan').touched)"
                           class="text-danger text-sm font-weight-bold">PAN No. is required.</div>
                     </div>
                  </div>

                  <div class="form-group">
                     <label for="address">Full Address <strong class="text-danger">*</strong></label>
                     <textarea placeholder="Please Enter your Address." class="form-control" id="address"
                        formControlName="address" rows="3"
                        [ngClass]="{ 'is-invalid': userForm.get('address').invalid && (userForm.get('address').dirty || userForm.get('address').touched) }">
                   </textarea>
                     <div
                        *ngIf="userForm.get('address').invalid && (userForm.get('address').dirty || userForm.get('address').touched)"
                        class="text-danger text-sm font-weight-bold">Address is required.</div>
                  </div>

                  <div class="row">
                     <div class="form-group col-md-4 ">
                        <label for="state_id">State</label>
                        <select class="form-control" id="state_id" formControlName="state_id"
                           (change)="stateChanged($event)"
                           [ngClass]="{ 'is-invalid': userForm.get('state_id').invalid && (userForm.get('state_id').dirty || userForm.get('state_id').touched) }">
                           <option value="">Select State</option>
                           <option *ngFor="let state of states" [value]="state.id">{{ state.name }}
                           </option>
                        </select>
                        <div
                           *ngIf="userForm.get('state_id').invalid && (userForm.get('state_id').dirty || userForm.get('state_id').touched)"
                           class="text-danger text-sm font-weight-bold">State is required.</div>
                     </div>
                     <div class="form-group col-md-4 ">
                        <label for="city_id">City</label>
                        <select class="form-control" id="city_id" formControlName="city_id"
                           [ngClass]="{ 'is-invalid': userForm.get('city_id').invalid && (userForm.get('city_id').dirty || userForm.get('city_id').touched) }">
                           <option value="">Select City</option>
                           <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}
                           </option>
                        </select>
                        <div
                           *ngIf="userForm.get('city_id').invalid && (userForm.get('city_id').dirty || userForm.get('city_id').touched)"
                           class="text-danger text-sm font-weight-bold">City is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="pincode">Pincode <strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your Pincode." type="text" class="form-control" id="pincode"
                           formControlName="pincode"
                           [ngClass]="{ 'is-invalid': userForm.get('pincode').invalid && (userForm.get('pincode').dirty || userForm.get('pincode').touched) }">
                        <div
                           *ngIf="userForm.get('pincode').invalid && (userForm.get('pincode').dirty || userForm.get('pincode').touched)"
                           class="text-danger text-sm font-weight-bold">Pincode is required.</div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- Card 3: Business Information -->
            <div class="card mb-3">
               <div class="card-header">Business Information</div>
               <div class="card-body">
                  <div class="row">
                     <div class="form-group col-md-6">
                        <label for="shop_name">Shop Name</label>
                        <input placeholder="Please Enter your Shop Name." type="text" class="form-control"
                           id="shop_name" formControlName="shop_name"
                           [ngClass]="{ 'is-invalid': userForm.get('shop_name').invalid && (userForm.get('shop_name').dirty || userForm.get('shop_name').touched) }">
                        <div
                           *ngIf="userForm.get('shop_name').invalid && (userForm.get('shop_name').dirty || userForm.get('shop_name').touched)"
                           class="text-danger text-sm font-weight-bold">Shop name is required.</div>
                     </div>
                     <div class="form-group col-md-6">
                        <label for="shop_address">Shop Address</label>
                        <input placeholder="Please Enter your Shop Address." type="text" class="form-control"
                           id="shop_address" formControlName="shop_address"
                           [ngClass]="{ 'is-invalid': userForm.get('shop_address').invalid && (userForm.get('shop_address').dirty || userForm.get('shop_address').touched) }">
                        <div
                           *ngIf="userForm.get('shop_address').invalid && (userForm.get('shop_address').dirty || userForm.get('shop_address').touched)"
                           class="text-danger text-sm font-weight-bold">Shop address is required.</div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="form-group col-md-6">
                        <label for="gst">GST No.</label>
                        <input placeholder="Please Enter your GST No." type="text" class="form-control" id="gst"
                           formControlName="gst"
                           [ngClass]="{ 'is-invalid': userForm.get('gst').invalid && (userForm.get('gst').dirty || userForm.get('gst').touched) }">
                        <div
                           *ngIf="userForm.get('gst').invalid && (userForm.get('gst').dirty || userForm.get('gst').touched)"
                           class="text-danger text-sm font-weight-bold">GST No. is required.</div>
                     </div>
                     <div class="form-group col-md-6">
                        <label for="business_pan">Business PAN No.</label>
                        <input placeholder="Please Enter your Business Pan." type="text" class="form-control"
                           id="business_pan" formControlName="business_pan"
                           [ngClass]="{ 'is-invalid': userForm.get('business_pan').invalid && (userForm.get('business_pan').dirty || userForm.get('business_pan').touched) }">
                        <div
                           *ngIf="userForm.get('business_pan').invalid && (userForm.get('business_pan').dirty || userForm.get('business_pan').touched)"
                           class="text-danger text-sm font-weight-bold">Business Pan is required.</div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- Card 4: Contact Information -->
            <div class="card mb-3">
               <div class="card-header">Contact Information</div>
               <div class="card-body">
                  <div class="row">
                     <div class="form-group col-md-4">
                        <label for="email">Email<strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your email." type="email" class="form-control" id="email"
                           formControlName="email"
                           [ngClass]="{ 'is-invalid': userForm.get('email').invalid && (userForm.get('email').dirty || userForm.get('email').touched) }">
                        <div
                           *ngIf="userForm.get('email').invalid && (userForm.get('email').dirty || userForm.get('email').touched)"
                           class="text-danger text-sm font-weight-bold">Email is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="mobile">Mobile<strong class="text-danger">*</strong></label>
                        <input placeholder="Please Enter your mobile." type="text" class="form-control" id="mobile"
                           formControlName="mobile"
                           [ngClass]="{ 'is-invalid': userForm.get('mobile').invalid && (userForm.get('mobile').dirty || userForm.get('mobile').touched) }">
                        <div
                           *ngIf="userForm.get('mobile').invalid && (userForm.get('mobile').dirty || userForm.get('mobile').touched)"
                           class="text-danger text-sm font-weight-bold">Mobile is required.</div>
                     </div>
                     <div class="form-group col-md-4">
                        <label for="phone">Phone No.</label>
                        <input placeholder="Please Enter your Phone no." type="text" class="form-control" id="phone"
                           formControlName="phone">
                     </div>
                  </div>
               </div>
            </div>
            <!-- Card 5: Other Information -->
            <div class="card mb-3">
               <div class="card-header">Other Information</div>
               <div class="card-body">
                  <div class="form-group">
                     <label for="profile_image">Profile Image<strong class="text-danger">*</strong></label>
                     <input type="file" class="form-control" id="profile_image" formControlName="profile_image"
                        (change)="onProfilePhotoChange($event)"
                        [ngClass]="{ 'is-invalid': userForm.get('profile_image').invalid && (userForm.get('profile_image').dirty || userForm.get('profile_image').touched) }">
                     <div
                        *ngIf="userForm.get('profile_image').invalid && (userForm.get('profile_image').dirty || userForm.get('profile_image').touched)"
                        class="text-danger text-sm font-weight-bold">Profile Image is required.</div>
                  </div>
               </div>
            </div>
            <pf-button [variant]="'primary'" [loading]="isLoading" (click)="submitForm()">
               Save
            </pf-button>
         </form>
      </div>
   </div>
</section>