export const PERMISSIONS = {
    "TOSHANI_DMT_LITE": "toshani-dmt",
    "TOSHANI_DMT_EXPRESS": "toshani-xdmt",
    "SAFEXPAY_PAYOUT": "safexpay-payout",
    "TOSHANI_UPI_LITE": "toshani-upi",
    "TOSHANI_UPI_EXPRESS": "toshani-xupi",
    "TOSHANI_AEPS": "toshani-aeps",
    // "AVENUE_BBPS": "avenue-bbps",
    "AVENUE_BBPS":"bbps-bill-pay",
    "RECHARGE": "recharge",
    "AVENUE_DMT": "avenue-dmt",
    "PG_transaction": "pg_transaction",
    "FUND_TRANS":"canara-obdx",
    "FUND_TRANS_AePS":"fundtransfer-aeps",
    "FUND_TRANS_PG":"fundtransfer-pg"
}

export const ROLES = {
    "SUBSIDIARY": 'Subsidiary Alliance',
    "DISTRIBUTOR": 'Distributor',
    "RETAILER": 'Retailer',
}
