<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>DMT 1 Service</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">DMT 1</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div #modal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Beneficiary</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="addBeneficiaryModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
      </div>
    </div>
  </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Authorize</h3>
                    </div>
                    <div class="card-body">

                        <form autocomplete="off" [formGroup]="customerVerificationForm" (ngSubmit)="verification()">
                            <div class="form-group">
                                <label for="mobileNumber">Mobile Number <strong class="text-danger">*</strong></label>
                                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                                    placeholder="Please enter your mobile number.">
                                <div *ngIf="customerVerificationForm.get('mobileNumber').invalid && (customerVerificationForm.get('mobileNumber').dirty || customerVerificationForm.get('mobileNumber').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerVerificationForm.get('mobileNumber').errors.required">Mobile
                                        number is required.
                                    </div>
                                    <div *ngIf="customerVerificationForm.get('mobileNumber').errors.pattern">Please
                                        enter a valid Indian
                                        mobile number.</div>
                                </div>
                            </div>
                            <pf-button [variant]="'primary'" [loading]="isLoading" (click)="verification()">
                                Continue
                            </pf-button>
                        </form>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Register</h3>
                    </div>
                    <div class="card-body">

                        <form autocomplete="off" [formGroup]="customerRegistrationForm" (ngSubmit)="register()">
                            <div class="form-group">
                                <label for="mobile">Mobile Number</label>
                                <input formControlName="mobile" type="text" class="form-control" id="mobile"
                                    placeholder="Please enter your mobile number.">
                                <div *ngIf="customerRegistrationForm.get('mobile').invalid && (customerRegistrationForm.get('mobile').dirty || customerRegistrationForm.get('mobile').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('mobile').errors.required">Mobile number is
                                        required.</div>
                                    <div *ngIf="customerRegistrationForm.get('mobile').errors.pattern">Please enter a
                                        valid mobile number.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="firstname">First Name</label>
                                <input formControlName="firstname" type="text" class="form-control" id="firstname"
                                    placeholder="Please enter your first name.">
                                <div *ngIf="customerRegistrationForm.get('firstname').invalid && (customerRegistrationForm.get('firstname').dirty || customerRegistrationForm.get('firstname').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('firstname').errors.required">First name is
                                        required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="lastname">Last Name</label>
                                <input formControlName="lastname" type="text" class="form-control" id="lastname"
                                    placeholder="Please enter your last name.">
                                <div *ngIf="customerRegistrationForm.get('lastname').invalid && (customerRegistrationForm.get('lastname').dirty || customerRegistrationForm.get('lastname').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('lastname').errors.required">Last name is
                                        required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input formControlName="address" type="text" class="form-control" id="address"
                                    placeholder="Please enter your address.">
                                <div *ngIf="customerRegistrationForm.get('address').invalid && (customerRegistrationForm.get('address').dirty || customerRegistrationForm.get('address').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('address').errors.required">Address is
                                        required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="otp">OTP</label>
                                <input formControlName="otp" type="text" class="form-control" id="otp"
                                    placeholder="Please enter the OTP received.">
                                <div *ngIf="customerRegistrationForm.get('otp').invalid && (customerRegistrationForm.get('otp').dirty || customerRegistrationForm.get('otp').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('otp').errors.required">OTP is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="pincode">Pincode</label>
                                <input formControlName="pincode" type="text" class="form-control" id="pincode"
                                    placeholder="Please enter your pincode.">
                                <div *ngIf="customerRegistrationForm.get('pincode').invalid && (customerRegistrationForm.get('pincode').dirty || customerRegistrationForm.get('pincode').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('pincode').errors.required">Pincode is
                                        required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="dob">Date of Birth</label>
                                <input formControlName="dob" type="text" class="form-control" id="dob"
                                    placeholder="Please enter your date of birth.">
                                <div *ngIf="customerRegistrationForm.get('dob').invalid && (customerRegistrationForm.get('dob').dirty || customerRegistrationForm.get('dob').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('dob').errors.required">Date of Birth is
                                        required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="gst_state">GST State</label>
                                <input formControlName="gst_state" type="text" class="form-control" id="gst_state"
                                    placeholder="Please enter your GST State.">
                                <div *ngIf="customerRegistrationForm.get('gst_state').invalid && (customerRegistrationForm.get('gst_state').dirty || customerRegistrationForm.get('gst_state').touched)"
                                    class="text-danger text-sm font-weight-bold">
                                    <div *ngIf="customerRegistrationForm.get('gst_state').errors.required">GST State is
                                        required.</div>
                                </div>
                            </div>
                            <button type="submit" class="d-none"></button>
                            <pf-button [variant]="'primary'" [loading]="isLoading" (click)="register()">
                                Continue
                            </pf-button>
                        </form>

                    </div>
                </div>
            </div>
        </div>

        <div class="row" (ngSubmit)="transfer()">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  <div class="card-title mb-0">
                    <small><strong>Hi, Jane</strong></small><br />
                    <small><strong>789</strong></small><br />
                    <small><strong>Reamaining Limit - 10</strong></small>
                    <!-- <small><strong>Hi, {{remitter.name}}</strong></small><br />
                    <small><strong>{{remitter.number}}</strong></small><br />
                    <small><strong>Reamaining Limit - {{remitter.limit.remaining}}</strong></small> -->
                  </div>
                  <div class="card-tools mt-3">
                    <pf-button [variant]="'danger'" [loading]="false" (click)="isLoggedIn = false">Change Remitter</pf-button>
                  </div>
                </div>
                <div class="card-body">
                  <form [formGroup]="accountForm">
                    <div class="form-group">
                      <label for="accountNumber" class="form-label">Account Number</label>
                      <input type="text" class="form-control" id="accountNumber" formControlName="accountNumber">
                    </div>
                    <div class="form-group">
                      <label for="bankName" class="form-label">Bank Name</label>
                      <input type="text" class="form-control" id="bankName" formControlName="bankName">
                    </div>
                    <div class="form-group">
                      <label for="ifsc" class="form-label">IFSC</label>
                      <input type="text" class="form-control" id="ifsc" formControlName="ifsc">
                    </div>
                    <div class="form-group">
                      <label for="amount" class="form-label">Amount</label>
                      <input type="number" class="form-control" min="0" max="25000" id="amount" formControlName="amount">
                    </div>
                    <div class="form-group">
                      <label for="state" class="form-label">State</label>
                      <select class="form-control" id="state" formControlName="state" (change)="stateChanged($event)">
                        <option value="" selected>Please choose a state</option>
                        <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="city" class="form-label">City</label>
                      <select class="form-control" id="city" formControlName="city">
                        <option value="" selected>Please choose a state</option>
                        <option *ngFor="let city of cities" [value]="city.id">{{city.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="address" class="form-label">Address</label>
                      <textarea type="text" class="form-control" id="address" formControlName="address"></textarea>
                    </div>
                    <div class="form-group">
                      <label for="pinCode" class="form-label">Pin Code</label>
                      <input type="text" class="form-control" id="pinCode" formControlName="pinCode">
                    </div>
                    <pf-button [variant]="'primary'" [loading]="isLoading" (click)="transfer()">Continue</pf-button>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title mt-2">Beneficiary List</h3>
                  <div class="card-tools">
                    <pf-button [variant]="'info'" [block]="true" [loading]="isLoading" (click)="addBeneficiaryModal.show()">
                      Add Beneficiary
                    </pf-button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="list-group">
                    <div *ngFor="let beneficiary of beneficiaries" class="list-group-item d-flex justify-content-between">
                      <div>
                        <h5 class="mb-1">{{beneficiary.ben_name}}</h5>
                        <small class="mb-1">A/c: {{beneficiary.ben_account_no}}</small> <br />
                        <small class="mb-1">IFSC: {{beneficiary.ben_ifsc_code}}</small>
                      </div>
                      <div class="d-flex justify-content-between align-items-center gap-2">
                        <pf-button [variant]="'success'" [loading]="false" (click)="details(beneficiary)">Transfer</pf-button>
                        <pf-button [variant]="'danger'" [loading]="false" (click)="delete(beneficiary)">Delete</pf-button>
                      </div>
                    </div>
      
                    <!-- <div *ngIf="!beneficiaries.length" class="list-group-item d-flex justify-content-between">
                      <h3 class="mb-0">No beneficiary found.</h3>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

    </div>
</section>