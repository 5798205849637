import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DataService } from '@services/data.service';
import { ROLES } from '@/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class FinancialServiceGuard {

  constructor(private data: DataService) { }

  canActivate = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
    try {
      const { data } = await firstValueFrom(this.data.getProfile());
      return data.roles.includes(ROLES.RETAILER)
    } catch (error) {
      return false;
    }
  }
}
