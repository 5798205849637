<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1> PG Reports</h1>

            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">BBPS Report</li>
                </ol> -->
            </div>
        </div>
    </div>
</section>

<section class="d-print-none content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body table-responsive" style="overflow: auto;
                    width: 100%;
                    position: relative;">
                        <table id="bbps-table" datatable [dtOptions]="dtOptions" class="table row-border hover" width="100%" scrollX="true"></table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-------------Txn Status Modal-->
<div class="modal fade" id="txnStatus" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color:#f1f1f1">
        <div class="col-md-12 p-0">
          <h4 class="modal-title" style="text-align: center;"> PG Transaction Status </h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="details">
            <p> Transaction Status : <b *ngIf="txnStatusResponse?.status == 'SUCCESS'" class="text-success">{{txnStatusResponse?.status}}</b>
              <b *ngIf="txnStatusResponse?.status == 'PENDING'" style="color: orange;">{{txnStatusResponse?.status}}</b>
              <b *ngIf="txnStatusResponse?.status == 'FAILED'" class="text-danger">{{txnStatusResponse?.status}}</b> </p>
            <p> Bank Name: <b>{{txnStatusResponse?.bankName}}</b> </p>
            <p> Amount : <b>{{txnStatusResponse?.amount}}</b> </p>
            <p> Payment Mode : <b>{{txnStatusResponse?.paymentMode}}</b> </p>
            <p> Message : <b>{{txnStatusResponse?.sabpaisaMessage}}</b> </p>
          </div>
        </div>
      </div>

      <div class="modal-footer border-top text-right">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onClosePopup()">Close</button>
      </div>
    </div>
  </div>
</div>
<app-pg-receipt [row]="row"></app-pg-receipt>

<!-------------Txn Enquiry Modal-->
<div class="modal fade" id="txnEnquiry" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color:#f1f1f1">
        <div class="col-md-12 p-0">
          <h4 class="modal-title" style="text-align: center;"> PG Transaction Status </h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="details">
            <p> Transaction Status : <b> SUCCESS </b> </p>
          </div>
        </div>
      </div>

      <div class="modal-footer border-top text-right">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onClosePopup()">Close</button>
      </div>
    </div>
  </div>
</div>
