<div id="mainReceipt">
    <div>
        <img
            src="./assets/img/logo.png"
            alt="Company Logo"
            class="logo align-center"
            width="40%"
        />
    </div>
    <h2 class="mt-2">Fund Transfer Receipt</h2>
    <hr />
    <table *ngIf="row">
        <tr>
            <td style="width: 50%">
                <div class="info">
                    <strong>Receiver's Name:</strong> {{ row.beneficiary_name }}
                </div>
                <div class="info">
                    <strong>Bank Name:</strong> {{ row.benef_bank_name }}
                </div>
                <div class="info">
                    <strong>Account Number:</strong>
                    {{ row.destination_acct_number }}
                </div>
                <div class="info">
                    <strong>IFSC Code:</strong> {{ row.ifsc_code }}
                </div>
                <div class="info">
                    <strong>Transaction Type:</strong>
                    {{ row.transaction_type }}
                </div>
                <div class="info">
                    <strong>Total Amount in Words:</strong>
                    {{ extractPriceAsInt(row.txn_amount) | numToWords }}
                </div>
            </td>
            <td style="width: 50%; float: right" align="left">
                <div class="info" style="text-transform: capitalize">
                    <strong>Retailer's Name:</strong> {{ this.first_name }}
                    {{ this.last_name }}
                </div>
                <!-- <div class="info"><strong>Account Number:</strong> {{ row.destination_acct_number }}</div> -->
                <div class="info">
                    <strong>Member Id:</strong> {{ this.member_id }}
                </div>
                <div class="info">
                    <strong>Mobile Number:</strong> {{ this.mobile }}
                </div>
                <div class="info"><strong>Email:</strong> {{ this.email }}</div>
            </td>
        </tr>
        <tr class="mt-0">
            <td colspan="2">
                <table class="innerTable">
                    <tr>
                        <th
                            *ngIf="
                                row.transaction_type === 'NEFT' ||
                                row.transaction_type === 'RTGS'
                            "
                        >
                            UTR
                        </th>
                        <th
                            *ngIf="
                                row.transaction_type === 'IMPS' ||
                                row.transaction_type === 'INTERNAL TRANSFER'
                            "
                        >
                            RRN Number
                        </th>
                        <th>User ref id</th>
                        <th>Amount</th>
                        <th>Transaction Date</th>
                        <th>Status</th>
                    </tr>
                    <tr>
                        <td
                            *ngIf="
                                row.transaction_type === 'NEFT' ||
                                row.transaction_type === 'RTGS'
                            "
                        >
                            {{ row.utr }}
                        </td>
                        <td
                            *ngIf="
                                row.transaction_type === 'IMPS' ||
                                row.transaction_type === 'INTERNAL TRANSFER'
                            "
                        >
                            {{ row.transaction_ref_no }}
                        </td>

                        <td>{{ row.user_ref_no }}</td>
                        <td>{{ extractPrice(row.txn_amount) }}</td>
                        <td>{{ row.transaction_date }}</td>
                        <td>{{ row.status }}</td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr>
            <td colspan="2">
                <hr />
                <p style="text-align: center">
                    <strong>Note:</strong>
                    This is a computer-generated receipt and does not require
                    any physical signature
                </p>
            </td>
        </tr>
    </table>
</div>
