import { BankDetails, CustomerData, DMTResponse } from '@/models/toshani';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { DataService } from '@services/data.service';
import { switchMap, tap, catchError, of } from 'rxjs';
import { Bank, State, City, Profile } from '@/models/common';
import { isAllowed } from '@/utils/helpers';
import { Router } from '@angular/router';
import { PERMISSIONS } from '@/utils/constants';
import { PennyDropService } from '@services/toshani/penny-drop.service';

@Component({
  selector: 'app-dmt',
  templateUrl: './dmt.component.html',
  styleUrl: './dmt.component.scss'
})
export class DmtComponent implements OnInit {

  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public mobileNumber: number;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public accountForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public remitter: CustomerData;
  public beneficiaries: BankDetails[];
  public beneficiary: BankDetails;
  public addBeneficiaryModal: Modal;
  public banks: Bank[];
  public states: State[];
  public cities: City[];
  public min: any;
  public max: any;
  searchQuery: string = '';
  showDropdown: boolean = false;
  filteredBanks: Bank[] = [];
  public tPinModal: Modal;

  constructor(private router: Router, private penny: PennyDropService, private formBuilder: FormBuilder, private apiService: ApiService, private alert: ToastrService, private data: DataService) { }

  ngOnInit(): void {

    this.data.getBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.TOSHANI_DMT_LITE, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.apiService.getMinMax('dmt', 'toshani').subscribe({
      next: (response: any) => {
        this.min = response?.data?.min_start_range;
        this.max = response?.data?.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      accountId: [{ value: '', disabled: true }, Validators.required],
      accountName: [{ value: '', disabled: true }, Validators.required],
      accountNumber: [{ value: '', disabled: true }, Validators.required],
      bankName: [{ value: '', disabled: true }, Validators.required],
      ifsc: [{ value: '', disabled: true }, Validators.required],
      amount: ['', [Validators.required, Validators.min(this.min), Validators.max(this.max)]],
      tpin: ['']
    });

    this.beneficiaryForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      accountNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{9,18}$/)]],
      bankCode: ['', [Validators.required, Validators.maxLength(255)]],
      ifscCode: ['', [Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)]],
      beneficiaryMobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  }

  register() {
    if (this.customerRegistrationForm.valid) {
      this.isLoading = true;

      this.apiService.setCustomerInfo(this.customerRegistrationForm.getRawValue()).subscribe({
        next: (response: DMTResponse) => {
          if (!response.status) {
            this.alert.error(response.message)
            return
          }

          this.isLoggedIn = true;
          this.isLoading = false;
          this.remitter = response.data
          this.beneficiaries = response.data.beneficiaries

          this.alert.success(response.message);
        },
        error: (error) => {
          this.isLoading = false;
        }
      });
    } else {
      this.customerRegistrationForm.markAsDirty()
    }
  }

  verification() {
    if (this.customerVerificationForm.valid) {
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;
      this.isLoading = true;

      this.apiService.getCustomerInfo(mobileNumber).subscribe({
        next: (response: DMTResponse) => {
          this.isLoading = false;
          if (!response.status) {
            this.alert.error(response.message)
            return
          }

          if (!response.data.isVerified) {
            this.customerRegistrationForm.patchValue({
              mobileNumber: mobileNumber
            })

            this.isOTPNeeded = true;
            return
          }

          this.isLoggedIn = true;
          this.remitter = response.data
          this.beneficiaries = response.data.beneficiaries

          this.beneficiaryForm.patchValue({
            mobileNumber: mobileNumber
          })
        },
        error: (response) => {
          this.isLoading = false;
        }
      });
    }
  }

  details(beneficiary: BankDetails) {
    this.beneficiary = beneficiary;
    this.accountForm.patchValue({
      accountNumber: beneficiary.ben_account_no,
      bankName: beneficiary.bank_name,
      ifsc: beneficiary.ben_ifsc_code,
      accountId: beneficiary.ben_name,
      accountName: beneficiary.ben_id,
      amount: '',
      tpin: ''
    });
  }

  delete(beneficiary: BankDetails) {
    if (confirm('Are you sure you want to delete')) {
      this.apiService.removeBeneficiary({
        mobileNumber: this.customerVerificationForm.value.mobileNumber,
        accountName: beneficiary.ben_id
      }).subscribe({
        next: (response: any) => {
          this.alert.success(response.message)
          this.verification()
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      })
    }
  }

  confirmTransfer() {
    if( this.accountForm.value.amount == 0 || !this.accountForm.valid ){
      this.alert.error('Please select beneficiary and fill required fields');
      return;  
  }
 
    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.accountForm.get('tpin')?.setValidators(Validators.required);
      this.accountForm.get('tpin')?.updateValueAndValidity(); 
      this.tPinModal.show();
    }
  }

  transfer() {
    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    const data = {
      ...this.accountForm.getRawValue(),
      mobileNumber: this.customerVerificationForm.value.mobileNumber
    };

    this.isLoading = true;
    this.apiService.transfer(data).subscribe({
      next: (response: any) => {
        this.alert.success(response.message);
        this.accountForm.reset();
        this.accountForm.markAsPristine();
        this.accountForm.markAsUntouched();
        this.tPinModal.hide();
        this.verification();
        this.isLoading = false;
        this.resetTpinForm();

        // window.open(response.download, '_blank');
      },
      error: (error) => {
        this.tPinModal.hide();
        this.isLoading = false;
        this.resetTpinForm();
      }
    });
  }


  addBeneficiary() {

    if (!this.beneficiaryForm.valid) {
      for (const control in this.beneficiaryForm.controls) {
        if (this.beneficiaryForm.controls.hasOwnProperty(control)) {
          this.beneficiaryForm.controls[control].markAsTouched();
        }
      }
      return;
    }

    this.isLoading = true;

    this.apiService.setBeneficiary(this.beneficiaryForm.getRawValue()).subscribe({
      next: (response: any) => {
        if (response.result == 0) {
          this.alert.error(response.message);
          return;
        }
        this.fetchBeneficiaries();
        this.alert.success(response.message);
      },
      error: ({ error }) => {

      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  fetchBeneficiaries() {
    this.apiService.getBeneficiaries(this.beneficiaryForm.getRawValue().mobileNumber).subscribe({
      next: (response: any) => {
        this.beneficiaries = response.data || [];
        console.log('Beneficiaries fetched successfully');
      },
      error: ({ error }) => {
        this.beneficiaries = [];
      },
      complete: () => {
        this.resetForm();
      }
    });
  }

  stateChanged(event: any) {
    this.data.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
  }

  get filteredBeneficiaries() {
    return this.beneficiaries.filter(beneficiary =>
      beneficiary.ben_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  verifyAccountNumber() {
    if (!this.checkValidation()) {
      this.beneficiaryForm.get('ifscCode').markAsTouched();
      this.beneficiaryForm.get('accountNumber').markAsTouched();
      return;
    }

    this.penny.isValid({
      "accountNumber": this.beneficiaryForm.value.accountNumber,
      "ifsc": this.beneficiaryForm.value.ifscCode,
    }).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.beneficiaryForm.patchValue({
          name: response.data.registered_name,
          ifscCode: response.data.ifsc,
          accountNumber: response.data.account_number,
        });

        this.beneficiaryForm.get('ifscCode').disable();
        this.beneficiaryForm.get('accountNumber').disable();
        this.beneficiaryForm.get('name').disable();

        this.alert.success(response.message);
      },
      error: ({ error }) => {
        this.isLoading = false;
      }
    });
  }


  filterBanks(value: string) {
    if (value.trim() === '') {
      this.filteredBanks = this.banks;
    } else {
      this.filteredBanks = this.banks.filter(bank => bank.name.toLowerCase().includes(value.toLowerCase()));
    }
  }


  selectBank(bank: any) {
    this.beneficiaryForm.patchValue({
      bankCode: bank.code
    });
    this.filteredBanks = [];
  }

  handleServerErrors(errors: any) {
    Object.keys(errors).forEach(key => {
      this.alert.error(errors[key][0], key, {
        timeOut: 10000
      });
    });
  }

  checkValidation() {
    const step = ["ifscCode", "accountNumber"];

    const form = this.beneficiaryForm;

    let allControlsValid = true;
    for (let i = 0; i < step.length; i++) {
      const control = step[i];
      const formControl = form.get(control);
      if (formControl && !formControl.valid && formControl.status != 'DISABLED') {
        console.log(formControl);
        allControlsValid = false;
        formControl.markAsTouched();
      }
    }

    if (allControlsValid) {
      return true;
    }
    return false;
  }

  resetForm() {

    this.beneficiaryForm.patchValue({
      accountNumber: '',
      bankCode: '',
      beneficiaryMobileNumber: '',
      ifscCode: '',
      name: ''
    })
    this.beneficiaryForm.markAsPristine();
    this.beneficiaryForm.markAsUntouched();

    this.beneficiaryForm.get('ifscCode').enable();
    this.beneficiaryForm.get('accountNumber').enable();
    this.beneficiaryForm.get('name').enable();

    this.addBeneficiaryModal.hide();
  }

  resetTpinForm() {
    const tpinControl = this.accountForm.get('tpin');
    if (tpinControl) {
      tpinControl.setValue('');
      tpinControl.markAsUntouched();
      tpinControl.setErrors(null);
    }
    this.accountForm.patchValue({
      accountId: '',
      accountName: '',
      bankName: '',
      ifsc: '',
      amount: '',
      accountNumber:''
     
  });
    this.beneficiaryForm.markAsPristine();
    this.beneficiaryForm.markAsUntouched();
    this.accountForm.markAsPristine();
    this.accountForm.markAsUntouched();
  }

}
