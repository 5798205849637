import { Component, ViewChild } from '@angular/core';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;

  constructor(
    private dataTableService: DataTableService,
  ) {
    this.dataTableService.url = 'user/list';
    this.dataTableService.export_name = 'MY_USER_LIST';
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "first_name", "title": "First Name" },
      { "data": "last_name", "title": "Last Name" },
      { "data": "email", "title": "Email" },
      { "data": "mobile", "title": "Mobile" },
      { "data": "member_type", "title": "Type" },
      { "data": "gender", "title": "Gender" },
      { "data": "status", "title": "Status" },
      { "data": "is_kyc", "title": "KYC Status" },
      { "data": "created_at", "title": "Added At" },
    ], function (settings, json) {
      $("#users-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="9">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY HH:mm', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate, picker.endDate])).draw();;
      });
    });
  }
}