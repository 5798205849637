<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Fund Request History</h1>
      </div>
      <div class="col-sm-6">
        <!-- <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
          <li class="breadcrumb-item active">EWallet</li>
        </ol> -->
      </div>
    </div>
  </div>
</section>
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <!-- <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
              <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Member ID</span>
                <span class="info-box-number">
                  {{ewallet.member_id}}
                 
                </span>
              </div>

            </div>

          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-up"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Debit Balance</span>
                <span class="info-box-number">{{ewallet.debit}}</span>
              </div>

            </div>

          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Credit Balance</span>
                <span class="info-box-number">{{ewallet.credit}}</span>
              </div>

            </div>

          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">Wallet Balance</span>
                <span class="info-box-number">{{ewallet.balance}}</span>
              </div>

            </div>

          </div>

        </div> -->
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body table-responsive">
            <table id="wallet-ledger" datatable [dtOptions]="dtOptions" class="table row-border hover"></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
