<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Two Factor Authentication</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Two Factor Authentication</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="alert alert-warning" role="alert" *ngIf="!formShow">
            <strong>{{verificationMessage}}</strong>
        </div>

        <div class="card" *ngIf="formShow">
            <div class="card-body">
                <form>
                    <div class="form-group">
                        <label for="deviceType">Select Device</label>
                        <select class="form-control" id="deviceType" name="deviceType" [(ngModel)]="selectedDeviceType" (change)="onDeviceTypeChange()">
                            <option value="">Please choose device</option>
                            <option value="mantra">Mantra</option>
                            <option value="morpho">Morpho</option>
                        </select>
                    </div>

                    <div *ngIf="selectedDeviceType">
                        <pf-button [variant]="'primary'" [disabled]="!biometric.biometricMachine.hasAvdm" [loading]="isLoading" (click)="authenticate()">
                            Authenticate
                        </pf-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>