<h3 class="text-center mt-3">Terms and Conditions</h3>

<div class="px-5 bg-grey">
<img src="assets/img/logo.png" width="20%" class="align-center"/>

    <p class="mt-3 text-left"><b style="font-size: 18px;">Welcome to Shri Dariyav Doorstep Services Pvt Ltd!</b>
       <br/> We, Shri Dariyav Doorstep Services Pvt Ltd, the franchisor, operate and facilitate services under the brand “SDDS.”
        <br/>
        Thank you for using and appreciating our products and services ("Services"). The services are provided by the franchisor, located at Badri Vishal Nagar, Bikaner, Rajasthan – 334001, India.
        <br/>
        By starting to use any of our services, you are presumed to have accepted the terms and conditions related to those services. Therefore, it is important that you read all relevant documents carefully before proceeding. Your use of our services signifies your agreement to these terms and conditions.
        <br/>
        When you access or use the services offered by the franchisor through our website or mobile application, you agree to be governed by the following terms and conditions ("Terms"). These terms are incorporated by reference and will take effect upon your acceptance of the services through our platform. They govern the relationship between you ("You", "Your", "User") and the franchisor ("Us", "We", "Our").
        </p>

        <h5>ACCEPTANCE OF TERMS</h5>
        <p>By accepting the terms and conditions, you acknowledge and agree that the Franchisor will consider your use of services, such as prepaid mobile/DTH recharge services, bill payment services, money transfer services, online marketplace services, or any additional services that may be introduced in the future (collectively referred to as "Service" or "Services"), on any of our websites, mobile platforms, networks, and mobile applications (collectively referred to as "Related Applications").</p>

        <p>Your use of these services is subject to your compliance with the terms and conditions, including relevant policies. The Franchisor reserves the right, at its sole discretion, to modify, add, or remove portions of these terms and conditions at any time without prior notice. You are encouraged to periodically revisit the "Terms & Conditions" page on our website at <a href="https://sddspl.com/member/terms-and-condition">https://sddspl.com/member/terms-and-condition</a> to stay informed of any changes made.</p>

        <p>If you do not agree with the terms and conditions of any policy, please refrain from using or accessing any of the Franchisor's websites, WAP sites, mobile applications, or the Be Smart Citizen application. Alternatively, you may contact us at <a>info&#64;sddsspl&#64;gmail.com </a>for any amendments or suggestions.</p>

        <p>We make no express or implied warranties regarding the site, its related applications, services, or any linked websites or applications. We expressly disclaim any implied warranties of non-infringement, merchantability, and fitness for a particular purpose. The site, related applications, and any services or technology on the site are provided "as is," "where is," and "with all faults." We do not guarantee that the site, related applications, or any products and services described will be uninterrupted, error-free, available, or operational at any given time, nor do we promise that any known defects will be corrected. We disclaim any responsibility for outdated information that may not have been updated since the last modification. We reserve the right to change and correct any part of this agreement or the website at any time without notice.</p>

        <p>You expressly acknowledge and agree that your use of the services is at your sole discretion, and the services are provided "as is" and "as available." Specifically, Franchisor/Be Smart Citizen Wallet, its subsidiaries, affiliates, and licensors do not represent or warrant that the services will meet your requirements. You recognize that the Franchisor acts as a facilitator for mobile recharges and other services and is not responsible for any third-party products or services offered on the site or Be Smart Citizen applications, including rates, quality, or any other aspects. By using the services, you agree that the Franchisor is not liable for any discrepancies in third-party products or services. It is your responsibility to evaluate the accuracy, completeness, and usefulness of all opinions, advice, services, merchandise, and information provided through the site or the internet.</p>

        <p>Any material downloaded or obtained through the use of the services is done at your own risk, and you agree that you will be solely responsible for any damage to your computer system or other devices, or for any loss of data that results from downloading such material. No advice or information, whether oral or written, obtained by you from the Franchisor or through the services, will create any warranty not expressly stated in the terms. The Franchisor further disclaims all warranties and conditions of any kind, whether expressed or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

        <p>You agree to indemnify, defend, and hold harmless the Franchisor and/or related parties from any and all claims, losses, damages, liabilities, costs, and expenses, including but not limited to legal fees and expenses, arising out of or related to your use or misuse of the Franchisor's services, any violation of these terms, or any breach of the representations, warranties, and covenants made by you.</p>

</div>