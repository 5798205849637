import { Profile } from '@/models/common';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bbps',
  templateUrl: './bbps.component.html',
  styleUrl: './bbps.component.scss'
})
export class BbpsComponent {

  constructor(
    private router: Router,
    private data: DataService,
    private alert: ToastrService
  ) { }
  isActiveTabName='';
  ngOnInit() {
    this.onActiveTab('prepaid');
    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.AVENUE_BBPS, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });
  }
  onActiveTab(value:any){
    this.isActiveTabName=value;
  }
}
