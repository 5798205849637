<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Prime Pay Reports</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Prime DMT Report</li>
                </ol> -->
            </div>
        </div>
    </div>
</section>

<section class="d-print-none content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body table-responsive">
                        <table id="prime-dmt-table" datatable [dtOptions]="dtOptions" class="table dataTable no-footer">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-dmt-prime-receipt [row]="row"></app-dmt-prime-receipt>