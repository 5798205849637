import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addLatLong, addHeaders } from '@services/api.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlusService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  customer(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/dmt-plus/customer`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  public register(body: any) {
    return this.http.post(`${this.apiUrl}/dmt-plus/register`, {
      ...body, ...addLatLong()
    }, addHeaders());
  }

  verification(body: { mobileNumber: number; otp: string; code: string }) {
    return this.http.post(`${this.apiUrl}/dmt-plus/verify-otp`, {
      ...body, ...addLatLong()
    }, addHeaders());
  }

  resend(data: any) {
    return this.http.post(`${this.apiUrl}/dmt-plus/resend-otp`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  transfer(data: any) {
    return this.http.post(`${this.apiUrl}/dmt-plus/transfer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  beneficiaries(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/dmt-plus/beneficiaries`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  getBeneficiary(recipientId: any, mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/dmt-plus/beneficiaries/${recipientId}`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  addBeneficiaries(data: any) {
    return this.http.post(`${this.apiUrl}/dmt-plus/beneficiaries/store`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  deleteBeneficiary(recipientId: any, mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/dmt-plus/beneficiaries/${recipientId}/delete`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  banks(mobileNumber: any) {
    return this.http.post(`${this.apiUrl}/dmt-plus/banks`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  ifscCode(code: any) {
    return this.http.get(`${this.apiUrl}/dmt-plus/ifsc?code=${code}`, addHeaders());
  }

  isValid(data: any) {
    return this.http.post(`${this.apiUrl}/dmt-plus/account-verification`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  refund(data: { transaction_id: string }) {
    return this.http.post(`${this.apiUrl}/dmt-plus/update`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  refundVerification(data: { otp: number, transaction_id: string, unique_reference_id: string }) {
    return this.http.post(`${this.apiUrl}/dmt-plus/refund`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  pending() {
    return this.http.get(`${this.apiUrl}/reports/dmt-plus/pending-refund`, addHeaders());
  }
}
