import { Component,ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-cbx',
  templateUrl: './cbx.component.html',
  styleUrl: './cbx.component.scss'
})
export class CbxComponent {
    public isLoading: boolean = false;
    public isLoggedIn: boolean = false;
    public isOTPNeeded: boolean = false;
    customerVerificationForm: FormGroup;
    public customerRegistrationForm: FormGroup;

    constructor(
     
      private formBuilder: FormBuilder,
    
  ) {}

    ngOnInit():void {
      
      this.customerVerificationForm = this.formBuilder.group({
          mobileNumber: [
              '',
              [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]
          ]
      });
  }
  
  verification() {
    const form = this.customerVerificationForm;
    if (!form.valid) {
        // this.alert.error('Invalid Form');
        return;
    }

    const mobileNumber = form.value.mobileNumber;
    this.isLoading = true;
   
}
register() {
  const form = this.customerRegistrationForm;
  if (!form.valid) {
      // this.alert.error('Invalid Form');
      return;
  }

  this.isLoading = true;


}

}
