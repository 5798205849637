import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { addLatLong, addHeaders } from '@services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AepsService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) { }

  banks(data: any) {
    return this.http.post(`${this.apiUrl}/aeps/banks`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  authentication(data: any) {
    return this.http.post(`${this.apiUrl}/aeps/authentication`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  daily_authentication(data: any) {
    return this.http.post(`${this.apiUrl}/aeps/daily_authentication`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  onboarding(data: any) {
    return this.http.post(`${this.apiUrl}/aeps/onboarding`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  verification() {
    return this.http.get(`${this.apiUrl}/aeps/onboarding/verification`, addHeaders());
  }

  transaction(data: any) {
    return this.http.post(`${this.apiUrl}/aeps/transaction`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  transfer(data: { type?: string, amount: any }) {
    const { type = 'wallet', amount } = data;
    const apiEndpoint = type === 'bank' ? 'aeps-wallet/transfer/bank' : 'aeps-wallet/transfer/wallet';

    return this.http.post(`${this.apiUrl}/${apiEndpoint}`, {
      ...data,
      ...addLatLong()
    }, addHeaders());
  }

}
