import { Bank, City, Profile, State } from '@/models/common';
import { BankDetails } from '@/models/toshani';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { ExpressService } from '@services/toshani/upi/express.service';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap, catchError, of } from 'rxjs';
import { Modal } from 'bootstrap';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-xupi',
  templateUrl: './xupi.component.html',
  styleUrl: './xupi.component.scss'
})

export class XUpiComponent {
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public customerAadharKYCForm: FormGroup;
  public customerAadharVerificationForm: FormGroup;
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public remitter: any;
  public min: any;
  public max: any;
  public aadharVerificationNeeded: boolean = false;
  public aadharKYCNeeded: boolean = false;
  public beneficiaryForm: FormGroup;
  public beneficiary: BankDetails;
  public addBeneficiaryModal: any;
  public beneficiaries: any;
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  public accountForm: FormGroup;
  public banks: Bank[];
  public states: State[];
  public cities: City[];
  searchQuery: string = '';
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public tPinModal: Modal;
  
  constructor(private router: Router, private formBuilder: FormBuilder, private apiService: ApiService, private api: ExpressService, private alert: ToastrService, private data: DataService) { }

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.TOSHANI_UPI_EXPRESS, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      amount: ['', [Validators.required, Validators.min(0), Validators.max(100000)]],
      benId: [{ value: '', disabled: true }, Validators.required],
      tpin: ['', Validators.required]
    });

    this.apiService.getMinMax('xupi', 'toshani').subscribe({
      next: (response: any) => {
        this.min = response.data.min_start_range;
        this.max = response.data.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.customerAadharKYCForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      aadharnumber: ['', Validators.required]
    });

    this.customerAadharVerificationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{5}$/)]],
    });

    this.beneficiaryForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9]\\d{9}$')]],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      upi: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\b$/)]],
      beneficiaryMobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.data.getBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });
  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
  }

  verification() {
    if (this.customerVerificationForm.valid) {
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;
      this.isLoading = true;

      this.api.customer(mobileNumber).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (response.result == 2) {

            this.customerRegistrationForm.patchValue({ mobileNumber })

            this.isOTPNeeded = true;
            return
          }

          if (response.result == 1 && response.data.kyc_status == 0) {

            this.customerAadharKYCForm.patchValue({
              mobileNumber: mobileNumber
            })

            this.aadharKYCNeeded = true
            return
          }

          if (response.result == 1 && response.data.kyc_status == 1 && response.data.is_verified == 1) {
            this.isOTPNeeded = this.aadharKYCNeeded = this.aadharVerificationNeeded = false
            this.isLoggedIn = true;
            this.remitter = {
              "name": response.data.cust_name,
              "number": response.data.mobileNumber,
              "limit": {
                "remaining": response.data.remaining_limit
              }
            }

            this.beneficiaries = response.data?.beni_list || []


            this.beneficiaryForm.patchValue({
              mobileNumber: mobileNumber
            })
          }
        },
        error: (error) => {
          console.error(error)
          this.isLoading = false;
        }
      });
    }

  }

  register() {
    if (this.customerRegistrationForm.valid) {
      this.isLoading = true;

      this.api.verification(this.customerRegistrationForm.getRawValue()).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (response.status == 0) {
            this.alert.error(response.message)
            return
          }

          this.verification()
          this.isLoggedIn = true;
        },
        error: (error) => {
          this.isLoading = false;
        }
      });
    } else {
      this.customerRegistrationForm.markAsDirty()
    }
  }

  aadharKYC() {
    this.isLoading = true;
    this.api.kyc(this.customerAadharKYCForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.result == "1") {
          const mobileNumber = this.customerVerificationForm.value.mobileNumber;

          this.customerAadharVerificationForm.patchValue({
            mobileNumber: mobileNumber
          })

          this.aadharVerificationNeeded = true;
          return
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  aadharKYCVerification() {
    this.isLoading = true;
    this.api.kycVerification(this.customerAadharVerificationForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.result == 1) {
          this.verification()
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  confirmTransfer() {
    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.tPinModal.show();
    }
  }


  transfer() {

    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    this.isLoading = true;
    this.api.transfer({ mobileNumber: this.customerVerificationForm.value.mobileNumber, ...this.accountForm.getRawValue() }).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (!response.status) {
          this.alert.error(response.message)
          return
        }

        this.accountForm.reset()
        this.alert.success(response.message)
      },
      error: ({ error }) => {
        if (error.message) {
          this.alert.error(error.message);
        }
        this.isLoading = false;
      }
    });
  }

  details(beneficiary: any) {
    this.beneficiary = beneficiary;
    this.accountForm.patchValue({
      number: this.remitter.number,
      benId: beneficiary.ben_id,
      amount: '',
      tpin: ''
    });
  }

  addBeneficiary() {
    if (!this.beneficiaryForm.valid) {
      for (const control in this.beneficiaryForm.controls) {
        if (this.beneficiaryForm.controls.hasOwnProperty(control)) {
          this.beneficiaryForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    this.isLoading = true;

    this.api.beneficiary(this.beneficiaryForm.value).pipe(
      switchMap((response: any) => {
        return this.api.beneficiaries(this.beneficiaryForm.value.mobileNumber).pipe(
          tap((response: { status: boolean; message: string; data?: BankDetails[] }) => {
            this.beneficiaries = response.data;
          }),
          catchError(error => {
            console.error('Error fetching beneficiaries:', error);
            return of([]);
          })
        );
      }),
      catchError(error => {
        console.error('Error setting beneficiary:', error);
        return of([]);
      })
    ).subscribe({
      complete: () => {
        this.beneficiaryForm.reset();
        this.isLoading = false;
        this.addBeneficiaryModal.hide()
      }
    });
  }

  delete(beneficiary: BankDetails) {
    if (confirm('Are you sure you want to delete')) {
      this.api.delete({
        mobileNumber: this.customerVerificationForm.value.mobileNumber,
        ben_id: beneficiary.ben_id
      }).subscribe({
        next: (response: any) => {
          this.alert.success(response.message)
          this.verification()
          this.isLoading = false;
        },
        error: ({ error }) => {
          this.alert.error(error.message)
          this.isLoading = false;
        }
      })
    }
  }

  get filteredBeneficiaries() {
    return this.beneficiaries.filter(beneficiary =>
      beneficiary.ben_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  resetTpinForm(){
    const tpinControl = this.accountForm.get('tpin');
      if (tpinControl) {
        tpinControl.setValue(''); 
        tpinControl.markAsUntouched(); 
        tpinControl.setErrors(null); 
      }
  }
}
