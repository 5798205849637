import { Component,Input } from '@angular/core';

interface RowData{
  id: number
  payerNamed: string
  payerMobile:string
  paymentMode:string
  amount:string
  sabpaisaTxnId:string
  bankTxnId:string
  transDate:string
  bankMessage:string
  status:string
  created_at: string
  
}
@Component({
  selector: 'app-pg-receipt',
  templateUrl: './pg-receipt.component.html',
  styleUrl: './pg-receipt.component.scss'
})
export class PgReceiptComponent {
  @Input() row: RowData;

  extractPrice(priceHtml:any) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = priceHtml;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  extractPriceAsInt(priceHtml: string): number {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = priceHtml;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const numericValue = parseInt(textContent.replace(/[^0-9]/g, ''), 10);
    return isNaN(numericValue) ? 0 : numericValue;
  }
}
