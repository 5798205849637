import { Component, ElementRef, ViewChild } from '@angular/core';
import { PlusService } from '@services/avenue/plus.service';
import { Modal, Tooltip } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dmt-lite-plus-report-pending-refund',
  templateUrl: './dmt-lite-plus-report-pending-refund.component.html',
  styleUrl: './dmt-lite-plus-report-pending-refund.component.scss'
})
export class DmtLitePlusReportPendingRefundComponent {
  transactions: PendingTransaction[] = [];
  transaction: PendingTransaction;
  isLoading: boolean = false;
  otp: number;

  @ViewChild('modal', { static: false })
  modal: ElementRef<HTMLDivElement>;
  refundModal: Modal;

  constructor(private api: PlusService, private alert: ToastrService) { }

  ngOnInit(): void {
    this.getTransactions()
  }

  ngAfterViewInit() {
    this.refundModal = new Modal(this.modal.nativeElement, {})
  }

  getTransactions() {
    this.api.pending().subscribe({
      next: (response: PendingTransaction[]) => {
        this.transactions = response;
        setTimeout(() => {
          const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
          tooltipTriggerList.forEach(tooltipTriggerEl => {
            new Tooltip(tooltipTriggerEl);
          });
        }, 1000);
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  status(transaction: PendingTransaction) {
    this.isLoading = true;
    this.transaction = transaction;
    this.api.refund({ transaction_id: transaction.transaction_id }).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (!response.status) {
          this.alert.error(response.message)
          return
        }
        this.refundModal.show()
      },
      error: () => {
        this.isLoading = false;
      },
    })
  }

  toggleDetails(transaction: PendingTransaction) {
    this.transactions.forEach((txn: any) => {
      if (txn !== transaction) {
        txn.showDetails = false;
      }
    });
    this.transaction = transaction
    transaction.showDetails = !transaction.showDetails;
  }

  refund() {
    this.isLoading = true;

    if (!this.otp) {
      this.alert.error('Please provide OTP before refund')
      return;
    }

    this.api.refundVerification({
      otp: this.otp,
      transaction_id: this.transaction.transaction_id,
      unique_reference_id: this.transaction.unique_reference_id
    }).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (!response.status) {
          this.alert.error(response.message)
          return
        }

        this.getTransactions()
        this.alert.success(response.message)
        this.transaction = {} as PendingTransaction
        this.refundModal.hide()
      },
      error: () => {
        this.isLoading = false;
      },
    })
  }
}

export interface PendingTransaction {
  bank_transaction_id: string
  unique_reference_id: string
  transaction_id: string
  unique_transaction_reference: string
  transaction_amount: string
  transaction_status: string
  transaction_status_description: string
  bank_account_number: string
  bank_code: string
  bank_name: string
  ifsc_code: string
  mobile_number: string
  recipient_name: string
  sender_mobile_number: string
  created_at: string
  showDetails: boolean
}
