import { Profile } from '@/models/common';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrl: './recharge.component.scss'
})
export class RechargeComponent {

  constructor(
    private router: Router,
    private data: DataService,
    private alert: ToastrService
  ) { }

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.RECHARGE, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });
  }
}