import { Component, Input } from '@angular/core';

interface RowData{
  created_at: string
  transaction_id: string
  bank_reference_number: string
  order_id: string
  bank_name: string
  aadhar_number: string
  transaction_type: string
  transaction_amount: number
  transaction_status: string
  mobile_number: string
  balance_amount: string
  id: number
}

@Component({
  selector: 'app-aeps-receipt',
  templateUrl: './aeps-receipt.component.html',
  styleUrl: './aeps-receipt.component.scss'
})
export class AepsReceiptComponent {
  @Input() row: RowData;
}
