import { Component, ViewChild } from '@angular/core';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-upi-express-report',
  templateUrl: './upi-express-report.component.html',
  styleUrl: './upi-express-report.component.scss'
})
export class UpiExpressReportComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;

  constructor(
    private dataTableService: DataTableService
  ) {
    this.dataTableService.url = 'reports/toshani/xupi';
    this.dataTableService.export_name = 'UPI_LITE_REPORT';
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "order_id", "title": "Order ID" },
      { "data": "transaction_id", "title": "Transaction ID" },
      { "data": "unique_transaction_reference", "title": "UTR" },
      { "data": "upi", "title": "Virtual Payment Address" },
      { "data": "amount", "title": "Transaction Amount" },
      { "data": "net_amount", "name": "net_amount", "title": "Net Amount"},
      { "data": "mobile_number", "title": "Contact Number" },
      { "data": "status", "title": "Status" },
      { "data": "created_at", "title": "Created At" }
    ], function (settings, json) {
      $("#upi-express-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="8">');
      $("#fromto").daterangepicker({
                timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY HH:mm', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate, picker.endDate])).draw();;
      });
    });
  }
}