import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  public changePassword: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private toast: ToastrService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.changePassword = new FormGroup({
      "current-password": new FormControl('', Validators.required),
      "new-password": new FormControl('', Validators.required),
      "confirm-password": new FormControl('', [Validators.required, this.confirmPasswordValidator.bind(this)])
    });
  }

  private confirmPasswordValidator(control: FormControl): { [s: string]: boolean } {
    if (!this.changePassword) {
      return { 'passwordsNotMatch': true };
    }
    if (control.value !== this.changePassword.get('new-password').value) {
      return { 'passwordsNotMatch': true };
    }
    return null;
  }

  change() {
    const data = this.changePassword.value

    if (data['new-password'] != data['confirm-password']) {
      this.changePassword.get('confirm-password').setErrors({
        passwordsNotMatch: true
      })
    }

    if (!this.changePassword.valid) {
      for (const control in this.changePassword.controls) {
        if (this.changePassword.controls.hasOwnProperty(control)) {
          this.changePassword.controls[control].markAsTouched();
        }
      }
      return;
    }

    this.api.changePassword({
      "old_password": data['current-password'],
      "password": data['new-password'],
      "password_confirmation": data['confirm-password']
    }).subscribe({
      next: (res: PasswordChangeRespone) => {
        this.toast.success(res.message);
        // Reset the form values
        this.changePassword.reset();

        // Additionally, you can reset the form's validation state as well
        this.changePassword.markAsPristine();
        this.changePassword.markAsUntouched();
      },
      error: ({ error }: { error: PasswordChangeRespone }) => {
        if (error.message) {
          this.toast.error(error.message);
        }
      },
    });
  }
}

export interface PasswordChangeRespone {
  status: boolean
  message: string
}
