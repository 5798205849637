<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Fund Request</h1>
      </div>
      <div class="col-sm-6 d-flex flex-column align-items-end">
        <!-- <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
          <li class="breadcrumb-item active">Fund Request</li>
        </ol> -->
        <small><strong>All <span style="color: red;">*</span> fields are mandatory.</strong></small>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Fund Request</div>
          </div>
          <div class="card-body">
            <form [formGroup]="fundTransferForm" (ngSubmit)="request()">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="fromBankName">From Bank Name:</label><span
                    class="text-danger font-weight-bold ml-2">*</span>
                  <ng-select formControlName="fromBankName" id="fromBankName"
                    [ngClass]="{'is-invalid': fundTransferForm.get('fromBankName').invalid && (fundTransferForm.get('fromBankName').dirty || fundTransferForm.get('fromBankName').touched)}">
                    <ng-option *ngFor="let bank of fromBanks" [value]="bank.name">{{bank.name}}</ng-option>
                  </ng-select>

                  <div
                    *ngIf="fundTransferForm.get('fromBankName').invalid && (fundTransferForm.get('fromBankName').touched || formSubmitted)"
                    class="text-danger"><strong>Please select a bank name.</strong></div>
                </div>

                <div class="form-group col-md-4">
                  <label for="toBankName">To Bank Name:</label><span class="text-danger font-weight-bold ml-2">*</span>
                  <select
                    [ngClass]="{ 'is-invalid': fundTransferForm.get('toBankName').invalid && (fundTransferForm.get('toBankName').touched || formSubmitted) }"
                    class="form-control" id="toBankName" formControlName="toBankName">
                    <option value="">Select To Bank Name</option>
                    <option *ngFor="let bank of banks" [value]="bank.id">{{bank.name}}</option>
                  </select>
                  <div
                    *ngIf="fundTransferForm.get('toBankName').invalid && (fundTransferForm.get('toBankName').touched || formSubmitted)"
                    class="text-danger"><strong>Please select a bank name.</strong></div>
                </div>

                <div class="form-group col-md-4">
                  <label id="paymentMode">Payment Mode:</label><span class="text-danger font-weight-bold ml-2">*</span>
                  <select
                    [ngClass]="{ 'is-invalid': fundTransferForm.get('paymentMode').invalid && (fundTransferForm.get('paymentMode').touched || formSubmitted) }"
                    class="form-control" id="paymentMode" formControlName="paymentMode">
                    <option selected="selected" value="">Select Payment Mode</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                    <!-- <option value="DD">Demand Draft(DD)</option> -->
                    <option value="IMPS">IMPS</option>
                    <option value="NEFT">NEFT</option>
                    <option value="RTGS">RTGS</option>
                    <option value="UPI">UPI</option>
                  </select>
                  <div
                    *ngIf="fundTransferForm.get('paymentMode').invalid && (fundTransferForm.get('paymentMode').touched || formSubmitted)"
                    class="text-danger"><strong>Please select a payment mode.</strong></div>

                </div>

              </div>

              <div class="form-row">

                <div class="form-group col-md-4">
                  <label>Payment Proof:</label>
                  <input type="file" accept="image/jpeg,image/png,application/pdf"  class="form-control" formControlName="paymentProof" (change)="paymentProff($event)"
                    [ngClass]="{ 'is-invalid': fundTransferForm.get('paymentProof').invalid && (fundTransferForm.get('paymentProof').touched || formSubmitted) }">
                  <div
                    *ngIf="fundTransferForm.get('paymentProof').invalid && (fundTransferForm.get('paymentProof').touched || formSubmitted)"
                    class="text-danger"><strong>Please upload payment proof.</strong></div>
                </div>

                <div class="form-group col-md-4">
                  <label>UTR: <span class="text-danger font-weight-bold ml-2">*</span></label>
                  <input type="text" class="form-control" formControlName="chequeOrDDNumber"
                    [ngClass]="{ 'is-invalid': fundTransferForm.get('chequeOrDDNumber').invalid && (fundTransferForm.get('chequeOrDDNumber').touched || formSubmitted) }">
                  <div
                    *ngIf="fundTransferForm.get('chequeOrDDNumber').invalid && (fundTransferForm.get('chequeOrDDNumber').touched || formSubmitted)"
                    class="text-danger"><strong>Please enter UTR.</strong></div>
                </div>

                <div class="form-group col-md-4">
                  <label>Fund Amount:<span class="text-danger font-weight-bold ml-2">*</span></label>
                  <input type="text" class="form-control" formControlName="amount" prefix="₹"
                    mask="0,000 || 00,000 || 0,00,000 || 00,00,000" thousandSeparator=","
                    [ngClass]="{ 'is-invalid': fundTransferForm.get('amount').invalid && (fundTransferForm.get('amount').touched || formSubmitted) }">
                  <small>{{ fundTransferForm?.value?.amount| numToWords }}</small>
                  <div
                    *ngIf="fundTransferForm.get('amount').invalid && (fundTransferForm.get('amount').touched || formSubmitted)"
                    class="text-danger"><strong>Please enter Fund Amount.</strong></div>


                </div>
              </div>

              <div class="form-group">
                <label>Remarks:<span class="text-danger font-weight-bold ml-2">*</span></label>
                <textarea class="form-control" formControlName="remarks"
                  [ngClass]="{ 'is-invalid': fundTransferForm.get('remarks').invalid && (fundTransferForm.get('remarks').touched || formSubmitted) }"></textarea>
                <div
                  *ngIf="fundTransferForm.get('remarks').invalid && (fundTransferForm.get('remarks').touched || formSubmitted)"
                  class="text-danger"><strong>Please enter Remarks.</strong></div>
              </div>

              <div class="text-right">
                <pf-button [variant]="'info'" [disabled]="isLoading" (click)="request()">
                  Submit
                </pf-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-tabs">
          <div class="card-header p-0">
            <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link" id="custom-tabs-one-home-tab" (click)="showTab('bank-details')" role="tab"
                  aria-controls="custom-tabs-one-home" [class.active]="activeTab === 'bank-details'">Bank Details</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="custom-tabs-one-profile-tab" (click)="showTab('qr-code')" role="tab"
                  aria-controls="custom-tabs-one-profile" [class.active]="activeTab === 'qr-code'">QR Code</a>
              </li>
            </ul>
          </div>
          <div class="card-body table-responsive">
            <div *ngIf="activeTab === 'bank-details'">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Member Name</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Branch Name</th>
                    <th scope="col">Account Type</th>
                    <th scope="col">Account Number</th>
                    <th scope="col">IFSC Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bank of banks">
                    <th scope="row">{{ bank.id }}</th>
                    <td>{{ bank.member_name }}</td>
                    <td>{{ bank.name }}</td>
                    <td>{{ bank.branch }}</td>
                    <td>{{ bank.account_type == 1 ? 'Saving Account' : 'Current Account'}}</td>
                    <td>{{ bank.account_no }}</td>
                    <td>{{ bank.ifsc }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="activeTab === 'qr-code'">
              <p>QR Code</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</section>