import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { addHeaders, addLatLong } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})

export class CanaraService {
    private apiUrl = environment.apiURL;

    constructor(private http: HttpClient) { }

    mobileVerify(data){
      return this.http.post(`${this.apiUrl}/financial-services/mobile-verify`, {
        ...data,
        ...addLatLong()
      }, addHeaders());
    }
    verifyOtp(data){
      return this.http.post(`${this.apiUrl}/financial-services/verification`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    resend(data){
      return this.http.post(`${this.apiUrl}/resend`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    AddBankDetails(data){
      return this.http.post(`${this.apiUrl}/users-bank-details/add_bank`, {
        ...data,
        ...addLatLong()
      }, addHeaders());
    }
    BankList(){
      return this.http.get(`${this.apiUrl}/users-bank-details/bank_list`, addHeaders());
    }
    remitterBankList(data){
      return this.http.post(`${this.apiUrl}/remitter-bank-details/remitter_bank_list`, {...data,...addLatLong()},addHeaders());

    }
    remitterAddBank(data){
      return this.http.post(`${this.apiUrl}/remitter-bank-details/add_bank`, {
        ...data,
        ...addLatLong()
      }, addHeaders());
    }
    deleteRemitterBenef(data){
      return this.http.post(`${this.apiUrl}/remitter-bank-details/delete_bank_account`,
        {
          ...data,
          ...addLatLong()
        }, addHeaders());
    }
    accountVerify(data){
      return this.http.post(`${this.apiUrl}/accountverification`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    deleteBenef(data){
      return this.http.post(`${this.apiUrl}/users-bank-details/delete`,{...data,...addLatLong()}, addHeaders());
    }
    neft_payment(data) {
      return this.http.post(`${this.apiUrl}/canara/payment-neft`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    rtgs_payment(data) {
      return this.http.post(`${this.apiUrl}/canara/payment-rtgs`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    
    imps_payment(data) {
      return this.http.post(`${this.apiUrl}/canara/payment-imps`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    fund_transfer(data) {
      return this.http.post(`${this.apiUrl}/canara/internaltransfer`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    balance_enquiry(data) {
      return this.http.post(`${this.apiUrl}/canara/balanceinquiry`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
    account_statement(data) {
      return this.http.post(`${this.apiUrl}/canara/acc-statement`, {
        ...data,
        // ...addLatLong()
      }, addHeaders());
    }
   transactionDetails(payload){
      return this.http.post(`${this.apiUrl}/canara/get-transaction-details`, payload, addHeaders());
    }
}
