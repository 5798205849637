<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User Profile</li>
                </ol> -->
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
    <div class="main-container">
        <div class="profile-card">
            <div class="row d-flex items-center">
                <div class="col-2" style="width: auto">
                    <div class="relative">
                        <img
                            src="{{ this.user.profile_image }}"
                            class="border-orange"
                        />
                        <img
                            src="assets/img/default-profile.png"
                            *ngIf="this.user.profile_image == ''"
                            width="100px"
                            class="border-orange"
                        />
                        <div
                            class="absolute bottom-0 right-1"
                            *ngIf="this.user.is_kyc == 1"
                        >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 16 16"
                                class="text-orange"
                                height="20"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <h6>
                        {{ this.user.first_name }} {{ this.user.last_name }}
                    </h6>
                    <p class="mb-0">{{ this.user.email }}</p>
                    <p>{{ this.user.address }}</p>
                </div>
            </div>
        </div>
        <div class="profile-card mt-2">
            <div class="row">
                <div
                    class="d-flex flex-wrap justify-start items-center text-[10px] xs:text-[13px] md:text-[14px] gap-0 md:gap-4"
                >
                    <div>
                        <div
                            class="d-flex justify-center gap-2 items-center p-2 ml-3"
                            (click)="this.activeTab = 'personal'"
                        >
                            <div>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    class="text-primary"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        d="M0 0h24v24H0V0z"
                                    ></path>
                                    <path
                                        d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                                    ></path>
                                </svg>
                            </div>
                            <p class="cursor-pointer">Personal Info</p>
                        </div>
                        <div
                            class="p-2"
                            *ngIf="this.activeTab != 'personal'"
                        ></div>

                        <div
                            class="border-t-2 p-2 animate-slide-in border-primary"
                            *ngIf="this.activeTab == 'personal'"
                        ></div>
                    </div>
                    <div>
                        <div
                            class="d-flex justify-center gap-2 items-center p-2 ml-3"
                            (click)="this.activeTab = 'kyc'"
                        >
                            <div>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    class="text-primary"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path
                                        d="M23 12l-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12zm-12.91 4.72l-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35z"
                                    ></path>
                                </svg>
                            </div>
                            <p class="cursor-pointer">KYC Details</p>
                        </div>
                        <div class="p-2" *ngIf="this.activeTab != 'kyc'"></div>
                        <div
                            class="border-t-2 p-2 animate-slide-in border-primary"
                            *ngIf="this.activeTab == 'kyc'"
                        ></div>
                    </div>
                    <div>
                        <div
                            class="d-flex justify-center gap-2 items-center p-2 ml-3"
                            (click)="this.activeTab = 'bank'"
                        >
                            <div>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 1024 1024"
                                    class="text-primary"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M894 462c30.9 0 43.8-39.7 18.7-58L530.8 126.2a31.81 31.81 0 0 0-37.6 0L111.3 404c-25.1 18.2-12.2 58 18.8 58H192v374h-72c-4.4 0-8 3.6-8 8v52c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-52c0-4.4-3.6-8-8-8h-72V462h62zM381 836H264V462h117v374zm189 0H453V462h117v374zm190 0H642V462h118v374z"
                                    ></path>
                                </svg>
                            </div>
                            <p class="cursor-pointer">Bank Details</p>
                        </div>
                        <div class="p-2" *ngIf="this.activeTab != 'bank'"></div>

                        <div
                            class="border-t-2 p-2 animate-slide-in border-primary"
                            *ngIf="this.activeTab == 'bank'"
                        ></div>
                    </div>
                    <div>
                        <div
                            class="d-flex justify-center gap-2 items-center p-2 ml-3"
                            (click)="this.activeTab = 'mpin'"
                        >
                            <div>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    class="text-primary"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 8H20C20.5523 8 21 8.44772 21 9V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V9C3 8.44772 3.44772 8 4 8H6V7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V8ZM5 10V20H19V10H5ZM11 14H13V16H11V14ZM7 14H9V16H7V14ZM15 14H17V16H15V14ZM16 8V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V8H16Z"
                                    ></path>
                                </svg>
                            </div>
                            <p class="cursor-pointer">Change M-PIN</p>
                        </div>
                        <div class="p-2" *ngIf="this.activeTab != 'mpin'"></div>

                        <div
                            class="border-t-2 p-2 animate-slide-in border-primary"
                            *ngIf="this.activeTab == 'mpin'"
                        ></div>
                    </div>
                    <div>
                        <div
                            class="d-flex justify-center gap-2 items-center p-2 ml-3"
                            (click)="this.activeTab = 'password'"
                        >
                            <div>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    class="text-primary"
                                    height="20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        d="M0 0h24v24H0V0z"
                                    ></path>
                                    <path
                                        d="M2 17h20v2H2v-2zm1.15-4.05L4 11.47l.85 1.48 1.3-.75-.85-1.48H7v-1.5H5.3l.85-1.47L4.85 7 4 8.47 3.15 7l-1.3.75.85 1.47H1v1.5h1.7l-.85 1.48 1.3.75zm6.7-.75l1.3.75.85-1.48.85 1.48 1.3-.75-.85-1.48H15v-1.5h-1.7l.85-1.47-1.3-.75L12 8.47 11.15 7l-1.3.75.85 1.47H9v1.5h1.7l-.85 1.48zM23 9.22h-1.7l.85-1.47-1.3-.75L20 8.47 19.15 7l-1.3.75.85 1.47H17v1.5h1.7l-.85 1.48 1.3.75.85-1.48.85 1.48 1.3-.75-.85-1.48H23v-1.5z"
                                    ></path>
                                </svg>
                            </div>
                            <p class="cursor-pointer">Change Password</p>
                        </div>
                        <div
                            class="p-2"
                            *ngIf="this.activeTab != 'password'"
                        ></div>

                        <div
                            class="border-t-2 p-2 animate-slide-in border-primary"
                            *ngIf="this.activeTab == 'password'"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="profile-card mt-2">
            <div *ngIf="this.activeTab == 'personal'">
                <h5>Personal Info</h5>
                <div
                    class="border-t-2 p-2 animate-slide-in border-primary"
                    style="width: 13%"
                ></div>
                <div class="row">
                    <div class="col-4">
                        <p><b>Name</b></p>
                    </div>
                    <div class="col-6">
                        <p>
                            {{ this.user.first_name }} {{ this.user.last_name }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p><b>Member I'd</b></p>
                    </div>
                    <div class="col-6">
                        <p>
                            {{ this.user['wallet'].member_id }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p><b>Email ID</b></p>
                    </div>
                    <div class="col-6">
                        <p>
                            {{ this.user.email }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p><b>Mobile Number</b></p>
                    </div>
                    <div class="col-6">
                        <p>
                            {{ this.user.mobile }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p><b>Date of Birth</b></p>
                    </div>
                    <div class="col-6">
                        <p>
                            {{ this.user.dob || '-' }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p><b>Gender</b></p>
                    </div>
                    <div class="col-6">
                        <p *ngIf="this.user.gender == 'M'">Male</p>
                        <p *ngIf="this.user.gender == 'F'">Female</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p><b>KYC Status</b></p>
                    </div>
                    <div class="col-6">
                        <p *ngIf="this.user.is_kyc == 1">Approved</p>
                        <p *ngIf="this.user.is_kyc == 0">Not Approved</p>
                    </div>
                </div>
            </div>
            <div *ngIf="this.activeTab == 'kyc'">
                <h5>KYC Details</h5>
                <div
                    class="border-t-2 p-2 animate-slide-in border-primary"
                    style="width: 13%"
                ></div>
                <div >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        class="text-success align-center"
                        height="50"
                        width="50"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"
                        ></path>
                    </svg>
                    <h4 class="text-center">KYC Approved</h4>
                </div>
                <div class="row mt-3" >
                    <div class="col-4" >
                        <p style="font-size: 17px;"><b>Aadhar Number</b></p>
                    </div>
                    <div class="col-6">
                        <p style="font-size: 17px;">
                            {{ this.user.aadhar }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p style="font-size: 17px;"><b>PAN Number</b></p>
                    </div>
                    <div class="col-6">
                        <p style="font-size: 17px;">
                            {{ this.user.pan }}
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="this.activeTab == 'bank'">
                <h5>Bank Details</h5>
                <div
                    class="border-t-2 p-2 animate-slide-in border-primary"
                    style="width: 13%"
                ></div>
                <!-- <div class="row"> -->
                <div class="grid grid-cols-1 p-8">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        fill="#000000"
                        height="60px"
                        width="60px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 515.01 515.01"
                        xml:space="preserve"
                        class="align-center"
                    >
                        <g>
                            <g>
                                <path
                                    d="M474.12,404.87h-28.101V199.024h68.99L257.505,10.168L0,199.024h68.99V404.87H40.889    c-16.289,0-29.539,13.25-29.539,29.538v70.433h492.308v-70.433C503.659,418.12,490.409,404.87,474.12,404.87z M60.144,179.332    l197.361-144.74l197.361,144.74H60.144z M426.327,199.024V404.87h-51.774V199.024H426.327z M354.861,199.024V404.87h-51.774    V199.024H354.861z M283.394,199.024V404.87H231.62V199.024H283.394z M211.928,199.024V404.87h-51.779V199.024H211.928z     M140.457,199.024V404.87H88.683V199.024H140.457z M483.966,485.149H31.043v-50.74c0-5.433,4.418-9.846,9.846-9.846H474.12    c5.428,0,9.846,4.414,9.846,9.846V485.149z"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    d="M257.505,80.322c-22.168,0-40.207,17.913-40.207,39.923c0,22.01,18.038,39.923,40.207,39.923s40.207-17.913,40.207-39.923    C297.712,98.236,279.673,80.322,257.505,80.322z M257.505,140.476c-11.313,0-20.514-9.077-20.514-20.231    c0-11.154,9.202-20.231,20.514-20.231c11.313,0,20.514,9.077,20.514,20.231C278.019,131.399,268.817,140.476,257.505,140.476z"
                                />
                            </g>
                        </g>
                    </svg>

                    <p
                        class="flex justify-center text-center font-semibold mt-2"
                        style="font-size: 16px; font-weight: 600"
                    >
                        Add Bank Account
                    </p>
                    <div class="row mt-2">
                        <form
                            autocomplete="off"
                            [formGroup]="beneficiaryForm"
                            (ngSubmit)="addBeneficiary()"
                        >
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="bankName"
                                            >Bank Name
                                            <strong class="text-danger"
                                                >*</strong
                                            ></label
                                        >
                                        <ng-select
                                            formControlName="bankName"
                                            id="bankName"
                                            [ngClass]="{
                                                'is-invalid':
                                                    beneficiaryForm.get(
                                                        'bankName'
                                                    ).invalid &&
                                                    (beneficiaryForm.get(
                                                        'bankName'
                                                    ).dirty ||
                                                        beneficiaryForm.get(
                                                            'bankName'
                                                        ).touched)
                                            }"
                                            (change)="
                                                getIfsc(
                                                    beneficiaryForm.get(
                                                        'bankName'
                                                    ).value
                                                )
                                            "
                                        >
                                            <ng-option
                                                *ngFor="let bank of banks"
                                                [value]="bank.bankName"
                                                >{{ bank.bankName }}</ng-option
                                            >
                                        </ng-select>
                                        <div
                                            *ngIf="
                                                beneficiaryForm.get('bankName')
                                                    .invalid &&
                                                (beneficiaryForm.get('bankName')
                                                    .dirty ||
                                                    beneficiaryForm.get(
                                                        'bankName'
                                                    ).touched)
                                            "
                                            class="text-danger text-sm font-weight-bold"
                                        >
                                            Bank name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="ifscCode"
                                            >IFSC Code
                                            <strong class="text-danger"
                                                >*</strong
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="ifscCode"
                                            id="ifscCode"
                                            placeholder="Enter IFSC code"
                                        />
                                        <div
                                            *ngIf="
                                                beneficiaryForm.get('ifscCode')
                                                    .invalid &&
                                                (beneficiaryForm.get('ifscCode')
                                                    .dirty ||
                                                    beneficiaryForm.get(
                                                        'ifscCode'
                                                    ).touched)
                                            "
                                            class="text-danger text-sm font-weight-bold"
                                        >
                                            IFSC code is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="accountNumber"
                                            >Account Number
                                            <strong class="text-danger"
                                                >*</strong
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="accountNumber"
                                            id="accountNumber"
                                            placeholder="Enter account number"
                                        />
                                        <div
                                            *ngIf="
                                                beneficiaryForm.get(
                                                    'accountNumber'
                                                ).invalid &&
                                                (beneficiaryForm.get(
                                                    'accountNumber'
                                                ).dirty ||
                                                    beneficiaryForm.get(
                                                        'accountNumber'
                                                    ).touched)
                                            "
                                            class="text-danger text-sm font-weight-bold"
                                        >
                                            Account number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="accountHolderName"
                                            >Account Holder Name
                                            <strong class="text-danger"
                                                >*</strong
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="accountHolderName"
                                            id="accountHolderName"
                                            placeholder="Enter account holder name"
                                            readonly
                                        />
                                        <div
                                            *ngIf="
                                                beneficiaryForm.get(
                                                    'accountHolderName'
                                                ).invalid &&
                                                (beneficiaryForm.get(
                                                    'accountHolderName'
                                                ).dirty ||
                                                    beneficiaryForm.get(
                                                        'accountHolderName'
                                                    ).touched)
                                            "
                                            class="text-danger text-sm font-weight-bold"
                                        >
                                            Account holder name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="accountHolderMobileNumber"
                                            >Account Holder Mobile Number
                                            <strong class="text-danger"
                                                >*</strong
                                            ></label
                                        >
                                        <input
                                            type="email"
                                            class="form-control"
                                            formControlName="accountHolderMobileNumber"
                                            id="accountHolderMobileNumber"
                                            placeholder="Enter account holder mobile number"
                                        />
                                        <div
                                            *ngIf="
                                                beneficiaryForm.get(
                                                    'accountHolderMobileNumber'
                                                ).invalid &&
                                                (beneficiaryForm.get(
                                                    'accountHolderMobileNumber'
                                                ).dirty ||
                                                    beneficiaryForm.get(
                                                        'accountHolderMobileNumber'
                                                    ).touched)
                                            "
                                            class="text-danger text-sm font-weight-bold"
                                        >
                                            Account holder mobile number is
                                            required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4" style="width: auto">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        (click)="verifyAccountNumber()"
                                        style="height: -webkit-fill-available"
                                    >
                                        Verify Bank
                                    </button>
                                </div>
                                <div class="col-4">
                                    <button
                                        type="submit"
                                        class="d-none"
                                    ></button>
                                    <pf-button
                                        [variant]="'primary'"
                                        [disabled]="isLoading == true"
                                        (click)="addBeneficiary()"
                                    >
                                        Add Bank
                                    </pf-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- </div> -->
                    <div>
                        <h6 class="mt-3">Bank Accounts</h6>
                        <table *ngIf="beneficiaries.length" class="mt-2">
                            <tr>
                                <th>Bank Name</th>
                                <th>Account Number</th>
                                <th>IFSC Code</th>
                                <th>Account Holder Name</th>
                                <th>Mobile Number</th>
                                <th>Action</th>
                            </tr>
                            <tr *ngFor="let beneficiary of beneficiaries">
                                <td>{{ beneficiary.bank_name }}</td>
                                <td>{{ beneficiary.bank_account_number }}</td>
                                <td>{{ beneficiary.bank_ifsc }}</td>
                                <td>
                                    {{ beneficiary.bank_account_holder_name }}
                                </td>
                                <td>{{ beneficiary.mobile }}</td>
                                <td>
                                    <i
                                        class="fas fa-trash text-danger text-center"
                                        aria-hidden="true"
                                        (click)="delete(beneficiary)"
                                        style="font-size: 20px"
                                    ></i>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div *ngIf="this.activeTab == 'mpin'">
                <!-- <h5>Change MPin</h5> -->
                <div class="row">
                    <form
                        autocomplete="off"
                        [formGroup]="changeTPin"
                        style="width: 400px"
                    >
                        <div class="form-group">
                            <label for="current-tpin">Current TPIN</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="current-tpin"
                                id="current-tpin"
                                placeholder="Current TPIN"
                                mask="0000"
                                [ngClass]="{
                                    'is-invalid':
                                        changeTPin.get('current-tpin')
                                            .invalid &&
                                        (changeTPin.get('current-tpin').dirty ||
                                            changeTPin.get('current-tpin')
                                                .touched)
                                }"
                            />
                            <div
                                *ngIf="
                                    changeTPin.get('current-tpin').invalid &&
                                    (changeTPin.get('current-tpin').dirty ||
                                        changeTPin.get('current-tpin').touched)
                                "
                                class="invalid-feedback"
                            >
                                <strong>Current TPIN is required</strong>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="new-tpin">New TPIN</label>
                            <input
                                type="password"
                                class="form-control"
                                formControlName="new-tpin"
                                id="new-tpin"
                                placeholder="New TPIN"
                                mask="0000"
                                [ngClass]="{
                                    'is-invalid':
                                        changeTPin.get('new-tpin').invalid &&
                                        (changeTPin.get('new-tpin').dirty ||
                                            changeTPin.get('new-tpin').touched)
                                }"
                            />
                            <div
                                *ngIf="
                                    changeTPin.get('new-tpin').invalid &&
                                    (changeTPin.get('new-tpin').dirty ||
                                        changeTPin.get('new-tpin').touched)
                                "
                                class="invalid-feedback"
                            >
                                <strong>New TPIN is required</strong>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="confirm-tpin">Confirm TPIN</label>
                            <input
                                type="password"
                                class="form-control"
                                formControlName="confirm-tpin"
                                id="confirm-tpin"
                                placeholder="Confirm TPIN"
                                mask="0000"
                                [ngClass]="{
                                    'is-invalid':
                                        changeTPin.get('confirm-tpin')
                                            .invalid &&
                                        (changeTPin.get('confirm-tpin').dirty ||
                                            changeTPin.get('confirm-tpin')
                                                .touched)
                                }"
                            />
                            <div
                                *ngIf="
                                    changeTPin.get('confirm-tpin').invalid &&
                                    (changeTPin.get('confirm-tpin').dirty ||
                                        changeTPin.get('confirm-tpin').touched)
                                "
                                class="invalid-feedback"
                            >
                                <div
                                    *ngIf="
                                        changeTPin.get('confirm-tpin').errors
                                            .required
                                    "
                                >
                                    <strong>Confirm TPIN is required.</strong>
                                </div>
                                <div
                                    *ngIf="
                                        !changeTPin.get('confirm-tpin').errors
                                            .required &&
                                        changeTPin.get('confirm-tpin').errors
                                            .tpinNotMatch
                                    "
                                >
                                    <strong>Confirm TPIN do not match.</strong>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <pf-button
                                [variant]="'info'"
                                [loading]="isLoading"
                                [disabled]="isLoading"
                                (click)="change()"
                            >
                                Change TPIN
                            </pf-button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="this.activeTab == 'password'">
                <!-- <h5>Change MPin</h5> -->
                <div class="row">
                    <form [formGroup]="changePassword" style="width: 400px">
                        <div class="form-group">
                            <label for="current-password"
                                >Current Password</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                formControlName="current-password"
                                id="current-password"
                                placeholder="Current Password"
                                [ngClass]="{
                                    'is-invalid':
                                        changePassword.get('current-password')
                                            .invalid &&
                                        (changePassword.get('current-password')
                                            .dirty ||
                                            changePassword.get(
                                                'current-password'
                                            ).touched)
                                }"
                            />
                            <div
                                *ngIf="
                                    changePassword.get('current-password')
                                        .invalid &&
                                    (changePassword.get('current-password')
                                        .dirty ||
                                        changePassword.get('current-password')
                                            .touched)
                                "
                                class="invalid-feedback"
                            >
                                <strong>Current Password is required</strong>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="new-password">New Password</label>
                            <input
                                type="password"
                                class="form-control"
                                formControlName="new-password"
                                id="new-password"
                                placeholder="New Password"
                                [ngClass]="{
                                    'is-invalid':
                                        changePassword.get('new-password')
                                            .invalid &&
                                        (changePassword.get('new-password')
                                            .dirty ||
                                            changePassword.get('new-password')
                                                .touched)
                                }"
                            />
                            <div
                                *ngIf="
                                    changePassword.get('new-password')
                                        .invalid &&
                                    (changePassword.get('new-password').dirty ||
                                        changePassword.get('new-password')
                                            .touched)
                                "
                                class="invalid-feedback"
                            >
                                <strong>New Password is required</strong>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="confirm-password"
                                >Confirm Password</label
                            >
                            <input
                                type="password"
                                class="form-control"
                                formControlName="confirm-password"
                                id="confirm-password"
                                placeholder="Confirm Password"
                                [ngClass]="{
                                    'is-invalid':
                                        changePassword.get('confirm-password')
                                            .invalid &&
                                        (changePassword.get('confirm-password')
                                            .dirty ||
                                            changePassword.get(
                                                'confirm-password'
                                            ).touched)
                                }"
                            />
                            <div
                                *ngIf="
                                    changePassword.get('confirm-password')
                                        .invalid &&
                                    (changePassword.get('confirm-password')
                                        .dirty ||
                                        changePassword.get('confirm-password')
                                            .touched)
                                "
                                class="invalid-feedback"
                            >
                                <div
                                    *ngIf="
                                        changePassword.get('confirm-password')
                                            .errors.required
                                    "
                                >
                                    <strong
                                        >Confirm Password is required.</strong
                                    >
                                </div>
                                <div
                                    *ngIf="
                                        !changePassword.get('confirm-password')
                                            .errors.required &&
                                        changePassword.get('confirm-password')
                                            .errors.passwordsNotMatch
                                    "
                                >
                                    <strong
                                        >Confirm Password do not match.</strong
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <pf-button
                                [variant]="'info'"
                                [loading]="isLoading"
                                [disabled]="isLoading"
                                (click)="updatePassword()"
                            >
                                Change Password
                            </pf-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="card p-4 mt-4">
        <form (submit)="update()" [formGroup]="profileForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="first_name">First Name <strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" id="first_name" formControlName="first_name"
                            [ngClass]="{'is-invalid': profileForm.get('first_name').invalid && (profileForm.get('first_name').dirty || profileForm.get('first_name').touched)}">
                        <div *ngIf="profileForm.get('first_name').invalid && (profileForm.get('first_name').dirty || profileForm.get('first_name').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('first_name').errors.required">First Name is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="last_name">Last Name <strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" id="last_name" formControlName="last_name"
                            [ngClass]="{'is-invalid': profileForm.get('last_name').invalid && (profileForm.get('last_name').dirty || profileForm.get('last_name').touched)}">
                        <div *ngIf="profileForm.get('last_name').invalid && (profileForm.get('last_name').dirty || profileForm.get('last_name').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('last_name').errors.required">Last Name is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="dob">Date of Birth <strong class="text-danger">*</strong></label>
                        <input type="date" class="form-control" id="dob" formControlName="dob"
                            [ngClass]="{'is-invalid': profileForm.get('dob').invalid && (profileForm.get('dob').dirty || profileForm.get('dob').touched)}">
                        <div *ngIf="profileForm.get('dob').invalid && (profileForm.get('dob').dirty || profileForm.get('dob').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('dob').errors.required">DOB is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" formControlName="email"
                            [ngClass]="{'is-invalid': profileForm.get('email').invalid && (profileForm.get('email').dirty || profileForm.get('email').touched)}">
                        <div *ngIf="profileForm.get('email').invalid && (profileForm.get('email').dirty || profileForm.get('email').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('email').errors.required">Email is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="mobile">Mobile</label>
                        <input type="tel" class="form-control" id="mobile" formControlName="mobile"
                            [ngClass]="{'is-invalid': profileForm.get('mobile').invalid && (profileForm.get('mobile').dirty || profileForm.get('mobile').touched)}">
                        <div *ngIf="profileForm.get('mobile').invalid && (profileForm.get('mobile').dirty || profileForm.get('mobile').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('mobile').errors.required">Mobile Number is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="phone">Phone <strong class="text-danger">*</strong></label>
                        <input type="text" class="form-control" id="phone" formControlName="phone"
                            [ngClass]="{'is-invalid': profileForm.get('phone').invalid && (profileForm.get('phone').dirty || profileForm.get('phone').touched)}">
                        <div *ngIf="profileForm.get('phone').invalid && (profileForm.get('phone').dirty || profileForm.get('phone').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('phone').errors.required">Phone number is required.</div>
                            <div *ngIf="profileForm.get('phone').errors?.pattern">Invalid phone number format.</div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="gender">Gender</label>
                        <select class="form-control" id="gender" formControlName="gender">
                            <option value="" selected>Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="member_type">Member Type</label>
                        <select class="form-control" id="member_type" formControlName="member_type">
                            <option value="" selected>Select Type</option>
                            <option *ngFor="let role of roles | keyvalue" [value]="role.key">{{ role.value }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="aadhar">Aadhar</label>
                        <input type="text" class="form-control" id="aadhar" formControlName="aadhar"
                            [ngClass]="{'is-invalid': profileForm.get('aadhar').invalid && (profileForm.get('aadhar').dirty || profileForm.get('aadhar').touched)}">
                        <div *ngIf="profileForm.get('aadhar').invalid && (profileForm.get('aadhar').dirty || profileForm.get('aadhar').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('aadhar').errors.required">Aadhar is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="pan">PAN</label>
                        <input type="text" class="form-control" id="pan" formControlName="pan"
                            [ngClass]="{'is-invalid': profileForm.get('pan').invalid && (profileForm.get('pan').dirty || profileForm.get('pan').touched)}">
                        <div *ngIf="profileForm.get('pan').invalid && (profileForm.get('pan').dirty || profileForm.get('pan').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('pan').errors.required">Pan is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="state_id">State</label>
                        <select class="form-control" id="state_id" formControlName="state_id">
                            <option [value]="state.id" *ngFor="let state of (states$ | async)">{{ state.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="city_id">City</label>
                        <select class="form-control" id="city_id" formControlName="city_id">
                            <option [value]="city.id"  *ngFor="let city of (cities$ | async)">{{ city.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="pincode">Pincode</label>
                        <input type="text" class="form-control" id="pincode" formControlName="pincode"
                            [ngClass]="{'is-invalid': profileForm.get('pincode').invalid && (profileForm.get('pincode').dirty || profileForm.get('pincode').touched)}">
                        <div *ngIf="profileForm.get('pincode').invalid && (profileForm.get('pincode').dirty || profileForm.get('pincode').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('pincode').errors.required">Pincode is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="address">Address</label>
                        <textarea class="form-control" id="address" formControlName="address" rows="3"
                            [ngClass]="{'is-invalid': profileForm.get('address').invalid && (profileForm.get('address').dirty || profileForm.get('address').touched)}">{{ user.address }}</textarea>
                        <div *ngIf="profileForm.get('address').invalid && (profileForm.get('address').dirty || profileForm.get('address').touched)"
                            class="invalid-feedback">
                            <div *ngIf="profileForm.get('address').errors.required"><strong>Address is
                                    required.</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="shop_name">Shop Name</label>
                        <input type="text" class="form-control" id="shop_name" formControlName="shop_name"
                            [ngClass]="{'is-invalid': profileForm.get('shop_name').invalid && (profileForm.get('shop_name').dirty || profileForm.get('shop_name').touched)}">
                        <div *ngIf="profileForm.get('shop_name').invalid && (profileForm.get('shop_name').dirty || profileForm.get('shop_name').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('shop_name').errors.required">Shop name is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="business_pan">Business PAN</label>
                        <input type="text" class="form-control" id="business_pan" formControlName="business_pan"
                            [ngClass]="{'is-invalid': profileForm.get('business_pan').invalid && (profileForm.get('business_pan').dirty || profileForm.get('business_pan').touched)}">
                        <div *ngIf="profileForm.get('business_pan').invalid && (profileForm.get('business_pan').dirty || profileForm.get('business_pan').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('business_pan').errors.required">Business Pan is required.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="gst">GST</label>
                        <input type="text" class="form-control" id="gst" formControlName="gst"
                            [ngClass]="{'is-invalid': profileForm.get('gst').invalid && (profileForm.get('gst').dirty || profileForm.get('gst').touched)}">
                        <div *ngIf="profileForm.get('gst').invalid && (profileForm.get('gst').dirty || profileForm.get('gst').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('gst').errors.required">GST is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="shop_address">Shop Address</label>
                        <textarea class="form-control" id="shop_address" formControlName="shop_address" rows="3"
                            [ngClass]="{'is-invalid': profileForm.get('shop_address').invalid && (profileForm.get('shop_address').dirty || profileForm.get('shop_address').touched)}">{{ user.shop_address }}</textarea>
                        <div *ngIf="profileForm.get('shop_address').invalid && (profileForm.get('shop_address').dirty || profileForm.get('shop_address').touched)"
                            class="invalid-feedback">
                            <div *ngIf="profileForm.get('shop_address').errors.required"><strong>Shop address is
                                    required.</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="profile_image">Profile Image</label>
                        <input type="file" class="form-control" id="profile_image" formControlName="profile_image"
                            [ngClass]="{'is-invalid': profileForm.get('profile_image').invalid && (profileForm.get('profile_image').dirty || profileForm.get('profile_image').touched)}">
                        <div *ngIf="profileForm.get('profile_image').invalid && (profileForm.get('profile_image').dirty || profileForm.get('profile_image').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="profileForm.get('profile_image').errors.required">Profile image is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pf-button [variant]="'info'" [loading]="isLoading" [disabled]="isLoading" (click)="update()">
                Update
            </pf-button>
            <button type="submit" class="d-none btn btn-primary">Submit</button>
        </form>
    </div> -->
</section>
