import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addLatLong, addHeaders } from '@services/api.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaysprintService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  introdction(callbackUrl: any) {
    const apiUrlWithCallback = `${this.apiUrl}/digilocker/initiate?callback=${callbackUrl}`;
    return this.http.get(apiUrlWithCallback, addHeaders());
  }

  store() {
    return this.http.get(`${this.apiUrl}/digilocker`, addHeaders());
  }
}
