import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { DataService } from '@services/data.service';
import { Profile } from '@/models/common';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.scss'
})
export class SubHeaderComponent {
  public profile: Profile;

  constructor(
    private loading:LoadingService,
    private router:Router,
    private data:DataService
  ) {

  }

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      this.profile = profile
    })
  }
  onActiveTab(value:any){
    this.loading.show();
    setTimeout(()=>{
     this.loading.hide();
    },1000)
      this.router.navigate(['/bbps']);
    }
}

