import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { KYCComponent } from '@pages/kyc/kyc.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { DmtComponent } from '@pages/main-menu/toshani/dmt/dmt.component';
import { EnableGeoLocationComponent } from '@modules/enable-geo-location/enable-geo-location.component';
import { HasOnboardedGuard } from '@guards/has-onboarded.guard';
import { FundRequestComponent } from '@pages/fund-request/fund-request.component';
import { FundRequestListComponent } from '@pages/fund-request-list/fund-request-list.component';
import { ChangePasswordComponent } from '@pages/change-password/change-password.component';
import { XdmtComponent } from '@pages/main-menu/toshani/xdmt/xdmt.component';
import { UpiComponent } from '@pages/main-menu/toshani/upi/upi.component';
import { XUpiComponent } from '@pages/main-menu/toshani/xupi/xupi.component';
import { EWalletLedgerComponent } from '@pages/e-wallet-ledger/e-wallet-ledger.component';
import { ChangeTpinComponent } from '@pages/change-tpin/change-tpin.component';
import { OnboardingComponent } from '@pages/main-menu/aeps/onboarding/onboarding.component';
import { FundsComponent } from '@pages/main-menu/funds/funds.component';
import { AddUserComponent } from '@pages/main-menu/users/add-user/add-user.component';
import { UserListComponent } from '@pages/main-menu/users/user-list/user-list.component';
import { RechargeComponent } from '@pages/main-menu/services/recharge/recharge.component';
import { FinancialServiceGuard } from '@guards/financial-service.guard';
import { HigherLevelGuard } from '@guards/higher-level.guard';
import { PrimeComponent } from '@pages/main-menu/toshani/prime/prime.component';
import { TransactionComponent } from '@pages/main-menu/aeps/transaction/transaction.component';
import { TwoFactorComponent } from '@pages/main-menu/aeps/two-factor/two-factor.component';
import { LitePlusComponent } from '@pages/main-menu/avenue/lite-plus/lite-plus.component';
import { SabpaisaComponent } from '@pages/main-menu/toshani/sabpaisa/sabpaisa.component';
import { OBDXComponent } from '@pages/main-menu/toshani/canara/obdx/obdx.component';
import { PayoutComponent } from '@pages/main-menu/toshani/canara/payout/payout.component';
import { CbxComponent } from '@pages/main-menu/toshani/hdfc/cbx/cbx.component';

import { UPIComponent } from '@pages/main-menu/toshani/canara/upi/upi.component';
import { BbpsComponent } from '@pages/main-menu/services/bbps/bbps.component';
import { WithdrawalAuthenticationComponent } from '@pages/main-menu/aeps/withdrawal-authentication/withdrawal-authentication.component';
import { AepsWalletLedgerComponent } from '@pages/aeps-wallet-ledger/aeps-wallet-ledger.component';
import { PaymentSuccessComponent } from '@pages/main-menu/toshani/payment_success/payment_success.component';
import { PaymentFailureComponent } from '@pages/main-menu/toshani/payment_failure/payment_failure.component';

import { DmtLiteReportComponent } from './reports/dmt-lite-report/dmt-lite-report.component';
import { DmtExpressReportComponent } from './reports/dmt-express-report/dmt-express-report.component';
import { UpiLiteReportComponent } from './reports/upi-lite-report/upi-lite-report.component';
import { UpiExpressReportComponent } from './reports/upi-express-report/upi-express-report.component';
import { DmtPrimeReportComponent } from './reports/dmt-prime-report/dmt-prime-report.component';
import { DmtLitePlusReportComponent } from './reports/dmt-lite-plus-report/dmt-lite-plus-report.component';
import { DmtLitePlusReportPendingRefundComponent }  from './reports/dmt-lite-plus-report-pending-refund/dmt-lite-plus-report-pending-refund.component';
import { AepsReportComponent } from './reports/aeps-report/aeps-report.component';
import { BBPSReportComponent } from './reports/bbps-report/bbps-report.component';
import { PGReportComponent } from './reports/pg-report/pg-report.component';
import { CANBankReportComponent } from './reports/ca-bank-report/ca-bank-report.component';
import { CertificateComponent } from '@pages/certificate/certificate.component';
import { IdCardComponent } from '@pages/id-card/id-card.component';
import { TermsConditionsComponent } from '@pages/terms-conditions/terms-conditions.component';
import { AepsPayoutComponent } from '@pages/main-menu/aeps/aeps-payout/aeps-payout.component';
import { PgPayoutComponent } from '@pages/main-menu/toshani/pg-payout/pg-payout.component';
import { PgPayoutReportComponent } from './reports/pg-payout-report/pg-payout-report.component';
import { AepsPayoutReportComponent } from './reports/aeps-payout-report/aeps-payout-report.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'change-tpin', component: ChangeTpinComponent },
      { path: 'kyc', component: KYCComponent },
      { path: 'e-wallet', component: FundRequestListComponent },
      { path: 'e-wallet-ledger', component: EWalletLedgerComponent },
      { path: 'aeps-wallet-ledger', component: AepsWalletLedgerComponent },
      { path: 'fund-request', component: FundRequestComponent },
      { path: 'give-fund', component: FundsComponent, canActivate: [HasOnboardedGuard, HigherLevelGuard] },
      { path: 'add-user', component: AddUserComponent, canActivate: [HasOnboardedGuard, HigherLevelGuard] },
      { path: 'user-list', component: UserListComponent, canActivate: [HasOnboardedGuard, HigherLevelGuard] },
      { path: 'recharge', component: RechargeComponent },
      { path: 'bbps', component: BbpsComponent },
      {
        path: 'aeps',
        canActivate: [HasOnboardedGuard],
        children: [
          { path: 'onboarding', component: OnboardingComponent },
          { path: 'two-factor', component: TwoFactorComponent },
          { path: 'daily-authentication', component: WithdrawalAuthenticationComponent },
          { path: 'transaction', component: TransactionComponent },
          { path: 'aeps-payout', component: AepsPayoutComponent },

        ]
      },
      {
        path: 'dmt',
        canActivate: [HasOnboardedGuard],
        children: [
          { path: '', component: DmtComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'express', component: XdmtComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'prime', component: PrimeComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'lite-plus', component: LitePlusComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          // { path: 'obdx', component: OBDXComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'upi', component: UPIComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'payment_success', component: PaymentSuccessComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'payment_failed', component: PaymentFailureComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'payout', component: PayoutComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'cbx', component: CbxComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] }

        ]
      },
      { path: 'payment-gateway', component: SabpaisaComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
      { path: 'pg-payout', component: PgPayoutComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },

      {
        path: 'fund',
        canActivate: [HasOnboardedGuard],
        children: [
          { path: '', component: DmtComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'transfer', component: OBDXComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },

        ]
      },
      {
        path: 'upi',
        canActivate: [HasOnboardedGuard],
        children: [
          { path: '', component: UpiComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] },
          { path: 'express', component: XUpiComponent, canActivate: [HasOnboardedGuard, FinancialServiceGuard] }
        ]
      },
      {
        path: 'reports',
        children: [
          { path: 'dmt-lite', component: DmtLiteReportComponent },
          { path: 'dmt-lite-plus', component: DmtLitePlusReportComponent },
          { path: 'dmt-lite-plus/refund-pending', component: DmtLitePlusReportPendingRefundComponent },
          { path: 'aeps', component: AepsReportComponent },
          { path: 'dmt-express', component: DmtExpressReportComponent },
          { path: 'prime-pay', component: DmtPrimeReportComponent },
          { path: 'upi-lite', component: UpiLiteReportComponent },
          { path: 'upi-express', component: UpiExpressReportComponent },
          { path: 'bbps', component: BBPSReportComponent },
          { path: 'pg-report', component: PGReportComponent },
          { path: 'transfer', component: CANBankReportComponent },
          { path: 'pg-payout-report', component: PgPayoutReportComponent },
          { path: 'aeps-payout-report', component: AepsPayoutReportComponent },


        ]
      },
      { path: 'certificate', component: CertificateComponent, canActivate: [HasOnboardedGuard] } ,
      { path: 'id-card', component: IdCardComponent, canActivate: [HasOnboardedGuard] } ,

      { path: '', component: DashboardComponent, canActivate: [HasOnboardedGuard] }// Default to dashboard
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [NonAuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [NonAuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [NonAuthGuard] },
  { path: 'recover-password', component: RecoverPasswordComponent, canActivate: [NonAuthGuard] },
  { path: 'enable-geolocation', component: EnableGeoLocationComponent, canActivate: [NonAuthGuard] },
  { path: 'terms-and-condition', component: TermsConditionsComponent, canActivate: [NonAuthGuard] },

  { path: '**', redirectTo: '' } // Redirect invalid routes to default
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload',preloadingStrategy: NoPreloading })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
