import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '@services/data.service';
import { City, State } from '@/models/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-prepaid',
  templateUrl: './prepaid.component.html',
  styleUrl: './prepaid.component.scss'
})
export class PrepaidComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  public states: State[];
  operators: string[] = ['Airtel', 'Jio'];
  mobileOperatorList: any[];
  formFields: any;
  selectedOperator: any;
  billerFetchDetails: any;

  constructor(private fb: FormBuilder,
    public api: ApiService,
    private data: DataService,
    private alert :ToastrService

  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      location: ['', Validators.required],
      amount: ['', Validators.required]

    });
    this.getBillerList();

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }
  stateChanged(event: any) {
    // this.data.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    // this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
    console.log( selectedStateId);
  }
  getBillerList(){
    var value = {
      billerCategory:'Mobile Prepaid'
    }
    this.api.billerList(value).subscribe({
      next: (response: any) => {
        this.mobileOperatorList = response.data;
        console.log(this.mobileOperatorList);
      },
      error: (error) => {
        this.mobileOperatorList = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onSelectOperator($event){
    var getData:any = this.mobileOperatorList.find(biller => biller.billerName === $event);
    this.selectedOperator = getData;
    this.formFields = getData.paramInfo;
  }

  proceedToRecharge(){
   const form = this.paymentForm 
   if (!form.valid) {
    this.alert.error('Please fill all the required details');
    return;
  }
    var value = {
      
        "initChannel": "AGT",
        "billerAdhoc": "true",
        "agentDeviceIp": "192.168.2.73",
        "agentDeviceMac": "01-23-45-67-89-ab",
        "customerMobile": "9898990084",
        "customerEmail": "",
        "customerAdhaar": "",
        "customerPan": "",
        "billerId": this.selectedOperator.billerId,
        "inputParams": [
          {
            "paramName": "Location",
            "paramValue": this.paymentForm.value.location
          },
          {
            "paramName": "Mobile Number",
            "paramValue": this.paymentForm.value.mobileNumber
          }
        ],
        "rechargeType": "topup",
        "amount": this.paymentForm.value.amount,
        "paymentMode": "Cash",
        "quickPay": "N",
        "splitPay": "N",
        "infoName": "Remarks",
        "infoValue": "Received"
      }
    this.api.rechargeBill(value).subscribe({
      next: (response: any) => {
        this.billerFetchDetails = response.results || [];
        console.log(this.billerFetchDetails)
        // $('#receiptBooking').modal('show');
      },
      error: (error) => {
        this.billerFetchDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
