import { Component } from '@angular/core';
import html2canvas from 'html2canvas';
import { ApiService } from '@services/api.service';
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrl: './certificate.component.scss'
})
export class CertificateComponent {
  first_name:any;
  last_name:any;
  address:any;
  code:string;
  doj:any;
  constructor(
    private api: ApiService
  ) {

  }
  ngOnInit(): void {
    const data=JSON.parse(localStorage.getItem('user'));
    this.code = data.member_id;
    this.api.me().subscribe({
      next: (response: any) => {
        console.log(response.data.user);
        this.first_name=response.data.user.first_name;
        this.last_name=response.data.user.last_name;
        this.address=response.data.user.address;
        this.doj=response.data.user.created_at



      }
    })

    setTimeout(() => {
      this.downloadCertificate();
    }, 1000);

  }

  downloadCertificate(): void {
    const certificate = document.getElementById('certificate');

    if (certificate) {
      html2canvas(certificate).then(canvas => {
        const link = document.createElement('a');
        link.download = 'certificate.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
}
}
