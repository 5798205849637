<div class="card card-outline card-primary">
  <app-brand-header />
  <div class="card-body">
    <h2 class="login-box-msg">Enable Geolocation</h2>
    <p class="login-box-msg">Geolocation is required for certain features of this application.</p>
    <p class="login-box-msg">Please enable geolocation in your browser settings to continue.</p>
    <div class="mt-3"></div>
    <pf-button [variant]="'primary'" block="true" [loading]="isCheckingPermissiong" [disabled]="isCheckingPermissiong"
      (click)="grantPermission()">
      Allow Location
    </pf-button>
  </div>
</div>
