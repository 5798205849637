import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addLatLong, addHeaders } from '@services/api.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiteService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  customer(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/upi/customer`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  verification(body: { mobileNumber: number; otp: string; name: string }) {
    return this.http.post(`${this.apiUrl}/upi/verify-otp`, {
      ...body, ...addLatLong()
    }, addHeaders());
  }

  resend(data: any) {
    return this.http.post(`${this.apiUrl}/upi/resend-otp`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  transfer(data: any) {
    return this.http.post(`${this.apiUrl}/upi/transfer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  beneficiaries(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/upi/beneficiaries`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  beneficiary(data: any) {
    return this.http.post(`${this.apiUrl}/upi/add-beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  delete(data: any) {
    return this.http.post(`${this.apiUrl}/upi/delete-beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }
}
