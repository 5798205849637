import { Bank, City, State } from '@/models/common';
import { PaySprintBank } from '@/models/pay-sprint';
import {  CustomerData } from '@/models/toshani';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '@services/data.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-psprint',
  templateUrl: './psprint.component.html',
  styleUrl: './psprint.component.scss'
})
export class PsprintComponent {
  public customerVerificationForm: FormGroup;
  customerRegistrationForm: FormGroup;
  public isLoading: boolean = false;
  public remitter: CustomerData;
  public isLoggedIn: boolean = false;
  public accountForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public cities: City[];
  public banks: Bank[];
  public states: State[];
  public beneficiaries: PaySprintBank[];
  public beneficiary: PaySprintBank;
  public addBeneficiaryModal: Modal;
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  
  constructor(private formBuilder: FormBuilder,private data: DataService) { }

  ngOnInit() {

    this.data.getBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      address: ['', Validators.required],
      otp: ['', Validators.required,Validators.pattern(/^[0-9]\d{3}$/)],
      pincode: ['', Validators.required],
      dob: ['', Validators.required],
      gst_state: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      accountNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      ifsc: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0), Validators.max(25000)]],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      pinCode: ['', Validators.required]
    });
    
  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
  }
  

  verification() {
    if (this.customerVerificationForm.valid) {
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;
      this.isLoading = true;

      // Api service here
      // this.remitter = {
      //   "name": response.data.cust_name,
      //   "number": response.data.mobileNumber,
      //   "limit": {
      //     "remaining": response.data.remaining_limit
      //   }
      // }
      

    }
  }

  register(){
    // REgister API
  }

  transfer(){
    // TRansfer api
  }

  details(beneficiary: PaySprintBank) {
    this.beneficiary = beneficiary;
  }

  delete(beneficiary: PaySprintBank) {
    if (confirm('Are you sure you want to delete')) {
     
    }
  }


  stateChanged(event: any) {
    this.data.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
  }

  addBeneficiary(){
    
  }
}
