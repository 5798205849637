<div class="m-3">
    <div class="row gap-2">
        <div class="col-md-6 cardContent bg-white p-4 shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Municipaltax Payment</h6>
                <div>
                    <img
                    src="assets/img/BharatBillPay.png"
                    alt="SDDS"
                    class="brand-image"
                    height="80px"
                    />
                    <!-- <svg width="23" height="27" viewBox="0 0 23 27" stroke="red" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_5304)">
                            <path
                                d="M14.4205 9.79787C14.8371 9.38723 15.1337 8.87063 15.2782 8.30381C15.4228 7.73699 15.4099 7.14144 15.2409 6.58143C15.0983 6.03456 14.7865 5.54678 14.3499 5.18789C13.9134 4.82899 13.3745 4.61735 12.8104 4.58327C11.7048 4.4715 10.5927 4.43704 9.48225 4.48013C8.6848 4.46785 7.88735 4.46785 7.0899 4.48013C6.81234 4.48013 6.73374 4.39296 6.73743 4.12401C6.74971 2.85824 6.74971 1.59203 6.73743 0.325442C6.73743 0.0712228 6.81112 -0.00125366 7.06902 -2.55512e-05C9.1347 0.0122555 11.2016 -0.0221075 13.2673 0.0245606C14.8397 0.00368808 16.3982 0.321161 17.8371 0.955463C18.7459 1.37171 19.5184 2.03652 20.0654 2.87319C20.6124 3.70985 20.9115 4.6842 20.9282 5.68366C21.0656 6.83153 20.9005 7.99538 20.4493 9.05976C20.0871 9.81101 19.5538 10.4668 18.892 10.9744C18.8452 11.0286 18.7823 11.0663 18.7124 11.082C18.6425 11.0977 18.5694 11.0906 18.5039 11.0616C17.2266 10.4967 15.8856 10.0885 14.5101 9.84577C14.4807 9.84577 14.4622 9.82489 14.4205 9.79787Z"
                                fill="#FCB834"></path>
                            <path
                                d="M22.6329 18.8613C22.6384 17.5335 22.2764 16.2302 21.5869 15.0954C20.8974 13.9607 19.9073 13.0389 18.7262 12.4322C16.7158 11.397 14.4779 10.8823 12.2173 10.9351C10.2732 10.9155 8.32907 10.9204 6.38497 10.9351C6.02636 10.9351 5.94652 10.8454 5.94898 10.4954C5.96371 7.13654 5.94899 3.77772 5.96741 0.424975C5.96741 0.101982 5.906 -0.00487367 5.55844 3.87725e-05C3.83909 0.0196885 2.12299 0.0196885 0.410186 3.87725e-05C0.0650872 3.87725e-05 -0.00121151 0.0982804 1.65961e-05 0.422501C0.011479 4.78475 0.0139124 9.147 0.00736247 13.5092C0.00736247 17.8584 0.00492904 22.2071 1.65961e-05 26.5554C1.65961e-05 26.8944 0.0614008 27.0012 0.431062 27C4.57389 26.9845 8.71712 26.982 12.8608 26.9927C13.8124 26.9997 14.7626 26.9174 15.6989 26.747C17.4315 26.4864 19.0549 25.7408 20.3817 24.5966C21.1713 23.877 21.7849 22.9855 22.1752 21.9911C22.5655 20.9967 22.7221 19.9259 22.6329 18.8613ZM16.6347 20.7527C16.5158 21.4463 16.1684 22.0802 15.6476 22.5535C15.1269 23.0269 14.4628 23.3124 13.761 23.3648C13.2848 23.4428 12.803 23.4819 12.3204 23.4815C10.3235 23.4815 8.3266 23.4814 6.33092 23.49C6.03004 23.49 5.94898 23.4127 5.95021 23.1118C5.96331 20.8922 5.96331 18.6722 5.95021 16.4518C5.95021 16.1448 6.03738 16.0723 6.33336 16.076C7.47182 16.0932 8.61152 16.0834 9.94648 16.0834C10.8884 16.1116 12.0343 16.0354 13.1654 16.1337C13.6709 16.1775 14.1673 16.2946 14.6391 16.4813C15.2098 16.6884 15.706 17.0608 16.0645 17.5509C16.423 18.041 16.6275 18.6268 16.652 19.2335C16.7164 19.7378 16.7106 20.2486 16.6347 20.7514V20.7527Z"
                                fill="#F37820"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_5304">
                                <rect width="22.6402" height="27" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg> -->
                </div>
            </div>

            <form [formGroup]="paymentForm" (ngSubmit)="fetchBill()">
                <div class="form-group">
                    <label for="operator">Operator <span class="text-danger">*</span></label>
                    <select formControlName="operator" class="form-control" id="operator"
                        [ngClass]="{ 'is-invalid': paymentForm.get('operator').invalid && (paymentForm.get('operator').dirty || paymentForm.get('operator').touched) }">
                        <option value="">Select operator</option>
                        <option *ngFor="let operator of operators" [value]="operator">{{ operator }}</option>
                    </select>
                    <div *ngIf="paymentForm.get('operator').invalid && (paymentForm.get('operator').dirty || paymentForm.get('operator').touched)"
                        class="text-danger">
                        Operator is required
                    </div>
                </div>

                <div class="form-group" *ngIf="showFields">
                    <label for="propertyID">Property  ID <span class="text-danger">*</span></label>
                    <input placeholder="Enter Property ID" type="text" formControlName="propertyID" class="form-control" id="propertyID"
                        [ngClass]="{ 'is-invalid': paymentForm.get('propertyID').invalid && (paymentForm.get('propertyID').dirty || paymentForm.get('propertyID').touched) }">
                    <div *ngIf="paymentForm.get('propertyID').invalid && (paymentForm.get('propertyID').dirty || paymentForm.get('propertyID').touched)"
                        class="text-danger">
                        Property ID is required
                    </div>
                </div>
                <pf-button *ngIf="showFields" [variant]="'primary'" [loading]="isLoading" [disabled]="isLoading"
                    (click)="fetchBill()">
                    Fetch bill
                </pf-button>
            </form>

        </div>
    </div>
</div>