import { Injectable } from '@angular/core';
import { MantraMachine } from '../machines/mantra-machine';
import { MorphoMachine } from '../machines/morpho-machine';
import { BiometricMachine } from '../interfaces/biometric-machine.interface';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {
  public biometricMachine: BiometricMachine | null = null;

  constructor(
    private mantraMachine: MantraMachine,
    private morphoMachine: MorphoMachine
  ) {}

  setMachineType(machineType: string): void {
    switch (machineType) {
      case 'mantra':
        this.biometricMachine = this.mantraMachine;
        break;
      case 'morpho':
        this.biometricMachine = this.morphoMachine;
        break;
      default:
        throw new Error('Unsupported machine type');
    }
  }

  async connect() {
    if (this.biometricMachine) {
      return await this.biometricMachine.connect();
    } else {
      throw new Error('No biometric machine selected');
    }
  }

  disconnect(): void {
    if (this.biometricMachine) {
      this.biometricMachine.disconnect();
    } else {
      throw new Error('No biometric machine selected');
    }
  }

  capture(): any {
    if (this.biometricMachine) {
      return this.biometricMachine.capture();
    } else {
      throw new Error('No biometric machine selected');
    }
  }
}
