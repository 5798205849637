import { ApiService } from './../../services/api.service';
import { Component } from '@angular/core';
import { DataService } from '@services/data.service';
import { City, State } from '@/models/common';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-id-card',
  templateUrl: './id-card.component.html',
  styleUrl: './id-card.component.scss'
})
export class IdCardComponent {
  first_name:any;
  last_name:any;
  state:any;
  code:string;
  department:any;
  doj:any;
  public states: State[];

  constructor(
    private api: ApiService,
    private dataService:DataService
  ) {
    this.dataService.getStates().subscribe((states: State[]) => {
      this.states = states;
    });
  }
  ngOnInit(): void {
    const data=JSON.parse(localStorage.getItem('user'));
    this.code = data.member_id;

    this.api.me().subscribe({
      next: (response: any) => {
        // console.log(response.data.user);
        this.first_name=response.data.user.first_name;
        this.last_name=response.data.user.last_name;
        this.department=response.data.roles['0'],
        this.doj=response.data.user.created_at
        const user = this.states.find(user => user.id === response.data.user.state_id);
        this.state=user.name;

      }
    })
  
    setTimeout(() => {
      this.downloadCertificate();
    }, 1000);

  }
  downloadCertificate(): void {
    const Icard = document.getElementById('id-card');

    if (Icard) {
      html2canvas(Icard).then(canvas => {
        const link = document.createElement('a');
        link.download = 'ICard.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
}
}
