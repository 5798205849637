import { Component, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataTableService } from '@services/data-table.service';
import { SafeXPayService } from '@services/payout/safe-xpay.service';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-dmt-prime-report',
  templateUrl: './dmt-prime-report.component.html',
  styleUrl: './dmt-prime-report.component.scss'
})
export class DmtPrimeReportComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  public row: any;
  public isCalling = false;
  private printButtonHandler: any;
  private statusUpdateHandler: any;

  constructor(private dataTableService: DataTableService, private api: SafeXPayService) {
    this.dataTableService.url = 'reports/prime-dmt'
    this.dataTableService.export_name = 'DMT_PRIME_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "bank_reference_number", "title": "UTR No" },
      { "data": "payout_id", "title": "Transaciton ID" },
      { "data": "account_number" },
      { "data": "transaction_amount" },
      { "data": "surcharge_amount" },
      { "data": "transaction_status" , render: function (data, type, row) {
        if (data != null) {
            let color = '';
            if (data.toLowerCase() === 'success') {
                color = 'green';
            } else if (data.toLowerCase() === 'failed') {
                color = 'red';
            } else if (data.toLowerCase() === 'pending') {
                color = 'orange';
            }

            return `<span style="color: ${color};">${data}</span>`;
        } else {
            return data ? data : '-';
        }
    }},
      { "data": "order_reference_number", "class": "d-none" },
      { "data": "created_at", "title": "Created At" },
      { "data": "account_holder_name", "class": "d-none" },
      { "data": "mobile_number", "class": "d-none" },
      { "data": "bank_status", "class": "d-none" },
      { "data": "raw_transaction_amount", "name": "raw_transaction_amount", "class": "d-none" },
      { "data": "ifsc_code", "class": "d-none" },
      { "data": "action", "name": "action", "title": "Action", "render": (_: any, __: any, data: any) => this.actionButton(data) },
    ], function (settings, json) {
      $("#prime-dmt-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="7">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate.format('DD-MM-YYYY'), picker.endDate.format('DD-MM-YYYY')])).draw();
      });
    });
  }

  ngAfterViewInit(): void {
    this.printButtonHandler = (e: { currentTarget: any; }) => {
      this.row = $(e.currentTarget).data('row');
      setTimeout(() => {
        window.print();
      }, 100);
    };

    this.statusUpdateHandler = (e: { currentTarget: any; }) => {
      const id = $(e.currentTarget).data('id');
      const reference = $(e.currentTarget).data('reference');

      this.api.status({ id, reference }).subscribe({
        next: async (response: any) => {
          (await this.datatableElement.dtInstance).ajax.reload(null, false);
        },
        error: (err) => {
          console.log(err)
        }
      })
    };

    $(document).on('click', '.btn-print', this.printButtonHandler);
    $(document).on('click', '.status-update', this.statusUpdateHandler);
  }

  ngOnDestroy(): void {
    $(document).off('click', '.btn-print', this.printButtonHandler);
    $(document).off('click', '.status-update', this.statusUpdateHandler);
  }

  actionButton(data: any): string {
    if (data.transaction_status === 'PENDING') {
      return `
        <div class='d-flex gap-4'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
          <button class='btn btn-sm btn-primary status-update' data-id='${data.payout_id}' data-reference='${data.order_reference_number}'>Update Status</button>
        </div>`;
    } else {
      return `
        <div class='d-flex'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
        </div>`;
    }
  }
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}