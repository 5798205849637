<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol> -->
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-3 col-md-3">
                        <div class="small-box bg-balance" routerLink="/e-wallet-ledger">
                            <div class="inner">
                                <h3>₹ {{ wallet_balance }}</h3>
                                <p>Total Wallet Balance</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-wallet"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-3"
                        *ngIf="can([roles.SUBSIDIARY])"
                    >
                        <div class="small-box bg-balance">
                            <div class="inner">
                                <h3>{{ distributor }}</h3>
                                <p>My Distributors</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-users"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-3"
                        *ngIf="can([roles.DISTRIBUTOR])"
                    >
                        <div class="small-box bg-balance">
                            <div class="inner">
                                <h3>{{ retailer }}</h3>
                                <p>My Retailers</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-store"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-3"
                        *ngIf="can([roles.RETAILER])"
                    >
                        <div class="small-box bg-transaction" routerLink="/aeps-wallet-ledger">
                            <div class="inner">
                                <h3>₹{{aeps_wallet_balance}}</h3>
                                <p>AePS Wallet</p>
                            </div>
                            <div class="icon">
                                <i class="fas fa-exchange-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-3"
                        *ngIf="can([roles.RETAILER]) && this.profile.data.permission.includes('pg_transaction')"
                    >
                        <div class="small-box bg-txn">
                            <div class="inner">
                                <h3>₹ {{ pg_wallet_balance }}</h3>
                                <p>PG Wallet Balance (Settled)</p>
                                <!-- <span>Settled</span> -->
                            </div>
                            <div class="icon">
                                <i class="fas fa-calendar-day"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-lg-3 col-md-3"
                        *ngIf="can([roles.RETAILER])&& this.profile.data.permission.includes('pg_transaction')"
                    >
                        <div class="small-box bg-pg-wallet" >
                            <div class="inner">
                                <h3>₹ </h3>
                                <p >PG Wallet Balance (Unsettled)</p>
                                <!-- <span>Unsettled</span> -->
                            </div>
                            <div class="icon">
                                <i class="fas fa-wallet"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row" *ngIf="can([roles.RETAILER])">
                    <div class="col-12">
                        <div class="card border-rounded">
                            <div class="card-header bg-orange">
                                <h3 class="card-title mt-1 mb-0">
                                    Active Services
                                </h3>
                                <div class="card-tools">
                                    <pf-button
                                        [variant]="'info'"
                                        size="small"
                                        [loading]="isLoading"
                                        [disabled]="isLoading"
                                        (click)="dashboard()"
                                    >
                                        <i class="fas fa-sync-alt"></i>
                                    </pf-button>
                                </div>
                            </div>

                            <div class="card-body table-responsive">
                                <!-- <div *ngFor=" let service of services; let i = index"  > -->
                                <!-- <p class="mb-0 mt-2 font-20"><b>{{ service.title }}</b><label class="switch">
                                        <input type="checkbox" checked (change)="toggle($event.target.checked)" disabled >
                                        <span class="slider"></span>
                                      </label>
                                      </p>
                                    <span>Total Amount : ₹ {{service.total_amount}} </span> -->
                                <div
                                    *ngFor="let item of services; let i = index"
                                    class="accordion-item"
                                >
                                    <div
                                        class="accordion-header"
                                        (click)="toggleItem(i)"
                                    >
                                        <div class="d-flex align-items-base">
                                            <i
                                                [ngClass]="
                                                    isOpen(i)
                                                        ? 'fas fa-chevron-down'
                                                        : 'fas fa-chevron-right'
                                                "
                                            ></i>

                                            &nbsp;&nbsp;
                                            <h4 class="fs-0">
                                                {{ item.title }}
                                            </h4>
                                        </div>
                                    </div>
                                    <div
                                        class="accordion-content"
                                        *ngIf="isOpen(i)"
                                        style="padding: 0px 18px"
                                    >
                                        <p class="accordian-bg">
                                            Total Amount :
                                            {{ item?.total_amount }}
                                        </p>

                                        <p class="accordian-bg">
                                            Success Amount :
                                            {{ item?.success_amount }}
                                        </p>
                                        <p class="accordian-bg">
                                            Pending Amount :
                                            {{ item?.pending_amount }}
                                        </p>
                                        <p class="accordian-bg">
                                            Failed Amount :
                                            {{ item?.failed_amount }}
                                        </p>
                                    </div>
                                </div>

                                <!-- </div> -->
                                <!-- <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Service Title</th>
                                        <th>Total Amount</th>
                                        <th>Success Amount</th>
                                        <th>Pending Amount</th>
                                        <th>Failed Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let service of services;
                                            let i = index
                                        "
                                    >
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ service.title }}</td>
                                        <td>{{ service.total_amount }}</td>
                                        <td>
                                            <span class="badge badge-success">{{
                                                service.success_amount
                                            }}</span>
                                        </td>
                                        <td>
                                            <span class="badge badge-warning">{{
                                                service.pending_amount
                                            }}</span>
                                        </td>
                                        <td>
                                            <span class="badge badge-danger">{{
                                                service.failed_amount
                                            }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container-fluid">
        <div class="row mt-5">
            <div class="col-md-3 mx-auto mt-5 text-decoration-underline">
                <h1>Welcome Aboard!</h1>
            </div>
        </div>
    </div> -->
</section>
