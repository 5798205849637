
<div>
<div id="certificate" class="certificate mt-3">
    <div class="row">
        <div class="col-md-6">
            <p class="text-left">Agent Code: {{code}}</p>
        </div>
        <div class="col-md-6">
            <p class="text-right">Date Of Issue: {{this.doj | date: 'dd-MM-yyyy'}}</p>
        </div>
    </div>
    
    <pf-image
    src="assets/img/logo-removebg-preview.png"
    alt="SDDS"
    class="brand-image"
    height="45"
    width="100%"
    style="opacity: 0.8"
   
></pf-image>

<div class="row mt-2">
    <i class="fas fa-award" style="font-size: 65px;color: #ea9b22; "></i>
</div>
    <h1 class="text-center color-blue mt-3">Certificate of Authorization</h1>
    <p class="text-center">Here By Certifies That</p>
    <h2 class="text-center color-orange mb-0 text-capitalize">{{first_name}} {{last_name}}</h2>
    <p class="text-center">{{address}}</p>
    <p>is an authorized Retailer Of SDDS and authorized to conduct Business on its Behalf with effect from the date of Issuance.</p>
    <p class="text-left mt-5 mb-0">Authorized Signature</p>
    <h4 class="text-left">SDDS</h4>
  </div>
  
</div>