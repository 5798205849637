import { Component, Input } from '@angular/core';

interface RowData{
  id: number
  user_id: number
  result: number
  message: string
  status: string
  order_id: string
  transaction_id: string
  unique_transaction_reference: number
  name: string
  account_number: number
  bank_name: string
  raw_amount: number
  ifsc_code: string
  mobile_number: number
  created_at: string
  updated_at: string
  surcharge: number
}

@Component({
  selector: 'app-dmt-express-receipt',
  templateUrl: './dmt-express-receipt.component.html',
  styleUrl: './dmt-express-receipt.component.scss'
})
export class DmtExpressReceiptComponent {
  @Input() row: RowData;
}
