<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Reports</h1>

            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">BBPS Report</li>
                </ol> -->
            </div>
        </div>
    </div>
</section>

<section class="d-print-none content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body table-responsive">
                        <table id="bbps-table" datatable [dtOptions]="dtOptions" class="table row-border hover" width="100%"></table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-------------Txn Status Modal-->
<div class="modal fade" id="txnStatus" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color:#f1f1f1">
        <div class="col-md-12 p-0">
          <h4 class="modal-title" style="text-align: center;"> Transaction Status </h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="details">
            <p> Transaction Status : <b>{{txnStatusResponse?.txnStatus}}</b> </p>
            <p> Agent Id : <b>{{txnStatusResponse?.agentId}}</b> </p>
            <p> Amount : <b>{{txnStatusResponse?.amount}}</b> </p>
            <p> Biller Id  : <b>{{txnStatusResponse?.billerId}}</b> </p>
          </div>
        </div>
      </div>

      <div class="modal-footer border-top text-right">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onCloseModal()">Close</button>
      </div>
    </div>
  </div>
</div>

<!----------Complaint List Modal-->
<div class="modal fade" id="complaintListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color:#f1f1f1">
        <div class="col-md-12 p-0">
          <h4 class="modal-title" style="text-align: center;"> Complaint List </h4>
        </div>
    
      </div>
      <div class="modal-body">
        <div class="row">
        <div class="col-md-6">         
          <button class="btn btn-info" style="background-color:#17a2b8" (click)="onAddNewComplaint()"> <i class="fa fa-plus"> Add new</i> </button>
       </div>
       <div class="col-md-6">
         <div class="input-group mb-2">
           <input type="text" class="form-control" placeholder="Search Complaint..." [(ngModel)]="searchQuery">
           <div class="input-group-append">
             <span class="input-group-text"><i class="fas fa-search"></i></span>
           </div>
         </div>
       </div>
      </div>
        <div class="row">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">SNo.</th>
                <th scope="col">Complaint Type</th>
                <th scope="col">Txn Id</th>
                <th scope="col">Complaint</th>
                <th scope="col">Check Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of filteredComplaints;let i =index;">
                <th scope="row"> {{i+1}} </th>
                <td> {{list?.complaintType}} </td>
                <td> {{list?.txnRefId}} </td>
                <td> {{list?.complaintDesc}} </td>
                <td> <button class="btn-sm btn-primary" (click)="onTrackComplaint(list)"> <i class="fa fa-check"></i> </button> </td>
              </tr>

              <tr *ngIf="complaintListResponse?.length == 0">
                <td colspan="5" style="text-align: center;"> <b>No data Avaialable!</b> </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="modal-footer border-top text-right">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onCloseModal()">Close</button>
      </div>
    </div>
  </div>
</div>

<!------------Add New Comopalint Modal-->
<div class="modal fade" id="addNewComplaint" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color:#f1f1f1">
        <div class="col-md-12 p-0">
          <h4 class="modal-title" style="text-align: center;"> Add New Complaint </h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="addComplaintForm">
            <div class="row">
              <div class="col-md-6">
                <label>Complaint Type</label>
                <input type="text" class="form-control" formControlName="complaintType" placeholder="Complaint Type" value="Transaction" readonly>
              </div>
              <div class="col-md-6">
                <label for="inputState">Select Dispositions</label>
                <select id="inputState" class="form-control" formControlName="complaintDisposition">
                  <option value=""> Select Complaint </option>
                  <option *ngFor="let list of dispositionsList;" value="{{list}}"> {{list}} </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>Complaint Description</label>
                <input type="text" class="form-control" formControlName="complaintDesc" placeholder="Complaint Description">
              </div>
              <div class="col-md-6">
                <label>Other Reason</label>
                <input type="text" class="form-control" formControlName="servReason" placeholder="Other Reason">
              </div>
            </div>
          </form>

        </div>
      </div>

      <div class="modal-footer border-top text-right">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onCloseModal()">Close</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="submitNewComplaint()">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-----Complaint Track Modal-->
<div class="modal fade" id="complaintTrack" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color:#f1f1f1">
        <div class="col-md-12 p-0">
          <h4 class="modal-title" style="text-align: center;"> Complaint Track Status </h4>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="details">
            <p> <b>Complaint Assigned : </b> {{complaintTrackDetails?.complaintAssigned}} </p>
            <p> <b>Complaint Id : </b> {{complaintTrackDetails?.complaintId}} </p>
            <p> <b>Complaint Status : </b> {{complaintTrackDetails?.complaintStatus}} </p>
            <p> <b>Biller Id  : </b> {{complaintTrackDetails?.billerId}} </p>
          </div>
        </div>
      </div>

      <div class="modal-footer border-top text-right">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onCloseModal()">Close</button>
      </div>
    </div>
  </div>
</div>
