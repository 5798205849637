export const sleep = (time: number) =>
    new Promise((res) => setTimeout(res, time));


export const isAllowed = (permission: string, against: string[] = []) => against.includes(permission);


export const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Months are zero based
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    const seconds = ('0' + currentDate.getSeconds()).slice(-2);

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}