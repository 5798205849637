import { Component, ViewChild } from '@angular/core';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;
@Component({
  selector: 'app-dmt-lite-plus-report',
  templateUrl: './dmt-lite-plus-report.component.html',
  styleUrl: './dmt-lite-plus-report.component.scss'
})
export class DmtLitePlusReportComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  public row: any;
  public isCalling = false;
  private printButtonHandler: any;
  private statusUpdateHandler: any;
  private closeModalHandler: any;

  constructor(private dataTableService: DataTableService) {
    this.dataTableService.url = 'reports/dmt-plus'
    this.dataTableService.export_name = 'DMT_LITE_PLUD_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "modal", "name": "modal", "title": "", "orderable": false, "searchable": false },
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "unique_reference_id", title: "Unique Reference Id" },
      { "data": "transaction_amount", title: "Amount" },
      { "data": "surcharge_amount", title: "Surcharge" },
      { "data": "net_amount", title: "Net Amount" },
      { "data": "status", title: "Status", render: function (data, type, row) {
        if (data != null) {
            let color = '';
            if (data.toLowerCase() === 'success') {
                color = 'green';
            } else if (data.toLowerCase() === 'failed') {
                color = 'red';
            } else if (data.toLowerCase() === 'pending') {
                color = 'orange';
            }

            return `<span style="color: ${color};">${data}</span>`;
        } else {
            return data ? data : '-';
        }
    } },
      { "data": "sender_mobile_number", title: "Remitter Mobile Number" },
      { "data": "recipient_name", title: "Recipient Name", class: "d-none" },
      { "data": "mobile_number", title: "Mobile Number", class: "d-none" },
      { "data": "bank_name", title: "Bank Name", class: "d-none" },
      { "data": "bank_account_number", title: "Bank Account Number", class: "d-none" },
      { "data": "ifsc_code", title: "IFSC Code", class: "d-none" },
      { "data": "created_at", title: "Created At" },
      { "data": "raw_transaction_amount", title: "IFSC Code", class: "d-none", "name": "raw_transaction_amount", "orderable": false, "searchable": false },
      { "data": "action", "name": "action", "title": "Details", "orderable": false, "searchable": false },
      { "data": "print", "name": "print", "title": "Action", "render": (_: any, __: any, data: any) => this.actionButton(data) },
    ], function (settings: any, json: any) {
      $("#dmt-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="9">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata',
        locale: {
          format: 'DD-MM-YYYY ',
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDateformat('DD-MM-YYYY'), picker.endDateformat('DD-MM-YYYY')])).draw();
      });
    });
  }

  ngAfterViewInit(): void {
    this.printButtonHandler = (e: { currentTarget: any; }) => {
      this.row = $(e.currentTarget).data('row');
      setTimeout(() => {
        window.print();
      }, 100);
    };

    this.statusUpdateHandler = (e: { currentTarget: any; }) => {
      const targetModalId = $(e.currentTarget).data('target');
      $(targetModalId).modal('show');
    };

    this.closeModalHandler = () => {
      // Close the modal when clicking on the close button
      $('.modal .close').click(function () {
        $(this).closest('.modal').modal('hide');
      });
    };

    $(document).on('click', '.modal .close', this.closeModalHandler);
    $(document).on('click', '.btn-print', this.printButtonHandler);
    $(document).on('click', '.status-update', this.statusUpdateHandler);
  }

  ngOnDestroy(): void {
    $(document).off('click', '.btn-print', this.printButtonHandler);
    $(document).off('click', '.status-update', this.statusUpdateHandler);
    $(document).off('click', '.modal .close', this.closeModalHandler);
  }

  actionButton(data: any): string {
    if (data.transaction_status === 'Pending') {
      return `
          <div class='d-flex gap-2'>
            <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
            <button class='btn btn-sm btn-primary status-update' data-order-id='${data.order_id}'>Update Status</button>
          </div>`;
    } else {
      return `
          <div class='d-flex'>
            <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
          </div>`;
    }


  }
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}