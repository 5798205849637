import { Pipe, PipeTransform } from '@angular/core';
import { ToWords } from 'to-words';

@Pipe({
  name: 'numToWords'
})
export class NumToWordsPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) {
      return ''
    }

    const toWords = new ToWords();
    return toWords.convert(value).toUpperCase();
  }

}
