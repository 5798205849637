import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-brand-header',
  templateUrl: './brand-header.component.html',
  styleUrl: './brand-header.component.scss'
})
export class BrandHeaderComponent {
  appName: string = environment.appName;
}
