import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { DataTableService } from '@services/data-table.service';
import { Modal } from 'bootstrap';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-upi-lite-report',
  templateUrl: './upi-lite-report.component.html',
  styleUrls: ['./upi-lite-report.component.scss']
})
export class UpiLiteReportComponent {
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  public transactionDetail: Modal;
  public transactionDetails: any;
  public dtOptions: DataTables.Settings = {};

  constructor(
    private dataTableService: DataTableService,
    private renderer: Renderer2
  ) {
    this.dataTableService.url = 'reports/toshani/upi';
    this.dataTableService.export_name = 'UPI_LITE_REPORT';
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "transaction_id", "title": "Transaction ID" },
      { "data": "customer_name", "title": "Customer Name" },
      { "data": "virtual_payment_address", "title": "Virtual Payment Address" },
      { "data": "transaction_amount", "title": "Amount" },
      { "data": "net_amount", "name": "net_amount", "title": "Net Amount"},
      { "data": "contact_number", "title": "Contact Number" },
      { "data": "status", "title": "Status" },
      { "data": "created_at", "title": "Created At" },
      { "data": "transaction_details", "title": "Transaction Details", "render": (data: any) => this.renderButton(data) }
    ], function (settings, json) {
      $("#upi-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="7">');
      $("#fromto").daterangepicker({
                timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY HH:mm', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate, picker.endDate])).draw();;
      });
    });
  }

  renderButton(data: any): string {
    const button = this.renderer.createElement('button');
    this.renderer.addClass(button, 'btn');
    this.renderer.addClass(button, 'btn-info');
    this.renderer.addClass(button, 'btn-sm');
    this.renderer.addClass(button, 'btn-view-more');
    this.renderer.setAttribute(button, 'data-model', JSON.stringify(data));
    this.renderer.setProperty(button, 'innerHTML', 'View More');

    // Returning the outerHTML of the button element
    return button.outerHTML;
  }

  ngAfterViewInit(): void {
    this.transactionDetail = new Modal(this.modal.nativeElement, {})

    setTimeout(() => {
      const buttons = document.querySelectorAll('.btn-view-more');
      buttons.forEach(button => {
        button.addEventListener('click', (event) => {
          const rowData = JSON.parse(button.getAttribute('data-model'));
          this.viewMore(rowData);
        });
      });
    }, 1000);
  }

  viewMore(data: any): void {
    this.transactionDetails = data
    this.transactionDetail.show()
  }
}