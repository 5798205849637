import { Component, Input } from '@angular/core';

interface RowData {
  payout_id: string
  mobile_number: number
  raw_transaction_amount: number
  surcharge_amount: number
  account_number: number
  ifsc_code: string
  account_holder_name: string
  transaction_type: string
  transaction_status: string
  bank_reference_number: number
  bank_status: string
  order_reference_number: string
  email_address: string
  created_at: string;
}

@Component({
  selector: 'app-dmt-prime-receipt',
  templateUrl: './dmt-prime-receipt.component.html',
  styleUrl: './dmt-prime-receipt.component.scss'
})
export class DmtPrimeReceiptComponent {
  @Input() row: RowData;
}
