import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrl: './hospital.component.scss'
})
export class HospitalComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  operators: string[] = ['B.K.', 'Billrath'];

  constructor(private fb: FormBuilder,
    public api : ApiService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['', Validators.required],
      mobileNumber: ['', Validators.required]
    });
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }

  fetchBill() {
    console.log('fetchBill')
  }
}
