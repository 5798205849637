<!-- Content Header (Page header) -->
<section class="content-header no-print">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Fund transfer Service</h1>
      </div>
      <div class="col-sm-6">
        <!-- <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">OBDX Pay</li>
        </ol> -->
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid ">
    <div class="row" *ngIf="!isLoggedIn && !isOTPNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Authorize</h3>
          </div>
          <div class="card-body">
            <form autocomplete="off" [formGroup]="customerVerificationForm" (ngSubmit)="verification()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number <strong class="text-danger">*</strong></label>
                <input formControlName="mobileNumber" type="tel" [maxlength]='10' class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerVerificationForm.get('mobileNumber').invalid && (customerVerificationForm.get('mobileNumber').dirty || customerVerificationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerVerificationForm.get('mobileNumber')?.errors?.['required']">Mobile
                    number is required.
                  </div>
                  <div *ngIf="customerVerificationForm.get('mobileNumber').errors.pattern">Please
                    enter a valid Indian
                    mobile
                    number.</div>
                </div>
              </div>
              <pf-button [variant]="'info'" [loading]="isLoading" (click)="verification()">
                Continue
              </pf-button>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isOTPNeeded && !isLoggedIn">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Register</h3>
          </div>
          <div class="card-body">

            <form autocomplete="off" [formGroup]="customerRegistrationForm" (ngSubmit)="register()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number</label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerRegistrationForm.get('mobileNumber').invalid && (customerRegistrationForm.get('mobileNumber').dirty || customerRegistrationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('mobileNumber').errors?.required">Mobile number is required.
                  </div>
                  <div *ngIf="customerRegistrationForm.get('mobileNumber').errors?.pattern">Please enter a valid Indian
                    mobile number.</div>
                </div>
              </div>

              <div class="form-group">
                <label for="name">Your Name</label>
                <input formControlName="name" type="text" class="form-control" id="name"
                  placeholder="Please enter your name.">
                <div
                  *ngIf="customerRegistrationForm.get('name').invalid && (customerRegistrationForm.get('name').dirty || customerRegistrationForm.get('name').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('name').errors?.required">Your name is required.</div>
                </div>
              </div>

              <div class="form-group">
                <label for="otp">OTP</label>
                <input formControlName="otp" type="text" class="form-control" id="otp"
                  placeholder="Please enter the OTP received.">
                <div
                  *ngIf="customerRegistrationForm.get('otp').invalid && (customerRegistrationForm.get('otp').dirty || customerRegistrationForm.get('otp').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('otp').errors?.required">OTP is required.</div>
                  <div *ngIf="customerRegistrationForm.get('otp').errors?.pattern">Please enter a valid OTP.</div>
                </div>
              </div>

              <button type="submit" class="d-none"></button>
              <pf-button [variant]="'info'" [loading]="isLoading" (click)="register()">
                Continue
              </pf-button>
            </form>


          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isLoggedIn">
      <!-- <div class="col-md-12 d-flex content-right">
        <pf-button [variant]="'info'" [loading]="isLoading" >
          Account Statement
        </pf-button>
      </div> -->
      <div class="col-md-8 mt-3">
        <div class="card">
          <div class="card-header">
            <!-- <div class="card-title mb-0" >
              <small><strong>Hi, {{customerName}}</strong></small><br />
              <small>Acc Number : {{accNumber}}<strong></strong></small><br />
              <small>Current Balance : ₹ {{balance}}<strong></strong></small>
            </div> -->
            <div class="card-tools mt-3">
              <pf-button [variant]="'danger'" [loading]="false" (click)="changeRemitter()">Change
                Remitter</pf-button>
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="accountForm">
              <div >
                <div class="form-group">
                  <label for="mobileNumber" class="form-label">Account Holder Name</label>
                  <input type="text" class="form-control" id="account_holder_name"
                    formControlName="account_holder_name">
                  <div
                    *ngIf="accountForm.get('account_holder_name').invalid && accountForm.get('account_holder_name').touched"
                    class="text-danger text-sm font-weight-bold">
                    Account Holder Name is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="beneficiaryId" class="form-label">Account Holder mobile number</label>
                  <input type="text" class="form-control" id="account_holder_mobile_number"
                    formControlName="account_holder_mobile_number">
                  <div
                    *ngIf="accountForm.get('account_holder_mobile_number').invalid && accountForm.get('account_holder_mobile_number').touched"
                    class="text-danger text-sm font-weight-bold">
                    Account Holder mobile number is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="bank_name" class="form-label">Bank Name</label>
                  <input type="text" class="form-control" id="bank_name" formControlName="bank_name">
                  <div *ngIf="accountForm.get('bank_name').invalid && accountForm.get('bank_name').touched"
                    class="text-danger text-sm font-weight-bold">
                    Bank Name is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="beneficiaryId" class="form-label">Account Number</label>
                  <input type="text" class="form-control" id="account_number" formControlName="account_number">
                  <div *ngIf="accountForm.get('account_number').invalid && accountForm.get('account_number').touched"
                    class="text-danger text-sm font-weight-bold">
                    Account Number is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="beneficiaryId" class="form-label">IFSC Code</label>
                  <input type="text" class="form-control" id="ifsc_code" formControlName="ifsc_code">
                  <div *ngIf="accountForm.get('ifsc_code').invalid && accountForm.get('ifsc_code').touched"
                    class="text-danger text-sm font-weight-bold">
                    IFSC Code is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="beneficiaryId" class="form-label">Remarks <strong class="text-danger">*</strong></label>
                  <select formControlName="narration" class="form-control" id="payment"
                 >
                  <option value="">Select purpose</option>
                 <option value="Deposit into known persons account">Deposit into known persons account</option>
                 <option value="Deposit into Family/ Friends account">Deposit into Family/ Friends account</option>
                 <option value="Deposit into own account">Deposit into own account</option>
                 <optgroup label="Other" >
                  <option value="Other">Payment towards Job Offers</option>
                  <option value="Other">Payment towards claiming Lottery Winning</option>
                  <option value="Other">Payment towards claiming awards</option>
                  <option value="Other">Payment towards claiming gift</option>
                  <option value="Other">Payment towards claiming inheritance</option>

              </select>
                </div>
                <div class="form-group">
                <label for="payment">Payment Mode <strong class="text-danger">*</strong></label>
                <select formControlName="paymentMode" class="form-control" id="payment"
                    (change)="onSelectMode($event.target.value)">
                    <option value="">Select Payment Mode</option>
                   <!-- <option value="neft">NEFT</option>
                   <option value="rtgs">RTGS</option> -->
                   <option value="imps">IMPS</option>
                   <!-- <option value="fund transfer">Canara Bank Transfer</option> -->
                </select>
              </div>
              </div>
              <div class="form-group">
                <label for="amount" class="form-label">Amount <strong class="text-danger">*</strong></label>
                <input type="tel" class="form-control" prefix="₹" mask="00 || 000 || 0,000 || 00,000 || 0,00,000"
                  thousandSeparator="," [min]="200" [max]="200000" id="amount" formControlName="amount" >

                <small>{{ accountForm?.value?.amount| numToWords }}</small>
                <div *ngIf="accountForm.get('amount').invalid && accountForm.get('amount').touched"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="accountForm.get('amount').errors?.required">Amount is required.</div>
                  <div *ngIf="accountForm.get('amount').errors?.min">Amount must be at least ₹{{min}}.</div>
                  <div *ngIf="accountForm.get('amount').errors?.max">Amount cannot exceed ₹{{max}}.</div>
                </div>
              </div>

              <div *ngIf="this.accountForm.value.narration == 'Other'"
              class="text-danger text-sm font-weight-bold">
              Other Transactions are prohibited
            </div>
              <pf-button [variant]="'info'" [disabled]="isLoading || this.accountForm.value.narration == 'Other'" [loading]="isLoading"
                (click)="confirmTransfer()" >Continue</pf-button>
            </form>
          </div>

        </div>
      </div>
      <div class="col-md-4 mt-3">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mt-2">Beneficiary List</h3>
            <div class="card-tools">
              <pf-button [variant]="'info'" [block]="true" [loading]="isLoading" [disabled]="isLoading" (click)="addBeneficiaryModal.show()">
                Add Beneficiary
              </pf-button>
            </div>
          </div>
          <div class="card-body">
            <div class="input-group mb-2">
              <input type="text" class="form-control" placeholder="Search beneficiaries..." [(ngModel)]="searchQuery">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-search"></i></span>
              </div>
            </div>
            <div class="list-group"  *ngIf="filteredBeneficiaries.length === 0 ">
              <p style="font-weight: 700;">No result found</p>
            </div>
            <div class="list-group" *ngIf="filteredBeneficiaries.length">
              <div *ngFor="let beneficiary of filteredBeneficiaries"
                class="list-group-item d-flex justify-content-between">
                <div>
                  <h5 class="mb-1">{{beneficiary.bank_account_holder_name}}</h5>
                  <small class="mb-1">A/c: {{beneficiary.bank_account_number}}</small> <br />
                  <small class="mb-1">IFSC: {{beneficiary.bank_ifsc}}</small>
                </div>
                <div id="btnGroup" class="d-flex justify-content-between align-items-center gap-2">
                  <pf-button [variant]="'success'" [loading]="false" (click)="details(beneficiary)">
                    <i class="fas fa-money-check" title="Transfer"></i>
                  </pf-button>
                  <pf-button [variant]="'danger'" [loading]="false" (click)="delete(beneficiary)">
                    <i class="fas fa-trash-alt" title="Delete"></i>
                  </pf-button>
                </div>
              </div>

              <div *ngIf="filteredBeneficiaries.length === 0 && beneficiaries.length !== 0"
                class="list-group-item d-flex justify-content-between">
                <h3 class="mb-0">No matching beneficiaries found.</h3>
              </div>

              <div *ngIf="!beneficiaries.length" class="list-group-item d-flex justify-content-between">
                <h3 class="mb-0">No beneficiary found.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #modal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Beneficiary</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="addBeneficiaryModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form autocomplete="off" [formGroup]="beneficiaryForm" (ngSubmit)="addBeneficiary()">

              <div class="form-group">
                <label for="bankName">Bank Name <strong class="text-danger">*</strong></label>
                <ng-select formControlName="bankName" id="bankName"
                [ngClass]="{'is-invalid': beneficiaryForm.get('bankName').invalid && (beneficiaryForm.get('bankName').dirty || beneficiaryForm.get('bankName').touched)}" (change)="getIfsc(beneficiaryForm.get('bankName').value)">
                <ng-option *ngFor="let bank of banks" [value]="bank.bankName">{{bank.bankName}}</ng-option>
              </ng-select>
                <div
                  *ngIf="beneficiaryForm.get('bankName').invalid && (beneficiaryForm.get('bankName').dirty || beneficiaryForm.get('bankName').touched)"
                  class="text-danger text-sm font-weight-bold">
                  Bank name is required.
                </div>
              </div>
             
              <div class="form-group">
                <label for="ifscCode">IFSC Code <strong class="text-danger">*</strong></label>
                <input type="text" class="form-control" formControlName="ifscCode" id="ifscCode"
                  placeholder="Enter IFSC code">
                <div
                  *ngIf="beneficiaryForm.get('ifscCode').invalid && (beneficiaryForm.get('ifscCode').dirty || beneficiaryForm.get('ifscCode').touched)"
                  class="text-danger text-sm font-weight-bold">
                  IFSC code is required.
                </div>
              </div>

              <div class="form-group">
                <label for="accountNumber">Account Number <strong class="text-danger">*</strong></label>
                <input type="text" class="form-control" formControlName="accountNumber" id="accountNumber"
                  placeholder="Enter account number">
                <div
                  *ngIf="beneficiaryForm.get('accountNumber').invalid && (beneficiaryForm.get('accountNumber').dirty || beneficiaryForm.get('accountNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  Account number is required.
                </div>
              </div>
              <div class="form-group text-right mb-0">
                <button type="button" class="btn btn-primary btn-sm"
                (click)="verifyAccountNumber()">Verify</button>
              </div>

              <div class="form-group">
                <label for="accountHolderName">Account Holder Name <strong class="text-danger">*</strong></label>
                <input type="text" class="form-control" formControlName="accountHolderName" id="accountHolderName"
                  placeholder="Enter account holder name" readonly>
                <div
                  *ngIf="beneficiaryForm.get('accountHolderName').invalid && (beneficiaryForm.get('accountHolderName').dirty || beneficiaryForm.get('accountHolderName').touched)"
                  class="text-danger text-sm font-weight-bold">
                  Account holder name is required.
                </div>
              </div>

              <div class="form-group">
                <label for="accountHolderMobileNumber">Account Holder Mobile Number <strong
                    class="text-danger">*</strong></label>
                <input type="email" class="form-control" formControlName="accountHolderMobileNumber"
                  id="accountHolderMobileNumber" placeholder="Enter account holder mobile number">
                <div
                  *ngIf="beneficiaryForm.get('accountHolderMobileNumber').invalid && (beneficiaryForm.get('accountHolderMobileNumber').dirty || beneficiaryForm.get('accountHolderMobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  Account holder mobile number is required.
                </div>
              </div>
            
            
              <button type="submit" class="d-none"></button>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="addBeneficiary()">
                Save
              </pf-button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- TPinmodal -->
    <div #TPinmodal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add TPin</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="resetForms(true); tPinModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form autocomplete="off" [formGroup]="accountForm" (ngSubmit)="verifyTpin()">

              <div class="form-group">
                <label for="tPin">TPin <strong class="text-danger">*</strong></label>
                <div class="input-group">
                <input class="form-control" [type]="showPassword ? 'text' : 'password'" formControlName="tpin" id="tpin"
                  placeholder="Please enter TPin."
                  [ngClass]="{'is-invalid': accountForm.get('tpin').invalid && (accountForm.get('tpin').dirty || accountForm.get('tpin').touched)}">
                  <div class="input-group-append" (click)="togglePasswordVisibility()">
                    <div class="input-group-text">
                      <span class="toggle-icon fas" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></span>
                    </div>
                  </div>
                  </div>
                <div
                  *ngIf="accountForm.get('tpin').invalid && (accountForm.get('tpin').dirty || accountForm.get('tpin').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="accountForm.get('tpin').errors.required">TPin is required.</div>
                  <div *ngIf="accountForm.get('tpin').errors.pattern">Please enter a valid TPin code.
                  </div>
                </div>
              </div>
               
              <button type="submit" class="d-none"></button>
              <button type="submit" class="btn btn-primary" [disabled]="isLoading == true">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="receiptmodal" class="modal fade " data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" >
        <div class="modal-header" style="display: block;">
          <div>
            <img src="./assets/img/logo.png" alt="Company Logo" class="logo align-center" width="20%" >
        </div>
        <h4 class="mt-2 text-center" >Fund Transfer Receipt</h4>
        <hr>
        </div>
        <div id="mainReceipt" style="display: block !important;">
          <table *ngIf="row" >
            <tr>
              <td style="width: 50%">
                <div class="info">
                    <strong>Receiver's Name:</strong> {{ row.beneficiary_name }}
                </div>
                <div class="info">
                    <strong>Bank Name:</strong> {{ row.benef_bank_name }}
                </div>
                <div class="info">
                    <strong>Account Number:</strong>
                    {{ row.destination_acct_number }}
                </div>
                <div class="info">
                    <strong>IFSC Code:</strong> {{ row.ifsc_code }}
                </div>
                <div class="info">
                    <strong>Transaction Type:</strong>
                    {{ row.transaction_type }}
                </div>
                <div class="info">
                    <strong>Total Amount in Words:</strong>
                    ₹{{ extractPriceAsInt(row.txn_amount) | numToWords }}
                </div>
            </td>
                <td style="width: 50%;" align="left" >
                    <div class="info" style="text-transform: capitalize;"><strong>Retailer's Name:</strong> {{this.first_name }} {{this.last_name}} </div>
                    <!-- <div class="info"><strong>Account Number:</strong> {{ row.destination_acct_number }}</div> -->
                    <div class="info"><strong>Member Id:</strong> {{ this.member_id}}</div>
                    <div class="info"><strong>Mobile Number:</strong> {{ this.mobile }}</div>
                    <div class="info"><strong>Email:</strong> {{ this.email }}</div>
                   
                </td>
            </tr>
            <tr>
                <td colspan="2">
                  <table class="innerTable">
                    <tr>
                        <th *ngIf="row.transaction_type === 'NEFT' ||row.transaction_type === 'RTGS'">UTR</th>
                        <th *ngIf="row.transaction_type === 'IMPS' ||row.transaction_type === 'INTERNAL TRANSFER' ">RRN Number</th>
                        <th>User ref id</th>
                        <th>Amount</th>
                        <th>Transaction Date</th>
                        <th>Status</th>
                        
                    </tr>
                    <tr>
                        <td *ngIf="row.transaction_type === 'NEFT' ||row.transaction_type === 'RTGS'  ">{{ row.utr }}</td>
                        <td *ngIf="row.transaction_type === 'IMPS' ||row.transaction_type === 'INTERNAL TRANSFER'  ">{{ row.transaction_ref_no }}</td>

                        <td>{{ row.user_ref_no }}</td>
                        <td>{{ extractPrice(row.txn_amount)}}</td>
                        <td>{{row.created_at}}</td>
                        <td>{{ row.status }}</td>
                       
                    </tr>
                </table>
                </td>
            </tr>
          
            <tr>
                <td colspan="2">
                    <hr>
                    <p style="text-align: center;"><strong>Note:</strong>
                        This is a computer-generated receipt and does not require any physical signature
                    </p>
                </td>
            </tr>
        </table>
        </div>
        <div class="d-flex" style="justify-content: center;
        padding-bottom: 10px;">
        <button class="btn btn-primary no-print" id="printButton" (click)="print()">Print</button>
        <button class="btn btn-primary no-print ml-2" id="printButton" (click)="closeModal()">Cancel</button>
      </div>
      </div>
    </div>
  </div>
   
  </div>

</section>

