<div class="m-3">
    <div class="row gap-2">
        <div class="col-md-6 cardContent bg-white p-4 shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Dth Payment</h6>
                <div>
                    <img
                        src="assets/img/BharatBillPay.png"
                        alt="SDDS"
                        class="brand-image"
                        height="80px"
                        />
                </div>
            </div>

            <form [formGroup]="paymentForm" (ngSubmit)="getBill()">
                <div class="form-group">
                    <label for="operator">Operator <span class="text-danger">*</span></label>
                    <select formControlName="operator" class="form-control" id="operator"
                        [ngClass]="{ 'is-invalid': paymentForm.get('operator').invalid && (paymentForm.get('operator').dirty || paymentForm.get('operator').touched) }">
                        <option value="">Select operator</option>
                        <option *ngFor="let operator of operators" [value]="operator">{{ operator }}</option>
                    </select>
                    <div *ngIf="paymentForm.get('operator').invalid && (paymentForm.get('operator').dirty || paymentForm.get('operator').touched)"
                        class="text-danger">
                        Operator is required
                    </div>
                </div>

                <div class="form-group" *ngIf="showFields">
                    <label for="mobileNumber">Registered Mobile Number / Viewing Card Number<span class="text-danger">*</span></label>
                    <input placeholder="Enter Registered Mobile Number / Viewing Card Number" type="text" formControlName="mobileNumber" class="form-control" id="mobileNumber"
                        [ngClass]="{ 'is-invalid': paymentForm.get('mobileNumber').invalid && (paymentForm.get('mobileNumber').dirty || paymentForm.get('mobileNumber').touched) }">
                    <div *ngIf="paymentForm.get('mobileNumber').invalid && (paymentForm.get('mobileNumber').dirty || paymentForm.get('mobileNumber').touched)"
                        class="text-danger">
                        Registered Mobile Number / Viewing Card Number is required
                    </div>
                </div>

                <div class="form-group" *ngIf="showFields">
                    <label for="amount">Amount <span class="text-danger">*</span></label>
                    <input type="number" formControlName="amount" class="form-control" id="amount"
                        [ngClass]="{ 'is-invalid': paymentForm.get('amount').invalid && (paymentForm.get('amount').dirty || paymentForm.get('amount').touched) }">
                    <div *ngIf="paymentForm.get('amount').invalid && (paymentForm.get('amount').dirty || paymentForm.get('amount').touched)"
                        class="text-danger">
                        Amount is required
                    </div>
                </div>
                <pf-button *ngIf="showFields" [variant]="'primary'" [loading]="isLoading" [disabled]="isLoading"
                    (click)="getBill()">
                    Get Bill
                </pf-button>
            </form>

        </div>
    </div>
</div>
