<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Give Funds</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Give Funds</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="card p-4 mt-4">
    <form (submit)="submitForm()" [formGroup]="fundsForm">
      <div class="form-group">
        <label for="member_id">Member ID <strong class="text-danger">*</strong></label>
        <select class="form-control" id="member_id" formControlName="member_id"
          [ngClass]="{'is-invalid': fundsForm.get('member_id').invalid && (fundsForm.get('member_id').dirty || fundsForm.get('member_id').touched)}">
          <option value="" disabled selected>Select Member ID</option>
          <option *ngFor="let user of users | keyvalue" [value]="user.key">{{ user.value }}</option>
          
        </select>
        <div *ngIf="fundsForm.get('member_id').invalid && fundsForm.get('member_id').touched"
          class="text-danger text-sm font-weight-bold">Member ID is required.</div>
      </div>

      <div class="form-group">
        <label for="amount">Amount <strong class="text-danger">*</strong></label>
        <input placeholder="Please enter amount here" type="text" class="form-control" prefix="₹" mask="00 || 000 || 0,000 || 00,000 || 0,00,000"
          thousandSeparator="," id="amount" formControlName="amount"
          [ngClass]="{'is-invalid': fundsForm.get('amount').invalid && (fundsForm.get('amount').dirty || fundsForm.get('amount').touched)}">
        <small>{{ fundsForm?.value?.amount| numToWords }}</small>
        <div *ngIf="fundsForm.get('amount').invalid && fundsForm.get('amount').touched"
          class="text-danger text-sm font-weight-bold">Amount is required.</div>
      </div>

      <div class="form-group">
        <label for="narration">Narration <strong class="text-danger">*</strong></label>
        <textarea placeholder="Please enter narration here" class="form-control" id="narration" formControlName="narration"
          [ngClass]="{'is-invalid': fundsForm.get('narration').invalid && (fundsForm.get('narration').dirty || fundsForm.get('narration').touched)}"></textarea>
        <div *ngIf="fundsForm.get('narration').invalid && fundsForm.get('narration').touched"
          class="text-danger text-sm font-weight-bold">Narration is required.</div>
      </div>
      <pf-button [variant]="'primary'" [loading]="isLoading" (click)="confirmTransfer();">
        Continue
      </pf-button>
    </form>
  </div>

  <!-- TPinmodal -->
  <div #TPinmodal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add TPin</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="resetTpinForm(); tPinModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form autocomplete="off" [formGroup]="fundsForm" (ngSubmit)="submitForm()">

            <div class="form-group">
              <label for="tPin">TPin <strong class="text-danger">*</strong></label>
              <input type="password" class="form-control" formControlName="tpin" id="tpin" placeholder="Please enter TPin."
                [ngClass]="{'is-invalid': fundsForm.get('tpin').invalid && (fundsForm.get('tpin').dirty || fundsForm.get('tpin').touched)}">
              <div
                *ngIf="fundsForm.get('tpin').invalid && (fundsForm.get('tpin').dirty || fundsForm.get('tpin').touched)"
                class="text-danger text-sm font-weight-bold">
                <div *ngIf="fundsForm.get('tpin').errors.required">TPin is required.</div>
                <div *ngIf="fundsForm.get('tpin').errors.pattern">Please enter a valid TPin code.</div>
              </div>
            </div>

            <button type="submit" class="d-none"></button>
            <button type="submit" class="btn btn-primary" [disabled]="isLoading">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>