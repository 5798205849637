import {Bank, Profile, User} from '@/models/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ApiService} from '@services/api.service';
import {DataService} from '@services/data.service';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {PlusService} from '@services/avenue/plus.service';
import {PennyDropService} from '@services/toshani/penny-drop.service';
import {CanaraService} from '@services/toshani/canara.service';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    profile: Profile;
    user: User;
    states$: Observable<any[]>;
    cities$: Observable<any[]>;
    isLoading: boolean = false;
    public profileForm: FormGroup;
    public roles: any;
    activeTab = 'personal';
    public changeTPin: FormGroup;
    public changePassword: FormGroup;
    public beneficiaryForm: FormGroup;
    public beneficiaries: any = [];
    public banks: Bank[];
    Ifsccode: any;
    bankCode: any;

    constructor(
        private alert: ToastrService,
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private plusService: PlusService,
        private penny: PennyDropService,
        private canaraService:CanaraService
    ) {}
  
    ngOnInit(): void {
        this.dataService.getRoles().subscribe((e) => {
            this.roles = e;
        });
        this.dataService.getProfile().subscribe({
            next: (profile: Profile) => {
                if (profile && profile.data && profile.data.user) {
                    this.profile = profile;
                    console.log(this.profile);
                    this.user = profile.data.user;
                    console.log(this.user.mobile)
                    this.initializeForm();
                }
            },
            error: (error) => {
                console.error('Error fetching profile:', error);
            }
        });
        this.getBanks(this.user.mobile);
        this.loadBeneficiaries(this.user.mobile);
        this.changeTPin = new FormGroup({
            'current-tpin': new FormControl('', Validators.required),
            'new-tpin': new FormControl('', Validators.required),
            'confirm-tpin': new FormControl('', [
                Validators.required,
                this.confirmTPinValidator.bind(this)
            ])
        });
        this.changePassword = new FormGroup({
            'current-password': new FormControl('', Validators.required),
            'new-password': new FormControl('', Validators.required),
            'confirm-password': new FormControl('', [
                Validators.required,
                this.confirmPasswordValidator.bind(this)
            ])
        });

        this.beneficiaryForm = this.formBuilder.group({
            mobileNumber: [''],
            accountHolderName: [
                '',
                [Validators.required, Validators.maxLength(255)]
            ],
            accountHolderMobileNumber: ['', Validators.required],
            // accountType: ['savings'],
            bankName: ['', Validators.required],
            // emailId: [this.email],
            accountNumber: ['', Validators.required],
            ifscCode: ['', Validators.required]
        });

        this.states$ = this.dataService.getStates();
        this.cities$ = this.dataService.getCities();
    }

    initializeForm(): void {
        this.profileForm = this.formBuilder.group({
            first_name: [
                {value: this.user.first_name, disabled: true},
                [Validators.required]
            ],
            last_name: [
                {value: this.user.last_name, disabled: true},
                [Validators.required]
            ],
            dob: [
                {value: this.user.dob, disabled: true},
                [Validators.required]
            ],
            email: [
                {value: this.user.email, disabled: true},
                [Validators.required, Validators.email]
            ],
            mobile: [
                {value: this.user.mobile, disabled: true},
                [Validators.required, Validators.pattern('^[0-9]{10}$')]
            ],
            phone: [
                {value: this.user.phone, disabled: true},
                [Validators.required, Validators.pattern('^[0-9]{10}$')]
            ],
            gender: [
                {value: this.user.gender, disabled: true},
                [Validators.required]
            ],
            member_type: [
                {value: this.user.member_type, disabled: true},
                [Validators.required]
            ],
            aadhar: [
                {value: this.user.aadhar, disabled: true},
                [Validators.required, Validators.pattern('^[0-9]{12}$')]
            ],
            pan: [
                {value: this.user.pan, disabled: true},
                [
                    Validators.required,
                    Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')
                ]
            ],
            state_id: [
                {value: this.user.state_id, disabled: true},
                [Validators.required]
            ],
            city_id: [
                {value: this.user.city_id, disabled: true},
                [Validators.required]
            ],
            pincode: [
                {value: this.user.pincode, disabled: true},
                [Validators.required, Validators.pattern('^[0-9]{6}$')]
            ], // PIN code format
            address: [
                {value: this.user.address, disabled: true},
                [Validators.required]
            ],
            shop_name: [
                {
                    value: this.user.shop_name,
                    disabled: this.user.shop_name ? true : false
                }
            ],
            business_pan: [
                {
                    value: this.user.business_pan,
                    disabled: this.user.business_pan ? true : false
                }
            ],
            gst: [
                {value: this.user.gst, disabled: this.user.gst ? true : false}
            ],
            shop_address: [
                {
                    value: this.user.shop_address,
                    disabled: this.user.shop_address ? true : false
                }
            ]
        });
    }

    update() {
        var error = false;
        Object.keys(this.profileForm.controls).forEach((key) => {
            const control = this.profileForm.get(key);

            if (control.errors && !control.disabled) {
                control.markAsTouched();
                error = true;
            }
        });

        if (error) {
            this.alert.error('Please fille all the mandatory fields');
            return;
        }

        this.apiService.profileEdit(this.profileForm.getRawValue()).subscribe({
            next: (response: any) => {
                if (response.status) {
                    this.alert.success(response.message);
                    return;
                }

                this.alert.error('Failed to edit profile');
            },
            error: ({error}) => {
                if (error.message) this.alert.error(error.message);
                console.error('Error updating profile:', error.message);
            }
        });
    }

    getBanks(num: any) {
        this.plusService.banks(num).subscribe((banks: any) => {
            this.banks = banks.data;
            // console.log(this.banks);
        });
    }
    getIfsc(code: any) {
        console.log(code);
        this.Ifsccode = this.banks.find((obj: any) => obj.bankName == code);
        this.bankCode = this.Ifsccode.bankCode;
        this.plusService.ifscCode(this.Ifsccode.bankCode).subscribe({
            next: (res: any) => {
                console.log(res);
                this.beneficiaryForm.patchValue({
                    ifscCode: res.masterIfscCode
                });

                // this.beneficiaryForm.get('ifsc').disable();
            },
            error: (err) => {
                console.log(err);
            }
        });
    }
    isFieldInvalid(fieldName: string): boolean {
        const control = this.profileForm.get(fieldName);
        return control?.invalid && (control?.dirty || control?.touched);
    }
    private confirmTPinValidator(control: FormControl): {[s: string]: boolean} {
        if (!this.changeTPin) {
            return {tpinNotMatch: true};
        }
        if (control.value !== this.changeTPin.get('new-tpin').value) {
            return {tpinNotMatch: true};
        }
        return null;
    }

    change() {
        const data = this.changeTPin.value;

        if (data['new-tpin'] != data['confirm-tpin']) {
            this.changeTPin.get('confirm-tpin').setErrors({
                tpinNotMatch: true
            });
        }

        if (!this.changeTPin.valid) {
            for (const control in this.changeTPin.controls) {
                if (this.changeTPin.controls.hasOwnProperty(control)) {
                    this.changeTPin.controls[control].markAsTouched();
                }
            }
            return;
        }

        this.apiService
            .changeTpin({
                old_tpin: data['current-tpin'],
                tpin: data['new-tpin'],
                tpin_confirmation: data['confirm-tpin']
            })
            .subscribe({
                next: (res: any) => {
                    this.alert.success(res.message);
                    // Reset the form values
                    this.changeTPin.reset();

                    // Additionally, you can reset the form's validation state as well
                    this.changeTPin.markAsPristine();
                    this.changeTPin.markAsUntouched();
                },
                error: ({error}: {error: any}) => {
                    if (error.message) {
                        this.alert.error(error.message);
                    }
                }
            });
    }
    private confirmPasswordValidator(control: FormControl): {
        [s: string]: boolean;
    } {
        if (!this.changePassword) {
            return {passwordsNotMatch: true};
        }
        if (control.value !== this.changePassword.get('new-password').value) {
            return {passwordsNotMatch: true};
        }
        return null;
    }
    updatePassword() {
        const data = this.changePassword.value;

        if (data['new-password'] != data['confirm-password']) {
            this.changePassword.get('confirm-password').setErrors({
                passwordsNotMatch: true
            });
        }

        if (!this.changePassword.valid) {
            for (const control in this.changePassword.controls) {
                if (this.changePassword.controls.hasOwnProperty(control)) {
                    this.changePassword.controls[control].markAsTouched();
                }
            }
            return;
        }

        this.apiService
            .changePassword({
                old_password: data['current-password'],
                password: data['new-password'],
                password_confirmation: data['confirm-password']
            })
            .subscribe({
                next: (res: PasswordChangeRespone) => {
                    this.alert.success(res.message);
                    // Reset the form values
                    this.changePassword.reset();

                    // Additionally, you can reset the form's validation state as well
                    this.changePassword.markAsPristine();
                    this.changePassword.markAsUntouched();
                },
                error: ({error}: {error: PasswordChangeRespone}) => {
                    if (error.message) {
                        this.alert.error(error.message);
                    }
                }
            });
    }
    checkValidation() {
        const step = ['ifscCode', 'accountNumber'];

        const form = this.beneficiaryForm;

        let allControlsValid = true;
        for (let i = 0; i < step.length; i++) {
            const control = step[i];
            const formControl = form.get(control);
            if (
                formControl &&
                !formControl.valid &&
                formControl.status != 'DISABLED'
            ) {
                allControlsValid = false;
                formControl.markAsTouched();
            }
        }

        if (allControlsValid) {
            return true;
        }
        return false;
    }
    verifyAccountNumber() {
        if (!this.checkValidation()) {
            this.beneficiaryForm.get('ifscCode').markAsTouched();
            this.beneficiaryForm.get('accountNumber').markAsTouched();
            // this.beneficiaryForm.get('accountHolderName').markAsTouched();

            return;
        }
        // const {accountNumber, ifscCode, code, bankName,accountHolderName} =
        //     this.beneficiaryForm.getRawValue();
        this.isLoading = true;
        this.penny
            .isValidTwo({
                accountNumber: this.beneficiaryForm.value.accountNumber,
                ifsc: this.beneficiaryForm.value.ifscCode
            })
            .subscribe({
                next: (response: any) => {
                    console.log(response);
                    if (!response.status) {
                        this.alert.error(response.message);
                        this.isLoading = false;

                        return;
                    }

                    this.beneficiaryForm.patchValue({
                        accountHolderName: response.data.registered_name,
                        ifscCode: response.data.ifsc,
                        accountNumber: response.data.account_number
                    });
                    this.isLoading = false;

                    // this.beneficiaryForm.get('ifscCode').disable();
                    // this.beneficiaryForm.get('accountNumber').disable();
                    // this.beneficiaryForm.get('accountHolderName').disable();

                    this.alert.success(response.message);
                },
                error: ({error}) => {
                    this.isLoading = false;
                }
            });
    }
    addBeneficiary() {
        const form = this.beneficiaryForm;
        console.log(form);

        const bankDetails = {
            // mobile: this.user.mobile,
            bank_name: this.beneficiaryForm.value.bankName,
            bank_account_number: this.beneficiaryForm.value.accountNumber,
            bank_account_holder_name:
                this.beneficiaryForm.value.accountHolderName,
            bank_ifsc: this.beneficiaryForm.value.ifscCode,
            mobile:
                this.beneficiaryForm.value.accountHolderMobileNumber,
            status: 1,
            isOwnAccount:0
        };
        console.log(bankDetails);
        if (!form.valid) {
            this.beneficiaryForm.markAsDirty();
            this.alert.error('Invalid Form');
            return;
        }

        this.isLoading = true;
        this.canaraService.AddBankDetails(bankDetails).subscribe({
            next: (response: any) => {
                this.isLoading = false;
                if (!response.status) {
                    this.alert.error(response.message);
                    return;
                }

                this.loadBeneficiaries(this.user.mobile);
                // this.addBeneficiaryModal.hide();
                this.alert.success(response.message);
                this.restBeneficiaryForm();
            },
            error: ({error}) => {
                this.isLoading = false;
                console.log(error);
                this.restBeneficiaryForm();
            }
        });
    }
    restBeneficiaryForm() {
      this.beneficiaryForm.patchValue({
          accountHolderName: '',
          accountHolderMobileNumber: '',
          bankName: '',
          accountNumber: '',
          ifscCode: ''
      });

      // this.beneficiaryForm.get('ifscCode').enable();
      // this.beneficiaryForm.get('accountNumber').enable();
      // this.beneficiaryForm.get('accountHolderName').enable();

      this.beneficiaryForm.markAsPristine();
      this.beneficiaryForm.markAsUntouched();
  }
  private loadBeneficiaries(mobileNumber: any) {
    this.canaraService.BankList().subscribe({
        next: (response: any) => {
            if (!response) {
                this.alert.error(response.message);
                return;
            }
            else{
            this.beneficiaries = response;
            console.log(this.beneficiaries)
        }
        },
        error: (error) => {
            console.log(error, 'error');
        }
    });
}
delete(beneficiary: any) {
  this.isLoading = true;
  this.canaraService.deleteBenef({"id":beneficiary.id}).subscribe({
      next: (response: any) => {
          if (!response.status) {
              this.alert.error(response.message);
          }
          this.restBeneficiaryForm();

        
          this.loadBeneficiaries(this.user.mobile);
          this.alert.success(response.message);
          this.isLoading = false;
      },
      error: (error) => {
          this.isLoading = false;
      }
  });
}
}
export interface PasswordChangeRespone {
    status: boolean;
    message: string;
}
