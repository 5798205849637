<!-- <div class="card card-outline card-primary ">
  <app-brand-header />
  <div class="card-body">
   
  </div>
</div> -->

<!-- <div class="card card-outline card-primary ">
  <app-brand-header />
  <div class="card-body">
   
  </div>
</div> -->

<div class=" login-container">
  <div class="row " style="justify-content: center;">
    
    <div class="col-md-5 " style="position: relative;">

      <div class="login-form">
        <div class="profile-img">
          <img src="./assets/img/logo.png" alt="logo">
        </div>
        <h3>Login</h3>

        <!-- <p class="login-box-msg">Sign in to start your session</p> -->

        <form [formGroup]="loginForm" *ngIf="!isOTPNeeded">
          <div class="form-group">
            <div class="input-group">
              <input formControlName="username" type="tel" maxlength="10" class="form-control" placeholder="Username" [ngClass]="{ 'is-invalid': isFormSubmitted && f.username.errors }" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div *ngIf="isFormSubmitted && f.username.errors"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="f.username.errors.required">Username is required.</div>
              <div *ngIf="f.username.errors.pattern">Please enter a valid Username.</div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input formControlName="password" [type]="showPassword ? 'text' : 'password'" class="form-control"
                placeholder="Password" [ngClass]="{ 'is-invalid': isFormSubmitted && f.password.errors }" />
              <div class="input-group-append" (click)="togglePasswordVisibility()">
                <div class="input-group-text">
                  <span class="toggle-icon fas" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></span>
                </div>
              </div>
            </div>
            <div *ngIf="isFormSubmitted && f.password.errors" class="text-danger text-sm font-weight-bold">
              <div *ngIf="f.password.errors.required">Password is required.</div>
            </div>
          </div>
    
          <div class="icheck-primary d-flex justify-content-between">
            <div class="col-auto">
              <div class="form-check mb-0"><input class="form-check-input" id="basic-checkbox" type="checkbox" formControlName="termscondition"
                [ngClass]="{ 'is-invalid': isFormSubmitted && f.termscondition.errors }" (change)="onChangeHandler($event)"><a href="/terms-and-condition" target="_blank">Terms & Conditions </a></div>
                <div *ngIf="isFormSubmitted && f.termscondition.errors"  class="text-danger text-sm font-weight-bold">
                <div *ngIf="f.termscondition.errors.required">Please accept the terms & conditions.</div>
              </div>
            </div>
            <div class="col-auto"><a class="fs--1 fw-semi-bold" href="javascript:void(0);" [routerLink]="['/forgot-password']">Forgot Password?</a></div>
          </div>

          <div class="row">
            <!-- <div class="icheck-primary d-flex justify-content-between">
              <div>
                <input type="checkbox" id="remember" formControlName="termscondition"
                [ngClass]="{'is-invalid': loginForm.get('termscondition').invalid && (loginForm.get('termscondition').touched || isFormSubmitted)}" />
                &nbsp;<label for="remember" class="Terms_text"> <a [routerLink]="['/t&C']">Terms & Conditions </a></label>
              </div>

              <a [routerLink]="['/forgot-password']"> Forgot </a>
            </div> -->
            <!-- <div class="col-md-12">
              
              <div
                *ngIf="loginForm.get('termscondition').invalid && (loginForm.get('termscondition').touched || isFormSubmitted)"
                class="text-danger text-sm font-weight-bold">
                <div *ngIf="loginForm.get('termscondition').errors.required">Please accept the terms & conditions.</div>
              </div>
    
            </div> -->
          
            <div class="col-md-12 my-2">
              <pf-button [variant]="'primary'" [loading]="isAuthLoading" [disabled]="isFacebookLoading || isGoogleLoading"
                (click)="loginByAuth()" >
                Sign In
              </pf-button>
            </div>
         
          </div>
        </form>
    
        <form [formGroup]="validateForm" *ngIf="isOTPNeeded" (ngSubmit)="validateAndLogin()">
          <div class="form-group">
            <div class="input-group mb-3">
              <input formControlName="username" type="tel" maxlength="10" class="form-control" placeholder="Username" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div
              *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched)"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="loginForm.get('username').errors.required">Username is required.
              </div>
              <div *ngIf="loginForm.get('username').errors.pattern">Please enter a valid Username.</div>
            </div>
          </div>
    
          <div class="form-group">
            <div class="input-group mb-3">
              <input formControlName="otp" type="tel" maxlength="6" class="form-control" placeholder="OTP" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
    
            <div *ngIf="loginForm.get('otp').invalid && (loginForm.get('otp').dirty || loginForm.get('otp').touched)"
              class="text-danger text-sm font-weight-bold">
              <div *ngIf="loginForm.get('otp').errors.required">OTP is required.
              </div>
              <div *ngIf="loginForm.get('otp').errors.pattern">Please enter a valid OTP.</div>
            </div>
          </div>
    
          <button type="submit" class="d-none"></button>
          <pf-button [variant]="'primary'" [loading]="isAuthLoading" [disabled]="isAuthLoading" class="my-2"
            (click)="validateAndLogin()">
            Sign In
          </pf-button>
        </form>
    
        <!-- <p class="mb-1 d-flex" style="justify-content: space-between;">
          <a [routerLink]="['/forgot-password']"> forgot </a>
          <a [routerLink]="['/']">Tpin</a>
        </p> -->
        <p class="mb-0">
          <a [routerLink]="['/register']" class="text-center">
            Register a new membership
          </a>
        </p>
      </div>
      
      
     
    </div>
    <div class="col-md-7 ads">
       <img src="https://cdn.prod.website-files.com/5f6e16afd274a43996712135/6134d14c5906657078944f6e_SEO-Optimization.gif" class="w-100 login_left_img" alt="">
    </div>
  </div>
</div>