import { Component, Input } from '@angular/core';

// Define an interface for the row data
interface RowData {
  customer_name: string;
  id: number;
  order_id: string;
  transaction_id: string;
  raw_amount: number
  surcharge: number;
  mobileNumber: string;
  transaction_status: string;
  customer_account_number: string;
  bank_name: string;
  ifsc_code: string;
  details: {
    id: number;
    transaction_id: number;
    utr: string;
    title: string;
    amount: string;
    ref_id: string;
    status: string;
    txn_id: string;
    created_at: string;
    updated_at: string;
  }[];
  created_at: string;
}

@Component({
  selector: 'app-dmt-lite-receipt',
  templateUrl: './dmt-lite-receipt.component.html',
  styleUrl: './dmt-lite-receipt.component.scss'
})
export class DmtLiteReceiptComponent {
  @Input() row: RowData;
}
