import { Component, Input } from '@angular/core';

// Define an interface for the row data
interface RowData {
  unique_reference_id: any
  raw_transaction_amount: number
  transaction_amount: number
  sender_mobile_number: number
  recipient_name: string
  mobile_number: number
  bank_name: string
  bank_account_number: number
  ifsc_code: string
  created_at: string
  details: {
    unique_reference_id: string
    bank_transaction_id: string
    customer_convenience_fee: string
    transaction_id: string
    unique_transaction_reference: string
    transaction_amount: string
    transaction_status: string
  }[]
}

@Component({
  selector: 'app-dmt-lite-plus-receipt',
  templateUrl: './dmt-lite-plus-receipt.component.html',
  styleUrl: './dmt-lite-plus-receipt.component.scss'
})
export class DmtLitePlusReceiptComponent {
  @Input() row: RowData;
}
