import {Component, ComponentRef, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {Modal} from 'bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Bank, City, Profile, State} from '@/models/common';
import {BankDetails} from '@/models/toshani';
import {SafeXPayService} from '@services/payout/safe-xpay.service';
import {Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {isAllowed} from '@/utils/helpers';
import {PERMISSIONS} from '@/utils/constants';
import {finalize, identity} from 'rxjs';
import {ApiService} from '@services/api.service';
import {PennyDropService} from '@services/toshani/penny-drop.service';
import {CanaraService} from '@services/toshani/canara.service';
import {environment} from 'environments/environment';
import {PlusService} from '@services/avenue/plus.service';
// import { ConnectionService } from 'ng-connection-service';
@Component({
    selector: 'app-obdx',
    templateUrl: './obdx.component.html',
    styleUrl: './obdx.component.scss'
})
export class OBDXComponent implements OnInit, OnDestroy {
    private isConnected:any;
    public isLoading: boolean = false;
    public isLoggedIn: boolean = false;
    public isOTPNeeded: boolean = false;
    public mobileNumber: number;
    public accountForm: FormGroup;
    public customerVerificationForm: FormGroup;
    public customerRegistrationForm: FormGroup;
    public beneficiaryForm: FormGroup;
    public states: State[];
    public cities: City[];
    public addBeneficiaryModal: Modal;
    public beneficiary: any;
    searchQuery: string = '';
    public min: any;
    public max: any;
    @ViewChild('TPinmodal', {static: false})
    public TPinmodal: ElementRef<HTMLDivElement>;
    public tPinModal: Modal;
    @ViewChild('modal', {static: false})
    public modal: ElementRef<HTMLDivElement>;
    public remitter: {name: any; number: any; limit: {remaining: any}};
    public beneficiaries: any = [];
    payMode: any;
    tpinFieldAdded: boolean = false;
    member_id: any;
    public banks: Bank[];
    public walletBalance: any;
    email: any;
    row:any;
    first_name:any;
    last_name:any;
    mobile:any;
    rrn_no:any;
    componentRef: ComponentRef<any>;
    Ifsccode:any;
    bankCode:any
  public showPassword: boolean = false;

    get filteredBeneficiaries() {
        return this.beneficiaries
            ? this.beneficiaries.filter((beneficiary) =>
                  beneficiary.bank_account_holder_name
                      .toLowerCase()
                      .includes(this.searchQuery.toLowerCase())
              )
            : [];
    }

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private alert: ToastrService,
        private data: DataService,
        private api: ApiService,
        private payout: SafeXPayService,
        private penny: PennyDropService,
        private canaraService: CanaraService,
        private plusService: PlusService,
        // private connectionService: ConnectionService,
    ) {

        // this.connectionService.monitor().subscribe(isConnected => {
        //     this.isConnected = isConnected;
        //     console.log(this.isConnected);
           
        //     if (isConnected.hasNetworkConnection) {
        //       // Reload the page when connection is restored
        //     //   window.location.reload();
        //     this.alert.success('Connected')
        //     console.log('connected')
        //     }
        //     if (isConnected.hasNetworkConnection ==false){
        //     this.alert.error('No Internet Connection')

        //         console.log('offline');
        //     }
        //   });
        const datauser = JSON.parse(localStorage.getItem('user'));
        this.member_id = datauser.member_id;
        this.email = datauser.email;
    }

    ngOnInit() {
        this.data.getProfile().subscribe((profile: Profile) => {
            if (!isAllowed(PERMISSIONS.FUND_TRANS, profile.data.permission)) {
                this.alert.info('You are not allowed to access this resource.');
                this.router.navigate(['/']);
            }
            this.first_name =profile.data.user.first_name;
            this.last_name =profile.data.user.last_name;
            this.mobile=profile.data.user.mobile;
           
            this.walletBalance = profile.data.user['wallet'].balance;
            // console.log(this.walletBalance);
        });

        this.data.getStates().subscribe((states: State[]) => {
            this.states = states;
        });

        this.data.getCities().subscribe((cities: City[]) => {
            this.cities = cities;
        });

        this.customerVerificationForm = this.formBuilder.group({
            mobileNumber: [
                '',
                [Validators.required, Validators.pattern(/^[6-9]\d{9}$/),Validators.maxLength(10)]
            ]
        });

        this.customerRegistrationForm = this.formBuilder.group({
            mobileNumber: [
                {value: '', disabled: true},
                [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]
            ],
            otp: [
                '',
                [Validators.required]
            ],
            name: ['', Validators.required]
        });

        this.accountForm = this.formBuilder.group({
            mobileNumber: [{value: '', disabled: true}, Validators.required],
            beneficiaryId: [{value: '', disabled: true}, Validators.required],
            account_holder_name: [
                {value: '', disabled: true},
                Validators.required
            ],
            account_holder_mobile_number: [
                {value: '', disabled: true},
                Validators.required
            ],
            bank_name: [{value: '', disabled: true}, Validators.required],
            account_number: [{value: '', disabled: true}, Validators.required],
            ifsc_code: [{value: '', disabled: true}, Validators.required],
            amount: [
                '',
                [Validators.required, Validators.min(0), Validators.max(200000)]
            ],
            tpin: [''],
            paymentMode: ['', Validators.required],
            txnCurrency: [{value: 'INR', disabled: true}],
            narration: ['']
        });

        this.beneficiaryForm = this.formBuilder.group({
            mobileNumber: [''],
            accountHolderName: [
                '',
                [Validators.required, Validators.maxLength(255)]
            ],
            accountHolderMobileNumber: ['', Validators.required],
            accountType: ['savings'],
            bankName: ['', Validators.required],
            emailId: [this.email],
            accountNumber: ['', Validators.required],
            ifscCode: ['', Validators.required]
        });

        this.api.getMinMax('payout', 'safexpay').subscribe({
            next: (response: any) => {
                this.min = response.data.min_start_range;
                this.max = response.data.max_end_range;
                // console.log(this.min);
                // console.log(this.max);

                this.accountForm.controls['amount'].setValidators([
                    Validators.required,
                    Validators.min(this.min),
                    Validators.max(this.max)
                ]);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    ngAfterViewInit() {
        this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {});
        this.tPinModal = new Modal(this.TPinmodal.nativeElement, {});
    }
    ngOnDestroy() {
        // Any cleanup or reinitialization you need to handle on each load
        if (this.componentRef) {
            this.componentRef.destroy();
        }
      }
    reloadComponent() {
        // Re-initialize component data or logic here
        this.ngOnDestroy();
      }
    verification() {
        const form = this.customerVerificationForm;
        if (!form.valid) {
            // this.alert.error('Invalid Form');
            this.customerVerificationForm.markAllAsTouched();
            this.customerVerificationForm.controls['mobileNumber'].setValue('');
            return;
        }

        const mobileNumber = form.value.mobileNumber;
        this.isLoading = true;

        this.canaraService
            .mobileVerify(
                {'mobileNumber':mobileNumber}
            )
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response: any) => {
                   
                     if(response.data.isUser == false){
                  
                        this.customerRegistrationForm.patchValue({
                            mobileNumber
                        });
                        this.isOTPNeeded = true;
                        this.isLoggedIn= false;
                        // this.alert.error("Enter Verified Mobile Number")
                     } 
                     else{  
                    this.isLoggedIn = true;
                    this.beneficiaryForm.patchValue({mobileNumber});
                    this.alert.success(response.message);

                    this.loadBeneficiaries(form.value.mobileNumber);
                    this.getBanks(form.value.mobileNumber);
                }
                },
                error: (error: any) => {
                    console.log(error);
                    this.isLoading = false;
                }
            });
    }

    register() {
        const form = this.customerRegistrationForm;
        if (!form.valid) {
            this.alert.error('Invalid Form');
            return;
        }

        this.isLoading = true;

        this.canaraService
            .verifyOtp(form.getRawValue())
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response: any) => {
                    if (!response.status) {
                        this.alert.error(response.message);
                        return;
                    }

                    this.isOTPNeeded = true;
                    this.isLoggedIn = true;
                    this.alert.success(response.message);

                    this.beneficiaryForm.patchValue({
                        mobileNumber: form.getRawValue().mobileNumber
                    });

                    this.loadBeneficiaries(form.getRawValue().mobileNumber);
                },
                error: (error: any) => {
                    // this.alert.error(error.message)
                    this.isLoading = false;
                }
            });
    }
    resendOTP() {
        const form = this.customerVerificationForm;
        if (!form.valid) {
            // this.alert.error('Invalid Form');
            this.customerVerificationForm.markAllAsTouched();
            this.customerVerificationForm.controls['mobileNumber'].setValue('');
            return;
        }

        const mobileNumber = form.value.mobileNumber;
        this.isLoading = true;

        this.canaraService
            .resend(mobileNumber)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response: any) => {
                    if (!response.status) {
                        this.customerRegistrationForm.patchValue({
                            mobileNumber
                        });
                        this.isOTPNeeded = true;
                        return;
                    }
                     if(response.data.isVerified == false){
                        this.customerRegistrationForm.patchValue({
                            mobileNumber
                        });
                        // this.isOTPNeeded = true;
                        // this.isLoggedIn= false;
                        this.alert.error("Enter Verified Mobile Number")
                     } 
                     else{  
                    this.isLoggedIn = true;
                    this.beneficiaryForm.patchValue({mobileNumber});
                    this.alert.success(response.message);

                    this.loadBeneficiaries(form.value.mobileNumber);
                    this.getBanks(form.value.mobileNumber);
                }
                },
                error: (error: any) => {
                    console.log(error);
                    this.isLoading = false;
                }
            });
    }


    getBanks(num: any) {
        this.plusService.banks(num).subscribe((banks: any) => {
            this.banks = banks.data;
            // console.log(this.banks);
        });
    }
    getIfsc(code: any) {
        // console.log(code)
    this.Ifsccode = this.banks.find((obj:any) => obj.bankName == code);
        this.bankCode = this.Ifsccode.bankCode
        this.plusService.ifscCode(this.Ifsccode.bankCode).subscribe({
            next: (res: any) => {
                // console.log(res);
                this.beneficiaryForm.patchValue({
                    ifscCode: res.masterIfscCode
                });

                // this.beneficiaryForm.get('ifsc').disable();
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

    changeRemitter() {
        this.isLoggedIn = false;
        this.customerVerificationForm.controls['mobileNumber'].setValue('');
    }
    addBeneficiary() {
        const form = this.beneficiaryForm;
       
        const bankDetails ={
            "mobile":this.customerVerificationForm.value.mobileNumber,
            "bank_name":this.beneficiaryForm.value.bankName,
            "bank_account_number":this.beneficiaryForm.value.accountNumber,
            "bank_account_holder_name":this.beneficiaryForm.value.accountHolderName,
            "bank_ifsc":this.beneficiaryForm.value.ifscCode,
            "BeneficiaryMobile":this.beneficiaryForm.value.accountHolderMobileNumber,
            "status":1
        }
       
        if (!form.valid) {
            this.beneficiaryForm.markAsDirty();
            this.alert.error('Invalid Form');
            return;
        }

        this.isLoading = true;
        this.canaraService.remitterAddBank(bankDetails).subscribe({
            next: (response: any) => {
                this.isLoading = false;
                if (!response.status) {
                    this.alert.error(response.message);
                    return;
                }

                this.loadBeneficiaries(form.value.mobileNumber);
                this.addBeneficiaryModal.hide();
                this.alert.success(response.message);
                this.restBeneficiaryForm();
            },
            error: ({error}) => {
                this.isLoading = false;
                console.log(error);
                this.restBeneficiaryForm();
            }
        });
    }

    restBeneficiaryForm() {
        this.accountForm.controls['account_holder_name'].setValue('');
        this.accountForm.controls['account_holder_mobile_number'].setValue('');
        this.accountForm.controls['bank_name'].setValue('');
        this.accountForm.controls['account_number'].setValue('');
        this.accountForm.controls['ifsc_code'].setValue('');
        this.accountForm.controls['narration'].setValue('');
        this.accountForm.controls['paymentMode'].setValue('');
        this.accountForm.controls['amount'].setValue('');

        this.beneficiaryForm.patchValue({
            accountHolderName: '',
            accountHolderMobileNumber: '',
            bankName: '',

            accountNumber: '',
            ifscCode: ''
        });

        // this.beneficiaryForm.get('ifscCode').enable();
        // this.beneficiaryForm.get('accountNumber').enable();
        // this.beneficiaryForm.get('accountHolderName').enable();

        this.beneficiaryForm.markAsPristine();
        this.beneficiaryForm.markAsUntouched();
    }

    details(beneficiary: any) {
        this.beneficiary = beneficiary;
        this.accountForm.patchValue({
            mobileNumber: beneficiary.mobile,
            beneficiaryId: beneficiary.id,
            account_holder_name: beneficiary.bank_account_holder_name.replace(
                /[^a-zA-Z0-9 ]/g,
                ''
            ),
            account_holder_mobile_number: beneficiary.mobile,
            bank_name: beneficiary.bank_name,
            account_number: beneficiary.bank_account_number,
            ifsc_code: beneficiary.bank_ifsc,
            tpin: ''
            // amount: ''
        });
    }

    delete(beneficiary: any) {
        this.isLoading = true;
        this.canaraService.deleteRemitterBenef({"id":beneficiary.id,"mobile":this.customerVerificationForm.value.mobileNumber}).subscribe({
            next: (response: any) => {
                if (!response.status) {
                    this.alert.error(response.message);
                }
                this.restBeneficiaryForm();

                const form = this.customerVerificationForm;
                this.loadBeneficiaries(form.value.mobileNumber);
                this.alert.success(response.message);
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
            }
        });
    }

    private loadBeneficiaries(mobileNumber: number) {
        this.canaraService.remitterBankList({mobile:mobileNumber}).subscribe({
            next: (response: any) => {
                if (!response) {
                    this.alert.error(response.message);
                    return;
                }
                else{
                this.beneficiaries = response;
              
            }
            },
            error: (error) => {
                console.log(error, 'error');
            }
        });
    }

    verifyTpin() {
        this.isLoading=true;
        const tpindata = {
            tpin: this.accountForm.value.tpin
        };
        this.api.verifyTpin(tpindata).subscribe({
            next: (response: any) => {
                if (response.status == false) {
                    const tpinControl = this.accountForm.get('tpin');
                    tpinControl.setValue('');
                    tpinControl.markAsUntouched();
                    tpinControl.setErrors(null);
                    this.isLoading=false;
                    this.alert.error(response.message);
                    this.showPassword=false
                }
                if (response.status == true) {
                    this.transfer();
                }
            },
            error: (error) => {
                console.log(error, 'error');
                const tpinControl = this.accountForm.get('tpin');
                this.isLoading=false;

                tpinControl.setValue('');
                tpinControl.markAsUntouched();
                tpinControl.setErrors(null);
            }
        });
    }
    transfer() {
        // this.isLoading = true;
       
        const tdata = this.accountForm.getRawValue();
        // console.log(tdata)
    // if (!tdata || this.isLoading) {
    //     for (const control in this.accountForm.controls) {
    //         if (this.accountForm.controls.hasOwnProperty(control)) {
    //             this.accountForm.controls[control].markAsTouched();
    //         }
    //     }
    //     this.alert.error('Please select beneficiary ');
    //     return;
    // }

        const data = this.accountForm.getRawValue();
        if (this.payMode == 'neft') {
            var srcAccountDetails = {
                identity: 'B001',
                currency: 'INR',
                branchCode: environment.can_branchCode
            };
            var destAccountDetails = {
                identity: 'B001',
                currency: 'INR'
            };
            var encryptData = {
                Authorization: environment.can_Auth,
                txnPassword: environment.can_txnpass,
                srcAcctNumber: environment.can_accountNumber,
                destAcctNumber: this.beneficiary.ben_account_no,
                customerID: environment.can_customerID,
                txnAmount: this.accountForm.value.amount,
                benefName: this.beneficiary.ben_name,
                userRefNo: `can${this.member_id}`
            };
            var value = {
                Request: {
                    body: {
                        srcAccountDetails: srcAccountDetails,
                        destAccountDetails: destAccountDetails,
                        txnCurrency: 'INR',
                        ifscCode: this.beneficiary.ben_ifsc_code,
                        narration: this.accountForm.value.narration,
                        paymentMode: 'N',
                        valueDate: '26-08-2024',
                        encryptData: encryptData
                    }
                }
            };
            this.canaraService.neft_payment(value).subscribe({
                next: (response: any) => {
                    this.isLoading = false;
                    this.tPinModal.hide();
                    this.resetForms();
                    this.alert.info(response.message);
                    // this.isLoggedIn = false;
                    // if (!response.status) {
                    //   this.alert.error(response.message);
                    //   return
                    // }
                },
                error: ({error}) => {
                    this.isLoading = false;
                    this.alert.error('Failed');
                    this.tPinModal.hide();
                    this.resetForms();
                    // setTimeout(()=>{
                    //     window.location.reload();
                    // },1000);
                }
            });
        }
        if (this.payMode == 'rtgs') {
            var srcAccountDetails1 = {
                currency: 'INR',
                branchCode: environment.can_branchCode
            };
            var destAccountDetails = {
                identity: 'B001',
                currency: 'INR'
            };
            var encryptData1 = {
                Authorization: environment.can_Auth,
                txnPassword: environment.can_txnpass,
                srcAcctNumber: environment.can_accountNumber,
                destAcctNumber: this.beneficiary.ben_account_no,
                customerID: environment.can_customerID,
                txnAmount: this.accountForm.value.amount,
                benefName: this.beneficiary.ben_name,
                userRefNo: `can${this.member_id}`
            };
            var value1 = {
                Request: {
                    body: {
                        srcAccountDetails: srcAccountDetails1,
                        destAccountDetails: destAccountDetails,
                        txnCurrency: 'INR',
                        benefBankName: this.beneficiary.bank_name,
                        benefBranchName: 'NA',
                        ifscCode: this.beneficiary.ben_ifsc_code,
                        narration: this.accountForm.value.narration,
                        encryptData: encryptData1
                    }
                }
            };
            this.canaraService.rtgs_payment(value1).subscribe({
                next: (response: any) => {
                    this.isLoading = false;
                    this.tPinModal.hide();
                    this.resetForms();
                    this.alert.info(response.message);
                    // this.isLoggedIn = false;

                    // if (!response.status) {
                    //   this.alert.error(response.message);
                    //   return
                    // }
                },
                error: ({error}) => {
                    this.isLoading = false;
                    this.alert.error('Failed');
                    this.tPinModal.hide();
                    this.resetForms();
                }
            });
        }
        if (this.payMode == 'imps') {
            this.isLoading = true;

            var srcAccountDetails2 = {
                currency: 'INR',
                branchCode: environment.can_branchCode,
                branchName: 'NA'
            };
            var destAccountDetails1 = {
                // currency: 'INR',
                // identity:'B001',
                ifscCode: this.beneficiary.bank_ifsc,
                benefBankName: this.beneficiary.bank_name
            };
            var encryptData2 = {
                Authorization: environment.can_Auth,
                txnPassword: environment.can_txnpass,
                srcAcctNumber: environment.can_accountNumber,
                destAcctNumber: this.beneficiary.bank_account_number,
                customerID: environment.can_customerID,
                benefName: this.beneficiary.bank_account_holder_name.replace(
                    /[^a-zA-Z0-9 ]/g,
                    ''
                ),
                txnAmount: this.accountForm.value.amount,
                userRefNo: `can${this.member_id}`
            };
            var value2 = {
                Request: {
                    body: {
                        srcAccountDetails: srcAccountDetails2,
                        destAccountDetails: destAccountDetails1,
                        txnCurrency: 'INR',
                        purpose: this.accountForm.value.narration,
                        encryptData: encryptData2
                    }
                },
                beneficiary: {
                    name: this.beneficiary.bank_account_holder_name.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ''
                    ),
                    accountNumber: this.beneficiary.bank_account_number,
                    mobileNumber: this.beneficiary.mobile,
                    bankName: this.beneficiary.bank_name,
                    ifscCode: this.beneficiary.bank_ifsc
                }
            };
            this.canaraService.imps_payment(value2).subscribe({
                next: (response: any) => {
                    this.tPinModal.hide();
                    this.resetForms();
                    if (response.success == true) {
                    this.isLoading = false;

                        this.alert.success(response.message);
                        this.row=response.details
                        this.rrn_no=response.transaction_ref_no
                        $('#receiptmodal').modal('show');
                    }
                    // console.log(response);
                    // this.isLoggedIn = false;

                    if (!response.success) {
                    this.isLoading = false;

                        this.alert.error(response.message);
                        return;
                    }
                    if (response.success == false) {
                    this.isLoading = false;

                        this.alert.error(response.message);
                        return;
                    }
                },
                error: ({error}) => {
                    this.isLoading = false;
                    this.alert.error('Transaction Failed');
                    this.tPinModal.hide();
                    this.resetForms();
                }
            });
        }
        if (this.payMode == 'fund transfer') {
            var srcAccountDetails3 = {
                currency: 'INR',
                branchCode: environment.can_branchCode,
                identity: 'B001'
            };
            var destAccountDetails2 = {
                currency: 'INR',
                identity: 'B001'
            };
            var encryptData2 = {
                Authorization: environment.can_Auth,
                txnPassword: environment.can_txnpass,
                srcAcctNumber: environment.can_accountNumber,
                destAcctNumber: this.beneficiary.ben_account_no,
                customerID: environment.can_customerID,
                benefName: this.beneficiary.ben_name,
                txnAmount: this.accountForm.value.amount,
                userRefNo: `can${this.member_id}`
            };
            var value3 = {
                Request: {
                    body: {
                        srcAccountDetails: srcAccountDetails3,
                        destAccountDetails: destAccountDetails2,
                        txnCurrency: 'INR',
                        paymentMode: 'N',
                        narration: this.accountForm.value.narration,
                        encryptData: encryptData2
                    }
                }
            };
            this.canaraService.fund_transfer(value3).subscribe({
                next: (response: any) => {
                    this.isLoading = false;
                    this.tPinModal.hide();
                    this.resetForms();
                    // console.log(response);
                    // this.isLoggedIn = false;

                    // if (!response.status) {
                    //     this.alert.error(response.message);
                    //     return;
                    // }

                    this.alert.info(response.txnMessages.result[0].desc);
                },
                error: ({error}) => {
                    this.isLoading = false;
                    this.alert.error('Failed');
                    this.tPinModal.hide();
                    this.resetForms();
                }
            });
        }
    }

    onSelectMode($event) {
        this.payMode = $event;
    }
    print(){
        document.querySelector('body').classList.add('non-printable');
        window.print()
        document.querySelector('body').classList.remove('non-printable');

    }
    closeModal(){
        $('#receiptmodal').modal('hide');
        
    }
    confirmTransfer() {
        const data = this.accountForm.getRawValue();
       
        if (
            this.accountForm.value.amount == 0 ||
            data.account_number == '' ||
            data.paymentMode == '' ||
            data.narration == ''
        ) {
            this.alert.error('Please fill required fields');
            return;
        }
        if(this.accountForm.value.amount < this.min || this.accountForm.value.amount > this.max){
            return;
        }
        if (
            parseInt(this.walletBalance) <
            parseInt(this.accountForm.value.amount)
        ) {
            this.alert.error('Insufficient Wallet balance');
        } else {
            const allowed = confirm('Are you sure you want to transfer?');
            if (allowed) {
                this.openTpinModal();
                const tpinControl = this.accountForm.get('tpin');
                this.isLoading= false;
                tpinControl.setValue('');
                tpinControl.markAsUntouched();
                tpinControl.setErrors(null);
            }
        }
    }

    openTpinModal() {
        if (this.tPinModal) {
            this.accountForm.get('tpin')?.setValidators(Validators.required);
            this.accountForm.get('tpin')?.updateValueAndValidity();
            this.tPinModal.show();
        }
    }

    resetForms(onlyTpin = false) {
        const tpinControl = this.accountForm.get('tpin');
        if (tpinControl) {
            tpinControl.setValue('');
            tpinControl.markAsUntouched();
            tpinControl.setErrors(null);
        }

        if (!onlyTpin) {
            this.accountForm.patchValue({
                beneficiaryId: '',
                account_holder_name: '',
                account_holder_mobile_number: '',
                bank_name: '',
                account_number: '',
                ifsc_code: '',
                amount: '',
                paymentMode: '',
                narration: ''
            });

            this.beneficiary = [];

            this.beneficiaryForm.markAsPristine();
            this.beneficiaryForm.markAsUntouched();
            this.accountForm.markAsPristine();
            this.accountForm.markAsUntouched();
        }
    }

    checkValidation() {
        const step = ['ifscCode', 'accountNumber'];

        const form = this.beneficiaryForm;

        let allControlsValid = true;
        for (let i = 0; i < step.length; i++) {
            const control = step[i];
            const formControl = form.get(control);
            if (
                formControl &&
                !formControl.valid &&
                formControl.status != 'DISABLED'
            ) {
                allControlsValid = false;
                formControl.markAsTouched();
            }
        }

        if (allControlsValid) {
            return true;
        }
        return false;
    }

    verifyAccountNumber() {
        if (!this.checkValidation()) {
            this.beneficiaryForm.get('ifscCode').markAsTouched();
            this.beneficiaryForm.get('accountNumber').markAsTouched();
            this.beneficiaryForm.get('accountHolderName').markAsTouched();

            return;
        }
        // const {accountNumber, ifscCode, code, bankName,accountHolderName} =
        //     this.beneficiaryForm.getRawValue();
        this.isLoading = true;
        this.penny.isValidTwo({
            "accountNumber": this.beneficiaryForm.value.accountNumber,
            "ifsc": this.beneficiaryForm.value.ifscCode,
          })
            .subscribe({
                next: (response: any) => {
                   
                    if (!response.status) {
                        this.alert.error(response.message);
                        this.isLoading = false;

                        return;
                    }

                    this.beneficiaryForm.patchValue({
                        accountHolderName: response.data.registered_name,
                        ifscCode: response.data.ifsc,
                        accountNumber: response.data.account_number
                    });
                    this.isLoading = false;

                    // this.beneficiaryForm.get('ifscCode').disable();
                    // this.beneficiaryForm.get('accountNumber').disable();
                    // this.beneficiaryForm.get('accountHolderName').disable();

                    this.alert.success(response.message);
                },
                error: ({error}) => {
                    this.isLoading = false;
                }
            });
    }
    extractPrice(priceHtml:any) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = priceHtml;
        return tempDiv.textContent || tempDiv.innerText || '';
      }
      extractPriceAsInt(priceHtml: string): number {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = priceHtml;
        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        const numericValue = parseInt(textContent.replace(/[^0-9]/g, ''), 10);
        return isNaN(numericValue) ? 0 : numericValue;
      }
      
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
