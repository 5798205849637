import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-enable-geo-location',
  templateUrl: './enable-geo-location.component.html',
  styleUrl: './enable-geo-location.component.scss'
})
export class EnableGeoLocationComponent implements OnInit, OnDestroy {
  public isCheckingPermissiong: boolean = false;


  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );

    this.grantPermission()
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }

  grantPermission() {
    this.isCheckingPermissiong = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.isCheckingPermissiong = false;
          window.location.href = '/admin';
        },
        error => {
          this.isCheckingPermissiong = false;
          this.redirectToBrowserSettings()
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      this.isCheckingPermissiong = false;
    }
  }

  redirectToBrowserSettings() {
    alert('Geolocation Disabled, enabling Geolocation at chrome://settings/content/location')
    // window.location.href = 'chrome://settings/content/location';
  }
}
