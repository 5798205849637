import { Component, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataTableService } from '@services/data-table.service';
import { AepsService } from '@services/toshani/aeps.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-aeps-wallet-ledger',
  templateUrl: './aeps-wallet-ledger.component.html',
  styleUrl: './aeps-wallet-ledger.component.scss'
})
export class AepsWalletLedgerComponent {
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public ewallet: {
    id: number;
    user_id: number;
    member_id: string;
    balance: number;
    credit: number;
    debit: number;
  } = {
      id: 0,
      user_id: 0,
      member_id: '',
      balance: 0,
      credit: 0,
      debit: 0,
    };

  constructor(private dataTableService: DataTableService,
    private api: ApiService,
    private aeps: AepsService,
    private alert: ToastrService
  ) {

    this.api.aepsWalletBalance().subscribe((e: any) => {
      this.ewallet = e.data;
    });
    if(this.ewallet != null){
    this.dataTableService.url = 'aeps-wallet/history'
    this.dataTableService.export_name = 'AEPS_WALLET_LEDGER_REPORT'
    }
    else{
      this.ewallet.member_id=null
    }
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { 'title': 'Date', 'data': 'created_at' },
      { 'title': 'Transaction ID', 'data': 'transaction_id' },
      { 'title': 'Opening Balance', 'data': 'opening_balance' },
      { 'title': 'Credit/Debit', 'data': 'amount',render: function (data, type, row) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = data; // Extract the numeric value
        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        const amountValue = parseFloat(textContent.replace(/[^0-9.-]/g, ''));

        if (amountValue < 0) {
          return `<span style="color: red;">${textContent}</span>`;
        } else {
          return `<span style="color: green;">${textContent}</span>`;
        }
      } },
      { 'title': 'Closing Balance', 'data': 'closing_balance' },
      { 'title': 'Transaction Description', 'data': 'narration' }
    ], function (settings, json) {
      $("#ewallet-ledger_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="1">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY ', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate.format('DD-MM-YYYY'), picker.endDate.format('DD-MM-YYYY')])).draw();
      });
    });
  }

  transfer() {
    const amount = prompt('How much amount do you want to transfer?');

    // Check if amount is valid
    if (!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
      this.alert.error('Please enter a valid number.', 'Invalid amount')
      return
    }

    this.aeps.transfer({ amount: parseFloat(amount) }).subscribe({
      next: async (response: { status: boolean, message: string }) => {
        if (!response.status) {
          this.alert.error(response.message)
          return
        }

        this.api.aepsWalletBalance().subscribe((e: any) => {
          this.ewallet = e.data;
        });

        (await this.datatableElement.dtInstance).ajax.reload(null, false);

        this.alert.success(response.message)
      },
      error: (error) => { console.log(error) }
    });
  }
}