import { BankDetails, CustomerData } from '@/models/toshani';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Bank, State, City, Profile } from '@/models/common';
import { ToastrService } from 'ngx-toastr';
import { Modal } from 'bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { PlusService } from '@services/avenue/plus.service';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-lite-plus',
  templateUrl: './lite-plus.component.html',
  styleUrl: './lite-plus.component.scss'
})
export class LitePlusComponent {
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public mobileNumber: any;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public customerPinForm: FormGroup;
  public accountForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public remitter: CustomerData;
  public beneficiaries: BankDetails[];
  public beneficiary: BankDetails;
  public addBeneficiaryModal: Modal;
  public banks: Bank[];
  public states: State[];
  public cities: City[];
  public min: 100;
  public max: 25000;
  searchQuery: string = '';
  showDropdown: boolean = false;
  filteredBanks: Bank[] = [];
  public tPinModal: Modal;
  isPinNeeded: boolean = false;


  constructor(private router: Router, private api: ApiService, private formBuilder: FormBuilder, private plusService: PlusService, private alert: ToastrService, private data: DataService) { }

  ngOnInit(): void {

    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.AVENUE_DMT, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required]],
      name: [{ value: '', disabled: true }, Validators.required]
    });

    this.customerPinForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      pin: ['', [Validators.required]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      accountId: [{ value: '', disabled: true }, Validators.required],
      accountName: [{ value: '', disabled: true }, Validators.required],
      accountNumber: [{ value: '', disabled: true }, Validators.required],
      bankName: [{ value: '', disabled: true }, Validators.required],
      ifsc: [{ value: '', disabled: true }, Validators.required],
      amount: ['', [Validators.required, Validators.min(this.min), Validators.max(this.max)]],
      tpin: ['']
    });

    this.beneficiaryForm = this.formBuilder.group({
      ifsc: ['', Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)],
      accountNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{9,18}$/)]],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      recMobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      code: ['', [Validators.required, Validators.maxLength(255)]]
    });

    this.api.getMinMax('dmt-lite-plus', 'avenue').subscribe({
      next: (response: any) => {
        this.min = response.data.min_start_range;
        this.max = response.data.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  }

  getBanks(num: any) {
    this.plusService.banks(num).subscribe((banks: any) => {
      this.banks = banks.data;
    });
  }

  verification() {
    if (this.customerVerificationForm.valid) {
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;
      this.isLoading = true;

      this.plusService.customer(mobileNumber).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (!response.status) {
            this.alert.error(response.message)
            return
          }

          if (!response.data.isVerified) {
            this.customerPinForm.patchValue({
              mobileNumber: mobileNumber
            })

            this.isPinNeeded = true;
            return
          }

          this.isLoggedIn = true;
          this.remitter = response.data
          this.mobileNumber = this.remitter.number
          this.getBanks(this.mobileNumber);
          this.beneficiaries = response.data.beneficiaries
          this.fetchBeneficiaries();
          this.beneficiaryForm.patchValue({
            mobileNumber: mobileNumber
          })
        },
        error: (response) => {
          this.isLoading = false;
        }
      });
    }
  }

  register() {
    if (this.customerPinForm.valid) {
      this.isLoading = true;
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;
      this.plusService.register(this.customerPinForm.getRawValue()).subscribe({
        next: (response: any) => {
          const name = this.customerPinForm.value.name;
          if (!response.status) {
            this.alert.error(response.message)
            return
          }

          if (!response.data.isVerified) {
            this.customerRegistrationForm.patchValue({
              mobileNumber: mobileNumber,
              name: name
            })
            this.isOTPNeeded = true;
            this.isLoading = false;
            this.isPinNeeded = false;
            return
          }
          // this.isPinNeeded = false;
          this.isLoading = false;
          this.remitter = response.data
          this.mobileNumber = this.remitter.number
          this.getBanks(this.mobileNumber);
          this.fetchBeneficiaries();
          this.beneficiaries = response.data.beneficiaries

          this.alert.success(response.message);
        },
        error: (error) => {
          this.isLoading = false;
        }
      });
    } else {
      this.customerPinForm.markAsDirty()
    }
  }

  verifyOTP() {
    if (this.customerRegistrationForm.valid) {
      this.isLoading = true;
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;

      const registrationFormValues = this.customerRegistrationForm.getRawValue();
      const pinFormValues = this.customerPinForm.getRawValue();
      const body = { ...registrationFormValues, code: pinFormValues.pin };

      this.plusService.verification(body).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (!response.status) {
            this.alert.error(response.message)
            return
          }
          this.isLoggedIn = true;
          this.remitter = response.data
          this.mobileNumber = this.remitter.number
          this.getBanks(this.mobileNumber);
          this.beneficiaries = response.data.beneficiaries
          this.fetchBeneficiaries();
          this.beneficiaryForm.patchValue({
            mobileNumber: mobileNumber
          })
        },
        error: (err: any) => {
          this.customerRegistrationForm.markAsDirty()
          this.isLoading = false;
        }
      })
    }

  }

  details(beneficiary: any) {
    this.beneficiary = beneficiary;
    this.accountForm.patchValue({
      accountNumber: beneficiary.ben_account_no,
      bankName: beneficiary.bank_name,
      ifsc: beneficiary.ben_ifsc_code,
      accountId: beneficiary.ben_name,
      accountName: beneficiary.ben_id,
      amount: '',
      tpin: ''
    });
  }

  delete(beneficiary: BankDetails) {
    if (confirm('Are you sure you want to delete')) {
      this.plusService.deleteBeneficiary(
        beneficiary.ben_id, this.mobileNumber
      ).subscribe({
        next: (response: any) => {
          this.alert.success(response.message)
          this.verification()
          this.isLoading = false;
          this.fetchBeneficiaries();
        },
        error: () => {
          this.isLoading = false;
        }
      })
    }
  }

  confirmTransfer() {
    if( this.accountForm.value.amount == 0 || !this.accountForm.valid ){
      this.alert.error('Please select beneficiary and fill required fields');
      return;  
  }
 
    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.accountForm.get('tpin')?.setValidators(Validators.required);
      this.accountForm.get('tpin')?.updateValueAndValidity(); 
      this.tPinModal.show();
    }
  }

  transfer() {
    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    const data = {
      ...this.accountForm.getRawValue(),
      mobileNumber: this.customerVerificationForm.value.mobileNumber
    };

    this.isLoading = true;
    this.plusService.transfer(data).subscribe({
      next: (response: any) => {
        this.alert.success(response.message);
        this.accountForm.reset();
        this.accountForm.markAsPristine();
        this.accountForm.markAsUntouched();
        this.tPinModal.hide();
        this.verification();
        this.isLoading = false;
        this.resetTpinForm();
      },
      error: (error) => {
        this.tPinModal.hide();
        this.isLoading = false;
        this.resetTpinForm();

      }
    });
  }

  get filteredBeneficiaries() {
    return this.beneficiaries.filter(beneficiary =>
      beneficiary.ben_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  resetForm() {

    this.beneficiaryForm.patchValue({
      accountNumber: '',
      code: '',
      recMobileNumber: '',
      ifsc: '',
      name: ''
    })
    this.beneficiaryForm.markAsPristine();
    this.beneficiaryForm.markAsUntouched();

    this.beneficiaryForm.get('ifsc').enable();
    this.beneficiaryForm.get('accountNumber').enable();
    this.beneficiaryForm.get('name').enable();
    this.beneficiaryForm.get('recMobileNumber').enable();
    this.addBeneficiaryModal.hide();
  }

  addBeneficiary() {
    var error = false
    Object.keys(this.beneficiaryForm.controls).forEach(key => {
      const control = this.beneficiaryForm.get(key);

      if (control.errors && !control.disabled) {
        console.log(control.errors)
        control.markAsTouched();
        error = true
      }
    });

    if (error) {
      this.alert.error('Please fille all the mandatory fields');
      return
    }

    this.isLoading = true;
    const formData = {
      ...this.beneficiaryForm.getRawValue(),
      mobileNumber: this.mobileNumber
    };

    this.plusService.addBeneficiaries(formData).subscribe({
      next: (response: any) => {
        if (response.result == 0) {
          this.alert.error(response.message);
          return;
        }
        this.fetchBeneficiaries();
        this.alert.success(response.message);
      },
      error: ({ error }) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  fetchBeneficiaries() {
    this.plusService.beneficiaries(this.mobileNumber).subscribe({
      next: (response: any) => {
        this.beneficiaries = response.data || [];
        this.isLoading = false;
      },
      error: ({ error }) => {
        this.beneficiaries = [];
        this.isLoading = false;
      },
      complete: () => {
        this.resetForm();
        this.isLoading = false;
      }
    });
  }

  verifyAccountNumber() {
    if (!this.checkValidation()) {
      this.beneficiaryForm.get('ifsc').markAsTouched();
      this.beneficiaryForm.get('accountNumber').markAsTouched();
      return;
    }

    const { accountNumber, ifsc, code } = this.beneficiaryForm.getRawValue()
    this.isLoading = true;

    this.plusService.isValid({ accountNumber, ifsc, code, mobileNumber: this.customerVerificationForm.value.mobileNumber }).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.beneficiaryForm.patchValue({
          name: response.data.registered_name,
          ifscCode: response.data.ifsc,
          accountNumber: response.data.account_number,
        });

        this.beneficiaryForm.get('ifsc').disable();
        this.beneficiaryForm.get('accountNumber').disable();
        this.beneficiaryForm.get('name').disable();
        this.beneficiaryForm.get('code').disable();

        this.alert.success(response.message);
      },
      error: ({ error }) => {
        this.isLoading = false;
      }
    });
  }

  checkValidation() {
    const step = ["ifscCode", "accountNumber"];

    const form = this.beneficiaryForm;

    let allControlsValid = true;
    for (let i = 0; i < step.length; i++) {
      const control = step[i];
      const formControl = form.get(control);
      if (formControl && !formControl.valid && formControl.status != 'DISABLED') {
        console.log(formControl);
        allControlsValid = false;
        formControl.markAsTouched();
      }
    }

    if (allControlsValid) {
      return true;
    }
    return false;
  }

  filterBanks(value: string) {
    if (value.trim() === '') {
      this.filteredBanks = this.banks;
    } else {
      this.filteredBanks = this.banks.filter(bank => bank.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  selectBank(bank: any) {
    this.beneficiaryForm.patchValue({
      code: bank.code
    });
    this.filteredBanks = [];
  }

  getIfsc(code: any) {
    this.plusService.ifscCode(code).subscribe({
      next: (res: any) => {
        this.beneficiaryForm.patchValue({
          ifsc: res.masterIfscCode
        });

        // this.beneficiaryForm.get('ifsc').disable();
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  resetTpinForm() {
    const tpinControl = this.accountForm.get('tpin');
    if (tpinControl) {
      tpinControl.setValue('');
      tpinControl.markAsUntouched();
      tpinControl.setErrors(null);
    }
    this.accountForm.patchValue({
      accountId: '',
      accountName: '',
      bankName: '',
      ifsc: '',
      amount: '',
      accountNumber:''
     
  });
    this.beneficiaryForm.markAsPristine();
    this.beneficiaryForm.markAsUntouched();
    this.accountForm.markAsPristine();
    this.accountForm.markAsUntouched();
  }
}
