<div class="card card-primary card-outline card-tabs no-print">
    <div class="card-header p-0 border-bottom-0">
<ul
class="nav nav-tabs d-flex justify-content-center bg-white p-3"
role="tablist"
>
<li class="nav-item mx-4 mb-2" role="presentation" style="margin-left: 15rem !important;" >
    <button
        class="nav-link-bbps custom-nav-link"
        id="prepaid-tab"
        data-bs-toggle="tab"
        data-bs-target="#prepaid"
        type="button"
        role="tab"
        aria-controls="prepaid"
        aria-selected="true"
        (click)="onActiveTab('')"
    >
        <div class="icon-circle">
            <i class="fas fa-mobile-alt"></i>
        </div>
        <span class="font-12">Mobile Recharge</span>
    </button>
</li>
<li class="nav-item mx-4 mb-2" role="presentation">
    <button
        class="nav-link-bbps custom-nav-link"
        id="electricity-tab"
        data-bs-toggle="tab"
        data-bs-target="#electricity"
        type="button"
        role="tab"
        aria-controls="electricity"
        aria-selected="false"
        (click)="onActiveTab('')"
    >
        <div class="icon-circle">
            <i class="far fa-lightbulb"></i>
        </div>
        <span class="font-12">Electricity</span>
    </button>
</li>
<li class="nav-item mx-4 mb-2" role="presentation">
    <button
        class="nav-link-bbps custom-nav-link"
        id="credit-card-tab"
        data-bs-toggle="tab"
        data-bs-target="#credit-card"
        type="button"
        role="tab"
        aria-controls="credit-card"
        aria-selected="false"
        (click)="onActiveTab('')"
    >
        <div class="icon-circle">
            <i class="fas fa-credit-card"></i>
        </div>
        <span class="font-12">Credit Card</span>
    </button>
</li>
<li class="nav-item dropdown mx-4 mb-2" role="presentation">
    <button
        class="nav-link-bbps custom-nav-link dropdown-toggle"
        id="dmt-tab"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <div class="icon-circle">
            <i class="fas fa-money-check-alt"></i>
        </div>
        <span class="font-12">DMT</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dmt-tab">
        <a
            class="dropdown-item nav-link-bbps custom-nav-link"
            id="insurance-tab"
            data-bs-toggle="tab"
            data-bs-target="#insurance"
            type="button"
            role="tab"
            aria-controls="insurance"
            aria-selected="false"
            routerLink="/dmt"
            ><i class="mr-3 fas fa-leaf"></i> Lite</a
        >
        <a
            class="dropdown-item nav-link-bbps custom-nav-link"
            id="landline-tab"
            data-bs-toggle="tab"
            data-bs-target="#landline"
            type="button"
            role="tab"
            aria-controls="landline"
            aria-selected="false"
            routerLink="/dmt/lite-plus"
            ><i class="mr-3 fas fa-level-up-alt"></i> Lite
            Plus</a
        >
    </div>
</li>
<li class="nav-item dropdown mx-4 mb-2" role="presentation">
    <button
        class="nav-link-bbps custom-nav-link dropdown-toggle"
        id="xpress-tab"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <div class="icon-circle">
            <i class="fas fa-bolt"></i>
        </div>
        <span class="font-12">Express</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="xpress-tab">
        <a
            class="dropdown-item nav-link-bbps custom-nav-link"
            id="insurance-tab"
            data-bs-toggle="tab"
            data-bs-target="#insurance"
            type="button"
            role="tab"
            aria-controls="insurance"
            aria-selected="false"
            routerLink="/dmt/express"
           ><i class="mr-3 fas fa-bolt"></i> Express
            Pay</a
        >
        <a
            class="dropdown-item nav-link-bbps custom-nav-link"
            id="landline-tab"
            data-bs-toggle="tab"
            data-bs-target="#landline"
            type="button"
            role="tab"
            aria-controls="landline"
            aria-selected="false"
           routerLink="/dmt/prime"
           ><i class="mr-3 fas fa-gem"></i> Prime pay</a
        >
    </div>
</li>
<li class="nav-item mx-4 mb-2" role="presentation">
    <button
        class="nav-link-bbps custom-nav-link"
        id="credit-card-tab"
        data-bs-toggle="tab"
        data-bs-target="#credit-card"
        type="button"
        role="tab"
        aria-controls="credit-card"
        aria-selected="false"
        routerLink="/fund/transfer"
    >
        <div class="icon-circle">
            <i class="fas fa-exchange-alt"></i>
        </div>
        <span class="font-12">Fund Transfer</span>
    </button>
</li>
<li class="nav-item dropdown mx-4 mb-2" role="presentation">
    <button
        class="nav-link-bbps custom-nav-link dropdown-toggle"
        id="aeps-tab"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <div class="icon-circle">
            <i class="fas fa-fingerprint"></i>
        </div>
        <span class="font-12">AePS</span>
    </button>
    <div class="dropdown-menu" aria-labelledby="aeps-tab">
        <a
            class="dropdown-item nav-link-bbps custom-nav-link"
            id="insurance-tab"
            data-bs-toggle="tab"
            data-bs-target="#insurance"
            type="button"
            role="tab"
            aria-controls="insurance"
            aria-selected="false"
           routerLink="/aeps/two-factor"
            ><i class="mr-3 fas fa-lock"></i> Two Factor Authentication</a
        >
        <a
            class="dropdown-item nav-link-bbps custom-nav-link"
            id="landline-tab"
            data-bs-toggle="tab"
            data-bs-target="#landline"
            type="button"
            role="tab"
            aria-controls="landline"
            aria-selected="false"
           routerLink="/aeps/transaction"
            ><i class="mr-3 fas fa-exchange-alt"></i> Transactions</a
        >
    </div>
</li>
</ul>
</div>
</div>