<div id="mainReceipt">
    <h2>DMT Lite Receipt</h2>
    <hr>
    <table *ngIf="row">
        <tr>
            <td style="width: 50%;">
                <div class="info"><strong>Receiver's Name:</strong> {{row.customer_name}} </div>
                <div class="info"><strong>Mobile Number:</strong> {{row.mobileNumber}}</div>
                <div class="info"><strong>Transaction Date:</strong> {{row.created_at}}</div>
                <div class="info"><strong>Transaction ID:</strong> {{row.transaction_id}}</div>
                <div class="info"><strong>Status:</strong> {{row.transaction_status}}</div>
            </td>
            <td style="width: 50%;" align="right">
                <div>
                    <img src="./assets/img/logo.png" alt="Company Logo" class="logo">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table class="innerTable">
                    <tr>
                        <th>UTR</th>
                        <th>Amount</th>
                        <th>Ref ID</th>
                        <th>Status</th>
                        <th>Txn ID</th>
                    </tr>
                    <tr *ngFor="let detail of row.details">
                        <td>{{detail.utr}}</td>
                        <td>{{detail.amount}}</td>
                        <td>{{detail.ref_id}}</td>
                        <td>{{detail.status}}</td>
                        <td>{{detail.txn_id}}</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div class="info"><strong>Total Amount in Words:</strong> {{row.raw_amount | numToWords}}</div>
                <div class="info"><strong>Account Number:</strong> {{row.customer_account_number}}</div>
                <div class="info"><strong>IFSC Code:</strong> {{row.ifsc_code}}</div>
                <div class="info"><strong>Bank Name:</strong> {{row.bank_name}}</div>
            </td>
        </tr>
        <tr><td colspan="2"></td></tr>
        <tr><td colspan="2"></td></tr>
        <tr>
            <td colspan="2">
                <hr>
                <p style="text-align: center;"><strong>Note:</strong>
                    This is a computer-generated receipt and does not require any physical signature.
                </p>
            </td>
        </tr>
    </table>
</div>