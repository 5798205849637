<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Reports</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">DMT Plus Refund Pending Report</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="d-print-none content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <ul class="nav nav-tabs card-header-tabs">
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/reports/dmt-lite-plus">
                                    <i class="fa fa-list mr-2"></i>Transactions
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" routerLink="/reports/dmt-lite-plus/refund-pending">
                                    <i class="fa fa-plus mr-2"></i>Refund Pending
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Bank Transaction ID</th>
                                    <th>Unique Reference ID</th>
                                    <th>Transaction ID</th>
                                    <th>Unique Transaction Reference</th>
                                    <th>Transaction Amount</th>
                                    <th>Transaction Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                           <tbody>
                            <ng-container *ngFor="let transaction of transactions; let i = index">
                                <tr>
                                  <td>{{ i + 1 }}</td>
                                  <td>{{ transaction.bank_transaction_id }}</td>
                                  <td>{{ transaction.unique_reference_id }}</td>
                                  <td>{{ transaction.transaction_id }}</td>
                                  <td>{{ transaction.unique_transaction_reference }}</td>
                                  <td>{{ transaction.transaction_amount / 100 | number:'1.2-2':'en-US' }}</td>
                                  <td>{{ transaction.transaction_status_description }}</td>
                                  <td class="d-flex gap-2">
                                    <button class="btn btn-sm btn-info" (click)="toggleDetails(transaction)" data-bs-toggle="tooltip" data-bs-placement="top" title="{{ transaction.showDetails ? 'Collapse' : 'Expand' }}">
                                      <i class="fa" [ngClass]="transaction.showDetails ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                                    </button>
                                    <button class="btn btn-sm btn-primary" (click)="status(transaction)" data-bs-toggle="tooltip" data-bs-placement="top" title="Refund">
                                      <i class="fa fa-undo"></i>
                                    </button>
                                  </td>
                                </tr>
                                <tr *ngIf="transaction.showDetails">
                                  <td colspan="9" class="p-0">
                                    <table class="table table-bordered mb-0">
                                      <tr>
                                        <td><strong>Bank Account Number:</strong></td>
                                        <td>{{ transaction.bank_account_number }}</td>
                                        <td><strong>Bank Code:</strong></td>
                                        <td>{{ transaction.bank_code }}</td>
                                        <td><strong>Bank Name:</strong></td>
                                        <td>{{ transaction.bank_name }}</td>
                                      </tr>
                                      <tr>
                                        <td><strong>IFSC Code:</strong></td>
                                        <td>{{ transaction.ifsc_code }}</td>
                                        <td><strong>Mobile Number:</strong></td>
                                        <td>{{ transaction.mobile_number }}</td>
                                        <td><strong>Recipient Name:</strong></td>
                                        <td>{{ transaction.recipient_name }}</td>
                                      </tr>
                                      <tr>
                                        <td><strong>Sender Mobile Number:</strong></td>
                                        <td>{{ transaction.sender_mobile_number }}</td>
                                        <td><strong>Created At:</strong></td>
                                        <td>{{ transaction.created_at }}</td>
                                        <td colspan="2"></td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </ng-container>
                           </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div #modal class="modal fade" id="refund-modal" tabindex="-1" role="dialog" aria-labelledby="refund-modal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Refund Pending Transaction</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="refundModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row" *ngIf="transaction">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="transaction_id">Transaction ID</label>
                                <input type="text" class="form-control" [value]="transaction.transaction_id"
                                    placeholder="Transaction ID" disabled>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="unique_reference_id">Unique Reference ID</label>
                                <input type="text" class="form-control" [value]="transaction.unique_reference_id"
                                    placeholder="Unique Reference ID" disabled>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="otp">OTP</label>
                                <input type="text" class="form-control" [(ngModel)]="otp" mask="000000"
                                    placeholder="Please enter the received OTP">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <pf-button [variant]="'primary'" block="true" size="small" [loading]="isLoading"
                                [disabled]="isLoading" (click)="refund()">
                                Submit
                            </pf-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>