import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrl: './insurance.component.scss'
})
export class InsuranceComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  operators: string[] = ['LIC', 'Bajaj'];

  constructor(private fb: FormBuilder,
    public api : ApiService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['',   Validators.required],
      policyNumber: ['', Validators.required],
      dob: ['', Validators.required]
    });
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }

  fetchBill() {
    console.log('fetchBill')
  }
}
