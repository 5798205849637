<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Lite Plus Reports</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">DMT Plus Report</li>
                </ol> -->
            </div>
        </div>
    </div>
</section>

<section class="d-print-none content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <ul class="nav nav-tabs card-header-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" routerLink="/reports/dmt-lite-plus">
                                    <i class="fa fa-list mr-2"></i>Transactions
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/reports/dmt-lite-plus/refund-pending">
                                    <i class="fa fa-plus mr-2"></i>Refund Pending
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body table-responsive">
                        <table id="dmt-table" datatable [dtOptions]="dtOptions" class="table dataTable no-footer">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-dmt-lite-plus-receipt [row]="row"></app-dmt-lite-plus-receipt>