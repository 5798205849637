import { Bank } from '@/models/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { DataService } from '@services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fund-request',
  templateUrl: './fund-request.component.html',
  styleUrl: './fund-request.component.scss'
})
export class FundRequestComponent {
  public isLoading: boolean = false;
  public fundTransferForm: FormGroup;
  public paymentProof: string;
  public fromBanks: Bank[];
  public banks: {
    id: number
    name: string
    member_name: string
    account_no: string
    account_type: string
    ifsc: string
    branch: string
    type: string
    status: number
    address: string
    created_at: string
    updated_at: string
  }[];
  formSubmitted = false;
  serverErrors: any;
  activeTab: string = 'bank-details'; 

  constructor(private fb: FormBuilder, private apiService: ApiService, private toastr: ToastrService, private data: DataService) { }

  ngOnInit(): void {
    this.apiService.funRequestOwnBanks().subscribe({
      next: (res: any) => {
        this.banks = res.data
      },
      error: ({ error }) => {
        console.log(error);
      }
    });

    this.data.getBanks().subscribe((banks: Bank[]) => {
      this.fromBanks = banks;
    });

    this.createForm();
  }

  createForm(): void {
    this.fundTransferForm = this.fb.group({
      fromBankName: [null, Validators.required],
      toBankName: ['', Validators.required],
      paymentMode: ['', Validators.required],
      paymentProof: [null, Validators.required],
      chequeOrDDNumber: ['', Validators.required],
      chequeDate: [''],
      amount: ['', [Validators.required, Validators.min(0)]],
      remarks: ['', Validators.required]
    });
  }

  request() {
    this.formSubmitted = true;
    if (!this.fundTransferForm.valid) {
      this.fundTransferForm.markAllAsTouched();
      return
    }

    this.isLoading = true;
  setTimeout(()=>{
    this.fundTransferForm.reset();

        // Additionally, you can reset the form's validation state as well
        this.fundTransferForm.markAsPristine();
        this.fundTransferForm.markAsUntouched();
  },3000)
    this.apiService.fundRequest({ ...this.fundTransferForm.value, paymentProof: this.paymentProof }).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.formSubmitted = false;
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          // Reset the form values
          this.fundTransferForm.reset();
          this.fundTransferForm.markAsPristine();
          this.fundTransferForm.markAsUntouched();
        } else {
          this.toastr.error(res.message, 'Error');
          this.fundTransferForm.reset();
          this.fundTransferForm.markAsPristine();
          this.fundTransferForm.markAsUntouched();
        }
      },
      error: ({ error }) => {
        this.isLoading = false;
        this.formSubmitted = false;
        this.fundTransferForm.reset();

        this.fundTransferForm.markAsPristine();
        this.fundTransferForm.markAsUntouched();
        if (error.errors) {
          // this.handleServerErrors(error.errors);
          return
        }
        // alert(error.message);
      }
    })
  }

  handleServerErrors(errors: any) {
    this.serverErrors = errors; // Assign server errors to variable
    // Loop through each error and set it as an error on the corresponding form control
    Object.keys(errors).forEach(key => {
      this.toastr.error(errors[key][0], key);

      const control = this.fundTransferForm.get(key);
      if (control) {
        control.setErrors({ 'serverError': errors[key][0] });
      }
    });
  }

  async paymentProff(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.paymentProof = base64;
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  showTab(tab: string) {
    this.activeTab = tab;
  }
}
