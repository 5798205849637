import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
// import {MENU} from '@modules/main/menu-sidebar/menu-sidebar.component';
// import {PfDropdown} from '@profabric/angular-components';

@Component({
    selector: 'app-sidebar-search',
    templateUrl: './sidebar-search.component.html',
    styleUrls: ['./sidebar-search.component.scss']
})
export class SidebarSearchComponent {
    public searchText: string = '';
    public foundMenuItems = [];
}
