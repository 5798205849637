<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Change Password</h1>
      </div>
      <div class="col-sm-6">
        <!-- <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
          <li class="breadcrumb-item active">Change Password</li>
        </ol> -->
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <!-- Change Password -->
          </div>
          <div class="card-body">
            <form [formGroup]="changePassword">
              <div class="form-group">
                <label for="current-password">Current Password</label>
                <input type="text" class="form-control" formControlName="current-password" id="current-password"
                  placeholder="Current Password"
                  [ngClass]="{ 'is-invalid': changePassword.get('current-password').invalid && (changePassword.get('current-password').dirty || changePassword.get('current-password').touched) }">
                <div
                  *ngIf="changePassword.get('current-password').invalid && (changePassword.get('current-password').dirty || changePassword.get('current-password').touched)"
                  class="invalid-feedback">
                  <strong>Current Password is required</strong>
                </div>
              </div>
              <div class="form-group">
                <label for="new-password">New Password</label>
                <input type="password" class="form-control" formControlName="new-password" id="new-password"
                  placeholder="New Password"
                  [ngClass]="{ 'is-invalid': changePassword.get('new-password').invalid && (changePassword.get('new-password').dirty || changePassword.get('new-password').touched) }">
                <div
                  *ngIf="changePassword.get('new-password').invalid && (changePassword.get('new-password').dirty || changePassword.get('new-password').touched)"
                  class="invalid-feedback">
                  <strong>New Password is required</strong>
                </div>
              </div>
              <div class="form-group">
                <label for="confirm-password">Confirm Password</label>
                <input type="password" class="form-control" formControlName="confirm-password" id="confirm-password"
                  placeholder="Confirm Password"
                  [ngClass]="{ 'is-invalid': changePassword.get('confirm-password').invalid && (changePassword.get('confirm-password').dirty || changePassword.get('confirm-password').touched) }">
                <div
                  *ngIf="changePassword.get('confirm-password').invalid && (changePassword.get('confirm-password').dirty || changePassword.get('confirm-password').touched)"
                  class="invalid-feedback">
                  <div *ngIf="changePassword.get('confirm-password').errors.required">
                    <strong>Confirm Password is required.</strong>
                  </div>
                  <div *ngIf="!changePassword.get('confirm-password').errors.required && changePassword.get('confirm-password').errors.passwordsNotMatch">
                    <strong>Confirm Password do not match.</strong>
                  </div>
                </div>
              </div>
              <div class="form-group text-right">
                <pf-button [variant]="'info'" [loading]="isLoading" [disabled]="isLoading" (click)="change()">
                  Change Password
                </pf-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>