import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { LoadingService } from '@services/loading.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject(DOCUMENT) private document: Document, private authService: AppService, private loadingService: LoadingService,
    private alert: ToastrService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.show();
    return next.handle(request).pipe(
      catchError((error: any) => {

        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout();
        } else if (error instanceof HttpErrorResponse && [400, 422].includes(error.status)) {
          this.handleServerErrors(error?.error?.errors || error?.error)
        } else {
          if (error && error.message && error.status === 0) {
            this.biometricErrors(error);
          } else {
            this.handleServerErrors(error?.error?.errors || error?.error)
          }
        }

        return throwError(() => error); // Use throwError with factory function
      }),
      finalize(() => {
        this.loadingService.hide();
        this.scrollToTop();
      })
    );
  }

  biometricErrors(error: any) {
    const generateErrorMessages = (baseUrls: string[], ports: number[], paths: string[] = ['']) => {
      const messages = [];
      baseUrls.forEach((baseUrl) => {
        ports.forEach((port) => {
          paths.forEach((path) => {
            messages.push(`Http failure response for ${baseUrl}:${port}${path}: 0 Unknown Error`);
            messages.push(`Https failure response for ${baseUrl}:${port}${path}: 0 Unknown Error`);
          });
        });
      });
      return messages;
    };

    const baseUrls = ['http://127.0.0.1', 'http://localhost', 'https://127.0.0.1', 'https://localhost'];
    const ports = [11100, 11101, 11102, 11103, 11104, 11105];
    const paths = ['', '/rd/capture'];

    const avdmErrors = generateErrorMessages(baseUrls, ports);
    const machineErrors = generateErrorMessages(baseUrls, ports, paths);

    const hasAvdm = avdmErrors.includes(error.message);
    const hasMachine = machineErrors.includes(error.message);

    if (hasAvdm) {
      this.handleServerErrors({ message: 'AVDM Not found, please check RD Service.' });
    } else if (hasMachine) {
      this.handleServerErrors({ message: 'Please attach machine, no machine found.' });
    } else {
      this.handleServerErrors({ message: 'Connection refused. Please check your internet connection or server availability.' });
    }
  }


  private scrollToTop() {
    this.document.body.scrollTop = 0;
    this.document.documentElement.scrollTop = 0;
  }

  private handleServerErrors(errors: any) {
    if (errors.message === 'Failed to logout') {
      return
    }

    if (errors.message) {
      this.alert.error(errors.message);
      return
    }

    Object.keys(errors).forEach(key => {
      this.alert.error(errors[key][0], key, {
        timeOut: 10000
      });
    });
  }
}

