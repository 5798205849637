
import {Component, ElementRef, ViewChild} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {Modal} from 'bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Bank, City, Profile, State} from '@/models/common';
import {BankDetails} from '@/models/toshani';
import {SafeXPayService} from '@services/payout/safe-xpay.service';
import {Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {isAllowed} from '@/utils/helpers';
import {PERMISSIONS} from '@/utils/constants';
import {finalize, identity} from 'rxjs';
import {ApiService} from '@services/api.service';
import {PennyDropService} from '@services/toshani/penny-drop.service';
import {CanaraService} from '@services/toshani/canara.service';
@Component({
  selector: 'app-payout',
  standalone: true,
  imports: [],
  templateUrl: './payout.component.html',
  styleUrl: './payout.component.scss'
})
export class PayoutComponent {
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public mobileNumber: number;
  public accountForm: FormGroup;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public states: State[];
  public cities: City[];
  public addBeneficiaryModal: Modal;
  public beneficiary: any;
  searchQuery: string = '';
  public min: any;
  public max: any;
 


  constructor(
      private router: Router,
      private formBuilder: FormBuilder,
      private alert: ToastrService,
      private data: DataService,
      private api: ApiService,
      private payout: SafeXPayService,
      private penny: PennyDropService,
      private canaraService: CanaraService
  ) {}

  ngOnInit() {
     
      this.data.getProfile().subscribe((profile: Profile) => {
          // if (!isAllowed(PERMISSIONS.SAFEXPAY_PAYOUT, profile.data.permission)) {
          //   this.alert.info('You are not allowed to access this resource.');
          //   this.router.navigate(['/']);
          // }
      });

      this.data.getStates().subscribe((states: State[]) => {
          this.states = states;
      });

      this.data.getCities().subscribe((cities: City[]) => {
          this.cities = cities;
      });

      this.customerVerificationForm = this.formBuilder.group({
          mobileNumber: [
              '',
              [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]
          ]
      });

      this.customerRegistrationForm = this.formBuilder.group({
          mobileNumber: [
              {value: '', disabled: true},
              [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]
          ],
          otp: [
              '',
              [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]
          ],
          name: ['', Validators.required]
      });

      this.accountForm = this.formBuilder.group({
          mobileNumber: [{value: '', disabled: true}, Validators.required],
          beneficiaryId: [{value: '', disabled: true}, Validators.required],
          account_holder_name: [
              {value: '', disabled: true},
              Validators.required
          ],
          account_holder_mobile_number: [
              {value: '', disabled: true},
              Validators.required
          ],
          bank_name: [{value: '', disabled: true}, Validators.required],
          account_number: [{value: '', disabled: true}, Validators.required],
          ifsc_code: [{value: '', disabled: true}, Validators.required],
          amount: [
              '',
              [Validators.required, Validators.min(0), Validators.max(200000)]
          ],
          tpin: ['', Validators.required],
          paymentMode: ['', Validators.required],
          txnCurrency: [{value: 'INR', disabled: true}],
          narration: ['']
      });

     

      this.api.getMinMax('payout', 'safexpay').subscribe({
          next: (response: any) => {
              this.min = response.data.min_start_range;
              this.max = response.data.max_end_range;

              this.accountForm.controls['amount'].setValidators([
                  Validators.required,
                  Validators.min(this.min),
                  Validators.max(this.max)
              ]);
          },
          error: (error) => {
              console.log(error);
          }
      });

  }


  

 

  resetForms(onlyTpin = false) {
      const tpinControl = this.accountForm.get('tpin');
      if (tpinControl) {
          tpinControl.setValue('');
          tpinControl.markAsUntouched();
          tpinControl.setErrors(null);
      }

      if (!onlyTpin) {
          this.accountForm.patchValue({
              beneficiaryId: '',
              account_holder_name: '',
              account_holder_mobile_number: '',
              bank_name: '',
              account_number: '',
              ifsc_code: '',
              amount: '',
              paymentMode: '',
              narration: ''
          });

          this.beneficiary = [];

          this.beneficiaryForm.markAsPristine();
          this.beneficiaryForm.markAsUntouched();
      }
  }

  checkValidation() {
      const step = ['ifscCode', 'accountNumber', 'accountHolderName'];

      const form = this.beneficiaryForm;

      let allControlsValid = true;
      for (let i = 0; i < step.length; i++) {
          const control = step[i];
          const formControl = form.get(control);
          if (
              formControl &&
              !formControl.valid &&
              formControl.status != 'DISABLED'
          ) {
              console.log(formControl);
              allControlsValid = false;
              formControl.markAsTouched();
          }
      }

      if (allControlsValid) {
          return true;
      }
      return false;
  }

  verifyAccountNumber() {
      if (!this.checkValidation()) {
          this.beneficiaryForm.get('ifscCode').markAsTouched();
          this.beneficiaryForm.get('accountNumber').markAsTouched();
          this.beneficiaryForm.get('accountHolderName').markAsTouched();
          return;
      }

      this.penny
          .isValidTwo({
              accountNumber: this.beneficiaryForm.value.accountNumber,
              ifsc: this.beneficiaryForm.value.ifscCode
          })
          .subscribe({
              next: (response: any) => {
                  if (!response.status) {
                      this.alert.error(response.message);
                      return;
                  }

                  this.beneficiaryForm.patchValue({
                      accountHolderName: response.data.registered_name,
                      ifscCode: response.data.ifsc,
                      accountNumber: response.data.account_number
                  });

                  // this.beneficiaryForm.get('ifscCode').disable();
                  // this.beneficiaryForm.get('accountNumber').disable();
                  // this.beneficiaryForm.get('accountHolderName').disable();

                  this.alert.success(response.message);
              },
              error: ({error}) => {
                  this.isLoading = false;
              }
          });
  }
}
