import { Profile } from '@/models/common';
import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataService } from '@services/data.service';
import { ROLES } from '@/utils/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  public wallet_balance: any;
  public distributor: any;
  public retailer: any;
  public total_txn: any;
  public total_transactions_today: any;
  public pg_wallet_balance: any;
  public aeps_wallet_balance:any;

  public services: any;
  public profile: Profile;
  public roles = ROLES;
  isLoading: Boolean = false;
  isActive: boolean = true;


  constructor(
    private data: DataService,
    private api: ApiService
  ) {

  }


  ngOnInit(): void {
    this.data.getProfile().subscribe((profile: Profile) => {
      this.profile = profile
    })
    this.dashboard();
  }
  toggle(isChecked: boolean) {
    this.isActive = isChecked;
  }
  can(roles: any[]) {
    return roles.some((i: string) => this.profile.data.roles.includes(i))
  }

  dashboard() {
    this.isLoading = true;
    this.api.dashboard().subscribe({
      next: (response: any) => {
        this.wallet_balance = response.wallet_balance;
        this.distributor = response.distributor;
        this.retailer = response.retailer;
        this.total_txn = response.total_transactions;
        this.total_transactions_today = response.total_transactions_today;
        this.pg_wallet_balance = response.pg_wallet_balance;
        this.aeps_wallet_balance=response.aeps_wallet_balance;
        this.services = response.services;
        this.isLoading = false;
      },
      error: (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  openIndex: number | null = null;

  toggleItem(index: number) {
    this.openIndex = this.openIndex === index ? null : index;
  }

  isOpen(index: number): boolean {
    return this.openIndex === index;
  }

}

export interface Dashboard {
  wallet_balance: number
  total_transactions: number
  total_transactions_today: number
  distributor: number
  retailer: number
  services: Service[]
}

export interface Service {
  title: string
  total_amount: number
  success_amount: number
  pending_amount: number
  failed_amount: number
}
