import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addLatLong, addHeaders } from '@services/api.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SafeXPayService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  customer(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/prime-dmt/customer`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  verification(body: { mobileNumber: number; name: string; otp: string }) {
    return this.http.post(`${this.apiUrl}/prime-dmt/verify-otp`, {
      ...body, ...addLatLong()
    }, addHeaders());
  }

  beneficiaries(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/prime-dmt/beneficiaries`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  beneficiary(data: any) {
    return this.http.post(`${this.apiUrl}/prime-dmt/add-beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  delete(beneficiary: any) {
    return this.http.post(`${this.apiUrl}/prime-dmt/beneficiary/${beneficiary}`, addLatLong(), addHeaders());
  }

  transfer(data: any) {
    return this.http.post(`${this.apiUrl}/prime-dmt/transfer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  status(data: any) {
    return this.http.post(`${this.apiUrl}/prime-dmt/status`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

}
