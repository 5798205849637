import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '@/app-routing.module';
import { ToastrModule } from 'ngx-toastr';

import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { DataTablesModule } from 'angular-datatables';
import { DataService } from '@services/data.service';
import { firstValueFrom } from 'rxjs';

import { AuthInterceptor } from './auth.interceptor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ErrorMessageComponent } from '@modules/error-message/error-message.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NumToWordsPipe } from './pipes/num-to-words.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SpinnerComponent } from "./components/spinner/spinner.component";

import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { BrandHeaderComponent } from '@modules/brand-header/brand-header.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { KYCComponent } from '@pages/kyc/kyc.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { DmtComponent } from '@pages/main-menu/toshani/dmt/dmt.component';
import { EnableGeoLocationComponent } from '@modules/enable-geo-location/enable-geo-location.component';
import { FundRequestComponent } from '@pages/fund-request/fund-request.component';
import { FundRequestListComponent } from '@pages/fund-request-list/fund-request-list.component';
import { ChangePasswordComponent } from '@pages/change-password/change-password.component';
import { XdmtComponent } from '@pages/main-menu/toshani/xdmt/xdmt.component';
import { UpiComponent } from '@pages/main-menu/toshani/upi/upi.component';
import { XUpiComponent } from '@pages/main-menu/toshani/xupi/xupi.component';
import { PsprintComponent } from '@pages/main-menu/psprint/dmt2/psprint.component';
import { EWalletLedgerComponent } from '@pages/e-wallet-ledger/e-wallet-ledger.component';
import { ChangeTpinComponent } from '@pages/change-tpin/change-tpin.component';
import { OnboardingComponent } from '@pages/main-menu/aeps/onboarding/onboarding.component';
import { FundsComponent } from '@pages/main-menu/funds/funds.component';
import { AddUserComponent } from '@pages/main-menu/users/add-user/add-user.component';
import { UserListComponent } from '@pages/main-menu/users/user-list/user-list.component';
import { RechargeComponent } from '@pages/main-menu/services/recharge/recharge.component';
import { PrimeComponent } from '@pages/main-menu/toshani/prime/prime.component';
import { TransactionComponent } from '@pages/main-menu/aeps/transaction/transaction.component';
import { TwoFactorComponent } from '@pages/main-menu/aeps/two-factor/two-factor.component';
import { LitePlusComponent } from '@pages/main-menu/avenue/lite-plus/lite-plus.component';
import { SabpaisaComponent } from '@pages/main-menu/toshani/sabpaisa/sabpaisa.component';
import { OBDXComponent } from '@pages/main-menu/toshani/canara/obdx/obdx.component';
import { CbxComponent } from '@pages/main-menu/toshani/hdfc/cbx/cbx.component';

import { UPIComponent } from '@pages/main-menu/toshani/canara/upi/upi.component';
import { PaymentSuccessComponent } from '@pages/main-menu/toshani/payment_success/payment_success.component';
import { PaymentFailureComponent } from '@pages/main-menu/toshani/payment_failure/payment_failure.component';

import { DmtLiteReceiptComponent } from './receipt/dmt-lite-receipt/dmt-lite-receipt.component';
import { DmtPrimeReceiptComponent } from './receipt/dmt-prime-receipt/dmt-prime-receipt.component';
import { DmtExpressReceiptComponent } from './receipt/dmt-express-receipt/dmt-express-receipt.component';
import { PrepaidComponent } from '@pages/main-menu/services/bbps/prepaid/prepaid.component';
import { BbpsComponent } from '@pages/main-menu/services/bbps/bbps.component';
import { PostpaidComponent } from '@pages/main-menu/services/bbps/postpaid/postpaid.component';
import { DthComponent } from '@pages/main-menu/services/bbps/dth/dth.component';
import { FasttagComponent } from '@pages/main-menu/services/bbps/fasttag/fasttag.component';
import { ElectricityComponent } from '@pages/main-menu/services/bbps/electricity/electricity.component';
import { GasComponent } from '@pages/main-menu/services/bbps/gas/gas.component';
import { WaterComponent } from '@pages/main-menu/services/bbps/water/water.component';
import { CreditCardComponent } from '@pages/main-menu/services/bbps/credit-card/credit-card.component';
import { BroadbandComponent } from '@pages/main-menu/services/bbps/broadband/broadband.component';
import { EntertainmentComponent } from '@pages/main-menu/services/bbps/entertainment/entertainment.component';
import { EducationComponent } from '@pages/main-menu/services/bbps/education/education.component';
import { CableComponent } from '@pages/main-menu/services/bbps/cable/cable.component';
import { ClubComponent } from '@pages/main-menu/services/bbps/club/club.component';
import { HospitalComponent } from '@pages/main-menu/services/bbps/hospital/hospital.component';
import { HousingComponent } from '@pages/main-menu/services/bbps/housing/housing.component';
import { InsuranceComponent } from '@pages/main-menu/services/bbps/insurance/insurance.component';
import { LandlineComponent } from '@pages/main-menu/services/bbps/landline/landline.component';
import { LoanComponent } from '@pages/main-menu/services/bbps/loan/loan.component';
import { LpgGasComponent } from '@pages/main-menu/services/bbps/lpg-gas/lpg-gas.component';
import { MunicipalServicesComponent } from '@pages/main-menu/services/bbps/municipal-services/municipal-services.component';
import { MunicipalTaxComponent } from '@pages/main-menu/services/bbps/municipal-tax/municipal-tax.component';
import { SubscriptionComponent } from '@pages/main-menu/services/bbps/subscription/subscription.component';
import { TransIdComponent } from '@pages/main-menu/services/bbps/tran-id/tran-id.component';

import { DmtLiteReportComponent } from './reports/dmt-lite-report/dmt-lite-report.component';
import { DmtExpressReportComponent } from './reports/dmt-express-report/dmt-express-report.component';
import { UpiLiteReportComponent } from './reports/upi-lite-report/upi-lite-report.component';
import { UpiExpressReportComponent } from './reports/upi-express-report/upi-express-report.component';
import { DmtPrimeReportComponent } from './reports/dmt-prime-report/dmt-prime-report.component';
import { DmtLitePlusReportComponent } from './reports/dmt-lite-plus-report/dmt-lite-plus-report.component';
import { DmtLitePlusReceiptComponent } from './receipt/dmt-lite-plus-receipt/dmt-lite-plus-receipt.component';
import { DmtLitePlusReportPendingRefundComponent } from './reports/dmt-lite-plus-report-pending-refund/dmt-lite-plus-report-pending-refund.component';
import { WithdrawalAuthenticationComponent } from '@pages/main-menu/aeps/withdrawal-authentication/withdrawal-authentication.component';
import { AepsWalletLedgerComponent } from '@pages/aeps-wallet-ledger/aeps-wallet-ledger.component';
import { AepsReportComponent } from './reports/aeps-report/aeps-report.component';
import { BBPSReportComponent } from './reports/bbps-report/bbps-report.component';
import { AepsReceiptComponent } from './receipt/aeps-receipt/aeps-receipt.component';
import { PGReportComponent } from './reports/pg-report/pg-report.component';
import { CertificateComponent } from '@pages/certificate/certificate.component';
import { SubHeaderComponent } from '@modules/main/header/sub-header/sub-header.component';
import { IdCardComponent } from '@pages/id-card/id-card.component';
import { CANBankReportComponent } from './reports/ca-bank-report/ca-bank-report.component';
import { BiometricService } from '@services/biometric.service';
import { MantraMachine } from './machines/mantra-machine';
import { MorphoMachine } from './machines/morpho-machine';
import { CaBankReceiptComponent } from './receipt/ca-bank-receipt/ca-bank-receipt.component';
import { PgReceiptComponent } from './receipt/pg-receipt/pg-receipt.component';
import { TermsConditionsComponent } from '@pages/terms-conditions/terms-conditions.component';
import { AepsPayoutComponent } from '@pages/main-menu/aeps/aeps-payout/aeps-payout.component';
import { PgPayoutComponent } from '@pages/main-menu/toshani/pg-payout/pg-payout.component';
import { PgPayoutReportComponent } from './reports/pg-payout-report/pg-payout-report.component';
import { AepsPayoutReportComponent } from './reports/aeps-payout-report/aeps-payout-report.component';
registerLocaleData(localeEn, 'en-EN');

export function initializeApp(dataService: DataService) {
    return () => firstValueFrom(dataService.initialize());
}

@NgModule({
    declarations: [
        AppComponent,
        NumToWordsPipe,
        MainComponent,
        BrandHeaderComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        ProfileComponent,
        KYCComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        DmtComponent,
        MenuItemComponent,
        SidebarSearchComponent,
        EnableGeoLocationComponent,
        ErrorMessageComponent,
        FundRequestComponent,
        FundRequestListComponent,
        EWalletLedgerComponent,
        AepsWalletLedgerComponent,
        ChangePasswordComponent,
        ChangeTpinComponent,
        SpinnerComponent,
        XdmtComponent,
        UpiComponent,
        XUpiComponent,
        PsprintComponent,
        DmtComponent,
        OnboardingComponent,
        FundsComponent,
        AddUserComponent,
        UserListComponent,
        RechargeComponent,
        PrimeComponent,
        TransactionComponent,
        TwoFactorComponent,
        WithdrawalAuthenticationComponent,
        LitePlusComponent,
        DmtLiteReceiptComponent,
        AepsReceiptComponent,
        DmtPrimeReceiptComponent,
        DmtExpressReceiptComponent,
        PrepaidComponent,
        BbpsComponent,
        PostpaidComponent,
        DthComponent,
        FasttagComponent,
        ElectricityComponent,
        GasComponent,
        WaterComponent,
        CreditCardComponent,
        BroadbandComponent,
        EntertainmentComponent,
        EducationComponent,
        CableComponent,
        ClubComponent,
        HospitalComponent,
        HousingComponent,
        InsuranceComponent,
        LandlineComponent,
        LoanComponent,
        LpgGasComponent,
        MunicipalServicesComponent,
        MunicipalTaxComponent,
        SubscriptionComponent,
        TransIdComponent,
        DmtLitePlusReceiptComponent,
        DmtLiteReportComponent,
        DmtLitePlusReportComponent,
        DmtExpressReportComponent,
        UpiLiteReportComponent,
        UpiExpressReportComponent,
        DmtPrimeReportComponent,
        AepsReportComponent,
        DmtLitePlusReportPendingRefundComponent,
        SabpaisaComponent,
        OBDXComponent,
        UPIComponent,
        BBPSReportComponent,
        PaymentSuccessComponent,
        PaymentFailureComponent,
        CbxComponent,
        PGReportComponent,
        CertificateComponent,
        SubHeaderComponent,
        IdCardComponent,
        CANBankReportComponent,
        CaBankReceiptComponent,
        PgReceiptComponent,
        TermsConditionsComponent,
        AepsPayoutComponent,
        PgPayoutComponent,
        PgPayoutReportComponent,
        AepsPayoutReportComponent
    ],
    providers: [
        DataService,
        BiometricService,
        MantraMachine,
        MorphoMachine,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [DataService], multi: true },
        // { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent],
    imports: [
        ProfabricComponentsModule,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DataTablesModule,
        FormsModule,
        NgSelectModule,
        LeafletModule,
        StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
        NgIdleKeepaliveModule.forRoot(),
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 10000,
            closeButton: true,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        })
    ]
})
export class AppModule { }

