export const environment = {
  appName: 'SDDS',
  production: false,
  baseURL: 'https://sdds.flightapi.co.in/',
  apiURL: 'https://sdds.flightapi.co.in/api',
  // apiURL: 'https://api.sddspl.com/api',
  idle: 60 * 5,
  can_txnpass:"113a8a49a4a35a80a26a36a66a1a6a121",
  can_accountNumber:"120029893968",
  can_branchCode:"8476",
  can_Auth:"Basic MzI1OTIwMzgxQVBJOjExM2E4YTQ5YTRhMzVhODBhMjZhMzZhNjZhMWE2YTEyNg==",
  can_customerID:"325920381"
};
