import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding,
  AfterViewInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Stepper from 'bs-stepper';
import { City, State } from '@/models/common';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostBinding('class') class = 'container';
  @ViewChild('stepper') private stepper: ElementRef;

  public registerForm: UntypedFormGroup;
  public isAuthLoading = false;
  public roles: Object;
  public countries: Object;
  public states: State[];
  public cities: City[];
  public stepper1Instance: Stepper;
  public serverErrors: any;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private api: ApiService,
    private data: DataService,
  ) {
    this.api.roles().subscribe((e) => { this.roles = e; });
    this.api.countries().subscribe((e) => { this.countries = e; });
    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );

    this.registerForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
      aadhar: new FormControl('', [Validators.required, Validators.pattern('[0-9]{12}')]),
      pan: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{6}')]),
    });
  }

  ngAfterViewInit(): void {
    this.stepper1Instance = new Stepper(this.stepper.nativeElement, {});
  }

  async registerByAuth() {
    console.log(this.registerForm.getRawValue())
    if (!this.registerForm.valid) {
      for (const control in this.registerForm.controls) {
        if (this.registerForm.controls.hasOwnProperty(control)) {
          this.registerForm.controls[control].markAsTouched();
        }
      }
      this.toastr.error('Form is not valid!');
      return;
    }

    this.isAuthLoading = true;
    this.appService.registerByAuth(this.registerForm.value).subscribe({
      next: async (res: any) => {
        this.toastr.success(res.message);
        this.isAuthLoading = false;
        this.router.navigate(['/login']);
      },
      error: ({ error }) => {
        if (error.message) {
          this.toastr.error(error.message);
        }
        // if (error.errors)
        //   this.handleServerErrors(error.errors);
        this.isAuthLoading = false;
      }
    })

  }

  handleServerErrors(errors: any) {
    this.serverErrors = errors; // Assign server errors to variable
    // Loop through each error and set it as an error on the corresponding form control
    Object.keys(errors).forEach(key => {
      this.toastr.error(errors[key][0], key);

      const control = this.registerForm.get(key);
      if (control) {
        control.setErrors({ 'serverError': errors[key][0] });
      }
    });
  }

  stateChanged(event: any) {
    this.data.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
    console.log(this.cities, selectedStateId);
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
  }
}
