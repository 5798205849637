import { Component, Renderer2, ViewChild } from '@angular/core';
import { DataTableService } from '@services/data-table.service';
import { environment } from 'environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from '@services/api.service';

declare var $: any;
@Component({
  selector: 'app-dmt-lite-report',
  templateUrl: './dmt-lite-report.component.html',
  styleUrl: './dmt-lite-report.component.scss'
})

export class DmtLiteReportComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  public row: any;
  public isCalling = false;
  private printButtonHandler: any;
  private statusUpdateHandler: any;

  constructor(private dataTableService: DataTableService,
    private renderer: Renderer2,
    private apiService: ApiService
  ) {
    this.dataTableService.url = 'reports/dmt'
    this.dataTableService.export_name = 'DMT_LITE_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "order_id", "title": "Sender Mobile", "class": "d-none" },
      { "data": "mobileNumber", "title": "Sender Mobile" },
      { "data": "customer_account_number", "title": "Account" },
      { "data": "amount", "title": "Amount" },
      { "data": "net_amount", "name": "net_amount", "title": "Net Amount" },
      { "data": "transaction_id", "title": "Transaction Id" },
      { "data": "bank_name", "title": "Bank" },
      { "data": "transaction_status", "title": "Status" , render: function (data, type, row) {
        if (data != null) {
            let color = '';
            if (data.toLowerCase() === 'success') {
                color = 'green';
            } else if (data.toLowerCase() === 'failed') {
                color = 'red';
            } else if (data.toLowerCase() === 'pending') {
                color = 'orange';
            }

            return `<span style="color: ${color};">${data}</span>`;
        } else {
            return data ? data : '-';
        }
    }},
      { "data": "created_at", "title": "Created At" },
      { "data": "customer_name", "title": "Customer Name", "class": "d-none" },
      { "data": "ifsc_code", "title": "IFSC Code", "class": "d-none" },
      { "data": "action", "name": "action", "title": "Action", "render": (_: any, __: any, data: any) => this.actionButton(data) },
    ], function (settings, json) {
      $("#dmt-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="9">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY ', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDateformat('DD-MM-YYYY'), picker.endDateformat('DD-MM-YYYY')])).draw();;
      });
    });
  }

  ngAfterViewInit(): void {
    this.printButtonHandler = (e: { currentTarget: any; }) => {
      this.row = $(e.currentTarget).data('row');
      setTimeout(() => {
        window.print();
      }, 100);
    };

    this.statusUpdateHandler = (e: { currentTarget: any; }) => {
      if (this.isCalling) {
        return
      }

      this.isCalling = true
      const orderId = $(e.currentTarget).data('order-id');
      this.apiService.dmtStatus(orderId).subscribe({
        next: async (response: any) => {
          (await this.datatableElement.dtInstance).ajax.reload(null, false);
          this.isCalling = false
        },
        error: (err) => {
          console.log(err)
          this.isCalling = false
        }
      })
    };

    $(document).on('click', '.btn-print', this.printButtonHandler);
    $(document).on('click', '.status-update', this.statusUpdateHandler);
  }

  ngOnDestroy(): void {
    $(document).off('click', '.btn-print', this.printButtonHandler);
    $(document).off('click', '.status-update', this.statusUpdateHandler);
  }

  actionButton(data: any): string {
    if (data.transaction_status === 'Pending') {
      return `
        <div class='d-flex gap-2'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
          <button class='btn btn-sm btn-primary status-update' data-order-id='${data.order_id}'>Update Status</button>
        </div>`;
    } else {
      return `
        <div class='d-flex'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
        </div>`;
    }


  }
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}