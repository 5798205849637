import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  public login(email: string, password: string) {
    return this.http.post(`${this.apiUrl}/validate/login`, {
      username: email,
      password: password,
      ...addLatLong()
    }, addHeaders());
  }

  public validate(mobile: string, otp: string) {
    return this.http.post(`${this.apiUrl}/validate/otp`, {
      mobile,
      otp,
      ...addLatLong()
    }, addHeaders());
  }


  public logout() {
    return this.http.post(`${this.apiUrl}/logout`, {
      ...addLatLong()
    }, addHeaders());
  }

  public roles() {
    return this.http.get(`${this.apiUrl}/roles`, addHeaders());
  }

  public banks(type: string = 'dmt-lite') {
    return this.http.get(`${this.apiUrl}/banks?type=${type}`, addHeaders());
  }

  public countries() {
    return this.http.get(`${this.apiUrl}/countries`, addHeaders());
  }

  public states() {
    return this.http.get(`${this.apiUrl}/states`, addHeaders());
  }

  public cities() {
    return this.http.get(`${this.apiUrl}/cities`, addHeaders());
  }

  public me() {
    return this.http.post(`${this.apiUrl}/me`, addLatLong(), addHeaders());
  }

  public profileEdit(user: any) {
    return this.http.post(`${this.apiUrl}/profile-update`, {
      ...user, ...addLatLong()
    }, addHeaders());
  }

  public register(user: any) {
    return this.http.post(`${this.apiUrl}/register`, {
      ...user, ...addLatLong()
    }, addHeaders());
  }

  public getCustomerInfo(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/dmt/customer`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  public setCustomerInfo(body: { mobileNumber: number; otp: string; name: string }) {
    return this.http.post(`${this.apiUrl}/dmt/verification`, {
      ...body, ...addLatLong()
    }, addHeaders());
  }

  public getBeneficiaries(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/dmt/beneficiaries`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  public setBeneficiary(data: {
    mobileNumber: number;
    name: string;
    accountNumber: string;
    bankCode: string;
    ifscCode: string;
    beneficiaryMobileNumber: number;
  }) {
    return this.http.post(`${this.apiUrl}/dmt/beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  public removeBeneficiary(data: any) {
    return this.http.post(`${this.apiUrl}/dmt/delete-beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  public transfer(data: any) {
    return this.http.post(`${this.apiUrl}/dmt/transfer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  // DMT API Ends

  onboardkyc(data: any) {
    return this.http.post(`${this.apiUrl}/onboarding/kyc`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  statuskyc() {
    return this.http.get(`${this.apiUrl}/onboarding/status`, addHeaders());
  }

  funRequestOwnBanks() {
    return this.http.get(`${this.apiUrl}/fund-request/bank-list`, addHeaders());
  }

  fundRequest(body: any) {
    return this.http.post(`${this.apiUrl}/fund-request/store`, {
      ...body, ...addLatLong()
    }, addHeaders());
  }

  walletBalance() {
    return this.http.get(`${this.apiUrl}/wallet/balance`, addHeaders());
  }

  aepsWalletBalance() {
    return this.http.get(`${this.apiUrl}/aeps-wallet/balance`, addHeaders());
  }

  walletHistory() {
    return this.http.get(`${this.apiUrl}/wallet/history`, addHeaders());
  }

  walletTransfer(data: any) {
    return this.http.post(`${this.apiUrl}/wallet/transfer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  changePassword(data: any) {
    return this.http.post(`${this.apiUrl}/profile/change-password`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  changeTpin(data: any) {
    return this.http.post(`${this.apiUrl}/tpin`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  xdmtCustomer(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/customer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  resendOTP(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/resend-otp`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  verifyXDMT(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/verify-otp`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  setKYC(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/kyc`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  verifyKYC(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/verify-kyc`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  setTransfer(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/transfer`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  setBeneficiaries(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/beneficiaries`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  addBeneficiaries(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/add-beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  deleteBeneficiary(data: any) {
    return this.http.post(`${this.apiUrl}/xdmt/delete-beneficiary`, {
      ...data, ...addLatLong()
    }, addHeaders());
  }

  public getXDMTBeneficiaries(mobileNumber: number) {
    return this.http.post(`${this.apiUrl}/xdmt/beneficiaries`, {
      mobileNumber, ...addLatLong()
    }, addHeaders());
  }

  public getMinMax(slug: string, api: string) {
    return this.http.post(`${this.apiUrl}/validation/get-min-max`, {
      ...{ slug, api }, ...addLatLong()
    }, addHeaders());
  }

  public dashboard() {
    return this.http.get(`${this.apiUrl}/dashboard`, addHeaders());
  }

  public users() {
    return this.http.get(`${this.apiUrl}/wallet/users`, addHeaders());
  }

  dmtStatus(id:any) {
    return this.http.get(`${this.apiUrl}/dmt/status/${id}`, addHeaders());
  }

  xdmtStatus(id:any) {
    return this.http.get(`${this.apiUrl}/xdmt/status/${id}`, addHeaders());
  }

  //----- BBPS APi
  public billerList(payload){
    return this.http.post(`${this.apiUrl}/bbps/billers-list-by-categories`, payload, addHeaders());
  }

  public billFetch(payload){
    return this.http.post(`${this.apiUrl}/bbps/bill-fetch`, payload, addHeaders());
  }
  public rechargeBill(payload){
    return this.http.post(`${this.apiUrl}/bbps/recharge-bill-pay`, payload, addHeaders());
  }

  public billPayment(payload){
    return this.http.post(`${this.apiUrl}/bbps/bill-payment`, payload, addHeaders());
  }

  public transactionStatus(payload){
    return this.http.post(`${this.apiUrl}/bbps/transaction-status`, payload, addHeaders());
  }

  public complaintList(payload){
    return this.http.post(`${this.apiUrl}/bbps/complaint-list`, payload, addHeaders());
  }

  public addNewComplaint(payload){
    return this.http.post(`${this.apiUrl}/bbps/complaint-registration`, payload, addHeaders());
  }

  public complaintTracking(payload){
    return this.http.post(`${this.apiUrl}/bbps/complaint-tracking`, payload, addHeaders());
  }

  public dispositions(){
    return this.http.get(`${this.apiUrl}/bbps/dispositions`, addHeaders());
  }
  //----End-------

  //-------------Sabpaisa Api-------
  public initiatePayment(payload){
    return this.http.post(`${this.apiUrl}/subpaisa/initiate-payment`, payload, addHeaders());
  }

  public transactionDetails(payload){
    return this.http.post(`${this.apiUrl}/subpaisa/get-transaction-details`, payload, addHeaders());
  }

  public transactionEnquiry(payload){
    return this.http.post(`${this.apiUrl}/subpaisa/transaction-enquiry`, payload, addHeaders());
  }
  public paymentGatewayList() {
    return this.http.get(`${this.apiUrl}/subpaisa/paymentgateway-list`, addHeaders());
  }
  public verifyTpin(payload) {
    return this.http.post(`${this.apiUrl}/verify-tpin`,payload, addHeaders());
  }
  //---------End----


  // -------Aeps Payout start-------
  mobileVerify(data){
    return this.http.post(`${this.apiUrl}/financial-services/mobile-verify`, {
      ...data,
      ...addLatLong()
    }, addHeaders());
  }
  verifyOtp(data){
    return this.http.post(`${this.apiUrl}/financial-services/verification`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  resend(data){
    return this.http.post(`${this.apiUrl}/resend`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  remitterBankList(data){
    return this.http.post(`${this.apiUrl}/remitter-bank-details/remitter_bank_list`, {...data,...addLatLong()},addHeaders());

  }
  remitterAddBank(data){
    return this.http.post(`${this.apiUrl}/remitter-bank-details/add_bank`, {
      ...data,
      ...addLatLong()
    }, addHeaders());
  }
  deleteRemitterBenef(data){
    return this.http.post(`${this.apiUrl}/remitter-bank-details/delete_bank_account`,
      {
        ...data,
        ...addLatLong()
      }, addHeaders());
  }
  accountVerify(data){
    return this.http.post(`${this.apiUrl}/accountverification`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  neft_payment(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/aepswalletpayment-neft`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  rtgs_payment(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/aepswalletpayment-rtgs`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  
  imps_payment(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/aepswalletpayment-imps`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  fund_transfer(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/internaltransfer`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  fundTransactionDetails(payload){
    return this.http.post(`${this.apiUrl}/canara/get-transaction-details`, payload, addHeaders());
  }
  // -------Aeps Payout end-------

  // ------Pg payout start------
  pg_neft_payment(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/pgwalletpayment-neft`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  pg_rtgs_payment(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/pgwalletpayment-rtgs`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  
  pg_imps_payment(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/pgwalletpayment-imps`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  pg_fund_transfer(data) {
    return this.http.post(`${this.apiUrl}/fund-transfer/internaltransfer`, {
      ...data,
      // ...addLatLong()
    }, addHeaders());
  }
  // --------PG payout end------
}

export const addJwtHeader = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
  }
  return null;
}

export const addLatLong = () => {
  const lat = localStorage.getItem('latitude');
  const long = localStorage.getItem('longitude');
  if (lat && long) {
    return {
      lat: lat,
      long: long
    };
  }
  return null;
}

export const addHeaders = () => {
  const headers = addJwtHeader();
  const latLong = addLatLong();
  let combinedHeaders = new HttpHeaders(); // Initialize HttpHeaders object
  if (headers) {
    combinedHeaders = combinedHeaders.append('Authorization', headers.get('Authorization')); // Add JWT header
  }
  if (latLong) {
    // Add latitude and longitude headers
    combinedHeaders = combinedHeaders.append('lat', latLong.lat);
    combinedHeaders = combinedHeaders.append('long', latLong.long);
  }

  combinedHeaders = combinedHeaders.append('Access-Control-Allow-Origin', '*');
  return { headers: combinedHeaders };
}
