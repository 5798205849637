<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Change TPIN</h1>
        </div>
        <div class="col-sm-6">
          <!-- <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
            <li class="breadcrumb-item active">Change TPIN</li>
          </ol> -->
        </div>
      </div>
    </div>
  </section>
  
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <!-- Change TPIN -->
            </div>
            <div class="card-body">
              <form autocomplete="off" [formGroup]="changePassword">
                <div class="form-group">
                  <label for="current-tpin">Current TPIN</label>
                  <input type="text" class="form-control" formControlName="current-tpin" id="current-tpin"
                    placeholder="Current TPIN" mask="0000"
                    [ngClass]="{ 'is-invalid': changePassword.get('current-tpin').invalid && (changePassword.get('current-tpin').dirty || changePassword.get('current-tpin').touched) }">
                  <div
                    *ngIf="changePassword.get('current-tpin').invalid && (changePassword.get('current-tpin').dirty || changePassword.get('current-tpin').touched)"
                    class="invalid-feedback">
                    <strong>Current TPIN is required</strong>
                  </div>
                </div>
                <div class="form-group">
                  <label for="new-tpin">New TPIN</label>
                  <input type="password" class="form-control" formControlName="new-tpin" id="new-tpin"
                    placeholder="New TPIN" mask="0000"
                    [ngClass]="{ 'is-invalid': changePassword.get('new-tpin').invalid && (changePassword.get('new-tpin').dirty || changePassword.get('new-tpin').touched) }">
                  <div
                    *ngIf="changePassword.get('new-tpin').invalid && (changePassword.get('new-tpin').dirty || changePassword.get('new-tpin').touched)"
                    class="invalid-feedback">
                    <strong>New TPIN is required</strong>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirm-tpin">Confirm TPIN</label>
                  <input type="password" class="form-control" formControlName="confirm-tpin" id="confirm-tpin"
                    placeholder="Confirm TPIN" mask="0000"
                    [ngClass]="{ 'is-invalid': changePassword.get('confirm-tpin').invalid && (changePassword.get('confirm-tpin').dirty || changePassword.get('confirm-tpin').touched) }">
                  <div
                    *ngIf="changePassword.get('confirm-tpin').invalid && (changePassword.get('confirm-tpin').dirty || changePassword.get('confirm-tpin').touched)"
                    class="invalid-feedback">
                    <div *ngIf="changePassword.get('confirm-tpin').errors.required">
                      <strong>Confirm TPIN is required.</strong>
                    </div>
                    <div *ngIf="!changePassword.get('confirm-tpin').errors.required && changePassword.get('confirm-tpin').errors.tpinNotMatch">
                      <strong>Confirm TPIN do not match.</strong>
                    </div>
                  </div>
                </div>
                <div class="form-group text-right">
                  <pf-button [variant]="'info'" [loading]="isLoading" [disabled]="isLoading" (click)="change()">
                    Change TPIN
                  </pf-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>