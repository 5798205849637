import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-postpaid',
  templateUrl: './postpaid.component.html',
  styleUrl: './postpaid.component.scss'
})
export class PostpaidComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  operators: string[] = ['Airtel', 'Jio'];
  mobileOperatorList: any[];
  formFields: any;
  selectedOperator: any;
  billerFetchDetails: any;
  billPaymentDetails: any;

  constructor(private fb: FormBuilder,
    public api: ApiService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      // amount: ['', Validators.required]
    });
    this.getBillerList();
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }

  getBillerList(){
    var value = {
      billerCategory:'Mobile Postpaid'
    }
    this.api.billerList(value).subscribe({
      next: (response: any) => {
        this.mobileOperatorList = response.data || [];
        console.log(this.mobileOperatorList);
      },
      error: (error) => {
        this.mobileOperatorList = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onSelectOperator($event){
    var getData:any = this.mobileOperatorList.find(biller => biller.billerName === $event);
    this.selectedOperator = getData;
    this.formFields = getData.paramInfo;
  }

  getBill() {
    var inputField1 = {
      paramName:'a',
      paramValue:'10',
    }

    var inputField2 = {
      paramName:'a b',
      paramValue:'20',
    }

    var inputField3 = {
      paramName:'a b c',
      paramValue:'30',
    }

    var inputField4 = {
      paramName:'a b c d',
      paramValue:'40',
    }

    var inputField5 = {
      paramName:'a b c d e',
      paramValue:'50',
    }

    var inputField = [
      inputField1,
      inputField2,
      inputField3,
      inputField4,
      inputField5,
    ]
    var value = {
      agentDeviceIp:'192.168.2.73',
      agentDeviceMac:'01-23-45-67-89-ab',
      customerMobile:'9898990084',
      customerEmail:'customer@email.com',
      customerAdhaar:'',
      customerPan:'',
      billerId:this.selectedOperator?.billerId,
      inputParams:inputField,
    }
    this.api.billFetch(value).subscribe({
      next: (response: any) => {
        this.billerFetchDetails = response.results || [];
        $('#receiptBooking').modal('show');
      },
      error: (error) => {
        this.billerFetchDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onBillPayment(){
    var inputField1 = {
      paramName:'a',
      paramValue:'10',
    }

    var inputField2 = {
      paramName:'a b',
      paramValue:'20',
    }

    var inputField3 = {
      paramName:'a b c',
      paramValue:'30',
    }

    var inputField4 = {
      paramName:'a b c d',
      paramValue:'40',
    }

    var inputField5 = {
      paramName:'a b c d e',
      paramValue:'50',
    }

    var inputField = [
      inputField1,
      inputField2,
      inputField3,
      inputField4,
      inputField5,
    ]
    var value = {
      billerAdhoc:'true',
      agentDeviceIp:'192.168.2.73',
      agentDeviceMac:'01-23-45-67-89-ab',
      customerMobile:'9898990084',
      customerEmail:'customer@email.com',
      customerAdhaar:'',
      customerPan:'',
      billerId:this.selectedOperator.billerId,
      initChannel: "INT",
      inputParams:inputField,
      cityLocation: "Mumbai",
      amount: 11,
      paymentMode: "Cash",
      quickPay: "N",
      splitPay: "N",
      paymentInfoRemarks: "Remarks",
      paymentInfoValue: "Received",
      infoName: "Remarks",
      infoValue: "Received",
    }

    this.api.billPayment(value).subscribe({
      next: (response: any) => {
        console.log(response);
        this.billPaymentDetails = response.results || [];
        $('#receiptBooking').modal('hide');
        $('#billPayment').modal('show');       
      },
      error: (error) => {
        // alert('Something Went Wrong');
        this.billPaymentDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onCloseModal(){
    $('#receiptBooking').modal('hide');
    $('#billPayment').modal('hide');
  }
}
