import { Component, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-aeps-report',
  templateUrl: './aeps-report.component.html',
  styleUrl: './aeps-report.component.scss'
})
export class AepsReportComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  private printButtonHandler: any;
  private statusUpdateHandler: any;
  public row: any;

  constructor(private dataTableService: DataTableService, private apiService: ApiService) {
    this.dataTableService.url = 'reports/aeps'
    this.dataTableService.export_name = 'AEPS_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { "data": "created_at", "searchable": false },
      { "data": "transaction_id" },
      { "data": "bank_reference_number", "title": "UTR" },
      { "data": "order_id" },
      { "data": "bank_name" },
      { "data": "aadhar_number" },
      { "data": "transaction_type" ,render:function(data,type,row){
        switch (data) {
          case 'CW':
            return 'Cash Withdrawal';
          case 'AP':
            return 'Aadhar Pay';
          case 'MS':
            return 'Mini Statement'
          case 'BE':
            return 'Balance Enquiry'   
          default:
            return data; // Return the original value if it doesn't match any case
        }
      }},
      { "data": "transaction_amount" },
      { "data": "transaction_status", render: function (data, type, row) {
        if (data != null) {
            let color = '';
            if (data.toLowerCase() === 'success') {
                color = 'green';
            } else if (data.toLowerCase() === 'failed') {
                color = 'red';
            } else if (data.toLowerCase() === 'pending') {
                color = 'orange';
            }

            return `<span style="color: ${color};">${data}</span>`;
        } else {
            return data ? data : '-';
        }
    } },
      { "data": "mobile_number" },
      { "data": "balance_amount" },
      { "data": "action", "name": "action", "title": "Action", "render": (_: any, __: any, data: any) => this.actionButton(data) },
    ], function (settings: any, json: any) {
      $("#aeps-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="2">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY ', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev: any, picker: { startDate: any; endDate: any; }) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate.format('DD-MM-YYYY'), picker.endDate.format('DD-MM-YYYY')])).draw();;
      });
    }, true);
  }

  ngAfterViewInit(): void {
    this.printButtonHandler = (e: { currentTarget: any; }) => {
      this.row = $(e.currentTarget).data('row');
      setTimeout(() => {
        window.print();
      }, 100);
    };

    $(document).on('click', '.btn-print', this.printButtonHandler);
  }

  ngOnDestroy(): void {
    $(document).off('click', '.btn-print', this.printButtonHandler);
  }
  
  actionButton(data: any): string {
      return `
        <div class='d-flex'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
        </div>`;
  }
}

function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}