import { Profile } from '@/models/common';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BiometricService } from '@services/biometric.service';
import { DataService } from '@services/data.service';
import { MFS100Service } from '@services/mfs100.service';
import { AepsService } from '@services/toshani/aeps.service';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-withdrawal-authentication',
  templateUrl: './withdrawal-authentication.component.html',
  styleUrl: './withdrawal-authentication.component.scss'
})
export class WithdrawalAuthenticationComponent {
  public selectedDeviceType: string = '';
  public isLoading: boolean = false;
  public formShow: boolean = true;
  public verificationMessage: string = '';

  constructor(
    protected biometric: BiometricService,
    private alert: ToastrService,
    private api: AepsService,
    private data: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.TOSHANI_AEPS, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.verification_status()
  }

  verification_status() {
    this.isLoading = true;
    this.api.verification().subscribe({
      next: (res: { result: any, message: string }) => {
        this.isLoading = false;
        if (res.result == 1) {
          this.formShow = true;
          return
        }

        this.formShow = false;
        this.verificationMessage = res.message
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }

  async onDeviceTypeChange(): Promise<void> {
    try {
      this.biometric.setMachineType(this.selectedDeviceType);
      const response = await this.biometric.connect();

      this.alert.success(
        response.message,
        'Connection Successful!'
      );
    } catch (error) {
      this.alert.error(
        `An error occurred while trying to connect to the machine: ${error.message}. Please check the connection and try again.`,
        'Connection Error'
      );
    }
  }


  async authenticate() {
    const capture = btoa(await firstValueFrom(this.biometric.capture()));

    this.api.authentication({ pidata: capture }).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message)
          return
        }

        this.alert.success(response.message)
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
}