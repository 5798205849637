import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { openCloseAnimation, rotateAnimation } from './menu-item.animations';
import { LoadingService } from '@services/loading.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataService } from '@services/data.service';
import { Profile } from '@/models/common';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class MenuItemComponent implements OnInit {
  @Input() menuItem: any = null;
  @HostBinding('class.nav-item') isNavItem: boolean = true;
  @HostBinding('class.menu-open') isMenuExtended: boolean = false;
  public isMainActive: boolean = false;
  public isExpandable: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  public submenuChildExpansionStates: boolean[][] = [];
  public profile:any=[];
  isLoggedIn:boolean=false;
  // isLoading:boolean=true;
  // loading:boolean=true;
  constructor(private router: Router,private loading:LoadingService,private sanitizer: DomSanitizer,private data:DataService) { }

  ngOnInit(): void {
    this.data.getProfile().subscribe((profile: Profile) => {
      this.profile=profile.data.permission
    })
    this.isExpandable = !!this.menuItem?.children?.length;

    if (this.menuItem && this.menuItem.children) {
      this.submenuChildExpansionStates = this.menuItem.children.map(submenu => {
        return submenu.children ? new Array(submenu.children.length).fill(false) : [];
      });
    }

    this.calculateIsActive(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.calculateIsActive(event.url);
      });
  }
  getSanitizedSvg(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
  handleMainMenuAction(): void {
    if (this.isExpandable) {
      this.toggleMenu();
    } else {
      this.loading.show();
    setTimeout(()=>{
        this.loading.hide();
    },1000)
      this.router.navigate(this.menuItem.path);
    }
  }

  handleSubMainMenuAction(submenuIndex: number,path): void { 
    this.submenuChildExpansionStates.forEach((submenu, i) => {
      this.submenuChildExpansionStates[i] = new Array(submenu.length).fill(false);
    });

    const submenu = this.menuItem.children[submenuIndex];
    if (submenu && submenu.children) {
      this.submenuChildExpansionStates[submenuIndex] = submenu.children.map(_ => true);
    }
    else {
      this.loading.show();
      this.isLoggedIn = false
      setTimeout(() => {
        this.loading.hide();
      }, 1000)
      if (this.router.url === path[0]) {
        this.router.navigate(['/']).then(() => {
          this.router.navigate(path);
        });
      } else {
        this.router.navigate(path);
      }
    }
  }
  redirectLoad(path:any) {
    this.loading.show();
    setTimeout(() => {
      this.loading.hide();
    }, 1000);
    if (this.router.url === path[0]) {
      this.router.navigate(['/']).then(() => {
        this.router.navigate(path);
      });
    } else {
      this.router.navigate(path);
    }

  }
  isSubMenuActive(index: number): boolean {
    return this.submenuChildExpansionStates[index].some(state => state);
  }

  isSubMenuExpanded(index: number): boolean {
  
    return this.submenuChildExpansionStates[index].some(state => state);
    
  }

  toggleMenu(): void {
    this.isMenuExtended = !this.isMenuExtended;
  }

  calculateIsActive(url: string): void {
    this.isMainActive = false;
    this.isOneOfChildrenActive = false;

    if (this.isExpandable) {
      this.menuItem.children.forEach((item, i) => {
        if (item.path && item.path[0] === url) {
          this.isOneOfChildrenActive = true;
          this.isMenuExtended = true;
          item.children?.forEach((_, j) => {
            this.submenuChildExpansionStates[i][j] = true;
          });
        } else if (item.children) {
          item.children.forEach((subitem, j) => {
            if (subitem.path && subitem.path[0] === url) {
              this.isOneOfChildrenActive = true;
              this.isMenuExtended = true;
              this.submenuChildExpansionStates[i][j] = true;
            }
          });
        }
      });
    } else if (this.menuItem.path[0] === url) {
      this.isMainActive = true;
    }

    if (!this.isMainActive && !this.isOneOfChildrenActive) {
      this.isMenuExtended = false;
    }
  }
}
