import {Component, ViewChild} from '@angular/core';
import {DataTableService} from '@services/data-table.service';
import {DataTableDirective} from 'angular-datatables';
import {ApiService} from '@services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CanaraService} from '@services/toshani/canara.service';
import {title} from 'process';
declare var $: any;

@Component({
    selector: 'app-ca-bank-report',
    templateUrl: './ca-bank-report.component.html',
    styleUrl: './ca-bank-report.component.scss'
})
export class CANBankReportComponent {
    addComplaintForm: FormGroup;
    public dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    public datatableElement: DataTableDirective;
    public row: any;
    public isCalling = false;
    private printButtonHandler: any;
    private statusUpdateHandler: any;
    txnStatusResponse: any;
    complaintListResponse: any;
    selectedComplaintData: any;
    dispositionsList: any;
    complaintTrackDetails: any;

    constructor(
        private dataTableService: DataTableService,
        private formGroup: FormBuilder,
        private api: ApiService,
        private canaraService: CanaraService
    ) {
        this.dataTableService.url = 'reports/canara';
        this.dataTableService.export_name = 'FUNDTRANSFER_REPORT';
    }

    isSelectedRow: any;
    ngOnInit(): void {
        this.dtOptions = this.dataTableService.initializeDataTableOptions(
            [
                {
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    title: 'S.No.',
                    orderable: false,
                    searchable: false
                },
                {
                    data: 'created_at',
                    title:'Transaction Date',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'beneficiary_name',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
               
                {
                    data: 'benef_bank_name',
                    title: 'Bank Name',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'beneficiary_mobile_number',
                    title: 'Beneficiary Mobile',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'destination_acct_number',
                    title: 'Beneficiary Acc No.',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'ifsc_code',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
               
                {
                    data: 'transaction_type',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'txn_amount',
                    title:'Amount',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'surcharge_amount',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'total_amount',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'user_ref_no',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'utr',
                    title: 'UTR Number',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                  data: 'transaction_ref_no',
                  title: 'RRN Number',
                  render: function (data, type, row) {
                    if (row.transaction_type.toUpperCase() === 'IMPS' || row.transaction_type.toUpperCase() === 'INTERNALTRANSFER' ) {
                      return data ? data : '-' 
                    } else if (row.transaction_type.toUpperCase() === 'NEFT' || row.transaction_type.toUpperCase() === 'RTGS') {
                      return '-';
                    } else {
                      return data ? data : '-' 
                    }
                  }
              },
                {
                    data: 'narration',
                    title: 'Remarks',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'status',
                    render: function (data, type, row) {
                        if (data != null) {
                            let color = '';
                            if (data.toLowerCase() === 'success') {
                                color = 'green';
                            } else if (data.toLowerCase() === 'failed') {
                                color = 'red';
                            } else if (data.toLowerCase() === 'pending') {
                                color = 'orange';
                            }

                            return `<span style="color: ${color};">${data}</span>`;
                        } else {
                            return data ? data : '-';
                        }
                    }
                },
                {
                    data: 'action',
                    name: 'action',
                    title: 'Action',
                    render: (_: any, __: any, data: any) =>
                        this.actionButton(data)
                }

                // {
                //   data: "action",
                //   title:'',
                //   render: (data, type, row) => {
                //       return `
                //         <button class="btn btn-primary action-btn" data-id="` + row.id + `"> <i class="fa fa-check"></i> </button>

                //       `;

                //   }
                // },
            ],
            (settings: any, json: any) => {
                $('#bbps-table_filter').append(
                    '<input type="text" class="form-control form-control-sm" id="fromto" data-column="7">'
                );
                $('#fromto').daterangepicker({
                    timeZone: 'Asia/Kolkata',
                    locale: {
                        format: 'DD-MM-YYYY'
                    }
                });

                $('#fromto').on(
                    'apply.daterangepicker',
                    async (ev: any, picker: {startDate: any; endDate: any}) => {
                        (await this.datatableElement.dtInstance)
                            .column($(this).data('column'))
                            .search(
                                JSON.stringify([
                                    picker.startDate.format('DD-MM-YYYY'),
                                    picker.endDate.format('DD-MM-YYYY')
                                ])
                            )
                            .draw();
                    }
                );

                $('#bbps-table').on(
                    'click',
                    'button.action-btn',
                    async (event) => {
                        const id = $(event.currentTarget).data('id');
                        const table = await this.datatableElement.dtInstance;
                        const rowData = table
                            .row($(event.currentTarget).closest('tr'))
                            .data();
                        this.onGetTxnStatus(rowData);
                        // this.rowClickHandler(rowData);
                    }
                );
                $('#bbps-table').on(
                    'click',
                    'button.another-action-btn',
                    async (event) => {
                        const id = $(event.currentTarget).data('id');
                        const table = await this.datatableElement.dtInstance;
                        const rowData = table
                            .row($(event.currentTarget).closest('tr'))
                            .data();
                        this.actionButton(rowData);
                    }
                );
            },
            true
        );
        this.dtOptions.scrollX = true;
    }
    ngAfterViewInit() {
        this.printButtonHandler = (e: {currentTarget: any}) => {
            this.row = $(e.currentTarget).data('row');
            setTimeout(() => {
                window.print();
            }, 100);
        };
        $(document).on('click', '.btn-print', this.printButtonHandler);
    }
    ngOnDestroy(): void {
        $(document).off('click', '.btn-print', this.printButtonHandler);
    }
    rowClickHandler(data: any): void {
        console.log('Row clicked:', data);
    }

    onGetTxnStatus(data: any) {
        // console.log(data)
        this.selectedComplaintData = data;
        var value = {
            user_ref_no: data.user_ref_no
        };
        this.canaraService.transactionDetails(value).subscribe({
            next: (response: any) => {
                this.txnStatusResponse = response.data || [];
                // console.log(this.txnStatusResponse.transaction.status)
                $('#txnStatus').modal('show');
            },
            error: (error) => {
                this.txnStatusResponse = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }

    onClosePopup() {
        $('#txnStatus').modal('hide');
    }
    actionButton(data: any): string {
        // console.log(data)
        if (data.status === 'Pending') {
            return (
                `
        <div class='d-flex '>
          <button class='btn btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'><i class="fa fa-print"></i></button>
         
        </div>`
            );
        } else {
            return (
                `
        <div class='d-flex'>
          <button class='btn  btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}' (click)="printData(data)"><i class="fa fa-print"></i></button>
          
        </div>`
            );
            // const dataRow = $(event.currentTarget).data('row');
        }
    }
    printData(data: any) {
        console.log('printData>>>>',data);
        this.selectedComplaintData = data;
        this.canaraService.transactionDetails(data).subscribe({
            next: (response: any) => {
                this.txnStatusResponse = response.data || [];
                console.log(this.txnStatusResponse.transaction.status);
                $('#txnStatus').modal('show');
            },
            error: (error) => {
                this.txnStatusResponse = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }
}

function escapeHtml(unsafe: string) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}
