<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Payout Service</h1>
        </div>
        <div class="col-sm-6">
          <!-- <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">OBDX Pay</li>
          </ol> -->
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
 
      <div class="row"  >
        <!-- <div class="col-md-12 d-flex content-right">
          <pf-button [variant]="'info'" [loading]="isLoading" >
            Account Statement
          </pf-button>
        </div> -->
        <div class="col-md-8 mt-3">
          <div class="card">
            <div class="card-header">
              <!-- <div class="card-title mb-0" >
                <small><strong>Hi, {{customerName}}</strong></small><br />
                <small>Acc Number : {{accNumber}}<strong></strong></small><br />
                <small>Current Balance : ₹ {{balance}}<strong></strong></small>
              </div> -->
             
            </div>
            <div class="card-body">
              <form >
                <div>
                  <div class="form-group">
                    <label for="Account Holder Name" class="form-label">Account Holder Name</label>
                    <input type="text" class="form-control" id="account_holder_name"
                      formControlName="account_holder_name">
                    <div
                      *ngIf="accountForm.get('account_holder_name').invalid && accountForm.get('account_holder_name').touched"
                      class="text-danger text-sm font-weight-bold">
                      Account Holder Name is required.
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="beneficiaryId" class="form-label">Account Holder mobile number</label>
                    <input type="text" class="form-control" id="account_holder_mobile_number"
                      formControlName="account_holder_mobile_number">
                    <div
                      *ngIf="accountForm.get('account_holder_mobile_number').invalid && accountForm.get('account_holder_mobile_number').touched"
                      class="text-danger text-sm font-weight-bold">
                      Account Holder mobile number is required.
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="mobileNumber" class="form-label">Bank Name</label>
                    <input type="text" class="form-control" id="bank_name" formControlName="bank_name">
                    <div *ngIf="accountForm.get('bank_name').invalid && accountForm.get('bank_name').touched"
                      class="text-danger text-sm font-weight-bold">
                      Bank Name is required.
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="beneficiaryId" class="form-label">Account Number</label>
                    <input type="text" class="form-control" id="account_number" formControlName="account_number">
                    <div *ngIf="accountForm.get('account_number').invalid && accountForm.get('account_number').touched"
                      class="text-danger text-sm font-weight-bold">
                      Account Number is required.
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="beneficiaryId" class="form-label">IFSC Code</label>
                    <input type="text" class="form-control" id="ifsc_code" formControlName="ifsc_code">
                    <div *ngIf="accountForm.get('ifsc_code').invalid && accountForm.get('ifsc_code').touched"
                      class="text-danger text-sm font-weight-bold">
                      IFSC Code is required.
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="beneficiaryId" class="form-label">Note</label>
                    <input type="text" class="form-control" id="narration" formControlName="narration">
                  </div>
                 
                </div>
                <div class="form-group">
                  <label for="amount" class="form-label">Amount</label>
                  <input type="tel" class="form-control" prefix="₹" mask="00 || 000 || 0,000 || 00,000 || 0,00,000"
                    thousandSeparator="," [min]="0" [max]="200000" id="amount" formControlName="amount">
                  <small>{{ accountForm?.value?.amount}}</small>
                  <div *ngIf="accountForm.get('amount').invalid && accountForm.get('amount').touched"
                    class="text-danger text-sm font-weight-bold">
                    <div *ngIf="accountForm.get('amount').errors?.required">Amount is required.</div>
                    <div *ngIf="accountForm.get('amount').errors?.min">Amount must be at least ₹{{min}}.</div>
                    <div *ngIf="accountForm.get('amount').errors?.max">Amount cannot exceed ₹{{max}}.</div>
                  </div>
                </div>
                <!-- <pf-button [variant]="'primary'">Continue</pf-button> -->
              </form>
            </div>
  
          </div>
        </div>
        <!-- <div class="col-md-4 mt-3">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title mt-2">Beneficiary List</h3>
              <div class="card-tools">
                <pf-button [variant]="'info'" [block]="true" [loading]="isLoading" (click)="addBeneficiaryModal.show()">
                  Add Beneficiary
                </pf-button>
              </div>
            </div>
            <div class="card-body">
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Search beneficiaries..." [(ngModel)]="searchQuery">
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fas fa-search"></i></span>
                </div>
              </div>
  
              <div class="list-group" *ngIf="filteredBeneficiaries.length">
                <div *ngFor="let beneficiary of filteredBeneficiaries"
                  class="list-group-item d-flex justify-content-between">
                  <div>
                    <h5 class="mb-1">{{beneficiary.account_holder_name}}</h5>
                    <small class="mb-1">A/c: {{beneficiary.account_number}}</small> <br />
                    <small class="mb-1">IFSC: {{beneficiary.ifsc_code}}</small>
                  </div>
                  <div id="btnGroup" class="d-flex justify-content-between align-items-center gap-2">
                    <pf-button [variant]="'success'" [loading]="false" (click)="details(beneficiary)">
                      <i class="fas fa-money-check" title="Transfer"></i>
                    </pf-button>
                    <pf-button [variant]="'danger'" [loading]="false" (click)="delete(beneficiary)">
                      <i class="fas fa-trash-alt" title="Delete"></i>
                    </pf-button>
                  </div>
                </div>
  
                <div *ngIf="filteredBeneficiaries.length === 0 && beneficiaries.length !== 0"
                  class="list-group-item d-flex justify-content-between">
                  <h3 class="mb-0">No matching beneficiaries found.</h3>
                </div>
  
                <div *ngIf="!beneficiaries.length" class="list-group-item d-flex justify-content-between">
                  <h3 class="mb-0">No beneficiary found.</h3>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
  
      <!-- <div #modal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add Beneficiary</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="addBeneficiaryModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form autocomplete="off" [formGroup]="beneficiaryForm" (ngSubmit)="addBeneficiary()">
  
                <div class="form-group">
                  <label for="bankName">Bank Name <strong class="text-danger">*</strong></label>
                  <input type="text" class="form-control" formControlName="bankName" id="bankName"
                    placeholder="Enter bank name">
                  <div
                    *ngIf="beneficiaryForm.get('bankName').invalid && (beneficiaryForm.get('bankName').dirty || beneficiaryForm.get('bankName').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Bank name is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="branchName">Branch Name<strong class="text-danger">*</strong></label>
                  <input type="text" class="form-control" formControlName="branchName" id="branchName"
                    placeholder="Enter branch name">
                  <div
                    *ngIf="beneficiaryForm.get('branchName').invalid && (beneficiaryForm.get('branchName').dirty || beneficiaryForm.get('branchName').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Branch name is required.
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="ifscCode">IFSC Code <strong class="text-danger">*</strong></label>
                  <input type="text" class="form-control" formControlName="ifscCode" id="ifscCode"
                    placeholder="Enter IFSC code">
                  <div
                    *ngIf="beneficiaryForm.get('ifscCode').invalid && (beneficiaryForm.get('ifscCode').dirty || beneficiaryForm.get('ifscCode').touched)"
                    class="text-danger text-sm font-weight-bold">
                    IFSC code is required.
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="accountType">Account Type <strong class="text-danger">*</strong></label>
                  <select class="form-control" formControlName="accountType" id="accountType">
                    <option value="Savings">Savings</option>
                  </select>
                  <div
                    *ngIf="beneficiaryForm.get('accountType').invalid && (beneficiaryForm.get('accountType').dirty || beneficiaryForm.get('accountType').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Account type is required.
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="accountNumber">Account Number <strong class="text-danger">*</strong></label>
                  <input type="text" class="form-control" formControlName="accountNumber" id="accountNumber"
                    placeholder="Enter account number">
                  <div
                    *ngIf="beneficiaryForm.get('accountNumber').invalid && (beneficiaryForm.get('accountNumber').dirty || beneficiaryForm.get('accountNumber').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Account number is required.
                  </div>
                </div>
  
                <div class="form-group text-right mb-0">
                  <button type="button" class="btn btn-primary btn-sm"
                  (click)="verifyAccountNumber()">Verify</button>
                </div>
  
                <div class="form-group">
                  <label for="accountHolderName">Account Holder Name <strong class="text-danger">*</strong></label>
                  <input type="text" class="form-control" formControlName="accountHolderName" id="accountHolderName"
                    placeholder="Enter account holder name">
                  <div
                    *ngIf="beneficiaryForm.get('accountHolderName').invalid && (beneficiaryForm.get('accountHolderName').dirty || beneficiaryForm.get('accountHolderName').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Account holder name is required.
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="accountHolderMobileNumber">Account Holder Mobile Number <strong
                      class="text-danger">*</strong></label>
                  <input type="email" class="form-control" formControlName="accountHolderMobileNumber"
                    id="accountHolderMobileNumber" placeholder="Enter account holder mobile number">
                  <div
                    *ngIf="beneficiaryForm.get('accountHolderMobileNumber').invalid && (beneficiaryForm.get('accountHolderMobileNumber').dirty || beneficiaryForm.get('accountHolderMobileNumber').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Account holder mobile number is required.
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="emailId">Email Address <strong class="text-danger">*</strong></label>
                  <input type="email" class="form-control" formControlName="emailId" id="emailId"
                    placeholder="Enter email address">
                  <div
                    *ngIf="beneficiaryForm.get('emailId').invalid && (beneficiaryForm.get('emailId').dirty || beneficiaryForm.get('emailId').touched)"
                    class="text-danger text-sm font-weight-bold">
                    Email address is required.
                  </div>
                </div>
  
                <button type="submit" class="d-none"></button>
                <pf-button [variant]="'primary'" [loading]="isLoading" (click)="addBeneficiary()">
                  Save
                </pf-button>
              </form>
            </div>
          </div>
        </div>
      </div> -->
  
      <!-- TPinmodal -->
      <!-- <div #TPinmodal class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add TPin</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="resetForms(true); tPinModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form autocomplete="off" [formGroup]="accountForm" (ngSubmit)="transfer()">
  
                <div class="form-group">
                  <label for="tPin">TPin <strong class="text-danger">*</strong></label>
                  <input type="password" class="form-control" formControlName="tpin" id="tpin"
                    placeholder="Please enter TPin."
                    [ngClass]="{'is-invalid': accountForm.get('tpin').invalid && (accountForm.get('tpin').dirty || accountForm.get('tpin').touched)}">
                  <div
                    *ngIf="accountForm.get('tpin').invalid && (accountForm.get('tpin').dirty || accountForm.get('tpin').touched)"
                    class="text-danger text-sm font-weight-bold">
                    <div *ngIf="accountForm.get('tpin').errors.required">TPin is required.</div>
                    <div *ngIf="accountForm.get('tpin').errors.pattern">Please enter a valid TPin code.
                    </div>
                  </div>
                </div>
  
                <button type="submit" class="d-none"></button>
                <button type="submit" class="btn btn-primary" [disabled]="isLoading">Save</button>
              </form>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
  