import {Component, ViewChild} from '@angular/core';
import {DataTableService} from '@services/data-table.service';
import {DataTableDirective} from 'angular-datatables';
import {ApiService} from '@services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var $: any;

@Component({
    selector: 'app-pg-report',
    templateUrl: './pg-report.component.html',
    styleUrl: './pg-report.component.scss'
})
export class PGReportComponent {
    addComplaintForm: FormGroup;
    public dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    public datatableElement: DataTableDirective;
    public row: any;
    public isCalling = false;
    private printButtonHandler: any;
    private statusUpdateHandler: any;
    txnStatusResponse: any;
    complaintListResponse: any;
    selectedComplaintData: any;
    dispositionsList: any;
    complaintTrackDetails: any;
    txnEnquiryResponse: any[];

    constructor(
        private dataTableService: DataTableService,
        private formGroup: FormBuilder,
        private api: ApiService
    ) {
        this.dataTableService.url = 'reports/pg-report';
        this.dataTableService.export_name = 'PG_REPORT';
    }

    isSelectedRow: any;
    ngOnInit(): void {
        this.dtOptions = this.dataTableService.initializeDataTableOptions(
            [
                {
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    title: '#',
                    orderable: false,
                    searchable: false
                },
                {
                    data: 'pg_no',
                    title: 'PG No.',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'payerNamed',
                    title: 'Payer Name',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                // { data: "payerEmail" },
                {
                    data: 'payerMobile',
                    title: 'Mobile Number',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'clientTxnId',
                    title: 'Client Txn Id',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'payerAddress',
                    title: 'Payer Address',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'amount',
                    title: 'Amount',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'surcharges',
                    title: 'Surcharges',
                    render: function (data, type, row) {
                        return data ? parseFloat(data).toFixed(2): '-';
                    }
                },
                {
                    data: 'paidAmount',
                    title: 'Paid Amount',
                    render: function (data, type, row) {
                        return data ? parseFloat(data).toFixed(2): '-';
                    }
                },
                {
                    data: 'paymentMode',
                    title: 'Payment Mode',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                //   { data: "bankName" },
                {
                    data: 'status',
                    title: 'Status',
                    render: function (data, type, row) {
                        if (data != null) {
                            let color = '';
                            if (data.toLowerCase() === 'success') {
                                color = 'green';
                            } else if (data.toLowerCase() === 'failed') {
                                color = 'red';
                            } else if (data.toLowerCase() === 'pending') {
                                color = 'orange';
                            }

                            return `<span style="color: ${color};">${data}</span>`;
                        } else {
                            return data ? data : `<span style="color: red;">Failed</span>`;
                        }
                    }
                },
                {
                    data: 'sabpaisaTxnId',
                    title: 'Transaction ID',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'created_at',
                    title: 'Transaction Date',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                
                { "data": "action", "name": "action", "title": "Action", "render": (_: any, __: any, data: any) => this.actionButton(data)
   
            },
            //     {
            //         data: 'action',
            //         render: (data, type, row) => {
            //             if (
            //                 row.status === 'SUCCESS' ||
            //                 row.status === 'FAILED'
            //             ) {
            //                 return (
            //                     `
            // <div class="row">
            //   <button class="btn btn-primary action-btn" data-id="` +
            //                     row.id +
            //                     `"> <i class="fa fa-check"></i> </button>
            //   <button class="btn btn-primary another-action-btn ml-1" data-id="` +
            //                     row.id +
            //                     `"> <i class="fa fa-question-circle"></i> </button>
            // </div>
            // `
            //                 );
            //             } else {
            //                 return '';
            //             }
            //         }
            //     }
            ],
            (settings: any, json: any) => {
                $('#bbps-table_filter').append(
                    '<input type="text" class="form-control form-control-sm" id="fromto" data-column="7">'
                );
                $('#fromto').daterangepicker({
                    timeZone: 'Asia/Kolkata',
                    locale: {
                        format: 'DD-MM-YYYY'
                    }
                });

                $('#fromto').on(
                    'apply.daterangepicker',
                    async (ev: any, picker: {startDate: any; endDate: any}) => {
                        (await this.datatableElement.dtInstance)
                            .column($(this).data('column'))
                            .search(
                                JSON.stringify([
                                    picker.startDate.format('DD-MM-YYYY'),
                                    picker.endDate.format('DD-MM-YYYY')
                                ])
                            )
                            .draw();
                    }
                );

                $('#bbps-table').on(
                    'click',
                    'button.action-btn',
                    async (event) => {
                        const id = $(event.currentTarget).data('id');
                        const table = await this.datatableElement.dtInstance;
                        const rowData = table
                            .row($(event.currentTarget).closest('tr'))
                            .data();
                        this.onGetTxnStatus(rowData);
                    }
                );
                $('#bbps-table').on(
                    'click',
                    'button.another-action-btn',
                    async (event) => {
                        const id = $(event.currentTarget).data('id');
                        const table = await this.datatableElement.dtInstance;
                        const rowData = table
                            .row($(event.currentTarget).closest('tr'))
                            .data();
                        this.onTransactionEnquiry(rowData);
                    }
                );
            },
            true
        );
        this.dtOptions.scrollX = true;
    }

    ngAfterViewInit() {
        this.printButtonHandler = (e: { currentTarget: any; }) => {
          this.row = $(e.currentTarget).data('row');
          setTimeout(() => {
            window.print();
          }, 100);
        };
        $(document).on('click', '.btn-print', this.printButtonHandler);
    
      }
      ngOnDestroy(): void {
        $(document).off('click', '.btn-print', this.printButtonHandler);
       
      }
    rowClickHandler(data: any): void {
        console.log('Row clicked:', data);
    }

    onGetTxnStatus(data: any) {
        this.selectedComplaintData = data;
        var value = {
            clientTxnId: data.clientTxnId
        };
        this.api.transactionDetails(value).subscribe({
            next: (response: any) => {
                this.txnStatusResponse = response.data || [];
                // console.log(this.txnStatusResponse)
                $('#txnStatus').modal('show');
            },
            error: (error) => {
                this.txnStatusResponse = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }

    onTransactionEnquiry(data: any) {
        var payLoad = {
            clientCode: data.clientCode,
            sabpaisaTxnId: data.sabpaisaTxnId
        };
        this.api.transactionEnquiry(payLoad).subscribe({
            next: (response: any) => {
                this.txnEnquiryResponse = response.data || [];
                $('#txnEnquiry').modal('show');
            },
            error: (error) => {
                this.txnEnquiryResponse = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }

    onClosePopup() {
        $('#txnStatus').modal('hide');
    }
    actionButton(data: any): string {
        // console.log(data)
        if (data.status === 'Pending') {
          return `
            <div class='d-flex '>
              <button class='btn btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'><i class="fa fa-print"></i></button>
              <button class="btn btn-primary action-btn ml-2" data-id="` + data.id + `"> <i class="fa fa-check"></i> </button>
              
            </div>`;
        } else {
          return `
            <div class='d-flex'>
              <button class='btn  btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'><i class="fa fa-print"></i></button>
              <button class="btn btn-primary action-btn ml-2" data-id="` + data.id + `"> <i class="fa fa-check"></i> </button>
              
            </div>`;
        }
      }
}  
// <button class="btn btn-primary another-action-btn ml-1" data-id="` +
// data.id +
// `"> <i class="fa fa-question-circle"></i> </button>

function escapeHtml(unsafe: string) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }