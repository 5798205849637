<div class="content" id="id-card">
    <div class="row">
        <div class="col-6" style="display: flex;justify-content: end;padding-right: 0;">
            <div class="card">
                <img
                    src="assets/img/logo.png"
                    width="120px"
                    class="mt-3 content-center"
                />
                <h4 class="text-center mt-2 color-grey">Identity Card</h4>
                <img
                    src="assets/img/default-profile.png"
                    width="120px"
                    class="mt-3 content-center border-blue"
                />
                <div class="row mt-3">
                    <div class="col-md-4 margin-left">
                        <span >Name :</span>
                    </div>
                    <div class="col-md-6">
                        <span style="text-transform: capitalize;"><b>{{this.first_name}} {{this.last_name}}</b></span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4 margin-left">
                        <span>Member ID :</span>
                    </div>
                    <div class="col-md-6">
                        <span><b>{{this.code}}</b></span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4 margin-left">
                        <span>State :</span>
                    </div>
                    <div class="col-md-6">
                        <span><b>{{this.state}}</b></span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4 margin-left">
                        <span>Member Type :</span>
                    </div>
                    <div class="col-md-6">
                        <span><b>{{this.department}}</b></span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4 margin-left">
                        <span>DOJ :</span>
                    </div>
                    <div class="col-md-6">
                        <span><b>{{this.doj | date: 'yyyy-MM-dd'}}</b></span>
                    </div>
                </div>
                <div class="row mt-3 text-center">
                    <p class="mb-0"><b>Shri Dariyav Doorstep Services Private Limited</b></p>
                    <span>(Issuing Authority)</span>
                </div>
                <div class="row mt-3 text-center">
                    <p class="mb-0 text-danger"><b>Essential Services Provider To The Country</b></p>
                    <span>(Banking and ATM services)</span>
                </div>
            </div>
        </div>
        <div class="col-6" style="padding-left: 0;">
            <div class="card">
                <img
                    src="assets/img/logo.png"
                    width="120px"
                    class="mt-3 content-center"
                />
                <h4 class="text-center mt-2 color-grey"><b>INSTRUCTIONS</b></h4>
               <ul>
                <li>
                    <span>This card must be produced as per demand</span>
                </li>
                <li>
                    <span>Holders of this card will be accountable for any misuse, lose or damage caused</span>
                </li>
                <li>
                    <span>Loss must be reported to the registered Office on an immediate basis</span>
                </li>
            </ul>
                <div class="row mt-3 text-center">
                    <p class="mb-0 text-danger"><b>If found please return to Registered office</b></p>
                </div>
                <div class="row mt-3 text-center">
                    <p class="mb-0"><b>Shri Dariyav Doorstep Services Private Limited</b></p>
                    <span>Badri Vishal Nagar, Bikaner, Rajasthan - 334004</span>
                </div>
                <div class="row mt-3 text-center">
                    <p class="mb-0 color-grey">The Holder of this card has documentary evidence to prove thatthey are involved in providing essential services to the citizen of this country</p>
                </div>
                <div class="row mt-3 text-center">
                    <p class="mb-0 color-grey"><b>Website : sddspl.com</b></p>
                </div>
            </div>
        </div>
    </div>
</div>
