import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Bank, City, Profile, State } from '@/models/common';
import { BankDetails } from '@/models/toshani';
import { SafeXPayService } from '@services/payout/safe-xpay.service';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { isAllowed } from '@/utils/helpers';
import { PERMISSIONS } from '@/utils/constants';
import { finalize } from 'rxjs';
import { ApiService } from '@services/api.service';
import { PennyDropService } from '@services/toshani/penny-drop.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-payment_failure',
  templateUrl: './payment_failure.component.html',
  styleUrl: './payment_failure.component.scss'
})
export class PaymentFailureComponent {
  userInfo:any;
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public states: State[];
  public cities: City[];
  searchQuery: string = '';
  public modal: ElementRef<HTMLDivElement>;
  txnDetails: any;
  clientTxnId: any | null = null;



  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: ToastrService,
    private data: DataService,
    private api: ApiService,
    private payout: SafeXPayService,
    private penny: PennyDropService,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    // alert("Test by pg failed");
    this.clientTxnId = this.route.snapshot.paramMap.get('clientTxnId');
    console.log(this.clientTxnId); 
    this.data.getProfile().subscribe((profile: Profile) => {
      (profile);
      this.userInfo = profile.data.user;
      if (!isAllowed(PERMISSIONS.PG_transaction, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

  }

  ngAfterViewInit() {
    // this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    // this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  }

  getTransactionDetails(){
    var value = {
      clientTxnId : this.clientTxnId
    }
    this.api.transactionDetails(value).subscribe({
      next: (response: any) => {
        this.txnDetails = response?.data;
      },
      error: (error) => {
        this.txnDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
  extractPriceAsInt(priceHtml: string): number {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = priceHtml;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const numericValue = parseInt(textContent.replace(/[^0-9]/g, ''), 10);
    return isNaN(numericValue) ? 0 : numericValue;
  }
  extractPrice(priceHtml:any) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = priceHtml;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  print(){
    // document.querySelector('body').classList.add('non-printable');
    window.print()
    // document.querySelector('body').classList.remove('non-printable');

}
}
