import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Bank, City, Profile, State } from '@/models/common';
import { BankDetails } from '@/models/toshani';
import { SafeXPayService } from '@services/payout/safe-xpay.service';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { isAllowed } from '@/utils/helpers';
import { PERMISSIONS } from '@/utils/constants';
import { finalize } from 'rxjs';
import { ApiService } from '@services/api.service';
import { PennyDropService } from '@services/toshani/penny-drop.service';

@Component({
  selector: 'app-sabpaisa',
  templateUrl: './sabpaisa.component.html',
  styleUrl: './sabpaisa.component.scss'
})
export class SabpaisaComponent {
  userInfo:any;
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public mobileNumber: number;
  public accountForm: FormGroup;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public states: State[];
  public cities: City[];
  public addBeneficiaryModal: Modal;
  public beneficiary: any;
  searchQuery: string = '';
  public min: any;
  public max: any;
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public tPinModal: Modal;
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  public remitter: { name: any; number: any; limit: { remaining: any; }; };
  public beneficiaries: any;
  public pgList:any;
  public paymentData:any;

  totalAmount:any;
  surcharge_type:any;
  setActive= '';
  surchargeAmount:any;
  getSurcharge:any;
  get filteredBeneficiaries() {
    return this.beneficiaries ? this.beneficiaries.filter(beneficiary =>
      beneficiary.account_holder_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    ) : [];
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: ToastrService,
    private data: DataService,
    private api: ApiService,
    private payout: SafeXPayService,
    private penny: PennyDropService
  ) { }

  ngOnInit() {

    this.data.getProfile().subscribe((profile: Profile) => {
      (profile);
      this.userInfo = profile.data.user;
      if (!isAllowed(PERMISSIONS.PG_transaction, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      // mobileNumber: [{ value: '', disabled: true }, Validators.required],
      // beneficiaryId: [{ value: '', disabled: true }, Validators.required],
      // account_holder_name: [{ value: '', disabled: true }, Validators.required],
      // account_holder_mobile_number: [{ value: '', disabled: true }, Validators.required],
      // bank_name: [{ value: '', disabled: true }, Validators.required],
      // account_number: [{ value: '', disabled: true }, Validators.required],
      // ifsc_code: [{ value: '', disabled: true }, Validators.required],
      // tpin: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0), Validators.max(200000)]],
      pg_name: ['',[Validators.required]],
      surcharge:['',[Validators.required]],
      total_amount:[0,[]]

    });

    this.beneficiaryForm = this.formBuilder.group({
      mobileNumber: [''],
      accountHolderName: ['', [Validators.required, Validators.maxLength(255)]],
      accountHolderMobileNumber: ['', Validators.required],
      emailId: ['', Validators.required],
      bankName: ['', Validators.required],
      accountType: ['', Validators.required],
      accountNumber: ['', Validators.required],
      ifscCode: ['', Validators.required]
    });

    this.api.getMinMax('payout', 'safexpay').subscribe({
      next: (response: any) => {
        this.min = response.data.min_start_range;
        this.max = response.data.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });
    this.getPgList();
   
  }

  ngAfterViewInit() {
    // this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    // this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  }

  getPgList(){
    this.api.paymentGatewayList().subscribe((res:any) => {
      console.log(res)
      // debugger
      if(res.packageService ==  null ){
        this.setActive='pg'
      this.pgList=res.data
    }
    else{
      this.setActive='payment'
      this.pgList=res.data
      this.paymentData=res.packageService


    }
    })
  }
  onSelectOperator($event){
    console.log($event)
    var getData:any = this.pgList.find(charge => charge.alias === $event);
    console.log(getData)
 
   if(this.setActive=='pg'){
    this.accountForm.controls['surcharge'].setValue(getData.surcharges)
    this.surcharge_type = getData.surcharge_type;
  }
  this.payemntGateway()
  }

  payemntGateway(){
    if(this.setActive == 'pg'){
      this.accountForm.valueChanges.subscribe(value => {
      if( this.surcharge_type == 'f'){
        debugger
       const totalAmount= parseInt(value.amount)+parseInt(value.surcharge)
       this.accountForm.get('total_amount').setValue(totalAmount, { emitEvent: false });
       
      }
      if( this.surcharge_type == 'p'){
      
        this.surchargeAmount =  (value.amount * value.surcharge) / 100;
        const totalAmount = parseInt(value.amount)+this.surchargeAmount
        // const formattedTotalAmount = this.decimalPipe.transform(totalAmount, '1.2-2');
        this.accountForm.patchValue({ total_amount: totalAmount }, { emitEvent: false }); 
        this.accountForm.patchValue({ surcharge: this.surchargeAmount }, { emitEvent: false }); 

      }
      });
    }
    if(this.setActive == 'payment'){
      this.accountForm.valueChanges.subscribe(value => {
        for (const item of this.paymentData) {
          
          if (value.amount >= item.start_range && value.amount <= item.end_range) {
         this.accountForm.controls['surcharge'].setValue( item.output_value)
  
         if( item.type == 'f'){
          const totalAmount= parseInt(value.amount)+parseInt(item.output_value)
          this.accountForm.get('total_amount').setValue(totalAmount, { emitEvent: false });
         }
         if( item.type == 'p'){
         
           this.surchargeAmount =  (value.amount * item.output_value) / 100;
           const totalAmount = parseInt(value.amount)+this.surchargeAmount
           // const formattedTotalAmount = this.decimalPipe.transform(totalAmount, '1.2-2');
           this.accountForm.patchValue({ total_amount: totalAmount }, { emitEvent: false }); 
           this.accountForm.patchValue({ surcharge: this.surchargeAmount }, { emitEvent: false }); 

   
         }
          }
        }
      
       
        });
    }
  }
  verification() {
    const form = this.customerVerificationForm;
    if (!form.valid) {
      this.alert.error('Invalid Form');
      return;
    }

    const mobileNumber = form.value.mobileNumber;
    this.isLoading = true;

    this.api.getCustomerInfo(mobileNumber).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: (response: any) => {
        this.alert.success(response.message);
        if (!response.status) {
          this.customerRegistrationForm.patchValue({ mobileNumber });
          this.isOTPNeeded = true;
          return;
        }

        this.isLoggedIn = true;
        this.beneficiaryForm.patchValue({ mobileNumber });

        // this.loadBeneficiaries(form.value.mobileNumber);
      },
      error: (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  register() {
    const form = this.customerRegistrationForm;
    if (!form.valid) {
      this.alert.error('Invalid Form');
      return;
    }

    this.isLoading = true;

    this.payout.verification(form.getRawValue()).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.isOTPNeeded = true;
        this.isLoggedIn = true;
        this.alert.success(response.message);

        this.beneficiaryForm.patchValue({ mobileNumber: form.getRawValue().mobileNumber });

        this.loadBeneficiaries(form.getRawValue().mobileNumber);
      },
      error: (error: any) => {
        // this.alert.error(error.message)
        this.isLoading = false;
      }
    });
  }

  private loadBeneficiaries(mobileNumber: number) {
    this.payout.beneficiaries(mobileNumber).subscribe({
      next: (response: any) => {

        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.beneficiaries = response.data;
      },
      error: (error) => {
        console.log(error, 'error');
      }
    });
  }


  transfer() {
   
    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }
      return;
    }

    const data = this.accountForm.getRawValue();
    this.isLoading = true;
    this.payout.transfer(data).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.tPinModal.hide();

        if (!response.status) {
          this.alert.error(response.message);
          return
        }

        this.alert.success(response.message);
      },
      error: ({ error }) => {
        this.isLoading = false;
        this.tPinModal.hide();
      }
    })
  }


  confirmTransfer() {
    const form = this.accountForm
    if (!form.valid) {
      this.alert.error('Please fill all the required details');
      return;
    }
    var value = {
      amount : this.accountForm.value.total_amount,
      payerName : this.userInfo.first_name,
      payerEmail : this.userInfo.email,
      payerMobile : this.userInfo.mobile,
      payerAddress : this.userInfo.address,
      surcharges:this.accountForm.value.surcharge
    
    }
debugger
    this.api.initiatePayment(value).subscribe({
      next: (response: any) => {
        this.redirectWithPost(response.data.redirectUrl, response.data.formData);
        this.alert.success(response.message);
      },
      error: (error) => {
        // this.operatorList = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  redirectWithPost(url,formData){
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    console.log(formData);
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = formData[key];
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }



}
