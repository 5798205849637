<div id="mainReceipt">
    <h2>AePS Transaction Receipt</h2>
    <hr>
    <table *ngIf="row">
        <tr>
            <td style="width: 50%;">
                <div class="info"><strong>Mobile Number:</strong> {{ row.mobile_number }}</div>
                <div class="info"><strong>Transaction Date:</strong> {{ row.created_at }}</div>
                <div class="info"><strong>Transaction ID:</strong> {{ row.transaction_id }}</div>
                <div class="info"><strong>Status:</strong> {{ row.transaction_status }}</div>
            </td>
            <td style="width: 50%;" align="right">
                <div>
                    <img src="./assets/img/logo.png" alt="Company Logo" class="logo">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table class="innerTable">
                    <tr>
                        <th>UTR</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Txn ID</th>
                    </tr>
                    <tr>
                        <td>{{ row.bank_reference_number }}</td>
                        <td>{{ row.transaction_amount }}</td>
                        <td>{{ row.transaction_status }}</td>
                        <td>{{ row.transaction_id }}</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div class="info"><strong>Total Amount in Words:</strong> {{ row.transaction_amount | numToWords }}</div>
                <div class="info"><strong>Bank Name:</strong> {{ row.bank_name }}</div>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <hr>
                <p style="text-align: center;"><strong>Note:</strong>
                    This is a computer-generated receipt and does not require any physical signature
                </p>
            </td>
        </tr>
    </table>
</div>

