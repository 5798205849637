import { Component, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;

@Component({
  selector: 'app-dmt-express-report',
  templateUrl: './dmt-express-report.component.html',
  styleUrl: './dmt-express-report.component.scss'
})
export class DmtExpressReportComponent {
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  private printButtonHandler: any;
  private statusUpdateHandler: any;
  public row: any;

  constructor(private dataTableService: DataTableService, private apiService: ApiService) {
    this.dataTableService.url = 'reports/xdmt'
    this.dataTableService.export_name = 'DMT_EXPRESS_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { 'title': 'Mobile Number', 'data': 'mobile_number' },
      { 'title': 'Transaction ID', 'data': 'transaction_id' },
      { 'title': 'Unique Transaction Reference', 'data': 'unique_transaction_reference' },
      { 'title': 'Name', 'data': 'name' },
      { 'title': 'Account Number', 'data': 'account_number' },
      { 'title': 'Bank Name', 'data': 'bank_name' },
      { 'title': 'Amount', 'data': 'amount' },
      { "title": "Net Amount", "data": "net_amount", "name": "net_amount" },
      { 'title': 'IFSC Code', 'data': 'ifsc_code' },
      { 'title': 'Status', 'data': 'status' , render: function (data, type, row) {
        if (data != null) {
            let color = '';
            if (data.toLowerCase() === 'success') {
                color = 'green';
            } else if (data.toLowerCase() === 'failed') {
                color = 'red';
            } else if (data.toLowerCase() === 'pending') {
                color = 'orange';
            }

            return `<span style="color: ${color};">${data}</span>`;
        } else {
            return data ? data : '-';
        }
    }},
      { "data": "created_at", "title": "Created At" },
      { "data": "order_id", "title": "Sender Mobile", "class": "d-none" },
      { "data": "action", "name": "action", "title": "Action", "render": (_: any, __: any, data: any) => this.actionButton(data) },
    ], function (settings: any, json: any) {
      $("#express-pay-table_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="10">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY ', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev: any, picker: { startDate: any; endDate: any; }) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate.format('DD-MM-YYYY'), picker.endDate.format('DD-MM-YYYY')])).draw();;
      });
    });
  }

  ngAfterViewInit(): void {
  
    this.printButtonHandler = (e: { currentTarget: any; }) => {
      this.row = $(e.currentTarget).data('row');
      setTimeout(() => {
        window.print();
      }, 100);
    };

    this.statusUpdateHandler = (e: { currentTarget: any; }) => {
      const orderId = $(e.currentTarget).data('order-id');
      this.apiService.xdmtStatus(orderId).subscribe({
        next: async (response: any) => {
          (await this.datatableElement.dtInstance).ajax.reload(null, false);
        },
        error: (err) => {
          console.log(err);
        }
      });
    };

    $(document).on('click', '.btn-print', this.printButtonHandler);
    $(document).on('click', '.status-update', this.statusUpdateHandler);
  }

  ngOnDestroy(): void {
    $(document).off('click', '.btn-print', this.printButtonHandler);
    $(document).off('click', '.status-update', this.statusUpdateHandler);
  }

  actionButton(data: any): string {
    
    if (data.status === 'Pending') {
      return `
        <div class='d-flex gap-4'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
          <button class='btn btn-sm btn-primary status-update' data-order-id='${data.order_id}'>Update Status</button>
        </div>`;
    } else {
      return `
        <div class='d-flex'>
          <button class='btn btn-sm btn-info btn-print' data-row='${escapeHtml(JSON.stringify(data))}'>Print</button>
        </div>`;
    }
  }
}

function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}