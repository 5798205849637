import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {XMLParserService} from '@services/aeps/xml-parser.service';
import {MFS100Service} from '@services/mfs100.service';
import {AepsService} from '@services/toshani/aeps.service';
import {firstValueFrom, from} from 'rxjs';
import {Modal} from 'bootstrap';

import {Profile} from '@/models/common';
import {PERMISSIONS} from '@/utils/constants';
import {isAllowed} from '@/utils/helpers';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '@services/data.service';
import {BiometricService} from '@services/biometric.service';

@Component({
    selector: 'app-transaction',
    templateUrl: './transaction.component.html',
    styleUrl: './transaction.component.scss'
})
export class TransactionComponent {
    selectedDeviceType: string = '';
    isLoading: boolean = false;
    selectedAEPS: string = '';
    transactionForm: FormGroup;
    public formShow: boolean = true;
    public verificationMessage: string = '';
    public banks: {bankName: string; iinno: string}[];
    public bankType: string = 'CW';
    @ViewChild('aepsTransactionModal', {static: false})
    public aepsTransaction: ElementRef<HTMLDivElement>;
    public aepsTransactionModal: Modal;
    transactionData: any;

    aadhaarValue: string = '';
    aadhaarStack: string[] = [];
    maskStack: string[] = [];
    isMasked: boolean = true;

    constructor(
        private formBuilder: FormBuilder,
        private api: AepsService,
        public biometric: BiometricService,
        private alert: ToastrService,
        private data: DataService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.data.getProfile().subscribe((profile: Profile) => {
            if (!isAllowed(PERMISSIONS.TOSHANI_AEPS, profile.data.permission)) {
                this.toastr.info(
                    'You are not allowed to access this resource.'
                );
                this.router.navigate(['/']);
            }
        });

        this.verification_status();

        this.typeUpdated();

        this.transactionForm = this.formBuilder.group({
            mobile_number: [
                '',
                [Validators.required, Validators.pattern('^[6-9]\\d{9}$')]
            ],
            aadhar_number: [
                '',
                [
                    Validators.required,
                    Validators.minLength(12),
                    Validators.maxLength(14)
                ]
            ],
            iinno: [''],
            amount: ['']
        });
    }

    ngAfterViewInit() {
        this.aepsTransactionModal = new Modal(
            this.aepsTransaction.nativeElement,
            {}
        );
    }

    async onDeviceTypeChange(): Promise<void> {
        try {
            this.biometric.setMachineType(this.selectedDeviceType);
            const response = await this.biometric.connect();

            this.alert.success(response.message, 'Connection Successful!');
        } catch (error) {
            this.alert.error(
                `An error occurred while trying to connect to the machine: ${error.message}. Please check the connection and try again.`,
                'Connection Error'
            );
        }
    }

    typeUpdated() {
        this.bankType = 'CW';

        if (this.selectedAEPS == 'AP') {
            this.bankType = 'AP';
        }

        this.api.banks({type: this.bankType}).subscribe({
            next: (response: any) => {
                if (response.result != 1) {
                    return;
                }

                this.banks = response.data;
            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    verification_status() {
        this.isLoading = true;
        this.api
            .verification()
            .subscribe((res: {result: any; message: string}) => {
                this.isLoading = false;
                if (res.result == 1) {
                    this.formShow = true;
                    return;
                }

                this.formShow = false;
                this.verificationMessage = res.message;
            });
    }

    async transaction() {
       
        if (this.transactionForm.invalid) {
            Object.values(this.transactionForm.controls).forEach((control) => {
                control.markAsTouched();
            });

            return;
        }

        this.isLoading = true;

        try {
            const captureData = await firstValueFrom(
                from(this.biometric.capture())
            );
            const captureString =
                typeof captureData === 'string'
                    ? captureData
                    : JSON.stringify(captureData);

            // Encode to Base64
            const capture = btoa(captureString);
           
            const data$ = {
                mobile_number: this.transactionForm.value.mobile_number,
                aadhar_number: this.aadhaarStack.join('').replace(/\s/g, ''),
                iinno: this.transactionForm.value.iinno,
                type: this.selectedAEPS,
                pidata: capture
            };

            // Include amount if provided
            const amount = this.transactionForm.value.amount;
            if (amount) {
              data$['amount'] = amount;
            }
           
          
            const response: AepsResponse = (await firstValueFrom(
                this.api.transaction(data$)
            )) as AepsResponse;
            if (!response.status) {
                this.alert.error(response.message);
                return;
            }

            this.transactionData = response.data;
            this.aepsTransactionModal.show();

            this.alert.success(response.message);
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    closeModal() {
        this.aepsTransactionModal.hide();
    }

    onInput(event: any): void {
        const key = event.data;
        const input = event.target;

        if (input.value.length < this.aadhaarStack.length) {
            this.aadhaarStack.pop();
            this.maskStack.pop();
        } else {
            const aadhaarLength = this.aadhaarStack.filter(
                (i) => i !== ' '
            ).length;

            if (aadhaarLength < 12 && !isNaN(key) && key !== null) {
                if (aadhaarLength > 0 && aadhaarLength % 4 === 0) {
                    this.aadhaarStack.push(' ');
                    this.maskStack.push(' ');
                }

                this.aadhaarStack.push(key);
                this.maskStack.push(aadhaarLength >= 8 ? key : '*');
            }
        }

        this.updateUi(input);
    }

    toggleMasking(): void {
        this.isMasked = !this.isMasked;
        const input = document.getElementById(
            'aadhar_number'
        ) as HTMLInputElement;
        this.updateUi(input);
    }

    updateUi(input: HTMLInputElement): void {
        setTimeout(() => {
            input.value = this.isMasked
                ? this.maskStack.join('')
                : this.aadhaarStack.join('');
        }, 100);
    }
}

export interface AepsResponse {
    status: boolean;
    message: string;
    data: AepsData;
}

export interface AepsData {
    bank: string;
    date: string;
    aadhar: string;
    status: string;
    txn_id: string;
    bankrrn: string;
    tnxtype: string;
    transaction_amount: number;
    balance: number;
    transactionType: string;
    transactions: Transaction[];
}

export interface Transaction {
    date: string;
    amount: string;
    type: string;
    narration: string;
}
