import { Bank, City, Profile, State } from '@/models/common';
import { BankDetails } from '@/models/toshani';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { DataService } from '@services/data.service';
import { LiteService } from '@services/toshani/upi/lite.service';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap, catchError, of } from 'rxjs';

@Component({
  selector: 'app-upi',
  templateUrl: './upi.component.html',
  styleUrl: './upi.component.scss'
})

export class UpiComponent {
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public isLoading: boolean = false;

  public remitter: { name: any; number: any; limit: { remaining: any; }; };
  public beneficiaries: any;

  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public tPinModal: Modal;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public accountForm: FormGroup;
  public states: State[];
  public cities: City[];
  public addBeneficiaryModal: Modal;
  public banks: Bank[];
  public beneficiary: BankDetails;
  searchQuery: string = '';
  public min: any;
  public max: any;
  
  constructor(private router: Router, private formBuilder: FormBuilder, private api: LiteService, private alert: ToastrService, private data: DataService,private apiService: ApiService) { }

  ngOnInit() {

    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.TOSHANI_UPI_LITE, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.data.getBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      upi: [{ value: '', disabled: true }, Validators.required],
      name: [{ value: '', disabled: true }, Validators.required],
      benId: [{ value: '', disabled: true }, Validators.required],
      beneficiaryMobileNumber: [{ value: '', disabled: true }, Validators.required],
      amount: ['', [Validators.required, Validators.min(0), Validators.max(100000)]],
      tpin: ['', Validators.required]
    });

    this.beneficiaryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      upi: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\b$/)]],
      beneficiaryMobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.apiService.getMinMax('upi', 'toshani').subscribe({
      next: (response: any) => {
        this.min = response.data.min_start_range;
        this.max = response.data.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  }

  verification() {
    if (!this.customerVerificationForm.valid) {
      this.alert.error('Invalid Form');
      return
    }

    const mobileNumber = this.customerVerificationForm.value.mobileNumber;
    this.isLoading = true;

    this.api.customer(mobileNumber).subscribe({
      next: (response: any) => {
        this.isLoading = false;

        console.log(response);

        if (response.result == 2) {

          this.customerRegistrationForm.patchValue({ mobileNumber })
          this.isOTPNeeded = true;
          return
        }

        if (response.result == 1 && response.data.is_verified == 1) {
          this.isOTPNeeded = false
          this.isLoggedIn = true;
          this.remitter = {
            "name": response.data.cust_name,
            "number": response.data.mobileNumber,
            "limit": {
              "remaining": response.data.remaining_limit
            }
          }

          this.beneficiaries = response.data?.beni_list || []
          this.beneficiaryForm.patchValue({
            mobileNumber: mobileNumber
          })
        }
      },
      error: ({ error }: any) => {
        console.log(error);
        this.isLoading = false;
      }
    })
  }

  register() {
    if (!this.customerRegistrationForm.valid) {
      this.alert.error('Invalid Form');
      return
    }

    this.isLoading = true;

    this.api.verification(this.customerRegistrationForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.status == 0) {
          this.alert.error(response.message)
          return
        }

        this.verification()
      },
      error: (error) => {
        this.isLoading = false;
      }
    })

  }

  confirmTransfer() {
    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.tPinModal.show();
    }
  }

  transfer() {
    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    const data = this.accountForm.getRawValue();
    this.isLoading = true;
    this.api.transfer({ ...data, mobileNumber: this.customerVerificationForm.value.mobileNumber }).subscribe({
      next: (response: any) => {
        this.alert.success(response.message)

        this.accountForm.reset();

        this.accountForm.markAsPristine();
        this.accountForm.markAsUntouched();

        this.verification()
        this.isLoading = false;
      },
      error: ({ error }) => {
        if (error.message) {
          this.alert.error(error.message);
        }
        this.isLoading = false;
      }
    })
  }

  stateChanged(event: any) {
    this.data.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
  }

  details(beneficiary: any) {
    this.beneficiary = beneficiary;
    this.accountForm.patchValue({
      upi: beneficiary.upi,
      name: beneficiary.ben_name,
      benId: beneficiary.ben_id,
      beneficiaryMobileNumber: beneficiary.ben__number,
      amount: '',
      tpin: ''
    });
  }

  delete(beneficiary: BankDetails) {
    if (confirm('Are you sure you want to delete')) {
      this.api.delete({
        mobileNumber: this.customerVerificationForm.value.mobileNumber,
        ben_id: beneficiary.ben_id
      }).subscribe({
        next: (response: any) => {
          this.alert.success(response.message)
          this.verification()
          this.isLoading = false;
        },
        error: ({ error }) => {
          if (error.message) {
            this.alert.error(error.message);
          }
          this.isLoading = false;
        }
      })
    }
  }

  addBeneficiary() {
    if (!this.beneficiaryForm.valid) {
      this.beneficiaryForm.markAsDirty();
      return;
    }

    this.isLoading = true;

    this.api.beneficiary({ mobileNumber: this.customerVerificationForm.value.mobileNumber, ...this.beneficiaryForm.value }).pipe(
      switchMap((response: any) => {
        return this.api.beneficiaries(this.customerVerificationForm.value.mobileNumber).pipe(
          tap((response: { status: boolean; message: string; data?: BankDetails[] }) => {
            this.beneficiaries = response.data;
          }),
          catchError(error => {
            console.error('Error fetching beneficiaries:', error);
            return of([]);
          })
        );
      }),
      catchError(error => {
        console.error('Error setting beneficiary:', error);
        return of([]);
      })
    ).subscribe({
      complete: () => {
        this.isLoading = false;
        this.addBeneficiaryModal.hide()
      }
    });
  }

  get filteredBeneficiaries() {
    return this.beneficiaries.filter(beneficiary =>
      beneficiary.ben_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  resetTpinForm(){
    const tpinControl = this.accountForm.get('tpin');
      if (tpinControl) {
        tpinControl.setValue(''); 
        tpinControl.markAsUntouched(); 
        tpinControl.setErrors(null); 
      }
  }
}
