<div class="d-print-none float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong class="d-print-none">
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        {{appName}}</a
    >
    <span>.</span>
</strong>
<span class="d-print-none"> All rights reserved.</span>
