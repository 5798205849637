import { Profile } from '@/models/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '@services/data.service';
import { AepsService } from '@services/toshani/aeps.service';
import { ToastrService } from 'ngx-toastr';
import { isAllowed } from '@/utils/helpers';
import { PERMISSIONS } from '@/utils/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent {
  @ViewChild('stepper') private stepper: ElementRef;
  public isLoading: boolean = false;
  public user: { first_name: string; last_name: string; email: string; mobile: string; aadhar: string; pan: string; pincode: string; address: string; };
  public formShow: boolean = true;
  public verificationMessage: string = '';
  public windowState: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private data: DataService,
    private api: AepsService) {
  }

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.TOSHANI_AEPS, profile.data.permission)) {
        this.toastr.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }

      this.verification_status()

      this.user = {
        "first_name": profile?.data?.user?.first_name,
        "last_name": profile?.data?.user?.last_name,
        "email": profile?.data?.user?.email,
        "mobile": profile?.data?.user?.mobile,
        "aadhar": profile?.data?.user?.aadhar,
        "pan": profile?.data?.user?.pan,
        "pincode": profile?.data?.user?.pincode,
        "address": profile?.data?.user?.address,
      };
    });
  }

  verification_status() {
    this.isLoading = true;
    this.api.verification().subscribe((res: { result: any, message: string }) => {
      this.isLoading = false;
      if (res.result == 0) {
        this.formShow = true;
        this.windowState = false;
        return
      }

      this.formShow = false;
      this.verificationMessage = res.message
    })
  }

  initOnboarding() {
    this.api.onboarding({}).subscribe({
      next: (res: any) => {
        const { status, message, data } = res

        if (!status) {
          this.toastr.error(message)
          return
        }

        this.toastr.success(message)

        const url = data.uri;
        const name = data.title;
        const specs = 'width=1080,height=640';

        this.windowState = true
        window.open(url, name, specs);
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }

  refresh() {
    this.verification_status()
  }
}
