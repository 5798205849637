import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-gas',
  templateUrl: './gas.component.html',
  styleUrl: './gas.component.scss'
})
export class GasComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  operators: string[] = ['LPG Gas','Indane', 'HP'];
  operatorList: any;
  formFields: any=[];
  selectedOperator: any;
  billerFetchDetails: any;
  billPaymentDetails: any;
  charges:any;

  constructor(private fb: FormBuilder,
    private api :ApiService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['', Validators.required],
      consumerNumber: ['', Validators.required],
      selectedOption: ['option1'],
      contact_number: [''],
      distributor_id: [''],
      consumer_id: [''],
    });

    this.getBillerList();
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }

  getBillerList(){
    var value = {
      billerCategory:'LPG Gas'
    }
    console.log(value,"----------44");

    this.api.billerList(value).subscribe({
      next: (response: any) => {
        console.log(response,"----------48---*");
        this.operatorList = response.data || [];
        console.log(this.operatorList,"----------50---*");

        // this.alert.success(response.message);
      },
      error: (error) => {
        this.operatorList = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onSelectOperator($event){
    var getData:any = this.operatorList.find(biller => biller.billerName === $event);
    this.selectedOperator = getData;
    this.formFields = getData.paramInfo;
  }

  fetchBill() {
    console.log(this.selectedOperator.billerId,"-0----72---");

    var inputField1 = {
        paramName:'a',
        paramValue:'10',
    }

    var inputField2 = {
      paramName:'a b',
      paramValue:'20',
    }

    var inputField3 = {
      paramName:'a b c',
      paramValue:'30',
    }

    var inputField4 = {
      paramName:'a b c d',
      paramValue:'40',
    }

    var inputField5 = {
      paramName:'a b c d e',
      paramValue:'50',
    }

    var inputField = [
      inputField1,
      inputField2,
      inputField3,
      inputField4,
      inputField5,
    ]
    var value = {
      agentDeviceIp:'192.168.2.73',
      agentDeviceMac:'01-23-45-67-89-ab',
      customerMobile:'9898990084',
      customerEmail:'customer@email.com',
      customerAdhaar:'',
      customerPan:'',
      billerId:"OTME00005XXZ43",
      inputParams:inputField,
    }
    this.api.billFetch(value).subscribe({
      next: (response: any) => {
        this.billerFetchDetails = response.results || [];
         this.charges = response.results.billerResponse.amountOptions.option;

        $('#receiptBooking').modal('show');
      },
      error: (error) => {
        this.billerFetchDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onCloseModal(){
    $('#receiptBooking').modal('toggle');
     $('#billPayment').modal('hide');

    
  }

  showInputField(event:any){
    this.paymentForm.patchValue({ selectedOption: event });
  }

  onBillPayment(){
    var inputField1 = {
      paramName:'a',
      paramValue:'10',
    }

    var inputField2 = {
      paramName:'a b',
      paramValue:'20',
    }

    var inputField3 = {
      paramName:'a b c',
      paramValue:'30',
    }

    var inputField4 = {
      paramName:'a b c d',
      paramValue:'40',
    }

    var inputField5 = {
      paramName:'a b c d e',
      paramValue:'50',
    }

    var inputField = [
      inputField1,
      inputField2,
      inputField3,
      inputField4,
      inputField5,
    ]
    var value = {
      billerAdhoc:'true',
      agentDeviceIp:'192.168.2.73',
      agentDeviceMac:'01-23-45-67-89-ab',
      customerMobile:'9898990084',
      customerEmail:'customer@email.com',
      customerAdhaar:'',
      customerPan:'',
      billerId:"OTME00005XXZ43",
      initChannel: "INT",
      inputParams:inputField,
      cityLocation: "Mumbai",
      amount: 11,
      paymentMode: "Cash",
      quickPay: "N",
      splitPay: "N",
      paymentInfoRemarks: "Remarks",
      paymentInfoValue: "Received",
      infoName: "Remarks",
      infoValue: "Received",
    }

    this.api.billPayment(value).subscribe({
      next: (response: any) => {
        console.log(response);
        this.billPaymentDetails = response.results || [];
        $('#receiptBooking').modal('hide');
        $('#billPayment').modal('show');
      },
      error: (error) => {
        // alert('Something Went Wrong');
        this.billPaymentDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

}
