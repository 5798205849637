import { Component, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-fund-request-list',
  templateUrl: './fund-request-list.component.html',
  styleUrl: './fund-request-list.component.scss'
})
export class FundRequestListComponent {
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public ewallet: {
    id: number;
    user_id: number;
    member_id: string;
    balance: number;
    credit: number;
    debit: number;
  } = {
      id: 0,
      user_id: 0,
      member_id: '',
      balance: 0,
      credit: 0,
      debit: 0,
    };

  constructor(private dataTableService: DataTableService, private apiService: ApiService) {
    this.apiService.walletBalance().subscribe((e: any) => {
      this.ewallet = e.data;
    });

    this.dataTableService.url = 'fund-request'
    this.dataTableService.export_name = 'FUND_REQUEST_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      
      { 'title': 'From Bank Name', 'data': 'fromBankName' },
      { 'title': 'To Bank Name', 'data': 'toBankName' },
      { 'title': 'Payment Mode', 'data': 'paymentMode' },
      { 'title': 'Payment Proof', 'data': 'paymentProof' },
      { 'title': 'Status', 'data': 'status' },
      { 'title': 'RR / UTR', 'data': 'chequeOrDDNumber' },
      { 'title': 'Amount', 'data': 'amount' },
      { 'title': 'Remarks', 'data': 'remarks' },
      { 'title': 'Response', 'data': 'reason' },
      { "data": "created_at", "title": "Created At" },
    ], function (settings, json) {
      $("#wallet-ledger_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="9">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY ', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate.format('DD-MM-YYYY'), picker.endDate.format('DD-MM-YYYY')])).draw();
      });
    });
  }
}
