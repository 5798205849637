import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiService, addHeaders } from './api.service';
import { getCurrentDateTime } from '@/utils/helpers';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DataService } from './data.service';
import { Profile} from '@/models/common';



pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  public url: string = 'fund-request';
  public export_name: string = 'Report';
  reportImg:any;
  userInfo:any;

  constructor(private http: HttpClient, private api: ApiService,private data:DataService) { 
    this.data.getProfile().subscribe((profile: Profile) => {
      this.userInfo = profile.data.user;
      console.log(this.userInfo);

    })
  }

  getBase64ImageFromURL(url: string): Promise<string> {
    return this.http.get(url, { responseType: 'blob' }).toPromise().then(blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
  }
  public initializeDataTableOptions(columns: any[], initCompleteFn?: Function, ordering: boolean = false, responsive: boolean = false) {
    // debugger
    this.getBase64ImageFromURL('assets/img/logo.png').then(base64Logo=>{
      this.reportImg = base64Logo
    })
    const options = {
      serverSide: true,
      processing: true,
      ordering: ordering,
      responsive: responsive,
      ajax: this.getDataFromServer.bind(this),
      columns: this.generateProperTitles(columns),
      scrollX: true,
      scrollCollapse: true,
    
      // pageLength: 10,
      // dom: 'Bfrtip',
      dom: "<'row'<'col-md-6 d-flex'<'mt-1'l> <'pl-4'B>><'col-md-6'f>><'row'<'col-md-12'tr>><'row'<'col-md-6'i><'col-md-6'p>>",
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
      buttons: [
        {
          extend: 'excel',
          text: 'Export',
          filename: `${this.export_name}_${getCurrentDateTime()}`, // Access export_name using 'self'
        },
        {
          extend: 'pdfHtml5',
          text: 'Export PDF', // Custom text for the button
          filename: `${this.export_name}_${getCurrentDateTime()}`, // Access export_name using 'self'
          orientation: 'landscape',
          title: '',
          paging: false,
          exportOptions: {
            modifier: {
              pageLength : 'all',    
              search : 'none' 
            }
          },
          customize: function (doc) {
            doc.images = doc.images || {};
            doc.images.logo = this.reportImg // Path to your logo image
            doc.content.unshift({
              image: 'logo',
              width: 150, // Adjust width as needed
              alignment: 'center',
              margin: [0, 0, 0, 20] // Margins for positioning
            });
           
            doc.content.splice(1, 0, {
              text: `Mobile number : ${this.userInfo.mobile}`,
              fontSize: 15,
              alignment: 'left',
              margin: [0, 0, 0, 20] // Adjust the margin below the header
            });
            doc.content.splice(1, 0, {
              text:`Member ID : ${this.userInfo.wallet?.member_id} `,
              fontSize: 15,
              alignment: 'left',
              margin: [0, 0, 0, 20] // Adjust the margin below the header
            });
            doc.content.splice(1, 0, {
              text:`Name : ${this.userInfo.first_name} ${this.userInfo.last_name}` ,
              fontSize: 15,
              alignment: 'left',
              margin: [0, 0, 0, 20] // Adjust the margin below the header
            });
            doc.pageOrientation = 'landscape'; 
            // doc.pageMargins = [ 40, 60, 60, 60 ]; 
            doc.content.forEach(function (item) {
              if (item.table) {
                item.table.widths =  [15, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40 ,40,40,20]; 
              }
            });
        }.bind(this),
          
        }
        
      ]
    };

    if (initCompleteFn) {
      options['initComplete'] = initCompleteFn;
    }

    return options;
  }

  private generateDataTableUrl(dataTableParams: any[]): string {
    const baseUrl = `${environment.apiURL}/${this.url}`;

    // Construct URL
    let url = baseUrl + '?';
    for (const key in dataTableParams) {
      if (dataTableParams.hasOwnProperty(key)) {
        const value = dataTableParams[key];
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
              const prop = value[i];
              for (const innerKey in prop) {
                if (prop.hasOwnProperty(innerKey)) {
                  url += `${key}[${i}][${innerKey}]=${encodeURIComponent(typeof prop[innerKey] == 'object' ? JSON.stringify(prop[innerKey]) : prop[innerKey])}&`;

                }
              }
            }
          } else {
            for (const prop in value) {
              if (value.hasOwnProperty(prop)) {
                url += `${key}[${prop}]=${encodeURIComponent(value[prop])}&`;
              }
            }
          }
        } else {
          url += `${key}=${encodeURIComponent(value)}&`;
        }
      }
    }

    return url.slice(0, -1); // Remove the trailing '&' character
  }

  private getDataFromServer(dataTablesParameters: any, callback: any) {
    this.http.get(
      this.generateDataTableUrl(dataTablesParameters), addHeaders()
    ).subscribe((resp: any) => {
      callback({
        recordsTotal: resp.recordsTotal,
        recordsFiltered: resp.recordsFiltered,
        data: resp.data
      });
    });
  }

  private generateProperTitles(columns: ColumnDefinition[]) {
    return columns.map((column: ColumnDefinition) => {
      const title = column.title ?? this.generateDefaultTitle(column.data);
      return { ...column, title, searchable: column.searchable ?? true, orderable: column.orderable ?? true };
    });
  }

  private generateDefaultTitle(data: string): string {
    return data.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
}

interface ColumnDefinition {
  data: string;
  title?: string;
  name?: string;
  searchable?: boolean;
  orderable?: boolean;
}
