import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '@services/data.service';
import { City, State } from '@/models/common';
import { UserService } from '@services/user.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss'
})
export class AddUserComponent {
  userForm: FormGroup;
  isLoading: boolean = false;
  public states: State[];
  public cities: City[];
  public roles: any;
  public profilePhotoBase64: string = '';

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private userService: UserService,
    private alert: ToastrService,
    private router: Router
  ) {
    this.dataService.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.dataService.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });
  }

  ngOnInit(): void {
    this.dataService.getRoles().subscribe((e) => { this.roles = e; });
    this.userForm = this.fb.group({
      member_type: ['', Validators.required],
      first_name: ['', [Validators.required, Validators.maxLength(255)]],
      last_name: ['', [Validators.required, Validators.maxLength(255)]],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      aadhar: ['', [Validators.required, Validators.pattern(/^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/)]],
      pan: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]],
      address: ['', Validators.required],
      state_id: ['', Validators.required],
      city_id: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
      shop_name: [''],
      shop_address: [''],
      gst: [''],
      business_pan: [''],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      phone: ['', [Validators.maxLength(15)]],
      profile_image: ['', Validators.required]
    });
  }

  stateChanged(event: any) {
    this.dataService.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
    console.log(this.cities, selectedStateId);
  }

  submitForm(): void {
    if (this.userForm.invalid) {
      Object.values(this.userForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }

    this.isLoading = true;

    const formData = this.userForm.getRawValue();
    const payload = {
      ...formData,
      profile_image: this.profilePhotoBase64
    };

    this.userService.create(payload).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (!response.status) {
          if (response.errors) {
            Object.keys(response.errors).forEach(fieldName => {
              const formControl = this.userForm.get(fieldName);
              if (formControl) {
                formControl.setErrors({ apiError: response.errors[fieldName][0] });
              }
            });
          } else {
            this.alert.error(response.message);
          }
          return;
        }
        this.alert.success('User added successfully!');
        this.userForm.reset();
        this.router.navigate(['/user-list'])
      },
      error: (e) => {
        this.isLoading = false;
        this.alert.error(e.error.message);
      }
    });
  }


  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  async onProfilePhotoChange(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.profilePhotoBase64 = base64
  }

}
