<div id="mainReceipt">
    <h2>DMT Lite Plus Receipt</h2>
    <hr>
    <table *ngIf="row">
        <tr>
            <td style="width: 50%;">
                <div class="info"><strong>Receiver's Name:</strong> {{row.recipient_name}} </div>
                <div class="info"><strong>Mobile Number:</strong> {{row.mobile_number}}</div>
                <div class="info"><strong>Transaction Date:</strong> {{row.created_at}}</div>
                <div class="info"><strong>Transaction ID:</strong> {{row.unique_reference_id}}</div>
                <!-- <div class="info"><strong>Status:</strong> {{row.transaction_status}}</div> -->
            </td>
            <td style="width: 50%;" align="right">
                <div>
                    <img src="./assets/img/logo.png" alt="Company Logo" class="logo">
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table class="innerTable">
                    <thead>
                        <tr>
                            <th>UTR</th>
                            <th>Request ID</th>
                            <th>Transaction ID</th>
                            <th>Unique Reference ID</th>
                            <th>Transaction Amount</th>
                            <th>Transaction Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let detail of row.details">
                            <td>{{ detail.bank_transaction_id }}</td>
                            <td>{{ detail.unique_transaction_reference }}</td>
                            <td>{{ detail.transaction_id }}</td>
                            <td>{{ detail.unique_reference_id }}</td>
                            <td> {{detail.transaction_amount / 100}}
                            <td>{{ detail.transaction_status }}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div class="info"><strong>Total Amount in Words:</strong> {{row.raw_transaction_amount | numToWords}}</div>
                <div class="info"><strong>Account Number:</strong> {{row.bank_account_number}}</div>
                <div class="info"><strong>IFSC Code:</strong> {{row.ifsc_code}}</div>
                <div class="info"><strong>Bank Name:</strong> {{row.bank_name}}</div>
            </td>
        </tr>
        <tr>
            <td colspan="2"></td>
        </tr>
        <tr>
            <td colspan="2"></td>
        </tr>
        <tr>
            <td colspan="2">
                <hr>
                <p style="text-align: center;"><strong>Note:</strong>
                    This is a computer-generated receipt and does not require any physical signature.
                </p>
            </td>
        </tr>
    </table>
</div>