import { Bank, City, Profile, State } from '@/models/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { DataService } from '@services/data.service';
import { PaysprintService } from '@services/digilocker/paysprint.service';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';




@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrl: './kyc.component.scss'
})

export class KYCComponent {
  @ViewChild('stepper') private stepper: ElementRef;

  public kycDetailsForm: FormGroup;
  public stepper1Instance: Stepper;
  public isLoading: boolean = false;
  public profilePhotoBase64: string = '';
  public shopInsidePhotoBase64: string = '';
  public shopOutsidePhotoBase64: string = '';
  public panImagePhotoBase64: string;
  public aadharImagePhotoBase64: string;
  public countries: Object;
  public states: State[];
  public cities: City[];
  public kycFormShow: boolean = true;
  public kycMessage: any;
  public banks: Bank[];
  public user: { first_name: string; last_name: string; email: string; mobile: string; aadhar: string; pan: string; state_id: number; city_id: number; pincode: string; address: string; };
  digilockerValues: any;
  safeUrl: SafeResourceUrl;
  verify:boolean=true;
  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private apiService: ApiService, private data: DataService, private paysprint: PaysprintService, private router: Router, private route: ActivatedRoute,private sanitizer: DomSanitizer) {

    this.data.getCountries().subscribe((e) => { this.countries = e; 
      console.log(this.countries)
    });
    this.data.getStates().subscribe((e) => { this.states = e; });
    this.data.getCities().subscribe((e) => { this.cities = e; });
    this.data.getProfile().subscribe((profile: Profile) => {
      this.user = {
        "first_name": profile?.data?.user?.first_name,
        "last_name": profile?.data?.user?.last_name,
        "email": profile?.data?.user?.email,
        "mobile": profile?.data?.user?.mobile,
        "aadhar": profile?.data?.user?.aadhar,
        "pan": profile?.data?.user?.pan,
        "state_id": profile?.data?.user?.state_id,
        "city_id": profile?.data?.user?.city_id,
        "pincode": profile?.data?.user?.pincode,
        "address": profile?.data?.user?.address
        
      };
    });

    this.apiService.statuskyc().subscribe({
      next: (response: any) => {

        if (!response) {
          this.kycFormShow = true;
          return
        }

        this.kycFormShow = response.retryAgain || false;
        this.kycMessage = response.message;
      },
      error: ({ error }) => {
        this.toastr.error(error.message);
        if (error.errors) {
          // this.handleServerErrors(error.errors);
        }
      }
    })

  }

  handleServerErrors(errors: any) {
    Object.keys(errors).forEach(key => {
      this.toastr.error(errors[key][0], key, {
        timeOut: 10000
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.stepper && this.stepper.nativeElement) { this.stepper1Instance = new Stepper(this.stepper.nativeElement, {}); }
  }

  ngOnInit(): void {
   
    this.kycDetailsForm = this.formBuilder.group({
      profilePhoto: ['', Validators.required],
      panImage: ['', Validators.required],
      aadharImage: ['', Validators.required],
      firstName: [this.user.first_name, [Validators.required, Validators.maxLength(255)]],
      lastName: [this.user.last_name, [Validators.required, Validators.maxLength(255)]],
      mobile: [this.user.mobile, [Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9]\\d{9}$')]],
      email: [this.user.email, [Validators.required, Validators.email, Validators.maxLength(255)]],
      dob:['',[Validators.required]],
      address: ['', [Validators.required, Validators.maxLength(255)]],
      country: ['1', [Validators.required, Validators.maxLength(255)]],
      state: [this.user.state_id, [Validators.required, Validators.maxLength(255)]],
      city: [this.user.city_id, [Validators.required, Validators.maxLength(255)]],
      pin: [this.user.pincode, [Validators.required, Validators.maxLength(6)]],
      panNumber: [this.user.pan, Validators.required],
      aadharNumber: [this.user.aadhar, Validators.required],
      businessName: ['', [Validators.required, Validators.maxLength(255)]],
      businessMobile: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9]\\d{9}$')]],
      businessEmail: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      businessAddress: ['', [Validators.required, Validators.maxLength(255)]],
      businessCountry: ['', [Validators.required, Validators.maxLength(255)]],
      businessCity: ['', [Validators.required, Validators.maxLength(255)]],
      businessState: ['', [Validators.required, Validators.maxLength(255)]],
      businessPin: ['', [Validators.required, Validators.maxLength(6)]],
      gstNumber: [''],
      bankName: [null, Validators.required],
      bankAccountNumber: ['', [Validators.required, Validators.maxLength(18), Validators.pattern('^[0-9]{9,18}$')]],
      bankAccountHolderName: ['', Validators.required],
      bankIFSC: ['', [Validators.required, Validators.maxLength(255), Validators.pattern('^[A-Z]{4}0[A-Z0-9]{6}$')]],
      shopInsidePhoto: ['', Validators.required],
      shopOutsidePhoto: ['', Validators.required]
    });

    this.data.getBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

    this.route.queryParams.subscribe(params => {
      
      if (!params.info) {
        return
      }
      
      const decoded = JSON.parse(atob(params.info))
     
      if (!decoded.status) {
        this.toastr.error(decoded.message);
      }

      this.digilockerValues = decoded.data
console.log(this.digilockerValues)
      this.kycDetailsForm.patchValue({
        firstName: this.digilockerValues.first_name,
        lastName: this.digilockerValues.last_name,
        country: this.digilockerValues.country,
        state: this.digilockerValues.state,
        city: this.digilockerValues.dist,
        address: this.digilockerValues.address,
        profilePhoto: this.digilockerValues.Pht,
        dob:this.digilockerValues.dob
      })

      this.profilePhotoBase64 = this.digilockerValues.Pht;
      if(this.profilePhotoBase64){
       const profileControl= this.kycDetailsForm.get('profilePhoto')
       profileControl.clearValidators();
      }
       this.panImagePhotoBase64 =this.digilockerValues.files.pan;
       if(this.panImagePhotoBase64){
        const panControl= this.kycDetailsForm.get('panImage')
        panControl.clearValidators();
       }

       this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.panImagePhotoBase64)
      //  console.log(this.safeUrl)
     
       if(this.digilockerValues.mask_aadhar.substr(-4) != this.user.aadhar.substr(-4)){
        this.verify=false;
        this.toastr.error('Aadhar Number Mismatch')

        setTimeout(()=>{
          
        window.location.assign('kyc');
        },5000)
        // this.router.navigate(['/kyc']);
       }
      else{
      this.verify=true
    }
      this.kycDetailsForm.get('firstName').disable();
      this.kycDetailsForm.get('lastName').disable();
      this.kycDetailsForm.get('mobile').disable();
      this.kycDetailsForm.get('email').disable();
      this.kycDetailsForm.get('panNumber').disable();
      this.kycDetailsForm.get('country').disable();
      this.kycDetailsForm.get('state').disable();
      this.kycDetailsForm.get('city').disable();
      this.kycDetailsForm.get('pin').disable();
      this.kycDetailsForm.get('address').disable();
      this.kycDetailsForm.get('profilePhoto').disable();
      this.kycDetailsForm.get('aadharNumber').disable();
      this.kycDetailsForm.get('dob').disable();


    });
  }

  onboard() {
      console.log(this.kycDetailsForm.getRawValue())
    if (!this.kycDetailsForm.valid) {
      for (const control in this.kycDetailsForm.controls) {
        if (this.kycDetailsForm.controls.hasOwnProperty(control)) {
          this.kycDetailsForm.controls[control].markAsTouched();
        }
      }
      return;
    }

    const data = {
      ...this.kycDetailsForm.getRawValue(),
      country: 'India',
      businessCountry:'India',
      panImage: this.panImagePhotoBase64,
      aadharImage: this.aadharImagePhotoBase64,
      profilePhoto: this.profilePhotoBase64,
      shopInsidePhoto: this.shopInsidePhotoBase64,
      shopOutsidePhoto: this.shopOutsidePhotoBase64,
    }
    this.apiService.onboardkyc(data).subscribe({
      next: (response: any) => {
        if (!response) {
          this.kycFormShow = true;
          return
        }

        this.kycFormShow = false;
        this.kycMessage = response.message;
      },
      error: ({ error }) => {
        if (error.message) {
          this.toastr.error(error.message);
        }
        if (error.errors) {
          // this.handleServerErrors(error.errors);
        }
      }
    })
  }

  async onProfilePhotoChange(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.profilePhotoBase64 = base64;
  }

  async onPanImagePhotoChange(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.panImagePhotoBase64 = base64;
  
  }

  async onAadharImagePhotoChange(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.aadharImagePhotoBase64 = base64;
  }

  async onShopInsidePhotoChange(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.shopInsidePhotoBase64 = base64;
  }

  async onShopOutsidePhotoChange(event: any) {
    const file = event.target.files[0];
    const base64 = await this.convertToBase64(file);
    this.shopOutsidePhotoBase64 = base64;
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  stateChanged(event: any) {
    this.data.getCities().subscribe((e) => { this.cities = e; });
    const selectedStateId = event.target.value;
    this.cities = this.cities.filter((e) => e.state_id == selectedStateId)
  }

  sameAddress($event: any) {
    // Get the checkbox element
    const checkbox = $event.target;

    if (checkbox.checked) {

      // Patch the business address fields with personal address values
      const stateValue = this.kycDetailsForm.get('state').value;
      const cityValue = this.kycDetailsForm.get('city').value;
      const countryValue = this.kycDetailsForm.get('country').value;
      const pinValue = this.kycDetailsForm.get('pin').value;
      const addressValue =this.kycDetailsForm.get('address').value;

      // Disable the business address fields
      stateValue ? this.kycDetailsForm.get('businessState').disable() : null;
      cityValue ? this.kycDetailsForm.get('businessCity').disable() : null;
      countryValue ? this.kycDetailsForm.get('businessPin').disable() : null;
      pinValue ? this.kycDetailsForm.get('businessCountry').disable() : null;
      addressValue ? this.kycDetailsForm.get('businessAddress').disable() : null;
      

      this.kycDetailsForm.patchValue({
        businessState: stateValue,
        businessCity: cityValue,
        businessCountry: countryValue,
        businessPin: pinValue,
        businessAddress:addressValue
      });

      return;
    }

    // Enable the business address fields and reset their values
    this.kycDetailsForm.get('businessState').enable();
    this.kycDetailsForm.get('businessCity').enable();
    this.kycDetailsForm.get('businessCountry').enable();
    this.kycDetailsForm.get('businessPin').enable();
    this.kycDetailsForm.get('businessAddress').enable();

    this.kycDetailsForm.patchValue({
      businessState: null,
      businessCity: null,
      businessCountry: null,
      businessPin: null,
      businessAddress:null
    });
  }

  toggleDirection(state: number) {
    const step = {
      "step_1": [
        'firstName',
        'lastName',
        'mobile',
        'email',
        'profilePhoto',
        'panNumber',
        'aadharNumber',
        'panImage',
        'aadharImage',
        'country',
        'state',
        'city',
        'pin',
        'address'
      ],
      "step_2": [
        'businessName',
        'businessMobile',
        'businessEmail',
        'shopInsidePhoto',
        'shopOutsidePhoto',
        'gstNumber',
        'businessCountry',
        'businessState',
        'businessCity',
        'businessPin',
        'businessAddress',
      ]
    }[`step_${state}`]

    const form = this.kycDetailsForm;

    let allControlsValid = true;
    for (let i = 0; i < step.length; i++) {
      const control = step[i];
      const formControl = form.get(control);
      if (formControl && !formControl.valid && formControl.status != 'DISABLED') {
        console.log(formControl);
        allControlsValid = false;
        formControl.markAsTouched();
      }
    }

    if (allControlsValid) {
      this.stepper1Instance.next()
    }
  }

  connectDigiLocker() {
    this.paysprint.introdction(encodeURIComponent(window.location.href)).subscribe({
      next: (response: any) => {
        if (response && response.url) {
          window.location.href = response.url;
        }
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }
}