<section class="content-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <h1>KYC Details</h1>
      </div>
      <div class="col-sm-6 d-flex flex-column align-items-end">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
          <li class="breadcrumb-item active">KYC</li>
        </ol>
        <small><strong>All <span style="color: red;">*</span> fields are mandatory.</strong></small>

      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <div class="alert alert-warning mt-5" role="alert" *ngIf="!kycFormShow">
          <strong>{{kycMessage}}</strong>
        </div>

        <div class="row mt-5" *ngIf="kycFormShow && !digilockerValues">
          <div class="col">
            <div class="d-flex justify-content-center align-items-center mb-4" style="height: 200px;">
              <div class="border rounded-circle p-4 px-5" style="font-size: 100px;">
                <i class="fas fa-user"></i>
              </div>
            </div>
            <h2 class="mb-4">Complete your KYC with DigiLocker</h2>
            <p class="mb-4">Ensure your KYC process is smooth and secure.</p>
            <a href="#" class="btn btn-primary" (click)="connectDigiLocker()">Start KYC Process</a>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="kycDetailsForm" (ngSubmit)="onboard()" *ngIf="kycFormShow && digilockerValues">

      <div #stepper class="bs-stepper">
        <small><strong>All <span style="color: red;">*</span> fields are mandatory.</strong></small>

        <div class="card">
          <div class="card-header">
            <div class="bs-stepper-header">
              <div class="step" data-target="#test-l-1">
                <button type="button" class="btn step-trigger">
                  <span class="bs-stepper-circle">1</span>
                  <span class="bs-stepper-label">Personal Information</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-2">
                <button type="button" class="btn step-trigger">
                  <span class="bs-stepper-circle">2</span>
                  <span class="bs-stepper-label">Business Information</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-3">
                <button type="button" class="btn step-trigger">
                  <span class="bs-stepper-circle">3</span>
                  <span class="bs-stepper-label">Bank Information</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="bs-stepper-content">
              <div id="test-l-1" class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="firstName">First Name</label> <span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="firstName" formControlName="firstName"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('firstName').invalid && (kycDetailsForm.get('firstName').dirty || kycDetailsForm.get('firstName').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('firstName').invalid && (kycDetailsForm.get('firstName').dirty || kycDetailsForm.get('firstName').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('firstName').errors.required"><strong>First Name is
                            required.</strong></div>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="lastName">Last Name</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="lastName" formControlName="lastName"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('lastName').invalid && (kycDetailsForm.get('lastName').dirty || kycDetailsForm.get('lastName').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('lastName').invalid && (kycDetailsForm.get('lastName').dirty || kycDetailsForm.get('lastName').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('lastName').errors.required"><strong>Last Name is
                            required.</strong></div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="mobile">Mobile Number</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="mobile" formControlName="mobile"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('mobile').invalid && (kycDetailsForm.get('mobile').dirty || kycDetailsForm.get('mobile').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('mobile').invalid && (kycDetailsForm.get('mobile').dirty || kycDetailsForm.get('mobile').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('mobile').errors.required"><strong>Mobile Number is
                            required.</strong></div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Email</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="email" class="form-control" id="email" formControlName="email"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('email').invalid && (kycDetailsForm.get('email').dirty || kycDetailsForm.get('email').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('email').invalid && (kycDetailsForm.get('email').dirty || kycDetailsForm.get('email').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('email').errors.required"><strong>Email is required.</strong>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="dob">Date of Birth</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="email" formControlName="dob"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('dob').invalid && (kycDetailsForm.get('dob').dirty || kycDetailsForm.get('email').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('dob').invalid && (kycDetailsForm.get('dob').dirty || kycDetailsForm.get('dob').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('dob').errors.required"><strong>Date of Birth is required.</strong>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6" *ngIf="digilockerValues.Pht">
                    <div class="form-group">
                      <label for="profilePhoto">Profile Photo</label><span
                        class="text-danger font-weight-bold ml-2">*</span><br/>
                        <img src="{{this.profilePhotoBase64}}"/>
                      <input type="file" class="form-control" id="profilePhoto" formControlName="profilePhoto"
                        (change)="onProfilePhotoChange($event)" accept="image/*"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('profilePhoto').invalid && (kycDetailsForm.get('profilePhoto').dirty || kycDetailsForm.get('profilePhoto').touched)}" *ngIf="!this.profilePhotoBase64">
                      <div
                        *ngIf="kycDetailsForm.get('profilePhoto').invalid && (kycDetailsForm.get('profilePhoto').dirty || kycDetailsForm.get('profilePhoto').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('profilePhoto').errors.required"><strong>Profile Photo is
                            required.</strong></div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pan">Pan</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="panNumber" formControlName="panNumber"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('panNumber').invalid && (kycDetailsForm.get('panNumber').dirty || kycDetailsForm.get('panNumber').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('panNumber').invalid && (kycDetailsForm.get('panNumber').dirty || kycDetailsForm.get('panNumber').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('panNumber').errors.required"><strong>Pan Number is
                            required.</strong></div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="aadhar">Aadhar</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="aadharNumber" formControlName="aadharNumber"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('aadharNumber').invalid && (kycDetailsForm.get('aadharNumber').dirty || kycDetailsForm.get('aadharNumber').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('aadharNumber').invalid && (kycDetailsForm.get('aadharNumber').dirty || kycDetailsForm.get('aadharNumber').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('aadharNumber').errors.required"><strong>Aadhar Number is
                            required.</strong></div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">

                    <div class="form-group">
                     
                      <label for="panImage">Pan Card Photo</label><span
                        class="text-danger font-weight-bold ml-2">*</span><br/>
                       <a href="{{this.panImagePhotoBase64}}" target="_blank"> <i class="fas fa-file-pdf" style="font-size: 40px;color:red"></i>
                       </a>
                       <!-- <object type="application/pdf" [data]="this.safeUrl"></object> -->
                      <input type="file" class="form-control" id="panImage" formControlName="panImage"
                        (change)="onPanImagePhotoChange($event)" accept="image/*"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('panImage').invalid && (kycDetailsForm.get('panImage').dirty || kycDetailsForm.get('panImage').touched)}" *ngIf="this.panImagePhotoBase64 ==  ''">
                      <div
                        *ngIf="kycDetailsForm.get('panImage').invalid && (kycDetailsForm.get('panImage').dirty || kycDetailsForm.get('panImage').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('panImage').errors.required"><strong>Profile Photo is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="aadharImage">Aadhar Card Photo (Full)</label><span
                        class="text-danger font-weight-bold ml-2">*</span>
                      <input type="file" class="form-control" id="aadharImage" formControlName="aadharImage"
                        (change)="onAadharImagePhotoChange($event)" accept="image/*"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('aadharImage').invalid && (kycDetailsForm.get('aadharImage').dirty || kycDetailsForm.get('aadharImage').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('aadharImage').invalid && (kycDetailsForm.get('aadharImage').dirty || kycDetailsForm.get('aadharImage').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('aadharImage').errors.required"><strong>Profile Photo is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="country">Country</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <select formControlName="country" class="form-select"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('country').invalid && (kycDetailsForm.get('country').dirty || kycDetailsForm.get('country').touched)}">
                        <option value="" selected>Select Country</option>
                        <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                      </select>
                      <div
                        *ngIf="kycDetailsForm.get('country').invalid && (kycDetailsForm.get('country').dirty || kycDetailsForm.get('country').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('country').errors.required"><strong>Country is required.</strong>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="state">State</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <select formControlName="state" class="form-select" (change)="stateChanged($event)"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('state').invalid && (kycDetailsForm.get('state').dirty || kycDetailsForm.get('state').touched)}">
                        <option value="" selected>Select State</option>
                        <option *ngFor="let state of states" [value]="state.id">{{ state.name }}</option>
                      </select>
                      <div
                        *ngIf="kycDetailsForm.get('state').invalid && (kycDetailsForm.get('state').dirty || kycDetailsForm.get('state').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('state').errors.required"><strong>State is required.</strong>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="city">City</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <select formControlName="city" class="form-select"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('city').invalid && (kycDetailsForm.get('city').dirty || kycDetailsForm.get('city').touched)}">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
                      </select>

                      <div
                        *ngIf="kycDetailsForm.get('city').invalid && (kycDetailsForm.get('city').dirty || kycDetailsForm.get('city').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('city').errors.required"><strong>City is required.</strong></div>
                      </div>

                    </div>

                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="pin">Pin Code</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="pin" formControlName="pin"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('pin').invalid && (kycDetailsForm.get('pin').dirty || kycDetailsForm.get('pin').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('pin').invalid && (kycDetailsForm.get('pin').dirty || kycDetailsForm.get('pin').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('pin').errors.required"><strong>Pin is required.</strong></div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="address">Address</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <textarea class="form-control" id="address" rows="3" formControlName="address"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('address').invalid && (kycDetailsForm.get('address').dirty || kycDetailsForm.get('address').touched)}"></textarea>
                      <div
                        *ngIf="kycDetailsForm.get('address').invalid && (kycDetailsForm.get('address').dirty || kycDetailsForm.get('address').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('address').errors.required"><strong>Address is required.</strong>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="addForBusinessInfo"
                        (change)="sameAddress($event)">
                      <label class="form-check-label" for="addForBusinessInfo">
                        Check if Business and Personal Address are the Same.
                      </label>
                    </div>
                  </div>

                  <div class="col-md-12 text-right">
                    <pf-button [loading]="isLoading" [disabled]="!verify" (click)="toggleDirection(1)">
                      Next
                    </pf-button>
                  </div>
                </div>
              </div>
              <div id="test-l-2" class="content">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="businessName">Business Name <span
                          class="text-danger font-weight-bold ml-2">*</span></label>
                      <input type="text" class="form-control" id="businessName"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessName').invalid && (kycDetailsForm.get('businessName').dirty || kycDetailsForm.get('businessName').touched)}"
                        formControlName="businessName">
                      <div
                        *ngIf="kycDetailsForm.get('businessName').invalid && (kycDetailsForm.get('businessName').dirty || kycDetailsForm.get('businessName').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessName').errors.required"><strong>Business Name is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="mobile">Bussiness Contact Number <span
                          class="text-danger font-weight-bold ml-2">*</span> </label>
                      <input type="text" class="form-control" id="mobile"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessMobile').invalid && (kycDetailsForm.get('businessMobile').dirty || kycDetailsForm.get('businessMobile').touched)}"
                        formControlName="businessMobile">
                      <div
                        *ngIf="kycDetailsForm.get('businessMobile').invalid && (kycDetailsForm.get('businessMobile').dirty || kycDetailsForm.get('businessMobile').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessMobile').errors.required"><strong>Business Mobile is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Bussiness Contact Email <span
                          class="text-danger font-weight-bold ml-2">*</span> </label>
                      <input type="email" class="form-control" id="email"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessEmail').invalid && (kycDetailsForm.get('businessEmail').dirty || kycDetailsForm.get('businessEmail').touched)}"
                        formControlName="businessEmail">
                      <div
                        *ngIf="kycDetailsForm.get('businessEmail').invalid && (kycDetailsForm.get('businessEmail').dirty || kycDetailsForm.get('businessEmail').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessEmail').errors.required"><strong>Business Email is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="shopInsidePhoto">Shop Inside Photo <span
                          class="text-danger font-weight-bold ml-2">*</span></label>
                      <input type="file" class="form-control" id="shopInsidePhoto"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('shopInsidePhoto').invalid && (kycDetailsForm.get('shopInsidePhoto').dirty || kycDetailsForm.get('shopInsidePhoto').touched)}"
                        formControlName="shopInsidePhoto" (change)="onShopInsidePhotoChange($event)" accept="image/*">
                      <div
                        *ngIf="kycDetailsForm.get('shopInsidePhoto').invalid && (kycDetailsForm.get('shopInsidePhoto').dirty || kycDetailsForm.get('shopInsidePhoto').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('shopInsidePhoto').errors.required"><strong>Shop Inside Photo is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="shopOutsidePhoto">Shop Outside Photo <span
                          class="text-danger font-weight-bold ml-2">*</span></label>
                      <input type="file" class="form-control" id="shopOutsidePhoto"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('shopOutsidePhoto').invalid && (kycDetailsForm.get('shopOutsidePhoto').dirty || kycDetailsForm.get('shopOutsidePhoto').touched)}"
                        formControlName="shopOutsidePhoto" (change)="onShopOutsidePhotoChange($event)" accept="image/*">
                      <div
                        *ngIf="kycDetailsForm.get('shopOutsidePhoto').invalid && (kycDetailsForm.get('shopOutsidePhoto').dirty || kycDetailsForm.get('shopOutsidePhoto').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('shopOutsidePhoto').errors.required"><strong>Shop Outside Photo
                            is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="gst">GST Number</label>
                      <input type="text" class="form-control" id="gstNumber"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('gstNumber').invalid && (kycDetailsForm.get('gstNumber').dirty || kycDetailsForm.get('gstNumber').touched)}"
                        formControlName="gstNumber">
                      <div
                        *ngIf="kycDetailsForm.get('gstNumber').invalid && (kycDetailsForm.get('gstNumber').dirty || kycDetailsForm.get('gstNumber').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('gstNumber').errors.required"><strong>GST Number is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="businessCountry">Country</label><span
                        class="text-danger font-weight-bold ml-2">*</span>
                      <select formControlName="businessCountry" class="form-select"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessCountry').invalid && (kycDetailsForm.get('businessCountry').dirty || kycDetailsForm.get('businessCountry').touched)}">
                        <option value="" selected>Select Country</option>
                        <option *ngFor="let businessCountry of countries" [value]="businessCountry.id">{{
                          businessCountry.name }}</option>
                      </select>
                      <div
                        *ngIf="kycDetailsForm.get('businessCountry').invalid && (kycDetailsForm.get('businessCountry').dirty || kycDetailsForm.get('businessCountry').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessCountry').errors.required"><strong>Country is
                            required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="businessState">State</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <select formControlName="businessState" class="form-select" (change)="stateChanged($event)"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessState').invalid && (kycDetailsForm.get('businessState').dirty || kycDetailsForm.get('businessState').touched)}">
                        <option value="" selected>Select State</option>
                        <option *ngFor="let businessState of states" [value]="businessState.id">{{ businessState.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="kycDetailsForm.get('businessState').invalid && (kycDetailsForm.get('businessState').dirty || kycDetailsForm.get('businessState').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessState').errors.required"><strong>State is
                            required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="businessCity">City</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <select formControlName="businessCity" class="form-select"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessCity').invalid && (kycDetailsForm.get('businessCity').dirty || kycDetailsForm.get('businessCity').touched)}">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let businessCity of cities" [value]="businessCity.id">{{ businessCity.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="kycDetailsForm.get('businessCity').invalid && (kycDetailsForm.get('businessCity').dirty || kycDetailsForm.get('businessCity').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessCity').errors.required"><strong>City is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="businessPin">Pin Code <span class="text-danger font-weight-bold ml-2">*</span></label>
                      <input type="text" class="form-control" id="businessPin"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessPin').invalid && (kycDetailsForm.get('businessPin').dirty || kycDetailsForm.get('businessPin').touched)}"
                        formControlName="businessPin">
                      <div
                        *ngIf="kycDetailsForm.get('businessPin').invalid && (kycDetailsForm.get('businessPin').dirty || kycDetailsForm.get('businessPin').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessPin').errors.required"><strong>Business Pin is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="businessAddress">Address</label>
                      <textarea class="form-control" id="businessAddress" rows="3"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('businessAddress').invalid && (kycDetailsForm.get('businessAddress').dirty || kycDetailsForm.get('businessAddress').touched)}"
                        formControlName="businessAddress"></textarea>
                      <div
                        *ngIf="kycDetailsForm.get('businessAddress').invalid && (kycDetailsForm.get('businessAddress').dirty || kycDetailsForm.get('businessAddress').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('businessAddress').errors.required"><strong>Business Address is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <pf-button [loading]="isLoading" (click)="stepper1Instance.previous()">
                      Previous
                    </pf-button>
                  </div>
                  <div class="col-md-6 text-right">
                    <pf-button [loading]="isLoading" (click)="toggleDirection(2)">
                      Next
                    </pf-button>
                  </div>
                </div>
              </div>
              <div id="test-l-3" class="content">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="bankName">Bank Name</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <select class="form-control" formControlName="bankName" id="bankName"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('bankName').invalid && (kycDetailsForm.get('bankName').dirty || kycDetailsForm.get('bankName').touched)}">
                        <option value="">Please select a bank</option>
                        <option *ngFor="let bank of banks" [value]="bank.code">{{bank.name}}</option>
                      </select>
                      <div
                        *ngIf="kycDetailsForm.get('bankName').invalid && (kycDetailsForm.get('bankName').dirty || kycDetailsForm.get('bankName').touched)"
                        class="text-danger text-sm font-weight-bold">
                        <div *ngIf="kycDetailsForm.get('bankName').errors.required">Please select a bank.</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="bankAccountNumber">Account Number <span
                          class="text-danger font-weight-bold ml-2">*</span></label>
                      <input type="text" class="form-control" id="bankAccountNumber" formControlName="bankAccountNumber"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('bankAccountNumber').invalid && (kycDetailsForm.get('bankAccountNumber').dirty || kycDetailsForm.get('bankAccountNumber').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('bankAccountNumber').invalid && (kycDetailsForm.get('bankAccountNumber').dirty || kycDetailsForm.get('bankAccountNumber').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('bankAccountNumber').errors.required"><strong>Bank Account Number
                            is required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="bankAccountHolderName">Account Holder Name <span
                          class="text-danger font-weight-bold ml-2">*</span></label>
                      <input type="email" class="form-control" id="bankAccountHolderName"
                        formControlName="bankAccountHolderName"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('bankAccountHolderName').invalid && (kycDetailsForm.get('bankAccountHolderName').dirty || kycDetailsForm.get('bankAccountHolderName').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('bankAccountHolderName').invalid && (kycDetailsForm.get('bankAccountHolderName').dirty || kycDetailsForm.get('bankAccountHolderName').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('bankAccountHolderName').errors.required"><strong>Bank Account
                            Holder Name is required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">

                      <label for="bankIFSC">Bank IFSC</label><span class="text-danger font-weight-bold ml-2">*</span>
                      <input type="text" class="form-control" id="bankIFSC" formControlName="bankIFSC"
                        [ngClass]="{'is-invalid': kycDetailsForm.get('bankIFSC').invalid && (kycDetailsForm.get('bankIFSC').dirty || kycDetailsForm.get('bankIFSC').touched)}">
                      <div
                        *ngIf="kycDetailsForm.get('bankIFSC').invalid && (kycDetailsForm.get('bankIFSC').dirty || kycDetailsForm.get('bankIFSC').touched)"
                        class="invalid-feedback">
                        <div *ngIf="kycDetailsForm.get('bankIFSC').errors.required"><strong>Bank IFSC is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <pf-button [loading]="isLoading" (click)="stepper1Instance.previous()">
                      Previous
                    </pf-button>
                  </div>
                  <div class="col-md-6 text-right">
                    <pf-button [loading]="isLoading" (click)="onboard()">
                      Submit
                    </pf-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="d-none"></button>
    </form>

  </div>
</section>