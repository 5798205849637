import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostBinding
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { DataService } from '@services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  public loginForm: UntypedFormGroup;
  public validateForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  public latitude: number;
  public longitude: number;
  public isOTPNeeded: boolean = false;
  public showPassword: boolean = false;
  public isFormSubmitted = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private data: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]),
      password: new UntypedFormControl(null, Validators.required),
      termscondition: new UntypedFormControl(null, Validators.required),
      otp: new UntypedFormControl(null),
    });

    this.validateForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]),
      otp: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[1-9]\d{5}$/)]),
    });
  }
  get f() { return this.loginForm.controls; }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        },
        error => {
          console.error('Error getting location:', error);
        }
      );
      console.log(this.latitude, this.longitude);
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  async loginByAuth() {
    this.isFormSubmitted=true;
    try {
      if (this.loginForm.valid) {
        this.isAuthLoading = true;
        const data = this.loginForm.value;
        try {
          const res: any = await this.appService.loginByAuth(data);

          if (res.status === 1 || res.status === 2) {
            this.isOTPNeeded = true;
            this.validateForm.patchValue({
              username: data.username
            });
          }
          this.isAuthLoading = false;
        } catch (error) {
          this.isAuthLoading = false;
        }
      } else {
        // Mark all form controls as touched to display error messages
        this.loginForm.markAllAsTouched();
        // this.toastr.error('Form is not valid!');
      }
    } catch (e) {
      this.isAuthLoading = false;
    } finally {
      this.isAuthLoading = false;
    }
  }


  validateAndLogin() {
    if (this.validateForm.valid) {
      this.isAuthLoading = true;
      this.appService.validateOTP(this.validateForm.value).subscribe({
        next: (response: any) => {
          this.isAuthLoading = false;
          this.appService.successLogin(response.data.user, response.data.authorisation, response.message)
          this.data.setProfile(response)
        },
        error: ({ error }) => {
          console.log(error.message);
          this.toastr.error(error.message, 'Form is not valid!', {
            timeOut: 10000,
            closeButton: true
          });
          this.isAuthLoading = false;
        },
      });
    } else {
      this.toastr.error('Form is not valid!');
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
  onChangeHandler(event: any) {
    if(event.target.checked){
      this.loginForm.get('termscondition')?.setValue(true);
    }else{
      this.loginForm.get('termscondition')?.setValue(null);
    }
  }
}
