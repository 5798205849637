import { Injectable } from '@angular/core';
import { BiometricMachine } from '../interfaces/biometric-machine.interface';
import { LoadingService } from '@services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class MorphoMachine implements BiometricMachine {
  public uri: string = '';
  public hasAvdm: boolean = false;
  private readonly baseUrls = [
    'https://localhost:11100/',
    'https://127.0.0.1:11100/',
    'http://127.0.0.1:11100/'
  ];

  constructor(private loader: LoadingService) { }

  connect(): Promise<{ success: boolean, message: string, data?: any }> {
    const headers = {
      'Accept': '/',
      'Connection': 'keep-alive',
      'Content-Type': 'text/xml; charset=utf-8'
    };

    return new Promise(async (resolve, reject) => {
      try {
        for (const baseUrl of this.baseUrls) {
          this.loader.show();

          try {
            const response = await fetch(baseUrl, {
              method: 'RDSERVICE',
              headers: headers
            });

            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseText = await response.text();
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(responseText, 'text/xml');
            const info = xmlDoc.querySelector('RDService')?.getAttribute('info');
            const status = xmlDoc.querySelector('RDService')?.getAttribute('status');

            if (info && info.includes('Morpho_RD_Service') && status === 'READY') {
              this.hasAvdm = true;
              this.uri = baseUrl;
              this.loader.hide();
              return resolve({ success: true, message: 'Connected to Morpho Machine successfully', data: { url: baseUrl, port: 11100 } });
            }

            this.loader.hide();
          } catch (error) {
            console.error(`Error connecting to URL ${baseUrl}:`, error.message);
            this.loader.hide();
          }
        }

        if (!this.hasAvdm) {
          return reject({ success: false, message: 'AVDM not found or RD Services is not available' });
        }
      } catch (error) {
        reject({ success: false, message: error.message });
      }
    });
  }

  disconnect(): void {
    console.log('Disconnecting from Morpho Machine...');
  }

  async capture(): Promise<any> {
    if (!this.hasAvdm) {
      throw new Error('Machine not connected or AVDM not found');
    }

    const url = `${this.uri}/rd/capture`;
    const headers = {
      'Accept': '/',
      'Connection': 'keep-alive',
      'Content-Type': 'text/xml; charset=UTF-8'
    };

    const body = `<PidOptions ver="1.0"><Opts fCount="1" fType="2" iCount="0" iType="" pCount="0" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/></PidOptions>`;

    try {
      this.loader.show()

      const response = await fetch(url, {
        method: 'CAPTURE',
        headers: headers,
        body: body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseText = await response.text();
      this.loader.hide()

      return responseText;
    } catch (error) {
      this.loader.hide()
      console.error('Capture failed:', error.message);
      throw error;
    }
  }
}
