
import { Component,Input } from '@angular/core';
import { DataService } from '@services/data.service';
import { Profile } from '@/models/common';

interface RowData{
  id: number
  beneficiary_name: string
  destination_acct_number: number
  transaction_type:string
  txn_amount:string
  surcharge_amount: number
  total_amount:string
  user_ref_no:string
  ifsc_code:string
  utr:string
  narration:string
  status:string
  created_at: string
  transaction_ref_no:string
  transaction_date:string
  benef_bank_name:string
  
}
@Component({
  selector: 'app-ca-bank-receipt',
  templateUrl: './ca-bank-receipt.component.html',
  styleUrl: './ca-bank-receipt.component.scss'
})
export class CaBankReceiptComponent {
  @Input() row: RowData;
  public profile: Profile;
  first_name:any;
  last_name:any;
  member_id:any;
  mobile:any;
  email:any;


  constructor(private data:DataService){}

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      this.profile = profile
      // console.log(this.profile);
      this.first_name =this.profile.data.user.first_name;
      this.last_name =this.profile.data.user.last_name;
      this.mobile=this.profile.data.user.mobile;
      this.email=this.profile.data.user.email;
      this.member_id=this.profile.data.user['wallet'].member_id


    })
  }
  extractPrice(priceHtml:any) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = priceHtml;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  extractPriceAsInt(priceHtml: string): number {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = priceHtml;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const numericValue = parseInt(textContent.replace(/[^0-9]/g, ''), 10);
    return isNaN(numericValue) ? 0 : numericValue;
  }
}
