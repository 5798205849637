import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dth',
  templateUrl: './dth.component.html',
  styleUrl: './dth.component.scss'
})
export class DthComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  operators: string[] = ['Bank of Baroda', 'ICIC'];
  dthOperatorList: any;
  selectedOperator: any;
  formFields: any;
  billerFetchDetails: any;

  constructor(private fb: FormBuilder,
    public api : ApiService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      amount: ['', Validators.required]
    });
    this.getBillerList();
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }

  getBillerList(){
    var value = {
      billerCategory:'DTH'
    }
    this.api.billerList(value).subscribe({
      next: (response: any) => {
        this.dthOperatorList = response.data || [];
        // this.alert.success(response.message);
      },
      error: (error) => {
        this.dthOperatorList = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onSelectOperator($event){
    var getData:any = this.dthOperatorList.find(biller => biller.billerName === $event);
    this.selectedOperator = getData;
    this.formFields = getData.paramInfo;
  }

  getBill() {
    var inputField1 = {
        paramName:'a',
        paramValue:'10',
    }

    var inputField2 = {
      paramName:'a b',
      paramValue:'20',
    }

    var inputField3 = {
      paramName:'a b c',
      paramValue:'30',
    }

    var inputField4 = {
      paramName:'a b c d',
      paramValue:'40',
    }

    var inputField5 = {
      paramName:'a b c d e',
      paramValue:'50',
    }

    var inputField = [
      inputField1,
      inputField2,
      inputField3,
      inputField4,
      inputField5,
    ]
    var value = {
      agentDeviceIp:'192.168.2.73',
      agentDeviceMac:'01-23-45-67-89-ab',
      customerMobile:'9898990084',
      customerEmail:'customer@email.com',
      customerAdhaar:'',
      customerPan:'',
      billerId:this.selectedOperator.billerId,
      inputParams:inputField,
    }
    this.api.billFetch(value).subscribe({
      next: (response: any) => {
        this.billerFetchDetails = response.results || [];
        $('#receiptBooking').modal('show');
      },
      error: (error) => {
        this.billerFetchDetails = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onCloseModal(){
    $('#receiptBooking').modal('hide');
  }

}
