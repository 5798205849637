import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {
  public isLoading: boolean = false;
  paymentForm: FormGroup;
  operators: string[] = ['JioSawan'];

  constructor(private fb: FormBuilder,
    public api : ApiService
  ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      operator: ['',   Validators.required],
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    });
  }

  get showFields(): boolean {
    return this.paymentForm.get('operator').value !== '';
  }

  fetchBill() {
    console.log('fetchBill')
  }
}
