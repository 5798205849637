<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Payment Status</h1>
      </div>
      <div class="col-sm-6">
        <!-- <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
        </ol> -->
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row" *ngIf="!isLoggedIn">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <div>
              <img src="./assets/img/logo.png" alt="Company Logo" class="logo align-center" width="20%" >
          </div>
        <h4 class="mt-2 text-center" >PG Receipt</h4>

          </div>
          <div class="card-body">
            <div id="mainReceipt" style="display: block !important;">
              <table *ngIf="txnDetails" >
                <tr>
                  <td style="width: 50%">
                    <div class="info">
                        <strong>Payer's Name:</strong> {{ txnDetails.payername }}
                    </div>
                    <div class="info">
                        <strong>Payer Email:</strong> {{ txnDetails.payeremail }}
                    </div>
                    <div class="info">
                        <strong>Mobile Number:</strong>
                        {{ txnDetails.payerMobile }}
                    </div>
                  
                    <div class="info">
                        <strong>Total Amount in Words:</strong>
                        ₹{{ extractPriceAsInt(txnDetails.amount) | numToWords }}
                    </div>
                </td>
                    <td style="width: 50%;" align="left" >
                        <div class="info" style="text-transform: capitalize;"><strong>Payment Mode:</strong>{{txnDetails.paymentMode}} </div>
                        <!-- <div class="info"><strong>Account Number:</strong> {{ row.destination_acct_number }}</div> -->
                        <!-- <div class="info"><strong>Card/Bank Name:</strong> {{ txnDetails.Card&bankName}}</div> -->
                       
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                      <table class="innerTable">
                        <tr>
                           
                            <th>Client Txn Id</th>
                            <th>Amount</th>
                            <th>Transaction Date</th>
                            <th>Status</th>
                            
                        </tr>
                        <tr>
                     
                            <td>{{ txnDetails.clientTxnId }}</td>
                            <td>{{ extractPrice(txnDetails.amount)}}</td>
                            <td>{{txnDetails.transDate}}</td>
                            <td>{{ txnDetails.status }}</td>
                           
                        </tr>
                    </table>
                    </td>
                </tr>
              
                <tr>
                    <td colspan="2">
                        <hr>
                        <p style="text-align: center;"><strong>Note:</strong>
                            This is a computer-generated receipt and does not require any physical signature
                        </p>
                    </td>
                </tr>
            </table>
            </div>
            <div class="d-flex" style="justify-content: center;
            padding-bottom: 10px;">
            <button class="btn btn-primary no-print" id="printButton" (click)="print()">Print</button>
           
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
