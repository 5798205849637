import { Bank, State, City, Profile } from '@/models/common';
import { BankDetails } from '@/models/toshani';
import { PERMISSIONS } from '@/utils/constants';
import { isAllowed } from '@/utils/helpers';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { DataService } from '@services/data.service';
import { PennyDropService } from '@services/toshani/penny-drop.service';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap, catchError, of } from 'rxjs';

@Component({
  selector: 'app-xdmt',
  templateUrl: './xdmt.component.html',
  styleUrl: './xdmt.component.scss'
})
export class XdmtComponent {
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public customerAadharKYCForm: FormGroup;
  public customerAadharVerificationForm: FormGroup;
  public isLoading: boolean = false;
  public aadharVerificationNeeded: boolean = false;
  public aadharKYCNeeded: boolean = false;
  public remitter: any;
  public accountForm: any;
  public banks: Bank[];
  public states: State[];
  public cities: City[];
  public addBeneficiaryModal: any;
  public beneficiaries: any;
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  public beneficiaryForm: FormGroup;
  public beneficiary: BankDetails;
  public min: any;
  public max: any;
  searchQuery: string = '';
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public tPinModal: Modal;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private data: DataService,
    private alert: ToastrService,
    private penny: PennyDropService) { }

  ngOnInit() {
    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.TOSHANI_DMT_EXPRESS, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerAadharKYCForm = this.formBuilder.group({
      number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      aadharnumber: ['', [Validators.required]]
    });

    this.customerAadharVerificationForm = this.formBuilder.group({
      number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{5}$/)]],
    });

    this.customerRegistrationForm = this.formBuilder.group({
      number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      number: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      amount: ['', [Validators.required, Validators.min(0), Validators.max(200000)]],
      beneficiary_id: [{ value: '', disabled: true }, Validators.required],
      tpin: ['', Validators.required]
    });

    this.api.getMinMax('xdmt', 'toshani').subscribe({
      next: (response: any) => {
        this.min = response.data.min_start_range;
        this.max = response.data.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.beneficiaryForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9]\\d{9}$')]],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      account_number: ['', [Validators.required, Validators.pattern(/^[0-9]{9,18}$/)]],
      bank_code: ['', [Validators.required, Validators.maxLength(255)]],
      ifsc_code: ['', [Validators.required, Validators.pattern(/^[A-Za-z]{4}[0-9]{1}[A-Za-z0-9]{6}$/)]],
      beneficiary_mobile_number: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[6-9]\\d{9}$')]]
    });

    this.api.banks('dmt-express').subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  } n

  verification() {
    if (this.customerVerificationForm.valid) {
      const mobileNumber = this.customerVerificationForm.value.mobileNumber;
      this.isLoading = true;

      this.api.xdmtCustomer({ mobileNumber }).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (response.result == 2) {

            this.customerRegistrationForm.patchValue({
              number: mobileNumber
            })

            this.isOTPNeeded = true;
            return
          }

          if (response.result == 1 && response.data.kyc_status == 0) {

            this.customerAadharKYCForm.patchValue({
              number: mobileNumber
            })

            this.aadharKYCNeeded = true
            return
          }

          if (response.result == 1 && response.data.kyc_status == 1 && response.data.is_verified == 1) {
            this.isOTPNeeded = this.aadharKYCNeeded = this.aadharVerificationNeeded = false
            this.isLoggedIn = true;
            this.remitter = {
              "name": response.data.cust_name,
              "number": response.data.mobileNumber,
              "limit": {
                "remaining": response.data.remaining_limit
              }
            }

            this.beneficiaries = response.data?.beni_list || []
            this.beneficiary = null;

            this.beneficiaryForm.patchValue({
              mobileNumber: mobileNumber
            })
          }
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
        }
      });
    }

  }

  register() {
    if (!this.customerRegistrationForm.valid) {
      this.customerRegistrationForm.markAsDirty()
    }

    this.isLoading = true;
    this.api.verifyXDMT(this.customerRegistrationForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.result == 1) {
          this.isOTPNeeded = false;

          this.verification()
          this.beneficiaries = response.data.beneficiaries
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      }
    });
  }

  aadharKYCVerification() {
    this.isLoading = true;
    this.api.verifyKYC(this.customerAadharVerificationForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.result == 1) {
          this.verification()
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      }
    });
  }

  aadharKYC() {
    this.isLoading = true;
    this.api.setKYC(this.customerAadharKYCForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.result == 1) {
          const mobileNumber = this.customerVerificationForm.value.mobileNumber;

          this.customerAadharVerificationForm.patchValue({
            number: mobileNumber
          })

          this.aadharVerificationNeeded = true;
          return
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      }
    });
  }

  stateChanged($event: any) {
    console.log($event.target.value)
  }

  confirmTransfer() {
    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.tPinModal.show();
    }
  }

  transfer() {
    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    const data = this.accountForm.getRawValue();
    console.log(this.accountForm)
    this.isLoading = true;
    this.api.setTransfer({ ...data, mobileNumber: this.customerVerificationForm.value.mobileNumber }).subscribe({
      next: (response: any) => {
        this.alert.success(response.message)

        this.resetTpinForm();

        this.accountForm.markAsPristine();
        this.accountForm.markAsUntouched();

        this.verification()
        this.isLoading = false;
        this.tPinModal.hide();
        // window.open(response.download, '_blank');
      },
      error: (error) => {
        this.isLoading = false;
        this.tPinModal.hide();
        this.resetTpinForm();
        this.accountForm.reset();
      }
    })

  }

  details(beneficiary: any) {
    this.beneficiary = beneficiary;
    this.accountForm.patchValue({
      number: this.remitter.number,
      beneficiary_id: beneficiary.ben_id,
      amount: '',
      tpin: ''
    });
  }

  addBeneficiary() {
    if (!this.beneficiaryForm.valid) {
      for (const control in this.beneficiaryForm.controls) {
        if (this.beneficiaryForm.controls.hasOwnProperty(control)) {
          this.beneficiaryForm.controls[control].markAsTouched();
        }
      }
      return;
    }

    this.isLoading = true;

    this.api.addBeneficiaries(this.beneficiaryForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.alert.success(response.message);
        this.fetchXDMTBeneficiaries();
        this.isLoading = false;

        this.beneficiaryForm.patchValue({
          name: '',
          account_number: '',
          bank_code: '',
          ifsc_code: '',
          beneficiary_mobile_number: ''
        })

        this.beneficiaryForm.markAsPristine();
        this.beneficiaryForm.markAsUntouched();

        this.beneficiaryForm.get('name').enable();
        this.beneficiaryForm.get('account_number').enable();
        this.beneficiaryForm.get('ifsc_code').enable();

        this.addBeneficiaryModal.hide();

      },
      error: (error) => {
        this.isLoading = false;
      }
    });
  }

  fetchXDMTBeneficiaries() {
    this.api.getXDMTBeneficiaries(this.beneficiaryForm.getRawValue().mobileNumber).subscribe({
      next: (response: any) => {
        this.beneficiaries = response.data || [];
        // this.alert.success(response.message);
      },
      error: (error) => {
        this.beneficiaries = [];
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  delete(beneficiary: BankDetails) {
    console.log(beneficiary)
    if (confirm('Are you sure you want to delete')) {
      this.api.deleteBeneficiary({
        number: this.customerVerificationForm.value.mobileNumber,
        beneficiary_id: beneficiary.ben_id
      }).subscribe({
        next: (response: any) => {
          this.alert.success(response.message)
          this.verification()
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        }
      })
    }
  }

  accountVerification() {
    const { account_number: accountNumber, ifsc_code: ifscCode } = this.beneficiaryForm.getRawValue()

    this.penny.isValid({
      accountNumber: accountNumber,
      ifsc: ifscCode
    }).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.beneficiaryForm.patchValue({
          name: response.data.registered_name,
          ifsc_code: response.data.ifsc,
          account_number: response.data.account_number,
        });

        this.beneficiaryForm.get('name').disable();
        this.beneficiaryForm.get('ifsc_code').disable();
        this.beneficiaryForm.get('account_number').disable();

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      }
    })
  }

  get filteredBeneficiaries() {
    return this.beneficiaries.filter(beneficiary =>
      beneficiary.ben_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  handleServerErrors(errors: any) {
    Object.keys(errors).forEach(key => {
      this.alert.error(errors[key][0], key, {
        timeOut: 10000
      });
    });
  }

  resetTpinForm(onlyTpin = false) {
    const tpinControl = this.accountForm.get('tpin');
    if (tpinControl) {
      tpinControl.setValue('');
      tpinControl.markAsUntouched();
      tpinControl.setErrors(null);
    }


      this.accountForm.patchValue({
        beneficiaryId: '',
        account_holder_name: '',
        account_holder_mobile_number: '',
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        amount: ''
      })
    
     this.beneficiary=null;
   
      this.beneficiaryForm.markAsPristine();
      this.beneficiaryForm.markAsUntouched();
      this.accountForm.markAsUntouched();
    
  }
}
