import {Component, ViewChild} from '@angular/core';
import {DataTableService} from '@services/data-table.service';
import {DataTableDirective} from 'angular-datatables';
import {ApiService} from '@services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var $: any;

@Component({
    selector: 'app-bbps-report',
    templateUrl: './bbps-report.component.html',
    styleUrl: './bbps-report.component.scss'
})
export class BBPSReportComponent {
    addComplaintForm: FormGroup;
    public dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    public datatableElement: DataTableDirective;
    public row: any;
    public isCalling = false;
    private printButtonHandler: any;
    private statusUpdateHandler: any;
    txnStatusResponse: any;
    complaintListResponse: any;
    selectedComplaintData: any;
    dispositionsList: any;
    complaintTrackDetails: any;
    ComplainData: any;
    searchQuery: string = '';


    get filteredComplaints() {
        return this.complaintListResponse
            ? this.complaintListResponse.filter((complaint) =>
                  complaint.txnRefId
                      .toLowerCase()
                      .includes(this.searchQuery.toLowerCase())
              )
            : [];
    }
    constructor(
        private dataTableService: DataTableService,
        private formGroup: FormBuilder,
        private api: ApiService
    ) {
        this.dataTableService.url = 'reports/bbps-bill-pay';
        this.dataTableService.export_name = 'BBPS_REPORT';
    }

    isSelectedRow: any;
    ngOnInit(): void {
        this.addComplaintForm = this.formGroup.group({
            complaintType: ['Transaction'],
            billerId: [''],
            servReason: [''],
            complaintDesc: [''],
            participationType: [''],
            complaintDisposition: ['']
        });

        this.dtOptions = this.dataTableService.initializeDataTableOptions(
            [
                {
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    title: '#',
                    orderable: false,
                    searchable: false
                },
                {
                    data: 'billerName',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'billerCategory',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'transaction_amount',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'net_amount',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'status',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'transaction_date',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'customer_mobile',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'location',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'payment_mode',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'quick_pay',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'transaction_reference_id',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'bill_number',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'approval_ref_number',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'bill_payment_date',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'due_date',
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'created_at',
                    searchable: false,
                    render: function (data, type, row) {
                        return data ? data : '-';
                    }
                },
                {
                    data: 'action',
                    render: (data, type, row) => {
                        return (
                            `
          <div class="row">
            <button class="btn-sm btn-primary action-btn col-md-6" data-id="` +
                            row.id +
                            `"><i class="fa fa-check"></i></button>
            <button class="btn-sm btn-secondary another-action-btn col-md-6" data-id="` +
                            row.id +
                            `"><i class="fa fa-edit"></i></button>
            </div>
          `
                        );
                    }
                }
            ],
            (settings: any, json: any) => {
                $('#bbps-table_filter').append(
                    '<input type="text" class="form-control form-control-sm" id="fromto" data-column="7">'
                );
                $('#fromto').daterangepicker({
                    timeZone: 'Asia/Kolkata',
                    locale: {
                        format: 'DD-MM-YYYY '
                    }
                });

                $('#fromto').on(
                    'apply.daterangepicker',
                    async (ev: any, picker: {startDate: any; endDate: any}) => {
                        (await this.datatableElement.dtInstance)
                            .column($(this).data('column'))
                            .search(
                                JSON.stringify([
                                    picker.startDate.format('DD-MM-YYYY'),
                                    picker.endDate.format('DD-MM-YYYY')
                                ])
                            )
                            .draw();
                    }
                );

                $('#bbps-table').on(
                    'click',
                    'button.action-btn',
                    async (event) => {
                        const id = $(event.currentTarget).data('id');
                        const table = await this.datatableElement.dtInstance;
                        const rowData = table
                            .row($(event.currentTarget).closest('tr'))
                            .data();
                        this.onGetTransactionStatus(rowData);
                        // this.rowClickHandler(rowData);
                    }
                );

                $('#bbps-table').on(
                    'click',
                    'button.another-action-btn',
                    async (event) => {
                        const id = $(event.currentTarget).data('id');
                        const table = await this.datatableElement.dtInstance;
                        const rowData = table
                            .row($(event.currentTarget).closest('tr'))
                            .data();
                        this.onGetComplaintList(rowData);
                    }
                );
            },
            true
        );
        this.dtOptions.scrollX = true;
    }
    rowClickHandler(data: any): void {
        console.log('Row clicked:', data);
    }
    onGetTransactionStatus(data: any) {
        this.selectedComplaintData = data;
        var value = {
            trackType: 'TRANS_REF_ID',
            trackValue: data.transaction_reference_id,
            fromDate: data.transaction_date,
            toDate: data.transaction_date
        };
        this.api.transactionStatus(value).subscribe({
            next: (response: any) => {
                this.txnStatusResponse = response.results.txnList || [];
                $('#txnStatus').modal('show');
            },
            error: (error) => {
                this.txnStatusResponse = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }

    onGetComplaintList(data: any) {
        this.ComplainData = data;
        var value = {
            id: data.id
        };
        this.api.complaintList(value).subscribe({
            next: (response: any) => {
                this.complaintListResponse = response?.data || [];
                $('#complaintListModal').modal('show');
            },
            error: (error) => {
                this.complaintListResponse = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }

    onAddNewComplaint() {
        this.api.dispositions().subscribe({
            next: (response: any) => {
                this.dispositionsList = response?.data || [];
            }
        });
        $('#addNewComplaint').modal('show');
    }

    submitNewComplaint() {
        if (this.addComplaintForm.invalid) {
            return;
        } else {
            var value = {
                txnRefId: this.ComplainData?.transaction_reference_id,
                complaintType: this.addComplaintForm.value.complaintType,
                participationType:
                    this.addComplaintForm.value.participationType,
                billerId: this.addComplaintForm.value.billerId,
                complaintDesc: this.addComplaintForm.value.complaintDesc,
                complaintDisposition:
                    this.addComplaintForm.value.complaintDisposition,
                servReason: this.addComplaintForm.value.servReason,
                id: this.ComplainData?.id
            };
            this.api.addNewComplaint(value).subscribe({
                next: (response: any) => {
                    this.txnStatusResponse = response.results.txnList || [];
                    $('#addNewComplaint').modal('hide');
                    // this.onGetComplaintList();
                    $('#complaintListModal').modal('show');
                    this.resetComplaintForm();
                },
                error: (error) => {
                    this.txnStatusResponse = [];
                    this.resetComplaintForm();
                },
                complete: () => {
                    // this.isLoading = false;
                }
            });
        }
    }
    resetComplaintForm() {
        this.addComplaintForm.controls['billerId'].setValue('');
        this.addComplaintForm.controls['servReason'].setValue('');
        this.addComplaintForm.controls['complaintDesc'].setValue('');
        this.addComplaintForm.controls['participationType'].setValue('');
        this.addComplaintForm.controls['complaintDisposition'].setValue('');
    }
    onTrackComplaint(event: any) {
        var value = {
            complaintType: event.complaintType,
            complaintId: event.complaintId,
            
        };
        this.api.complaintTracking(value).subscribe({
            next: (response: any) => {
                this.complaintTrackDetails = response.results || [];
                $('#complaintTrack').modal('show');
            },
            error: (error) => {
                this.complaintTrackDetails = [];
            },
            complete: () => {
                // this.isLoading = false;
            }
        });
    }

    onCloseModal() {
        $('#txnStatus').modal('hide');
        $('#complaintListModal').modal('hide');
        $('#complaintTrack').modal('hide');
        $('#addNewComplaint').modal('hide');
        this.resetComplaintForm();
    }
}
