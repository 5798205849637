<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Aadhaar Enabled Payment System (AePS)</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">AePS</li>
                </ol> -->
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="alert alert-warning" role="alert" *ngIf="!formShow">
            <strong>{{verificationMessage}}</strong>
        </div>

        <div class="card" *ngIf="formShow">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="deviceType">Select Device <span
                                    class="text-danger font-weight-bold">*</span></label>
                                    <select class="form-control" id="deviceType" name="deviceType" [(ngModel)]="selectedDeviceType" (change)="onDeviceTypeChange()">
                                        <option value="">Please choose device</option>
                                        <option value="mantra">Mantra</option>
                                        <option value="morpho">Morpho</option>
                                    </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="aeps">Transaction Type <span
                                    class="text-danger font-weight-bold">*</span></label>
                            <select class="form-control" id="aeps" name="aeps" [(ngModel)]="selectedAEPS"
                                (ngModelChange)="typeUpdated()">
                                <option value="">Please choose transaction type</option>
                                <option value="CW">Cash Withdrawal</option>
                                <option value="BE">Balance Enquiry</option>
                                <option value="AP">Aadhar Pay</option>
                                <option value="MS">Mini Statement</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="formShow">
            <div class="card-header">
                <h3 class="card-title mb-0">AePS Transaction</h3>
            </div>
            <div class="card-body">
                <form [formGroup]="transactionForm" (ngSubmit)="transaction()" autocomplete="off">

                    <div class="form-group">
                        <label for="mobile_number">Mobile Number <span
                                class="text-danger font-weight-bold">*</span></label>
                        <input type="text" name="mobile_number" id="mobile_number" class="form-control"
                            formControlName="mobile_number" placeholder="Please provide your mobile number registered with bank"
                            [ngClass]="{'is-invalid': transactionForm.get('mobile_number').invalid && (transactionForm.get('mobile_number').dirty || transactionForm.get('mobile_number').touched)}">
                        <div *ngIf="transactionForm.get('mobile_number').invalid && (transactionForm.get('mobile_number').dirty || transactionForm.get('mobile_number').touched)"
                            class="invalid-feedback">
                            <div *ngIf="transactionForm.get('mobile_number').errors.required"><strong>Mobile Number is
                                    required.</strong></div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="iinno">Bank Name</label><span class="text-danger font-weight-bold ml-2">*</span>
                        <select class="form-control" formControlName="iinno" id="iinno"
                            [ngClass]="{'is-invalid': transactionForm.get('iinno').invalid && (transactionForm.get('iinno').dirty || transactionForm.get('iinno').touched)}">
                            <option value="">Please select a bank</option>
                            <option *ngFor="let bank of banks" [value]="bank.iinno">{{bank.bankName}}
                            </option>
                        </select>
                        <div *ngIf="transactionForm.get('iinno').invalid && (transactionForm.get('iinno').dirty || transactionForm.get('iinno').touched)"
                            class="text-danger text-sm font-weight-bold">
                            <div *ngIf="transactionForm.get('iinno').errors.required">
                                Please select a bank.</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="aadhar_number">Aadhar Number <span
                                class="text-danger font-weight-bold">*</span></label>

                        <div class="input-group">
                            <input type="text" name="aadhar_number" id="aadhar_number" class="form-control"
                                formControlName="aadhar_number" (input)="onInput($event)" minlength="12" maxlength="14" placeholder="Please provide your aadhar number"
                                [ngClass]="{'is-invalid': transactionForm.get('aadhar_number').invalid && (transactionForm.get('aadhar_number').dirty || transactionForm.get('aadhar_number').touched)}">

                            <div class="input-group-append">
                                <span class="input-group-text" style="cursor: pointer;" (click)="toggleMasking()">
                                    {{ isMasked ? '🔒' : '🔓' }}
                                </span>
                            </div>

                            <div *ngIf="transactionForm.get('aadhar_number').invalid && (transactionForm.get('aadhar_number').dirty || transactionForm.get('aadhar_number').touched)"
                                class="invalid-feedback">
                                <div *ngIf="transactionForm.get('aadhar_number').errors?.required"><strong>Aadhar Number is required.</strong></div>
                                <div *ngIf="transactionForm.get('aadhar_number').errors?.pattern"><strong>Please enter a valid Aadhar Number (12 digits).</strong></div>
                                <div *ngIf="transactionForm.get('aadhar_number').errors?.minlength"><strong>Aadhar Number must be exactly 12 digits.</strong></div>
                                <div *ngIf="transactionForm.get('aadhar_number').errors?.maxlength"><strong>Aadhar Number must be exactly 12 digits.</strong></div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="selectedAEPS == 'CW' || selectedAEPS == 'AP'">
                        <label for="amount">Amount <span class="text-danger font-weight-bold">*</span></label>
                        <input type="text" name="amount" class="form-control" prefix="₹"
                            mask="00 || 000 || 0,000 || 00,000 || 0,00,000" thousandSeparator="," id="amount"
                            formControlName="amount">
                        <small class="text-danger"
                            *ngIf="transactionForm.get('amount').invalid && transactionForm.get('amount').touched">Please
                            enter a valid amount.</small>
                        <small>{{ transactionForm?.value?.amount | numToWords }}</small>
                    </div>

                    <div *ngIf="selectedDeviceType && selectedAEPS">
                        <pf-button [variant]="'primary'" [disabled]="!biometric.biometricMachine.hasAvdm" [loading]="isLoading" (click)="transaction()">
                            Authenticate
                        </pf-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div #aepsTransactionModal class="modal fade" id="balanceEnquiryModal" tabindex="-1" role="dialog"
    aria-labelledby="infoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="infoModalLabel">Transaction Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="bank">Bank</label>
                            <input type="text" class="form-control" id="bank" value="State Bank of India" disabled>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="date">Date</label>
                            <input type="text" class="form-control" [value]="transactionData?.date" disabled>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="aadhar">Aadhar</label>
                            <input type="text" class="form-control" [value]="transactionData?.aadhar" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label for="status">Status</label>
                            <input type="text" class="form-control" [value]="transactionData?.status" disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="txn_id">Transaction ID</label>
                            <input type="text" class="form-control" [value]="transactionData?.txn_id" disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="transactionType">Transaction Type Code</label>
                            <input type="text" class="form-control" [value]="transactionData?.transactionType" disabled>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="transactionType">transaction Amount</label>
                            <input type="text" class="form-control" [value]="transactionData?.transaction_amount" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="bankrrn">Bank RRN</label>
                            <input type="text" class="form-control" [value]="transactionData?.bankrrn" disabled>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="tnxtype">Transaction Type</label>
                            <input type="text" class="form-control" [value]="transactionData?.tnxtype" disabled>
                        </div>
                        <div class="form-group col-md-4" *ngIf="transactionData?.balance">
                            <label for="balance">Balance</label>
                            <input type="text" class="form-control" [value]="transactionData?.balance" disabled>
                        </div>
                    </div>

                    <div class="row" *ngIf="transactionData?.transactions.length > 0">
                        <div class="col-md-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>Narration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transaction of transactionData.transactions; let i = index">
                                        <td>{{i + 1}}</td>
                                        <td>{{transaction.date}}</td>
                                        <td>{{transaction.amount}}</td>
                                        <td>{{transaction.type}}</td>
                                        <td>{{transaction.narration}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>