<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Bank Transaction Report</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">BBPS Report</li>
                </ol> -->
            </div>
        </div>
    </div>
</section>

<section class="d-print-none content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body table-responsive" style="overflow: auto;
                    width: 100%;
                    position: relative;">
                        <table
                            id="bbps-table"
                            datatable
                            [dtOptions]="dtOptions"
                            class="table row-border hover"
                          
                        ></table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-ca-bank-receipt [row]="row"></app-ca-bank-receipt>

<!-------------Txn Status Modal-->
<div
    class="modal fade"
    id="txnStatus"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #f1f1f1">
                <div class="col-md-12 p-0">
                    <h4 class="modal-title" style="text-align: center">
                        Bank Transaction Status
                    </h4>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-8">
                    <div class="details">
                        <p >
                            Transaction Status :
                            <b *ngIf="txnStatusResponse?.transaction.status == 'SUCCESS'" class="text-success">{{ txnStatusResponse?.transaction.status }}</b>
                            <b *ngIf="txnStatusResponse?.transaction.status == 'Pending'" style="color: orange;">{{ txnStatusResponse?.transaction.status }}</b>
                            <b *ngIf="txnStatusResponse?.transaction.status == 'Failed'" style="color: red;">{{ txnStatusResponse?.transaction.status }}</b>
                            <b *ngIf="txnStatusResponse?.transaction.status == 'Awaited'" style="color: orange;">{{ txnStatusResponse?.transaction.status }}</b>

                        </p>
                      
                        <p>
                            Beneficiary Name: <b>{{ txnStatusResponse?.transaction.beneficiary_name }}</b>
                        </p>
                        <p>
                            Beneficiary Account Number: <b>{{ txnStatusResponse?.transaction.destination_acct_number }}</b>
                        </p>
                        <p>
                            Bank Name: <b>{{ txnStatusResponse?.transaction.benef_bank_name }}</b>
                        </p>
                        <p>
                           Beneficiary Mobile Number: <b>{{ txnStatusResponse?.transaction.beneficiary_mobile_number }}</b>
                        </p>
                        <p>
                            IFSC Code: <b>{{ txnStatusResponse?.transaction.ifsc_code }}</b>
                        </p>
                        <p>
                            Amount : <b>₹ {{ txnStatusResponse?.total_amount }}</b>
                        </p>
                        <p>
                            Payment Mode :
                            <b>{{ txnStatusResponse?.transaction.transaction_type }}</b>
                        </p>
                        <p>
                            Message :
                            <b>{{ txnStatusResponse?.transaction.narration }}</b>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <img src="assets/img/logo.png" alt="Company Logo" class="logo" width="50%"/>
                </div>
                </div>
            </div>

            <div class="modal-footer border-top text-right">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    (click)="onClosePopup()"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

