import { Component, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { DataTableService } from '@services/data-table.service';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-e-wallet-ledger',
  templateUrl: './e-wallet-ledger.component.html',
  styleUrl: './e-wallet-ledger.component.scss'
})
export class EWalletLedgerComponent {
  @ViewChild(DataTableDirective, { static: false })
  public datatableElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};

  constructor(private dataTableService: DataTableService, private api: ApiService) {
    this.dataTableService.url = 'wallet/history'
    this.dataTableService.export_name = 'WALLET_LEDGER_REPORT'
  }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = this.dataTableService.initializeDataTableOptions([
      { "data": "DT_RowIndex", "name": "DT_RowIndex", "title": "#", "orderable": false, "searchable": false },
      { 'title': 'Date', 'data': 'created_at' },
      { 'title': 'Transaction ID', 'data': 'transaction_id' },
      { 'title': 'Opening Balance', 'data': 'opening_balance' },
      { 'title': 'Credit/Debit', 'data': 'amount' ,
       render: function (data, type, row) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = data; // Extract the numeric value
        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        const amountValue = parseFloat(textContent.replace(/[^0-9.-]/g, ''));

        if (amountValue < 0) {
          return `<span style="color: red;">${textContent}</span>`;
        } else {
          return `<span style="color: green;">${textContent}</span>`;
        }
      }},
      { 'title': 'Closing Balance', 'data': 'closing_balance' },
      { 'title': 'Transaction Description', 'data': 'narration' }
    ], function (settings, json) {
      $("#ewallet-ledger_filter").append('<input type="text" class="form-control form-control-sm" id="fromto" data-column="1">');
      $("#fromto").daterangepicker({
        timeZone: 'Asia/Kolkata', // Indian time zone
        locale: {
          format: 'DD-MM-YYYY HH:mm', // Date format with 24-hour format (e.g., 01-01-2024 09:00)
        }
      });
      $("#fromto").on('apply.daterangepicker', async function (ev, picker) {
        (await self.datatableElement.dtInstance).column($(this).data('column')).search(JSON.stringify([picker.startDate, picker.endDate])).draw();;
      });
    });
  }
}