<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>AePS Onboarding</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active">Onboarding</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="alert alert-warning" role="alert" *ngIf="!formShow || verificationMessage">
            <strong [innerHTML]="verificationMessage"></strong>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
              
              <div class="row mt-5" >
                <div class="col">
                  <div class="d-flex justify-content-center align-items-center mb-4" style="height: 200px;">
                    <div class="border rounded-circle p-4 px-5" style="font-size: 100px;">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <h2 class="mb-4">Complete your AePS Onboarding</h2>
                  <p class="mb-4">Ensure your AePS onboarding to start using this service, once the process is completed please come back on this page and refresh.</p>
                  <button class="btn btn-primary" (click)="initOnboarding()" *ngIf="!windowState">Start Onboarding</button>
                  <button class="btn btn-primary" (click)="refresh()" *ngIf="windowState">Completed?</button>
                </div>
              </div>
            </div>
          </div>

    </div>
</section>