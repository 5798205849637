<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Payment Gateway Service</h1>
      </div>
      <div class="col-sm-6">
        <!-- <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Sabpaisa Pay</li>
        </ol> -->
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row" *ngIf="!isLoggedIn && !isOTPNeeded">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Authorize</h3>
          </div>
          <div class="card-body">
            <form autocomplete="off" [formGroup]="customerVerificationForm" (ngSubmit)="verification()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number <strong class="text-danger">*</strong></label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerVerificationForm.get('mobileNumber').invalid && (customerVerificationForm.get('mobileNumber').dirty || customerVerificationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerVerificationForm.get('mobileNumber').errors.required">Mobile
                    number is required.
                  </div>
                  <div *ngIf="customerVerificationForm.get('mobileNumber').errors.pattern">Please
                    enter a valid Indian
                    mobile
                    number.</div>
                </div>
              </div>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="verification()">
                Continue
              </pf-button>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isOTPNeeded && !isLoggedIn">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Register</h3>
          </div>
          <div class="card-body">

            <form autocomplete="off" [formGroup]="customerRegistrationForm" (ngSubmit)="register()">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number</label>
                <input formControlName="mobileNumber" type="text" class="form-control" id="mobileNumber"
                  placeholder="Please enter your mobile number.">
                <div
                  *ngIf="customerRegistrationForm.get('mobileNumber').invalid && (customerRegistrationForm.get('mobileNumber').dirty || customerRegistrationForm.get('mobileNumber').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('mobileNumber').errors?.required">Mobile number is required.
                  </div>
                  <div *ngIf="customerRegistrationForm.get('mobileNumber').errors?.pattern">Please enter a valid Indian
                    mobile number.</div>
                </div>
              </div>

              <div class="form-group">
                <label for="name">Your Name</label>
                <input formControlName="name" type="text" class="form-control" id="name"
                  placeholder="Please enter your name.">
                <div
                  *ngIf="customerRegistrationForm.get('name').invalid && (customerRegistrationForm.get('name').dirty || customerRegistrationForm.get('name').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('name').errors?.required">Your name is required.</div>
                </div>
              </div>

              <div class="form-group">
                <label for="otp">OTP</label>
                <input formControlName="otp" type="text" class="form-control" id="otp"
                  placeholder="Please enter the OTP received.">
                <div
                  *ngIf="customerRegistrationForm.get('otp').invalid && (customerRegistrationForm.get('otp').dirty || customerRegistrationForm.get('otp').touched)"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="customerRegistrationForm.get('otp').errors?.required">OTP is required.</div>
                  <div *ngIf="customerRegistrationForm.get('otp').errors?.pattern">Please enter a valid OTP.</div>
                </div>
              </div>

              <button type="submit" class="d-none"></button>
              <pf-button [variant]="'primary'" [loading]="isLoading" (click)="register()">
                Continue
              </pf-button>
            </form>


          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isLoggedIn" (ngSubmit)="transfer()">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">
              <!-- <small><strong>Hi, {{remitter.name}}</strong></small><br />
              <small><strong>{{remitter.number}}</strong></small><br />
              <small><strong>Reamaining Limit - {{remitter.limit.remaining}}</strong></small> -->
            </div>
            <div class="card-tools mt-3">
              <!-- <pf-button [variant]="'danger'" [loading]="false" (click)="isLoggedIn = false">Change Remitter</pf-button> -->
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="accountForm">
              <div class="form-group">
                <label for="pg_name" class="form-label">Select Payment Gateway <strong class="text-danger">*</strong></label>
                <select type="text" class="form-control" formControlName="pg_name" (change)="onSelectOperator($event.target.value)">
                  <option value="" selected> Select Type</option>
                  <option [value]="list?.alias" *ngFor="let list of pgList" > {{list.alias}} </option>
                </select>
                <div *ngIf="accountForm.get('pg_name').invalid && accountForm.get('pg_name').touched"
                  class="text-danger text-sm font-weight-bold">
                <div *ngIf="accountForm.get('pg_name').errors?.required">Payment Gateway is required.</div>
                </div>
              </div>
              <div class="form-group">
                <label for="amount" class="form-label">Surcharge <strong class="text-danger">*</strong></label>
                <input type="text"  class="form-control" formControlName="surcharge" readonly>
              </div>
              <div class="form-group">
                <label for="amount" class="form-label">Amount <strong class="text-danger">*</strong></label>
                <input type="text" class="form-control" prefix="₹" mask="00 || 000 || 0,000 || 00,000 || 0,00,000"
                  thousandSeparator="," [min]="0" [max]="200000" id="amount" formControlName="amount" placeholder="Enter Amount">
                <small>{{ accountForm?.value?.amount| numToWords }}</small>
                <div *ngIf="accountForm.get('amount').invalid && accountForm.get('amount').touched"
                  class="text-danger text-sm font-weight-bold">
                  <div *ngIf="accountForm.get('amount').errors?.required">Amount is required.</div>
                  <div *ngIf="accountForm.get('amount').errors?.min">Amount must be at least ₹{{min}}.</div>
                  <div *ngIf="accountForm.get('amount').errors?.max">Amount cannot exceed ₹{{max}}.</div>
                </div>
              </div>
              <div class="form-group">
                <label for="t_amount" class="form-label">Total Amount <strong class="text-danger">*</strong></label>
                <input type="number" class="form-control t_amount" prefix="₹" id="t_amount" formControlName="total_amount" readonly>
               
              
              </div>
              <pf-button [variant]="'primary'" [disabled]="isLoading" [loading]="isLoading"
                (click)="confirmTransfer()">Continue</pf-button>
            </form>
          </div>

        </div>
      </div>

    </div>
  </div>
</section>
