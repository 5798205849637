import { Injectable } from '@angular/core';
import { BiometricMachine } from '../interfaces/biometric-machine.interface';
import { LoadingService } from '@services/loading.service';

@Injectable({
    providedIn: 'root'
})
export class MantraMachine implements BiometricMachine {

    public uri: string = 'http://localhost:11101';
    public hasAvdm: boolean = false;
    private readonly getCustomDomName = '127.0.0.1';
    constructor(private loader: LoadingService) { }

    connect(): Promise<{ success: boolean, message: string, data?: any }> {
        const baseProtocol = 'http://';
        const ports = Array.from({ length: 5 }, (_, i) => 11100 + i);

        return new Promise(async (resolve, reject) => {
            try {
                for (const port of ports) {
                    this.loader.show();

                    const url = `${baseProtocol}${this.getCustomDomName}:${port}`;
                    const headers = {
                        'Accept': '/',
                        'Connection': 'keep-alive',
                        'Content-Type': 'text/xml; charset=utf-8'
                    };

                    try {
                        const response = await fetch(url, {
                            method: 'RDSERVICE',
                            headers: headers
                        });

                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        const responseText = await response.text();
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(responseText, 'text/xml');
                        const info = xmlDoc.querySelector('RDService')?.getAttribute('info');
                        const status = xmlDoc.querySelector('RDService')?.getAttribute('status');

                        if (info && info.includes('Mantra') && status === 'READY') {
                            this.hasAvdm = true;
                            this.uri = url;
                            this.loader.hide();
                            return resolve({ success: true, message: 'Successfully connected to the Mantra machine', data: { url, port } });
                        }
                        this.loader.hide();
                    } catch (error) {
                        console.error(`Error connecting to port ${port}:`, error.message);
                        this.loader.hide();
                    }
                }

                if (!this.hasAvdm) {
                    return reject({ success: false, message: 'AVDM not found or RD Services is not available' });
                }
            } catch (error) {
                reject({ success: false, message: error.message });
            }
        });
    }

    disconnect(): void {
        console.log('Disconnecting from Mantra Machine...');
    }

    async capture(): Promise<any> {
        if (!this.hasAvdm) {
            throw new Error('Machine not connected or AVDM not found');
        }

        const url = `${this.uri}/rd/capture`;
        const headers = {
            'Accept': '/',
            'Connection': 'keep-alive',
            'Content-Type': 'text/xml; charset=UTF-8'
        };

        const body = `<?xml version="1.0"?>
            <PidOptions ver="1.0">
                <Opts fCount="1" fType="2" iCount="0" pCount="0" pgCount="2" format="0" pidVer="2.0" timeout="10000" pTimeout="20000" posh="UNKNOWN" env="P" />
                <CustOpts>
                    <Param name="mantrakey" value="" />
                </CustOpts>
            </PidOptions>`;

        try {
            const response = await fetch(url, {
                method: 'CAPTURE',
                headers: headers,
                body: body
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseText = await response.text();
            return responseText;
        } catch (error) {
            console.error('Capture failed:', error.message);
            throw error;
        }
    }
}
