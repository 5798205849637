import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-tpin',
  templateUrl: './change-tpin.component.html',
  styleUrl: './change-tpin.component.scss'
})
export class ChangeTpinComponent {
  public changePassword: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private toast: ToastrService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.changePassword = new FormGroup({
      "current-tpin": new FormControl('', Validators.required),
      "new-tpin": new FormControl('', Validators.required),
      "confirm-tpin": new FormControl('', [Validators.required, this.confirmTPinValidator.bind(this)])
    });
  }

  private confirmTPinValidator(control: FormControl): { [s: string]: boolean } {
    if (!this.changePassword) {
      return { 'tpinNotMatch': true };
    }
    if (control.value !== this.changePassword.get('new-tpin').value) {
      return { 'tpinNotMatch': true };
    }
    return null;
  }

  change() {
    const data = this.changePassword.value

    if (data['new-tpin'] != data['confirm-tpin']) {
      this.changePassword.get('confirm-tpin').setErrors({
        tpinNotMatch: true
      })
    }

    if (!this.changePassword.valid) {
      for (const control in this.changePassword.controls) {
        if (this.changePassword.controls.hasOwnProperty(control)) {
          this.changePassword.controls[control].markAsTouched();
        }
      }
      return;
    }

    this.api.changeTpin({
      "old_tpin": data['current-tpin'],
      "tpin": data['new-tpin'],
      "tpin_confirmation": data['confirm-tpin']
    }).subscribe({
      next: (res: any) => {
        this.toast.success(res.message);
        // Reset the form values
        this.changePassword.reset();

        // Additionally, you can reset the form's validation state as well
        this.changePassword.markAsPristine();
        this.changePassword.markAsUntouched();
      },
      error: ({ error }: { error: any }) => {
        if (error.message) {
          this.toast.error(error.message);
        }
      },
    });
  }
}
