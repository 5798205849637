import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrl: './funds.component.scss'
})
export class FundsComponent {
  isLoading: boolean = false;
  public fundsForm: FormGroup;

  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public tPinModal: Modal;
  users: Object;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private alert: ToastrService,
  ) { }

  ngAfterViewInit() {
    this.tPinModal = new Modal(this.TPinmodal.nativeElement, {});
  }

  ngOnInit() {
    this.fundsForm = this.fb.group({
      member_id: ['', Validators.required],
      amount: ['', Validators.required],
      narration: ['', Validators.required],
      tpin: ['', Validators.required]
    });

    this.api.users().subscribe({
      next: (response) => {
        this.users = response
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  submitForm() {
    if (this.fundsForm.invalid) {
      Object.values(this.fundsForm.controls).forEach(control => {
        control.markAsTouched();
      });

      return;
    }

    this.api.walletTransfer(this.fundsForm.value).subscribe({
      next: (data: any) => {
        if (!data.status) {
          this.alert.error(data.message)
          return
        }

        this.alert.success(data.message)

        this.fundsForm.reset();
        this.fundsForm.markAsPristine();
        this.fundsForm.markAsUntouched();
      },
      error: ({ error }) => {
        console.log(error)
      }
    })
  }


  confirmTransfer() {
    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.tPinModal.show();
    }
  }

  resetTpinForm() {
    const tpinControl = this.fundsForm.get('tpin');
    if (tpinControl) {
      tpinControl.setValue('');
      tpinControl.markAsUntouched();
      tpinControl.setErrors(null);
    }
  }


}
