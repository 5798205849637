import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { addHeaders, addLatLong } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})

export class PennyDropService {
    private apiUrl = environment.apiURL;

    constructor(private http: HttpClient) {
    }

    public isValid(data: { accountNumber: string; ifsc: string; }) {
        return this.http.post(`${this.apiUrl}/penny-drop`, {
            ...data, ...addLatLong()
        }, addHeaders());
    }

    public isValidTwo(data: { accountNumber: string; ifsc: string; }) {
        return this.http.post(`${this.apiUrl}/accountverification`, {
            ...data, ...addLatLong()
        }, addHeaders());
    }
}