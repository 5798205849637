import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { ApiService } from './api.service';
import { tap, filter } from 'rxjs/operators';
import { Profile } from '@/models/common';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private rolesSubject = new BehaviorSubject<any[]>([]);
  private locationSubject = new BehaviorSubject<Position>(null);
  private banksSubject = new BehaviorSubject<any[]>([]);
  private countriesSubject = new BehaviorSubject<any[]>([]);
  private statesSubject = new BehaviorSubject<any[]>([]);
  private citiesSubject = new BehaviorSubject<any[]>([]);
  private profileSubject = new BehaviorSubject<any>([]);
  private initialized = false;

  constructor(private api: ApiService) {
    this.fetchData();
  }

  initialize(): Observable<any[]> {
    if (!this.initialized) {
      return this.fetchData().pipe(
        tap(() => this.initialized = true)
      );
    } else {
      return new Observable<any[]>();
    }
  }

  private initializeLocation(): Observable<Position> {
    
    return new Observable(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            localStorage.setItem('latitude', position.coords.latitude as undefined as string)
            localStorage.setItem('longitude', position.coords.longitude as undefined as string)
            observer.next(position);
            observer.complete();
          },
          error => {
            observer.error(error);
          }
        );
      } else {
        observer.error('Geolocation is not supported by this browser.');
      }
    });
  }

  private fetchData(): Observable<any[]> {
    return this.api.roles().pipe(
      tap((roles: any[]) => this.rolesSubject.next(roles)),
      tap(() => this.fetchLocation()),
      // tap(() => this.fetchBanks()),
      tap(() => this.fetchCountries()),
      tap(() => this.fetchStates()),
      tap(() => this.fetchCities()),
      tap(() => this.fetchProfile())
    );
  }

  private fetchLocation(): void {
    this.initializeLocation().subscribe((e) => {
      this.locationSubject.next(e);
    });
  }

  private fetchBanks(): void {
    this.api.banks().subscribe((banks: any[]) => {
      this.banksSubject.next(banks);
    });
  }

  private fetchCountries(): void {
    this.api.countries().subscribe((countries: any[]) => {
      this.countriesSubject.next(countries);
    });
  }

  private fetchStates(): void {
    this.api.states().subscribe((states: any[]) => {
      this.statesSubject.next(states);
    });
  }

  private fetchCities(): void {
    this.api.cities().subscribe((cities: any[]) => {
      this.citiesSubject.next(cities);
    });
  }

  public fetchProfile(): void {
    this.api.me().subscribe((me: Profile) => {
      this.profileSubject.next(me);
    });
  }

  public setProfile(user: Profile): void {
    this.profileSubject.next(user);
  }

  getProfile(): Observable<Profile> {
    return this.profileSubject.asObservable();
  }

  getRoles(): Observable<any[]> {
    return this.rolesSubject.asObservable();
  }

  getLocation(): Observable<Position> {
    return this.locationSubject.asObservable();
  }

  getBanks(): Observable<any[]> {
    return this.banksSubject.pipe(
      tap(banks => {
        if (banks.length === 0) {
          this.fetchBanks();
        }
      }),
      filter(banks => banks.length > 0), // Ensure non-empty banks list before emitting
    );
  }

  getCountries(): Observable<any[]> {
    return this.countriesSubject.asObservable();
  }

  getStates(): Observable<any[]> {
    return this.statesSubject.asObservable();
  }

  getCities(): Observable<any[]> {
    return this.citiesSubject.asObservable();
  }
}


interface Position {
  coords: {
    latitude: number;
    longitude: number;
    altitude?: number;
    accuracy?: number;
    altitudeAccuracy?: number;
    heading?: number;
    speed?: number;
  };
  timestamp: number;
}
