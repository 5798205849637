import {Profile} from '@/models/common';
import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {ROLES} from '@/utils/constants';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {DataService} from '@services/data.service';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user: any;
    public menu = MENU;
    public appName: string = environment.appName;
    public menuSidebarCollapsed: any;

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private data: DataService
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.menuSidebarCollapsed = state.menuSidebarCollapsed;
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });

        this.user = this.appService.user;

        this.data.getProfile().subscribe((profile: Profile) => {
            this.menu = MENU.filter((e) => {
                if (e.can) {
                    return e.can.some((i) => profile.data.roles.includes(i));
                }

                return e;
            });
        });
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt mr-3 img',
        path: ['/'],
        permission: ''
    },
    {
        name: 'My Profile',
        iconClasses: 'far fa-id-badge mr-3',
        children: [
            {
                name: 'Profile',
                iconClasses: 'fas fa-user mr-3',
                path: ['/profile'],
                permission: ''
            },
            {
                name: 'Change TPIN',
                iconClasses: 'fas fa-lock mr-3',
                path: ['/change-tpin'],
                permission: ''
            },
            {
                name: 'Change Password',
                iconClasses: 'fas fa-unlock-alt mr-3',
                path: ['/change-password'],
                permission: ''
            },
            {
                name: 'KYC',
                iconClasses: 'fas fa-address-card mr-3 ',
                path: ['/kyc'],
                permission: ''
            },
            {
                name: 'Download Certificate',
                iconClasses: 'mr-3 fas fa-award',
                path: ['/certificate'],
                permission: ''
            },
            {
                name: 'Download ID-Card',
                iconClasses: 'mr-3 fas fa-id-card',
                path: ['/id-card'],
                permission: ''
        
            }
        ]
    },
    {
        name: 'Wallet',
        iconClasses: 'fas fa-money-bill-alt mr-3',
        children: [
           
            {
                name: 'E-Wallet Ledger',
                iconClasses: 'fas fa-book mr-3',
                path: ['/e-wallet-ledger'],
                permission: ''
            },
            {
                name: 'AePS Wallet Ledger',
                iconClasses: 'fas fa-fingerprint mr-3',
                path: ['/aeps-wallet-ledger'],
                permission: ''
            },
            // {
            //     name: 'PG Wallet Ledger',
            //     iconClasses: 'fas fa-fingerprint mr-3',
            //     // path: ['/aeps-wallet-ledger']
            // },
            {
                name: 'Add Fund Request',
                iconClasses: 'fas fa-plus-circle mr-3',
                path: ['/fund-request'],
                permission: ''
            },
            {
                name: 'Fund Request History ',
                iconClasses: 'fas fa-history mr-3',
                path: ['/e-wallet'],
                permission: ''
            }
        ]
    },
    {
        name: 'Give Funds',
        iconClasses: 'fas fa-donate mr-3',
        path: ['/give-fund'],
        can: [ROLES.SUBSIDIARY, ROLES.DISTRIBUTOR]
    },
    {
        name: 'Users',
        iconClasses: 'fas fa-user-friends mr-3',
        can: [ROLES.SUBSIDIARY, ROLES.DISTRIBUTOR],
        children: [
            {
                name: 'Add user',
                iconClasses: 'fas fa-user-plus mr-3',
                path: ['/add-user'],
                permission: ''
            },
            {
                name: 'User List',
                iconClasses: 'fas fa-list-ul mr-3',
                path: ['/user-list'],
                permission: ''
            }
        ]
    },
    {
        name: 'Financial Services',
        iconClasses: 'fas fa-hand-holding-usd mr-3',
        can: [ROLES.RETAILER],
        permission: '',

        children: [
            {
                name: 'AePS',
                iconClasses: 'mr-3 fas fa-fingerprint',
                permission: '',

                children: [
                    {
                        name: 'KYC',
                        iconClasses: 'mr-3 fas fa-id-card-alt',
                        path: ['/aeps/onboarding'],
                        permission: ''
                    },
                    {
                        name: 'Two-Factor Authentication',
                        iconClasses: 'mr-3 fas fa-lock',
                        path: ['/aeps/two-factor'],
                        permission: ''
                    },
                    {
                        name: 'Transactions',
                        iconClasses: 'mr-3 fas fa-exchange-alt',
                        path: ['/aeps/transaction'],
                        permission: ''
                    },
                    {
                        name: 'Aeps Payout',
                        iconClasses: 'mr-3 fas fa-id-card-alt',
                        path: ['/aeps/aeps-payout'],
                        permission: ''
                    },
                    // {
                    //   name: 'Merchant Withdrawal Authentication',
                    //   iconClasses: 'mr-3 fas fa-shield-alt',
                    //   path: ['/aeps/daily-authentication']
                    // },
                   
                ]
            },
            {
                name: 'DMT',
                iconClasses: 'mr-3 fas fa-money-check-alt',
                permission:'',
                children: [
                    {
                        name: 'Lite',
                        iconClasses: 'mr-3 fas fa-bolt',
                        path: ['/dmt'],
                        permission: ''
                    },
                    {
                        name: 'Lite Plus',
                        iconClasses: 'mr-3 fas fa-plus-circle',
                        path: ['/dmt/lite-plus'],
                        permission: ''
                    }
                ]
            },
            {
                name: 'Fund transfer',
                iconClasses: 'mr-3 fas fa-exchange-alt',
                path: ['/fund/transfer'],
                permission: ''
            },
            {
                name: 'Express Pay',
                iconClasses: 'mr-3 fas fa-bolt',
                path: ['/dmt/express'],
                permission: ''
            },
            {
                name: 'Prime Pay',
                iconClasses: 'mr-3 fas fa-crown',
                path: ['/dmt/prime'],
                permission: ''
            },
            {
                name: 'Payment Gateway',
                iconClasses: 'mr-3 fas fa-money-check-alt',
                permission:'',
                children: [
                    {
                        name: 'Payment Gateway',
                        iconClasses: 'mr-3 fas fa-bolt',
                        path: ['/payment-gateway'],
                        permission: ''
                    },
                    {
                        name: 'PG Payout',
                        iconClasses: 'mr-3 fas fa-money-check-alt',
                        path: ['/pg-payout'],
                        permission: ''
                    }
                ]
            },
            // {
            //     name: 'Payment Gateway',
            //     iconClasses: 'mr-3 fas fa-money-bill-alt',
            //     path: ['/payment-gateway'],
            //     permission: 'pg_transaction'
            // }
            // {
            //   name: 'UPI',
            //   iconClasses: 'mr-3 fas fa-qrcode',
            //   children: [
            //     {
            //       name: 'Lite',
            //       iconClasses: 'mr-3 fas fa-bolt',
            //       path: ['/upi']
            //     },
            //     {
            //       name: 'Express',
            //       iconClasses: 'mr-3 fas fa-paper-plane',
            //       path: ['/upi/express']
            //     }
            //   ]
            // }
        ]
    },
    {
        name: 'Services',
        iconClasses: 'mr-3 fas fa-concierge-bell',
        can: [ROLES.RETAILER],
        children: [
            {
                name: 'Bill Pay',
                // iconClasses: 'mr-3 fas fa-credit-card',
                svgMarkup: `<svg width="20" height="23" viewBox="0 0 23 27" stroke="red" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_5304)">
            <path
                d="M14.4205 9.79787C14.8371 9.38723 15.1337 8.87063 15.2782 8.30381C15.4228 7.73699 15.4099 7.14144 15.2409 6.58143C15.0983 6.03456 14.7865 5.54678 14.3499 5.18789C13.9134 4.82899 13.3745 4.61735 12.8104 4.58327C11.7048 4.4715 10.5927 4.43704 9.48225 4.48013C8.6848 4.46785 7.88735 4.46785 7.0899 4.48013C6.81234 4.48013 6.73374 4.39296 6.73743 4.12401C6.74971 2.85824 6.74971 1.59203 6.73743 0.325442C6.73743 0.0712228 6.81112 -0.00125366 7.06902 -2.55512e-05C9.1347 0.0122555 11.2016 -0.0221075 13.2673 0.0245606C14.8397 0.00368808 16.3982 0.321161 17.8371 0.955463C18.7459 1.37171 19.5184 2.03652 20.0654 2.87319C20.6124 3.70985 20.9115 4.6842 20.9282 5.68366C21.0656 6.83153 20.9005 7.99538 20.4493 9.05976C20.0871 9.81101 19.5538 10.4668 18.892 10.9744C18.8452 11.0286 18.7823 11.0663 18.7124 11.082C18.6425 11.0977 18.5694 11.0906 18.5039 11.0616C17.2266 10.4967 15.8856 10.0885 14.5101 9.84577C14.4807 9.84577 14.4622 9.82489 14.4205 9.79787Z"
                fill="#FCB834"></path>
            <path
                d="M22.6329 18.8613C22.6384 17.5335 22.2764 16.2302 21.5869 15.0954C20.8974 13.9607 19.9073 13.0389 18.7262 12.4322C16.7158 11.397 14.4779 10.8823 12.2173 10.9351C10.2732 10.9155 8.32907 10.9204 6.38497 10.9351C6.02636 10.9351 5.94652 10.8454 5.94898 10.4954C5.96371 7.13654 5.94899 3.77772 5.96741 0.424975C5.96741 0.101982 5.906 -0.00487367 5.55844 3.87725e-05C3.83909 0.0196885 2.12299 0.0196885 0.410186 3.87725e-05C0.0650872 3.87725e-05 -0.00121151 0.0982804 1.65961e-05 0.422501C0.011479 4.78475 0.0139124 9.147 0.00736247 13.5092C0.00736247 17.8584 0.00492904 22.2071 1.65961e-05 26.5554C1.65961e-05 26.8944 0.0614008 27.0012 0.431062 27C4.57389 26.9845 8.71712 26.982 12.8608 26.9927C13.8124 26.9997 14.7626 26.9174 15.6989 26.747C17.4315 26.4864 19.0549 25.7408 20.3817 24.5966C21.1713 23.877 21.7849 22.9855 22.1752 21.9911C22.5655 20.9967 22.7221 19.9259 22.6329 18.8613ZM16.6347 20.7527C16.5158 21.4463 16.1684 22.0802 15.6476 22.5535C15.1269 23.0269 14.4628 23.3124 13.761 23.3648C13.2848 23.4428 12.803 23.4819 12.3204 23.4815C10.3235 23.4815 8.3266 23.4814 6.33092 23.49C6.03004 23.49 5.94898 23.4127 5.95021 23.1118C5.96331 20.8922 5.96331 18.6722 5.95021 16.4518C5.95021 16.1448 6.03738 16.0723 6.33336 16.076C7.47182 16.0932 8.61152 16.0834 9.94648 16.0834C10.8884 16.1116 12.0343 16.0354 13.1654 16.1337C13.6709 16.1775 14.1673 16.2946 14.6391 16.4813C15.2098 16.6884 15.706 17.0608 16.0645 17.5509C16.423 18.041 16.6275 18.6268 16.652 19.2335C16.7164 19.7378 16.7106 20.2486 16.6347 20.7514V20.7527Z"
                fill="#F37820"></path>
        </g>
        <defs>
            <clipPath id="clip0_1_5304">
                <rect width="22.6402" height="27" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>`,
                path: ['/bbps'],
                permission: ''
            }
            // {
            //     name: 'Recharge',
            //     iconClasses: 'mr-3 fas fa-battery-full',
            //     path: ['/recharge']
            // }
        ]
    },
    {
        name: 'Reports',
        iconClasses: 'mr-3 fas fa-file-alt', // updated
        children: [
            {
                name: 'DMT',
                iconClasses: 'mr-3 fas fa-money-check-alt',
                permission: '',
                can: ['ROLES.RETAILER'],
                children: [
                    {
                        name: 'Lite',
                        iconClasses: 'mr-3 fas fa-bolt', // updated
                        path: ['/reports/dmt-lite'],
                        permission: ''
                    },
                    {
                        name: 'Lite Plus',
                        iconClasses: 'mr-3 fas fa-plus-circle', // updated
                        path: ['/reports/dmt-lite-plus'],
                        permission: ''
                    },
                    {
                        name: 'Lite Plus - Pending',
                        iconClasses: 'mr-3 fas fa-hourglass-half',
                        path: ['/reports/dmt-lite-plus/refund-pending'],
                        permission: ''
                    }
                ]
            },
            {
                name: 'AePS',
                iconClasses: 'mr-3 fas fa-fingerprint',
                path: ['/reports/aeps'],
                permission: ''
            },
            {
                name: 'Express Pay',
                iconClasses: 'mr-3 fas fa-bolt', // updated
                path: ['/reports/dmt-express'],
                permission: ''
            },
            {
                name: 'Prime Pay',
                iconClasses: 'mr-3 fas fa-crown', // updated
                path: ['/reports/prime-pay'],
                permission: ''
            },
            {
                name: 'Bill Pay',
                svgMarkup: `<svg width="20" height="23" viewBox="0 0 23 27" stroke="red" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_5304)">
                    <path
                        d="M14.4205 9.79787C14.8371 9.38723 15.1337 8.87063 15.2782 8.30381C15.4228 7.73699 15.4099 7.14144 15.2409 6.58143C15.0983 6.03456 14.7865 5.54678 14.3499 5.18789C13.9134 4.82899 13.3745 4.61735 12.8104 4.58327C11.7048 4.4715 10.5927 4.43704 9.48225 4.48013C8.6848 4.46785 7.88735 4.46785 7.0899 4.48013C6.81234 4.48013 6.73374 4.39296 6.73743 4.12401C6.74971 2.85824 6.74971 1.59203 6.73743 0.325442C6.73743 0.0712228 6.81112 -0.00125366 7.06902 -2.55512e-05C9.1347 0.0122555 11.2016 -0.0221075 13.2673 0.0245606C14.8397 0.00368808 16.3982 0.321161 17.8371 0.955463C18.7459 1.37171 19.5184 2.03652 20.0654 2.87319C20.6124 3.70985 20.9115 4.6842 20.9282 5.68366C21.0656 6.83153 20.9005 7.99538 20.4493 9.05976C20.0871 9.81101 19.5538 10.4668 18.892 10.9744C18.8452 11.0286 18.7823 11.0663 18.7124 11.082C18.6425 11.0977 18.5694 11.0906 18.5039 11.0616C17.2266 10.4967 15.8856 10.0885 14.5101 9.84577C14.4807 9.84577 14.4622 9.82489 14.4205 9.79787Z"
                        fill="#FCB834"></path>
                    <path
                        d="M22.6329 18.8613C22.6384 17.5335 22.2764 16.2302 21.5869 15.0954C20.8974 13.9607 19.9073 13.0389 18.7262 12.4322C16.7158 11.397 14.4779 10.8823 12.2173 10.9351C10.2732 10.9155 8.32907 10.9204 6.38497 10.9351C6.02636 10.9351 5.94652 10.8454 5.94898 10.4954C5.96371 7.13654 5.94899 3.77772 5.96741 0.424975C5.96741 0.101982 5.906 -0.00487367 5.55844 3.87725e-05C3.83909 0.0196885 2.12299 0.0196885 0.410186 3.87725e-05C0.0650872 3.87725e-05 -0.00121151 0.0982804 1.65961e-05 0.422501C0.011479 4.78475 0.0139124 9.147 0.00736247 13.5092C0.00736247 17.8584 0.00492904 22.2071 1.65961e-05 26.5554C1.65961e-05 26.8944 0.0614008 27.0012 0.431062 27C4.57389 26.9845 8.71712 26.982 12.8608 26.9927C13.8124 26.9997 14.7626 26.9174 15.6989 26.747C17.4315 26.4864 19.0549 25.7408 20.3817 24.5966C21.1713 23.877 21.7849 22.9855 22.1752 21.9911C22.5655 20.9967 22.7221 19.9259 22.6329 18.8613ZM16.6347 20.7527C16.5158 21.4463 16.1684 22.0802 15.6476 22.5535C15.1269 23.0269 14.4628 23.3124 13.761 23.3648C13.2848 23.4428 12.803 23.4819 12.3204 23.4815C10.3235 23.4815 8.3266 23.4814 6.33092 23.49C6.03004 23.49 5.94898 23.4127 5.95021 23.1118C5.96331 20.8922 5.96331 18.6722 5.95021 16.4518C5.95021 16.1448 6.03738 16.0723 6.33336 16.076C7.47182 16.0932 8.61152 16.0834 9.94648 16.0834C10.8884 16.1116 12.0343 16.0354 13.1654 16.1337C13.6709 16.1775 14.1673 16.2946 14.6391 16.4813C15.2098 16.6884 15.706 17.0608 16.0645 17.5509C16.423 18.041 16.6275 18.6268 16.652 19.2335C16.7164 19.7378 16.7106 20.2486 16.6347 20.7514V20.7527Z"
                        fill="#F37820"></path>
                </g>
                <defs>
                    <clipPath id="clip0_1_5304">
                        <rect width="22.6402" height="27" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>`,
                path: ['/reports/bbps'],
                permission: ''
            },
            {
                name: 'PG Report',
                iconClasses: 'mr-3 fas fa-money-bill-alt', // updated
                path: ['/reports/pg-report'],
                permission: 'pg-report'
            },
            {
                name: 'Fund Transfer', 
                iconClasses: 'mr-3 fas fa-exchange-alt', // updated
                path: ['/reports/transfer'],
                permission: ''
            },
            // {
            //     name: 'PG Payout', 
            //     iconClasses: 'mr-3 fas fa-exchange-alt', // updated
            //     path: ['/reports/pg-payout-report'],
            //     permission: ''
            // },
            // {
            //     name: 'AePS Payout', 
            //     iconClasses: 'mr-3 fas fa-exchange-alt', // updated
            //     path: ['/reports/aeps-payout-report'],
            //     permission: ''
            // }
            // {
            //   name: 'UPI Lite',
            //   iconClasses: 'mr-3 fas fa-mobile-alt', // updated
            //   path: ['/reports/upi-lite']
            // },
            // {
            //   name: 'UPI Express',
            //   iconClasses: 'mr-3 fas fa-rocket', // updated
            //   path: ['/reports/upi-express']
            // }
        ]
    },
   
];
