<a (click)="handleMainMenuAction()" class="nav-link" [ngClass]="{active: isMainActive || isOneOfChildrenActive}" >
  <i class="nav-icon {{ menuItem.iconClasses }}"></i>
  <p>{{ menuItem.name }}
    <i *ngIf="isExpandable && menuItem.children.length > 0" class="right fas fa-angle-right"
      [@rotate]="isMenuExtended"></i>
  </p>
</a>

<ul class="nav nav-treeview" *ngIf="menuItem.children && menuItem.children.length > 0" [@openClose]="isMenuExtended">
  <li class="nav-item" *ngFor="let item of menuItem.children; let i = index">
    <a  [routerLinkActive]="'active'" class="nav-link" [ngClass]="{active: isSubMenuActive(i)}"
      (click)="handleSubMainMenuAction(i,item.path)" style="display: flex;" *ngIf="this.profile.includes(item.permission)|| item.permission == ''">
      <i class="nav-icon {{ item.iconClasses }}" *ngIf="item.iconClasses"></i>
      <div [innerHTML]="getSanitizedSvg(item.svgMarkup)" *ngIf="item.svgMarkup" style="width: 20%;" class="ml-1"></div>

      <p>{{ item.name }}
        <i class="fas fa-angle-right right" [@rotate]="isSubMenuExpanded(i)" *ngIf="item.children"></i>
      </p>
    </a>

    <ul class="nav nav-treeview" *ngIf="item.children && item.children.length > 0" [@openClose]="isSubMenuExpanded(i)">
      <li class="nav-item" *ngFor="let sub of item.children; let j = index">
        <a [routerLink]="sub.path" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}" class="nav-link" (click)="redirectLoad(sub.path)" *ngIf="this.profile.includes(sub.permission )|| sub.permission == ''">
          <i class="nav-icon {{ sub.iconClasses }}"></i>
          <p style=" width: 70%;
          display: -webkit-inline-box;">{{ sub.name }}
          </p>
            
        </a>
      </li>
    </ul>
  </li>
</ul>
