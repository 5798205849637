<div class="row">
  <div class="col-md-7 mx-auto">
    <form [formGroup]="registerForm" (ngSubmit)="registerByAuth()">
      <div class="card card-outline card-primary">
        <!-- <app-brand-header /> -->
        <div class="card-body">
          <div class="profile-img" style="text-align: center;">
            <img src="./assets/img/logo.png" alt="logo" class="w-25">
          </div>
          <p class="login-box-msg">Register a new membership</p>

          <div #stepper class="bs-stepper">
            <div class="bs-stepper-header">
              <div class="step" data-target="#test-l-1">
                <button type="button" class="btn step-trigger">
                  <span class="bs-stepper-circle">1</span>
                  <span class="bs-stepper-label">Personal Information</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-2">
                <button type="button" class="btn step-trigger">
                  <span class="bs-stepper-circle">2</span>
                  <span class="bs-stepper-label">Contact Information</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-3">
                <button type="button" class="btn step-trigger">
                  <span class="bs-stepper-circle">3</span>
                  <span class="bs-stepper-label">Communication Information</span>
                </button>
              </div>
            </div>
            <div class="bs-stepper-content">
              <div id="test-l-1" class="content">

                <div class="row">

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="type">What do you Want *</label><span class=" font-weight-bold ml-2"></span>
                      <select formControlName="type" class="form-select"
                        [ngClass]="{'is-invalid': registerForm.get('type').invalid && (registerForm.get('type').dirty || registerForm.get('type').touched)}">
                        <option value="" selected>Select Type</option>
                        <option *ngFor="let role of roles | keyvalue" [value]="role.key">{{ role.value }}</option>
                      </select>
                      <div
                        *ngIf="registerForm.get('type').invalid && (registerForm.get('type').dirty || registerForm.get('type').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('type').errors.required"><strong>Type is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first_name">First Name *</label> <span
                        class=" font-weight-bold ml-2"></span>
                      <input type="text" class="form-control" id="first_name" formControlName="first_name"
                        [ngClass]="{'is-invalid': registerForm.get('first_name').invalid && (registerForm.get('first_name').dirty || registerForm.get('first_name').touched)}">
                      <div
                        *ngIf="registerForm.get('first_name').invalid && (registerForm.get('first_name').dirty || registerForm.get('first_name').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('first_name').errors.required"><strong>First Name is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last_name">Last Name *</label> <span class=" font-weight-bold ml-2"></span>
                      <input type="text" class="form-control" id="last_name" formControlName="last_name"
                        [ngClass]="{'is-invalid': registerForm.get('last_name').invalid && (registerForm.get('last_name').dirty || registerForm.get('last_name').touched)}">
                      <div
                        *ngIf="registerForm.get('last_name').invalid && (registerForm.get('last_name').dirty || registerForm.get('last_name').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('last_name').errors.required"><strong>Last Name is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="gender">Gender *</label><span class=" font-weight-bold ml-2"></span>
                      <select formControlName="gender" class="form-select"
                        [ngClass]="{'is-invalid': registerForm.get('gender').invalid && (registerForm.get('gender').dirty || registerForm.get('gender').touched)}">
                        <option value="" selected>Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                      <div
                        *ngIf="registerForm.get('gender').invalid && (registerForm.get('gender').dirty || registerForm.get('gender').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('gender').errors.required"><strong>Gender is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="dob">DOB *</label><span class=" font-weight-bold ml-2"></span>
                      <input type="date" formControlName="dob" class="form-control" id="dob" placeholder="Date of birth">
                      <div
                        *ngIf="registerForm.get('dob').invalid && (registerForm.get('dob').dirty || registerForm.get('dob').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('dob').errors.required"><strong>Gender is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <a [routerLink]="['/login']" class="text-center">
                      I already have a membership</a>
                  </div>
                  <div class="col-md-6 m-auto mt-3">
                    <pf-button [loading]="isAuthLoading" type="submit" (click)="stepper1Instance.next()">
                      Next
                    </pf-button>
                  </div>
                </div>
              </div>
              <div id="test-l-2" class="content">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Email Id: *</label><span class=" font-weight-bold ml-2"></span>
                      <input formControlName="email" type="email" class="form-control" id="email" placeholder="Email"
                        [ngClass]="{'is-invalid': registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)}">
                      <div
                        *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('email').errors.required"><strong>Email is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="mobile">Mobile No: *</label><span class=" font-weight-bold ml-2"></span>
                      <input formControlName="mobile" type="tel" class="form-control" id="mobile" placeholder="Mobile"
                        [ngClass]="{'is-invalid': registerForm.get('mobile').invalid && (registerForm.get('mobile').dirty || registerForm.get('mobile').touched)}">
                      <div
                        *ngIf="registerForm.get('mobile').invalid && (registerForm.get('mobile').dirty || registerForm.get('mobile').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('mobile').errors.required"><strong>Mobile is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="aadhar">Aadhar No: *</label><span class=" font-weight-bold ml-2"></span>
                      <input formControlName="aadhar" type="tel" class="form-control" id="aadhar"
                        placeholder="Aadhar Number"
                        [ngClass]="{'is-invalid': registerForm.get('aadhar').invalid && (registerForm.get('aadhar').dirty || registerForm.get('aadhar').touched)}">
                      <div
                        *ngIf="registerForm.get('aadhar').invalid && (registerForm.get('aadhar').dirty || registerForm.get('aadhar').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('aadhar').errors.required"><strong>Aadhar is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pan">Pan No: *</label><span class=" font-weight-bold ml-2"></span>
                      <input formControlName="pan" type="text" class="form-control" id="pan" placeholder="PAN"
                        [ngClass]="{'is-invalid': registerForm.get('pan').invalid && (registerForm.get('pan').dirty || registerForm.get('pan').touched)}">
                      <div
                        *ngIf="registerForm.get('pan').invalid && (registerForm.get('pan').dirty || registerForm.get('pan').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('pan').errors.required"><strong>Pan is required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <a [routerLink]="['/login']" class="text-center">
                      I already have a membership</a>
                  </div>
                  <div class="col-md-6  m-auto mt-3">
                    <div class="row">
                      <div class="col-md-6">
                        <pf-button [loading]="isAuthLoading" type="submit" (click)="stepper1Instance.previous()">
                          Previous
                        </pf-button>
                      </div>
                      <div class="col-md-6">
                        <pf-button [loading]="isAuthLoading" type="submit" (click)="stepper1Instance.next()">
                          Next
                        </pf-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="test-l-3" class="content">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="country">Country *</label><span class=" font-weight-bold ml-2"></span>
                      <select formControlName="country" class="form-select"
                        [ngClass]="{'is-invalid': registerForm.get('country').invalid && (registerForm.get('country').dirty || registerForm.get('country').touched)}">
                        <option value="" selected>Select Country</option>
                        <option *ngFor="let country of countries" [value]="country.id">{{
                          country.name }}</option>
                      </select>
                      <div
                        *ngIf="registerForm.get('country').invalid && (registerForm.get('country').dirty || registerForm.get('country').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('country').errors.required"><strong>Country is
                            required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="state">State *</label><span class=" font-weight-bold ml-2"></span>
                      <select formControlName="state" class="form-select" (change)="stateChanged($event)"
                        [ngClass]="{'is-invalid': registerForm.get('state').invalid && (registerForm.get('state').dirty || registerForm.get('state').touched)}">
                        <option value="" selected>Select State</option>
                        <option *ngFor="let state of states" [value]="state.id">{{ state.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="registerForm.get('state').invalid && (registerForm.get('state').dirty || registerForm.get('state').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('state').errors.required"><strong>State is
                            required.</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="city">City *</label><span class=" font-weight-bold ml-2"></span>
                      <select formControlName="city" class="form-select"
                        [ngClass]="{'is-invalid': registerForm.get('city').invalid && (registerForm.get('city').dirty || registerForm.get('city').touched)}">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="registerForm.get('city').invalid && (registerForm.get('city').dirty || registerForm.get('city').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('city').errors.required"><strong>City is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="pincode">Pin Code</label>
                      <input type="text" class="form-control" id="pincode"
                        [ngClass]="{'is-invalid': registerForm.get('pincode').invalid && (registerForm.get('pincode').dirty || registerForm.get('pincode').touched)}"
                        formControlName="pincode">
                      <div
                        *ngIf="registerForm.get('pincode').invalid && (registerForm.get('pincode').dirty || registerForm.get('pincode').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('pincode').errors.required"><strong>Pin is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="address">Address</label>
                      <textarea class="form-control" id="address" rows="3"
                        [ngClass]="{'is-invalid': registerForm.get('address').invalid && (registerForm.get('address').dirty || registerForm.get('address').touched)}"
                        formControlName="address"></textarea>
                      <div
                        *ngIf="registerForm.get('address').invalid && (registerForm.get('address').dirty || registerForm.get('address').touched)"
                        class="invalid-feedback">
                        <div *ngIf="registerForm.get('address').errors.required"><strong>Address is
                            required.</strong></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 d-flex justify-content-between">
                    <div class="icheck-primary">
                      <input type="checkbox" id="agreeTerms" name="terms" value="agree" required />
                      <label for="agreeTerms">
                        I agree to the <a>terms and conditions</a>
                      </label>
                    </div>

                    <a [routerLink]="['/login']" class="text-center">
                      I already have a membership</a>
                  </div>
                  <!-- /.col -->
                  <div class="col-5  m-auto mt-3">
                    <div class="row">
                      <div class="col-md-6">
                        <pf-button [loading]="isAuthLoading" type="submit" (click)="stepper1Instance.previous()">
                          Previous
                        </pf-button>
                      </div>
                      <div class="col-md-6">
                        <pf-button [loading]="isAuthLoading" type="submit" (click)="registerByAuth()">
                          Register
                        </pf-button>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>

  </div>
</div>