import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasOnboardedGuard {
  constructor(private apiService: ApiService, private router: Router) { }

  canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    return this.apiService.statuskyc().pipe(
      map((hasOnboarded: { success: boolean }) => {
        if (!hasOnboarded || !hasOnboarded.success) {
          return this.router.createUrlTree(['/kyc']);
        }
        return true;
      })
    );
  }
}
