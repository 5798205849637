<app-header></app-header>
<app-sub-header></app-sub-header>
<!-- <marquee direction="left" style="font-family: 'Rajdhani', sans-serif; color: #FFFFFF; background-color: #003366; padding: 10px;" scrolldelay="5" behavior="scroll">Notice: All services will be temporarily unavailable due to scheduled maintenance from 10:30 PM to 12:30 AM. We apologize for any inconvenience.</marquee> -->
<app-menu-sidebar class="sidebar-light-info"></app-menu-sidebar>

<div class="content-wrapper">
    <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
