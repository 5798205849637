<pf-dropdown [hideArrow]="true" class="user-menu">
    <div slot="button">
        <a class="nav-link">
            <pf-image
                [src]="user?.picture"
                fallbackSrc="assets/img/default-profile.png"
                class="user-image-small"
                width="40"
                height="40"
                alt="User Image"
                rounded="true"
            />
        </a>
    </div>
    <div slot="menu">
        <li class="user-header bg-primary">
            <pf-image
                [src]="user?.picture"
                fallbackSrc="assets/img/default-profile.png"
                class="user-image-big"
                alt="User Image"
                width="90"
                height="90"
                rounded="true"
            />

            <p>
                <span>{{ user?.email }}</span>
            </p>
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right">
                Sign out
            </a>
        </li>
    </div>
</pf-dropdown>
