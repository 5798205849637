import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Bank, City, Profile, State } from '@/models/common';
import { BankDetails } from '@/models/toshani';
import { SafeXPayService } from '@services/payout/safe-xpay.service';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { isAllowed } from '@/utils/helpers';
import { PERMISSIONS } from '@/utils/constants';
import { finalize } from 'rxjs';
import { ApiService } from '@services/api.service';
import { PennyDropService } from '@services/toshani/penny-drop.service';
import { CanaraService } from '@services/toshani/canara.service';

@Component({
  selector: 'app-upi',
  templateUrl: './upi.component.html',
  styleUrl: './upi.component.scss'
})
export class UPIComponent {
  public isLoading: boolean = false;
  public isLoggedIn: boolean = false;
  public isOTPNeeded: boolean = false;
  public mobileNumber: number;
  public accountForm: FormGroup;
  public customerVerificationForm: FormGroup;
  public customerRegistrationForm: FormGroup;
  public beneficiaryForm: FormGroup;
  public states: State[];
  public cities: City[];
  public addBeneficiaryModal: Modal;
  public beneficiary: any;
  searchQuery: string = '';
  public min: any;
  public max: any;
  @ViewChild('TPinmodal', { static: false })
  public TPinmodal: ElementRef<HTMLDivElement>;
  public tPinModal: Modal;
  @ViewChild('modal', { static: false })
  public modal: ElementRef<HTMLDivElement>;
  public remitter: { name: any; number: any; limit: { remaining: any; }; };
  public beneficiaries: any;

  get filteredBeneficiaries() {
    return this.beneficiaries ? this.beneficiaries.filter(beneficiary =>
      beneficiary.account_holder_name.toLowerCase().includes(this.searchQuery.toLowerCase())
    ) : [];
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private alert: ToastrService,
    private data: DataService,
    private api: ApiService,
    private payout: SafeXPayService,
    private penny: PennyDropService,
    private canaraService: CanaraService
  ) { }

  ngOnInit() {

    this.data.getProfile().subscribe((profile: Profile) => {
      if (!isAllowed(PERMISSIONS.SAFEXPAY_PAYOUT, profile.data.permission)) {
        this.alert.info('You are not allowed to access this resource.');
        this.router.navigate(['/']);
      }
    });

    this.data.getStates().subscribe((states: State[]) => {
      this.states = states;
    });

    this.data.getCities().subscribe((cities: City[]) => {
      this.cities = cities;
    });

    this.customerVerificationForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]
    });

    this.customerRegistrationForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/)]],
      name: ['', Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      mobileNumber: [{ value: '', disabled: true }, Validators.required],
      beneficiaryId: [{ value: '', disabled: true }, Validators.required],
      account_holder_name: [{ value: '', disabled: true }, Validators.required],
      account_holder_mobile_number: [{ value: '', disabled: true }, Validators.required],
      bank_name: [{ value: '', disabled: true }, Validators.required],
      account_number: [{ value: '', disabled: true }, Validators.required],
      ifsc_code: [{ value: '', disabled: true }, Validators.required],
      amount: ['', [Validators.required, Validators.min(0), Validators.max(200000)]],
      tpin: ['', Validators.required]
    });

    this.beneficiaryForm = this.formBuilder.group({
      mobileNumber: [''],
      accountHolderName: ['', [Validators.required, Validators.maxLength(255)]],
      accountHolderMobileNumber: ['', Validators.required],
      emailId: ['', Validators.required],
      bankName: ['', Validators.required],
      accountType: ['', Validators.required],
      accountNumber: ['', Validators.required],
      ifscCode: ['', Validators.required]
    });

    this.api.getMinMax('payout', 'safexpay').subscribe({
      next: (response: any) => {
        this.min = response.data.min_start_range;
        this.max = response.data.max_end_range;

        this.accountForm.controls['amount'].setValidators([
          Validators.required,
          Validators.min(this.min),
          Validators.max(this.max),
        ]);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  ngAfterViewInit() {
    this.addBeneficiaryModal = new Modal(this.modal.nativeElement, {})
    this.tPinModal = new Modal(this.TPinmodal.nativeElement, {})
  }

  verification() {
    const form = this.customerVerificationForm;
    if (!form.valid) {
      this.alert.error('Invalid Form');
      return;
    }

    const mobileNumber = form.value.mobileNumber;
    this.isLoading = true;

    this.payout.customer(mobileNumber).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: (response: any) => {
        this.alert.success(response.message);
        if (!response.status) {
          this.customerRegistrationForm.patchValue({ mobileNumber });
          this.isOTPNeeded = true;
          return;
        }

        this.isLoggedIn = true;
        this.beneficiaryForm.patchValue({ mobileNumber });

        this.loadBeneficiaries(form.value.mobileNumber);
      },
      error: (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  register() {
    const form = this.customerRegistrationForm;
    if (!form.valid) {
      this.alert.error('Invalid Form');
      return;
    }

    this.isLoading = true;

    this.payout.verification(form.getRawValue()).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.isOTPNeeded = true;
        this.isLoggedIn = true;
        this.alert.success(response.message);

        this.beneficiaryForm.patchValue({ mobileNumber: form.getRawValue().mobileNumber });

        this.loadBeneficiaries(form.getRawValue().mobileNumber);
      },
      error: (error: any) => {
        // this.alert.error(error.message)
        this.isLoading = false;
      }
    });
  }


  addBeneficiary() {
    const form = this.beneficiaryForm;
    if (!form.valid) {
      this.beneficiaryForm.markAsDirty();
      this.alert.error('Invalid Form');
      return;
    }

    this.isLoading = true;
    this.payout.beneficiary(this.beneficiaryForm.getRawValue()).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.loadBeneficiaries(form.value.mobileNumber)
        this.addBeneficiaryModal.hide();
        this.alert.success(response.message);
        this.restBeneficiaryForm()
      },
      error: ({ error }) => {
        this.isLoading = false;
        console.log(error)
        this.restBeneficiaryForm()
      }
    })
  }

  restBeneficiaryForm() {
    this.beneficiaryForm.patchValue({
      accountHolderName: '',
      accountHolderMobileNumber: '',
      emailId: '',
      bankName: '',
      accountType: '',
      accountNumber: '',
      ifscCode: '',
    })

    // this.beneficiaryForm.get('ifscCode').enable();
    // this.beneficiaryForm.get('accountNumber').enable();
    // this.beneficiaryForm.get('accountHolderName').enable();

    this.beneficiaryForm.markAsPristine();
    this.beneficiaryForm.markAsUntouched();
  }

  details(beneficiary: any) {
    this.beneficiary = beneficiary;
    this.accountForm.patchValue({
      mobileNumber: beneficiary.mobile_number,
      beneficiaryId: beneficiary.beneficiary_id,
      account_holder_name: beneficiary.account_holder_name,
      account_holder_mobile_number: beneficiary.account_holder_mobile_number,
      bank_name: beneficiary.bank_name,
      account_number: beneficiary.account_number,
      ifsc_code: beneficiary.ifsc_code,
      tpin: '',
      amount: ''
    });
  }

  delete(beneficiary: any) {
    this.isLoading = true;
    this.payout.delete(beneficiary.beneficiary_id).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message);
        }

        const form = this.customerVerificationForm;
        this.loadBeneficiaries(form.value.mobileNumber);
        this.alert.success(response.message);
        this.isLoading = false
      },
      error: (error) => {
        this.isLoading = false
      }
    });
  }

  private loadBeneficiaries(mobileNumber: number) {
    this.payout.beneficiaries(mobileNumber).subscribe({
      next: (response: any) => {

        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.beneficiaries = response.data;
      },
      error: (error) => {
        console.log(error, 'error');
      }
    });
  }


  transfer() {
    if (!this.accountForm.valid || this.isLoading) {
      for (const control in this.accountForm.controls) {
        if (this.accountForm.controls.hasOwnProperty(control)) {
          this.accountForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    const data = this.accountForm.getRawValue();
    this.isLoading = true;
    this.canaraService.neft_payment(data).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.tPinModal.hide();
        this.resetForms()

        if (!response.status) {
          this.alert.error(response.message);
          return
        }

        this.alert.success(response.message);
      },
      error: ({ error }) => {
        this.isLoading = false;
        this.tPinModal.hide();
        this.resetForms()
      }
    })
  }


  confirmTransfer() {

    if (!this.beneficiary && this.accountForm.value.amount == 0) {
      this.alert.error('Invalid Form values');
      return
    }

    const allowed = confirm('Are you sure you want to transfer?');
    if (allowed) {
      this.openTpinModal();
    }
  }

  openTpinModal() {
    if (this.tPinModal) {
      this.tPinModal.show();
    }
  }

  resetForms(onlyTpin = false) {
    const tpinControl = this.accountForm.get('tpin');
    if (tpinControl) {
      tpinControl.setValue('');
      tpinControl.markAsUntouched();
      tpinControl.setErrors(null);
    }

    if (!onlyTpin) {

      this.accountForm.patchValue({
        beneficiaryId: '',
        account_holder_name: '',
        account_holder_mobile_number: '',
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        amount: ''
      })

      this.beneficiary = [];

      this.beneficiaryForm.markAsPristine();
      this.beneficiaryForm.markAsUntouched();
    }
  }

  checkValidation() {
    const step = ["ifscCode", "accountNumber", "accountHolderName"];

    const form = this.beneficiaryForm;

    let allControlsValid = true;
    for (let i = 0; i < step.length; i++) {
      const control = step[i];
      const formControl = form.get(control);
      if (formControl && !formControl.valid && formControl.status != 'DISABLED') {
        console.log(formControl);
        allControlsValid = false;
        formControl.markAsTouched();
      }
    }

    if (allControlsValid) {
      return true;
    }
    return false;
  }

  verifyAccountNumber() {
    if (!this.checkValidation()) {
      this.beneficiaryForm.get('ifscCode').markAsTouched();
      this.beneficiaryForm.get('accountNumber').markAsTouched();
      this.beneficiaryForm.get('accountHolderName').markAsTouched();
      return;
    }

    this.penny.isValidTwo({
      "accountNumber": this.beneficiaryForm.value.accountNumber,
      "ifsc": this.beneficiaryForm.value.ifscCode,
    }).subscribe({
      next: (response: any) => {
        if (!response.status) {
          this.alert.error(response.message);
          return;
        }

        this.beneficiaryForm.patchValue({
          accountHolderName: response.data.registered_name,
          ifscCode: response.data.ifsc,
          accountNumber: response.data.account_number,
        });

        // this.beneficiaryForm.get('ifscCode').disable();
        // this.beneficiaryForm.get('accountNumber').disable();
        // this.beneficiaryForm.get('accountHolderName').disable();

        this.alert.success(response.message);
      },
      error: ({ error }) => {
        this.isLoading = false;
      }
    });
  }
}
