import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { sleep } from '@/utils/helpers';
import { ApiService } from './api.service';
import { firstValueFrom } from 'rxjs';
import { DataService } from './data.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user: any = null;
  
  constructor(private router: Router, private toastr: ToastrService, private api: ApiService, private data: DataService) { }

  loginByAuth({ username, password }) {
    const that = this;
    return new Promise(async function (resolve, reject) {
      try {
        const response: any = await firstValueFrom(that.api.login(username, password));

        switch (response.status) {
          case 1:
            that.toastr.success(response.message, 'OTP sent successfully');
            break;
          case 2:
            that.toastr.success(response.message, 'Please wait, OTP is being sent');
            break;
          case true:
            that.successLogin(response.data.user, response.data.authorisation, response.message)
            that.data.setProfile(response)
            break;
          default:
            that.toastr.error(response.message, 'Invalid Request', {
              timeOut: 10000,
              closeButton: true
            });

        }

        resolve(response)

      } catch (error) {
        if (error.error) {
          switch (error.status) {
            case 0:
              that.toastr.error(error.message, 'Invalid Request', {
                timeOut: 10000,
                closeButton: true
              });

            case 1:
              that.toastr.error(error.message, '', {
                timeOut: 100000,
                closeButton: true
              });

            default:
              that.toastr.error(error.error.message || error.message, 'Invalid Request', {
                timeOut: 100000,
                closeButton: true
              });
          }
          reject(error)
        } else {
          reject(error)
        }

      }
    });
  }

  public successLogin(user: any, authorisation: any, message: string) {
    localStorage.setItem('user', JSON.stringify({ name: user.name, email: user.email, isKyc: user.is_kyc, mobile: user.mobile, member_id: user.wallet?.member_id}))
    localStorage.setItem('token', authorisation.token)
    this.toastr.success(message);
    this.getProfile().then((e) => this.router.navigate(['/']));
  }

  validateOTP({ username, otp }) {
    return this.api.validate(username, otp)
  }

  registerByAuth(user: any) {
    try {
      return this.api.register(user)

    } catch ({ error }) {
      this.toastr.error(error.message, '', {
        timeOut: 10000,
        closeButton: true
      });
    }
  }

  async getProfile() {
    try {
      const user = await getAuthStatus();
      if (user) {
        this.user = user;
      } else {
        this.logout();
      }
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  logout() {
    $('.modal').modal('hide');
    this.api.logout().subscribe()
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.user = null;
    this.router.navigate(['/login']);
  }
}

export const getAuthStatus = () => {
  return new Promise(async (res, rej) => {
    await sleep(500);
    try {
      let user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        return res(user);
      }
      return res(undefined);
    } catch (error) {
      return res(undefined);
    }
  });
};
