<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Reports</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">UPI Report</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body table-responsive">
            <table id="upi-table" datatable [dtOptions]="dtOptions" class="table row-border hover"></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div #modal class="modal fade" id="transaction-details" tabindex="-1" role="dialog"
  aria-labelledby="transaction-details" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Transaction Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Unique Transaction Reference</th>
              <th>Title of Transaction</th>
              <th>Transaction Amount</th>
              <th>Reference ID</th>
              <th>Transaction Status</th>
              <th>Transaction ID</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let transaction of transactionDetails">
              <td></td>
              <td>{{transaction.utr}}</td>
              <td>{{transaction.title}}</td>
              <td>{{transaction.amount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
              <td>{{transaction.ref_id}}</td>
              <td>{{transaction.status}}</td>
              <td>{{transaction.txn_id}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>